import { Box, Typography } from "@mui/material";

export const CustomError = ({ error, sx, align = "center" }) => {
    const topMapping = {
        top: "0%",
        semiTop: "25%",
        center: "50%",
        semiBottom: "75%",
        bottom: "100%",
    };

    const topValue = topMapping[align] || "50%";

    return (
        <Box
            sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                position: "relative",
                flex: 1,
                ...sx,
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: topValue,
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                Ошибка при загрузке данных. Причина:
                <Typography
                    sx={{ fontSize: "20px", color: "red", lineHeight: "24px" }}
                >
                    {error}
                </Typography>
            </Box>
        </Box>
    );
};
