import { Box } from '@mui/material';
import HeadHunterLogo from '../../assets/logos/headhunter.png';
import TapHrIcon from '../../assets/logos/tapHrGreen.svg';
import { SOURCE_CAREER_SITE, SOURCE_HEADHUNTER, SOURCE_HEADHUNTER_EXTENSION, SOURCE_TAPHR } from '../../utils/manageSources';

const SourceIcon = ({ source }) => {
    return (
        <>
            {(source === SOURCE_HEADHUNTER || source === SOURCE_HEADHUNTER_EXTENSION) && (
                <Box component="img" src={HeadHunterLogo} alt="HeadHunter" sx={{width: 24, height: 24}} loading='lazy'/>
            )}
            {(source === SOURCE_TAPHR || source === SOURCE_CAREER_SITE) && (
                <Box component="img" src={TapHrIcon} alt="Another Source" sx={{width: 24, height: 24}} loading='lazy'/>
            )}
        </>
    );
};

export default SourceIcon;