import React, {useState, memo} from "react";
import { Autocomplete, TextField } from "@mui/material";
import { ArrowUpIcon, CloseIcon } from "../svgComponents";
import { useInfiniteQuery } from "@tanstack/react-query";
import useDebounce from "../../hooks/useDebounce";
import { CheckBox } from "../tapHrStyledComponents";


const CustomCheckboxAutoComplete = memo(({values = [], setValues = () => {}, queryKeyStartingValue, fetchFunction = () => {}, maxHeight = 320, additional }) => {
    const {debouncedValue : debouncedSearchQuery, value: searchQuery, setValue : setSearchQuery, isDebouncing} = useDebounce("", 300, queryKeyStartingValue);
    const [open, setOpen] = useState(false);

    // fetch data
    const {
        data,
        isFetching,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery([queryKeyStartingValue, debouncedSearchQuery], async ({ pageParam = 1 }) => fetchFunction({searchQuery: debouncedSearchQuery, page: pageParam, perPage: 10, additional}), {
        getNextPageParam: (lastPage) => {
            const currentPage = parseInt(lastPage?.meta?.current_page, 10);
            const totalPages = Math.ceil(lastPage?.meta?.total / lastPage?.meta?.per_page);
            return currentPage < totalPages ? currentPage + 1 : undefined;
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const flatData = (data && data.pages && data.pages.flatMap((page) => page?.data || page)) || [];

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const bottom =
            listboxNode.scrollHeight - listboxNode.scrollTop <=
            listboxNode.clientHeight + 10;
        if (bottom && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };

    // check if new Values is in the values list
    const handleNewValue = (newValue) => {
        if (newValue) {
            const newValueIndex = values.findIndex((value) => value.id === newValue.id);
            if (newValueIndex === -1) {
                setValues([...values, { name : newValue.name, id : newValue.id }]);
            } else {
                // Обновляем порядок после удаления
                const newStages = values.filter((value) => value.id !== newValue.id);
                setValues(newStages);
            }
        }
    }

    // check if value is in the values list
    const isSelected = (id) => {
        return values.findIndex((value) => value.id === id) !== -1;
    }

    // logic is that we need to reload the list, only if we are not fetching the next page, all other cases such as searching and debouncing should trigger the loading
    const isOptionsLoading = (isFetching && !isFetchingNextPage) || isDebouncing;

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            noOptionsText={"Нет доступных вариантов"}
            disableClearable={true}
            inputValue={searchQuery}
            onInputChange={(e, newInputValue) => {
                if (e && e.type === "change") {
                    // Only update searchQuery when typing, not when selecting an option
                    setSearchQuery(newInputValue);
                }
            }}
            options={isOptionsLoading ? [] : flatData}
            getOptionLabel={(option) => option?.name || "Без названия"}
            filterOptions={(options) => options}
            loading={isOptionsLoading || isFetchingNextPage}
            popupIcon={
                <ArrowUpIcon
                    sx={{ width: 24, height: 24, transform: "rotate(180deg)" }}
                    svgSx={{
                        width: 10,
                        height: 4,
                        color: searchQuery
                            ? "rgba(21, 24, 19, 1)"
                            : "rgba(173, 181, 189, 1)",
                    }}
                />
            }
            renderOption={(props, option) => {
                const { key, ...rest } = props; // Extract `key` from `props`
                return (<li
                    key = {option?.id || key}
                    {...rest}
                    onClick={(event) => {
                        event.preventDefault(); // Prevent default selection behavior
                        handleNewValue(option); // Add or remove the option
                        setOpen(true); // Keep the popup open
                    }}
                    style={{
                        ...props.style,
                        height: "52px",
                        maxHeight: "52px",
                        padding: "16px",
                        display: "flex",
                        gap: "8px",
                    }}
                >
                    <CheckBox checked={isSelected(option?.id)} onClick={() => {}} />
                    {option?.name || "Без названия"}{" "}
                </li>)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={"Выберите из списка"}
                    variant="outlined"
                    sx={{
                        height: "56px",
                        borderRadius: "10px",
                        backgroundColor: "#FFFFFF",
                        color: "#151813",
                        border: "1px solid #E2E4E7",
                        boxShadow: "0px 1px 2px 0px rgba(10, 13, 18, 0.05)",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "18px",

                        "&:hover": {
                            borderColor: "#599D15", // Hover border color
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        "&.MuiFormControl-root:focus-within": {
                            border: "2px solid rgba(205, 221, 198, 1)",
                        },
                    }}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {searchQuery && (
                                        <CloseIcon
                                            onClick={() => {
                                                setSearchQuery("");
                                                setOpen(true);
                                            }}
                                            sx={{
                                                cursor: "pointer",
                                                width: 24,
                                                height: 24,
                                            }}
                                            svgSx={{
                                                width: 10,
                                                height: 10,
                                                color: "rgba(0,0,0,0.5)",
                                                hoverColor: "rgba(0,0,0,0.75)",
                                                pressedColor: "rgba(0,0,0,1)",
                                            }}
                                        />
                                    )}
                                    {params.InputProps?.endAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
            slotProps={{
                listbox: {
                    style: { maxHeight: maxHeight, overflow: "auto" },
                    onScroll: handleScroll,
                },
            }}
        />
    );
})

export default CustomCheckboxAutoComplete;