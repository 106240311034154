import React, { useState } from "react";
import {
    Button,
    Popover,
    TextField,
    Box,
    Typography,
    Chip,
} from "@mui/material";
import CustomInput from "../styledComponents/CustomInput";

const ResultsComment = ({
    open,
    anchorEl,
    handleClose,
    handleSave,
    sx,
    scoreInfo,
}) => {
    const [comment, setComment] = useState("");

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            sx={{
                "& .MuiPaper-root": {
                    width: "350px",
                    boxShadow: `
                            0px 5px 50px 0px rgba(0, 0, 0, 0.1)
                        `,
                    marginTop: "8px",
                },
            }}
        >
            <Box sx={{ p: 2 }}>
                <Chip
                    label={
                        <Typography
                            variant="body1"
                            component="div"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "12px",
                                fontWeight: 500,
                                color: scoreInfo?.primaryColor,
                            }}
                        >
                            <Box
                                sx={{
                                    width: "6px",
                                    height: "6px",
                                    borderRadius: "50%",
                                    backgroundColor: scoreInfo?.primaryColor,
                                    display: "inline-block",
                                    mr: "6px",
                                }}
                            />
                            Вы поставили оценку {scoreInfo?.text}
                        </Typography>
                    }
                    sx={{
                        backgroundColor: scoreInfo?.secondaryColor,
                        borderRadius: "16px",
                        padding: "2px 0px",
                        height: "fit-content",
                        "& span": {
                            padding: "0 6px",
                        },
                    }}
                />

                <CustomInput
                    label="Комментарий"
                    value={comment}
                    onChange={setComment}
                    placeholder="Введите комментарий (необязательно)"
                    multiline
                    rows={4}
                    sx={{
                        mt: "16px",
                        inputBorder: "1px solid rgba(226, 228, 231, 1)",
                    }}
                />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                        gap: "10px",
                    }}
                >
                    <Box
                        component="button"
                        onClick={handleClose}
                        sx={{
                            width: "138px",
                            color: "rgba(46, 64, 82, 1)",
                            border: "none",
                            backgroundColor: "white",
                            height: "44px",
                            borderRadius: "8px",
                            padding: "12px 20px",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            height: 46,
                            cursor: "pointer",
                        }}
                    >
                        Отменить
                    </Box>
                    <Box
                        component="button"
                        onClick={() => handleSave(comment)}
                        sx={{
                            flex: 1,
                            height: 46,
                            padding: "13px 21px",
                            width: "fit-content",
                            backgroundColor: "rgba(46, 64, 82, 1)",
                            borderRadius: "8px",
                            fontSize: "16px",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            textWrap: "nowrap",
                            "&:hover": {
                                backgroundColor: "rgba(29, 40, 52, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(21, 29, 37, 1)",
                            },
                        }}
                    >
                        Сохранить оценку
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};

export default ResultsComment;
