import { useQuery } from "@tanstack/react-query";
import { getProcesses } from "../services/processesApi";

const useProcesses = ({enabled, queryKey}) => {
    const {
        data,
        isLoading,
        isError,
        error,
    } = useQuery(queryKey, () => getProcesses(), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        enabled: enabled,
    });

    return {
        processesData : data,
        isProcessesLoading : isLoading,
        isProcessesError : isError,
        processesError : error,
    };
};

export default useProcesses;
