import { memo, useCallback } from "react";
import { Box, Divider } from "@mui/material";
import { TIcon } from "../../svgComponents";
import QuestionPreview from "./QuestionPreview";
import CustomSelect from "../../styledComponents/CustomSelect";

const words_count = [{name: 25, id: 25}, {name: 50, id: 50}, {name: 100, id: 100}, {name: 250, id: 250}, {name: 500, id: 500}, {name: 1000, id: 1000}];

const TextComponent = memo(({ types, data, isActive, order, handleQuestion, handleQuestionField }) => {

    const handleQuestionType = useCallback((value) => {
        handleQuestion(order - 1, value);
    }, [handleQuestion, order]);

    const handleWordsLimit = useCallback((value) => {
        handleQuestionField(order - 1, "words_limit", value);
    }, [handleQuestionField, order]);

    return isActive ? (
        <Box sx={{ display: "flex", gap: "16px"}}>
            {/* Тип ответа */}
            <CustomSelect 
                label="Тип ответа"
                value={data.type} 
                onChange={handleQuestionType}
                options={types.names}
                icons={types.icons}
            />
            <CustomSelect 
                label="Количество слов"
                value={data.words_limit || words_count[2].id} 
                onChange={handleWordsLimit}
                options={words_count}
            />
        </Box>
    ) : (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "16px",
            }}
        >
            <QuestionPreview
                title={`Вопрос №${order}`}
                subtitle={data.text}
            />
            <Divider
                sx={{
                    width: "100%",
                    backgroundColor: "rgba(241, 242, 243, 1)",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    flexWrap: "wrap",
                }}
            >
                <QuestionPreview
                    title="Тип ответа"
                    icon={
                        <TIcon
                            sx={{ width: 20, height: 20 }}
                            svgSx={{
                                width: 20,
                                height: 20,
                                color: "rgba(173, 181, 189, 1)",
                            }}
                        />
                    }
                    subtitle={"Текстовый ответ"}
                    sx={{ width: "314px" }}
                />
                <QuestionPreview
                    title="Количество слов"
                    subtitle={data.words_limit}
                />
            </Box>
        </Box>
    );
});

export default TextComponent;
