import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

// update profile that gets data formData that has fields name, phone, email all strings and avatar image file or null
export const updateProfile = async (data) => {
    try{
        const response = await axiosClassic.post("/api/profile/edit?_method=PUT", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}


export const getOflineAdresses = async () => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/offices`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

export const createOflineAdress = async (body) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/offices`, body);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

export const updateOflineAdress = async ({id, city_name, address, name}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const body = {
        ...(city_name && {city_name}),
        ...(address && {address}),
        ...(name && {name}),
    }
    
    try {
        const response = await axiosClassic.put(`/api/company/${companyId}/offices/${id}`, body);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

export const deleteOflineAdress = async (id) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(`/api/company/${companyId}/offices/${id}`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

export const getTariff = async () => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/tariffs`);
        return responseWrapper(response);
    }
    catch (error) {
        throw error;
    }
}

export const askMoreTariff = async ({ managers, whatsapp_messages }) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const body = {
        ...(managers && { managers: 1 }),
        ...(whatsapp_messages && { whatsapp_messages: 1 }),
    };

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/tariffs/ask-more/`, body);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

