import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

export const fetchResults = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/results/${vacancyId}`);
        return responseWrapper(response);
    } catch (error) {
        throw error; // Re-throw the error for the caller to handle
    }
}

export const changeRecruiterScore = async ({resultId, recruiter_score}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/recruiter-score/${resultId}`, { ...(recruiter_score && { recruiter_score }) });
        const data = responseWrapper(response);
        return { updatedScore: data?.data, resultId };
    } catch (error) {
        throw error; // Re-throw the error for the caller to handle
    }
}