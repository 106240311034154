import React, { useState } from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CheckBox } from "../tapHrStyledComponents";
import { LockIcon, MenuIcon } from "../svgComponents";
import CustomInput from "../styledComponents/CustomInput";

const FourthStep = ({ handleBack, vacancyInfo, externalAdditionalFields, id, createMutation }) => {

    const handleCreate = () => {
        if (id) {
            const step1Old = vacancyInfo.step1;

            // Transform the old structure into the new structure
            const formattedStep1 = {
                name: step1Old.name,
                description: step1Old.text,
                salary_from: step1Old.salaryFrom
                    ? Number(step1Old.salaryFrom)
                    : null,
                salary_to: step1Old.salaryTo ? Number(step1Old.salaryTo) : null,
                salary_gross: Boolean(step1Old.taxType),
                salary_currency: step1Old.currency,
                city_name: step1Old.city?.name,
                hh_area_id: step1Old.city?.id,
                recruiter_id: localStorage.getItem("manager_id"),
                department_id: step1Old.department?.id,
                category_ids: [step1Old.category?.id],
                experience: step1Old.experience,
                schedule: step1Old.workSchedule,
                employment: step1Old.employmentType,
                accept_temporary: step1Old.accept_temporary,
                is_completed: step1Old.is_completed,
                // Optionally include external fields if they exist and the flag is true
                ...(externalAdditionalFields.external_source && { external_source: externalAdditionalFields.external_source }),
                ...(externalAdditionalFields.external_id && { external_id: externalAdditionalFields.external_id }),
            };
            createMutation.mutate({
                ...vacancyInfo,
                step1: formattedStep1,
                step4: {
                    ...vacancyInfo.step4,
                    is_completed: true,
                },
            });
        }
    };

    const [vacancyExpanded, setVacancyExpanded] = useState(true);
    const [stageExpanded, setStageExpanded] = useState(true);

    const handleVacancyAccordionChange = (event) => {
        setVacancyExpanded((prev) => !prev);
    };

    const handleStageAccordionChange = (event) => {
        setStageExpanded((prev) => !prev);
    };

    const returnExperienceText = (key) => {
        switch (key) {
            case "noExperience":
                return "Нет опыта";
            case "between1And3":
                return "От 1 года до 3 лет";
            case "between3And6":
                return "От 3 до 6 лет";
            case "moreThan6":
                return "Более 6 лет";
            default:
                return "Нет опыта";
        }
    };

    const returnEmploymentTypeText = (key) => {
        switch (key) {
            case "full":
                return "Полная занятость";
            case "part":
                return "Частичная занятость";
            case "project":
                return "Проектная работа";
            case "volunteer":
                return "Волонтерство";
            case "probation":
                return "Стажировка";
            default:
                return "Полная занятость";
        }
    };

    const returnScheduleText = (key) => {
        switch (key) {
            case "fullDay":
                return "Полный день";
            case "shift":
                return "Сменный график";
            case "flexible":
                return "Гибкий график";
            case "remote":
                return "Удаленная работа";
            case "flyInFlyOut":
                return "Вахтовый метод";
            default:
                return "Полный день";
        }
    };

    return (
        <>
            {/* Заголовок Шага 3 */}
            {/* Accordion для "Посмотреть вакансию" */}
            <Accordion
                expanded={vacancyExpanded}
                onChange={handleVacancyAccordionChange}
                sx={{
                    boxShadow: "none",
                    borderTopLeftRadius: "10px !important",
                    borderTopRightRadius: "10px !important",
                    backgroundColor: vacancyExpanded ? "#fff" : "transparent",
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: "#E8EFE4",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomLeftRadius: vacancyExpanded
                            ? "0px"
                            : "10px", // Border radius when closed
                        borderBottomRightRadius: vacancyExpanded
                            ? "0px"
                            : "10px", // Border radius when closed
                        minHeight: "56px", // Фиксируем минимальную высоту
                        height: "56px", // Фиксируем общую высоту
                        boxShadow: "none", // Убираем тень
                        "&.Mui-expanded": {
                            minHeight: "56px", // Фиксируем высоту при открытии
                            height: "56px",
                        },
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 600,
                            color: "#599D15",
                            fontSize: "18px",
                        }}
                    >
                        Посмотреть вакансию
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "24px" }}>
                    <Box
                        sx={{
                            display: "grid",
                            gap: 2,
                            gridTemplateColumns: "356px 356px",
                            mb: 3,
                        }}
                    >
                        <CustomInput
                            readOnly={true}
                            label="Название"
                            value={vacancyInfo["step1"]?.name}
                        />
                        <CustomInput
                            readOnly={true}
                            label="Город"
                            value={
                                vacancyInfo["step1"]?.city
                                    ? vacancyInfo["step1"]?.city.name
                                    : null
                            }
                        />
                        <CustomInput
                            readOnly={true}
                            label="Отдел"
                            value={
                                vacancyInfo["step1"]?.department
                                    ? vacancyInfo["step1"]?.department.name
                                    : null
                            }
                        />
                        <CustomInput
                            readOnly={true}
                            label="Категория"
                            value={
                                vacancyInfo["step1"]?.category
                                    ? vacancyInfo["step1"]?.category.name
                                    : null
                            }
                        />
                        <CustomInput
                            readOnly={true}
                            label="Заработная плата от"
                            value={vacancyInfo["step1"]?.salaryFrom}
                        />
                        <CustomInput
                            readOnly={true}
                            label="Заработная плата до"
                            value={vacancyInfo["step1"]?.salaryTo}
                        />
                        <CustomInput
                            readOnly={true}
                            label="Налогообложение"
                            value={
                                vacancyInfo["step1"]?.taxType
                                    ? "До вычета налогов"
                                    : "На руки"
                            }
                        />
                        <CustomInput
                            readOnly={true}
                            label="Валюта"
                            value={vacancyInfo["step1"]?.currency}
                        />
                        <CustomInput
                            readOnly={true}
                            label="Опыт работы"
                            value={returnExperienceText(
                                vacancyInfo["step1"]?.experience
                            )}
                        />
                        <CustomInput
                            readOnly={true}
                            label="Тип занятости"
                            value={returnEmploymentTypeText(
                                vacancyInfo["step1"]?.employmentType
                            )}
                        />
                        <CustomInput
                            readOnly={true}
                            label="График работы"
                            value={returnScheduleText(
                                vacancyInfo["step1"]?.workSchedule
                            )}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                py: 2,
                            }}
                        >
                            <CheckBox
                                checked={vacancyInfo["step1"]?.accept_temporary}
                                noHover={true}
                            />
                            <Typography
                                variant="body1"
                                sx={{
                                    ml: 1,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#151813",
                                }}
                            >
                                Возможно временное оформление
                            </Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ fontWeight: 500 }}>Описание</Typography>
                    <Paper
                        elevation={0}
                        sx={{
                            mt: 1,
                            padding: "8px 14px",
                            borderRadius: "4px",
                            border: "1px solid #D2D6DA",
                            backgroundColor: "#FFFFFF",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                "& ul": {
                                    listStyleType: "disc",
                                    marginLeft: "20px",
                                    width: "calc(100% - 20px)",
                                },
                                "& li": {
                                    width: "100%",
                                    whiteSpace:
                                        "normal" /* Allow text wrapping */,
                                    wordWrap:
                                        "break-word" /* Break long words onto a new line */,
                                    wordBreak:
                                        "break-word" /* Ensure words wrap even if very long */,
                                },
                            }}
                        >
                            {/* HTML-содержимое description */}
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "#151813",
                                    padding: 0,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: vacancyInfo["step1"]?.text,
                                }}
                            />
                        </Box>
                    </Paper>
                </AccordionDetails>
            </Accordion>

            {/* Accordion для "Посмотреть этапы" */}
            <Accordion
                expanded={stageExpanded}
                onChange={handleStageAccordionChange}
                sx={{
                    mt: 3,
                    boxShadow: "none",
                    borderTopLeftRadius: "10px !important",
                    borderTopRightRadius: "10px !important",
                    backgroundColor: stageExpanded ? "#fff" : "transparent",
                    "&::before": {
                        display: "none", // Prevent any unwanted line or pseudo-element
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        backgroundColor: "#E8EFE4",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomLeftRadius: stageExpanded ? "0px" : "10px", // Border radius when closed
                        borderBottomRightRadius: stageExpanded ? "0px" : "10px", // Border radius when closed
                        minHeight: "56px", // Фиксируем минимальную высоту
                        height: "56px", // Фиксируем общую высоту
                        boxShadow: "none", // Убираем тень
                        "&.Mui-expanded": {
                            minHeight: "56px", // Фиксируем высоту при открытии
                            height: "56px",
                        },
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 600,
                            color: "#599D15",
                            fontSize: "18px",
                        }}
                    >
                        Посмотреть этапы
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: "0px",
                    }}
                >
                    <Box
                        key={"first"}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #E0E0E0",
                            padding: "16px 24px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                            }}
                        >
                            <LockIcon
                                sx={{ width: "40px", height: "40px" }}
                                svgSx={{
                                    width: "24px",
                                    height: "20px",
                                    color: "rgba(21, 24, 19, 1)",
                                }}
                            />
                            <Typography>Новый</Typography>
                        </Box>
                    </Box>
                    {vacancyInfo["step3"].stages &&
                        vacancyInfo["step3"]?.stages?.map((stage, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #E0E0E0",
                                    padding: "16px 24px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <MenuIcon
                                        sx={{ width: "40px", height: "40px" }}
                                        svgSx={{
                                            width: "16px",
                                            height: "14px",
                                            color: "rgba(21, 24, 19, 1)",
                                        }}
                                    />
                                    <Typography>{stage.name}</Typography>
                                </Box>
                            </Box>
                        ))}
                    <Box
                        key={"last"}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #E0E0E0",
                            padding: "16px 24px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                            }}
                        >
                            <LockIcon
                                sx={{ width: "40px", height: "40px" }}
                                svgSx={{
                                    width: "24px",
                                    height: "20px",
                                    color: "rgba(21, 24, 19, 1)",
                                }}
                            />
                            <Typography>Принял оффер</Typography>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>

            {/* Кнопки "Назад" и "Создать" */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 3,
                    gap: 2,
                }}
            >
                <Button
                    variant="outlined"
                    sx={{
                        marginRight: 2,
                        height: "56px",
                        boxShadow: "none",
                        borderRadius: "8px",
                        width: "202px",
                        color: "#599D15",
                        borderColor: "#599D15",
                        fontSize: "16px",
                        fontWeight: "400",
                    }}
                    onClick={handleBack}
                >
                    Назад
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#599D15",
                        color: "white",
                        height: "56px",
                        boxShadow: "none",
                        borderRadius: "8px",
                        width: "202px",
                        fontSize: "16px",
                        fontWeight: "400",
                    }}
                    onClick={handleCreate} // Здесь обработчик для создания вакансии
                >
                    Создать
                </Button>
            </Box>
        </>
    );
};

export default FourthStep;
