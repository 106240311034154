import { useState } from 'react';
import { Input } from '@mui/material';

const CreateDepartment = ({setNewChildInput, handleNew, paddingLeft, parentId}) => {
    console.log("CreateDepartment being rendered");
    const [departmentName, setDepartmentName] = useState("");

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && departmentName.trim()) {
            handleNew(departmentName.trim(), parentId); // Pass the new department name to the parent component
          setDepartmentName(""); // Clear the input after submission
        }
    };

    const handleBlur = () => {
        setNewChildInput(false); // Close the input when blurred
        setDepartmentName(""); // Clear the input
    };

    return (
        <Input
            value={departmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
            onKeyDown={handleKeyDown} // Handle the Enter key
            onBlur={handleBlur} // Handle the Blur event
            placeholder="Название отдела"
            autoFocus = {true}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                pl: paddingLeft,
                height: "72px",
                "&::after": {
                    borderBottom: "2px solid rgba(89, 157, 21, 1)", // Default green border
                },
            }}
        />
    )
};

export default CreateDepartment;