import { Box } from "@mui/system";
import MainInformationUi from "../candidateInfo/candidateMainInfoUi";
import ResumeUi from "../candidateInfo/candidateResumeUi";
import RatingsUi from "../candidateInfo/candidateRatingUi";
import StageInformation from "../candidateInfo/StageInformationUI";
import CommentSectionUi from "../candidateInfo/candidateCommentsUi";
import FileDownloadComponent from "../candidateInfo/FileDownloadComponent";
import { useRef } from "react";
import { getRelevanceDescription } from "../../utils/aiRelevance";

const LeftContent = ({
    candidate,
}) => {
    const resumeSxRef = useRef({
        display: "flex",
        paddingBottom: "24px",
        gap: "16px",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
    });
    const menuSxRef = useRef({ padding: "24px", backgroundColor: "#FFFFFF" });
    const resumes = candidate?.resumes ?? [];
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "586px",
                gap: "16px",
                flex: 1,
            }}
        >
            <MainInformationUi
                sx={menuSxRef.current}
                phone={candidate?.phone}
                email={candidate?.email}
                desired_position={candidate?.desired_position}
                salary={candidate?.salary}
            />
            <ResumeUi sx={resumeSxRef.current} resumes={resumes} />
        </Box>
    );
};

const RightContent = ({
    vacancyId,
    currNegotiationIdx,
    candidate,
    queryKey,
    handleRefusal,
    onStageChange,
    negotiationId,
    currStageId,
    stages,
}) => {
    const negotiations = candidate?.negotiations ?? [];
    const negotiation = negotiations?.[currNegotiationIdx];

    const rel_score = Math.floor(negotiation?.full_relevance_description?.percentage_match || negotiation?.relevance_rate || 0);
    const rel_description = getRelevanceDescription(
        rel_score,
        negotiation?.full_relevance_description?.criterion_relevance_details,
        negotiation?.relevance_description,
    );
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: 440,
                gap: "16px",
                flex: 1,
            }}
        >
            <RatingsUi
                key={candidate.id}
                score={rel_score}
                description={rel_description}
            />
            <StageInformation
                negotiation={negotiation}
                vacancyId={vacancyId}
                handleRefusal={handleRefusal}
                onStageChange={onStageChange}
                negotiationId={negotiationId}
                currStageId={currStageId}
                stages={stages}
            />
            <FileDownloadComponent firstName={candidate?.first_name} last_name={candidate?.last_name} fileUrl={negotiation?.cv_path} id={candidate?.id} sourceId={negotiation?.source_id}/>
            <CommentSectionUi
                candidateId={candidate.id}
                comments={candidate?.comments}
                queryKey={[queryKey, candidate.id]}
            />
        </Box>
    );
};

const CandidateInfoHolder = ({candidate, queryKey, currNegotiationIdx, handleRefusal, onStageChange, negotiationId, currStageId, stages, vacancyId
 }) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: "16px",
                padding: "16px",
            }}
        >
            <LeftContent
                candidate={candidate}
            />
            <RightContent
                currNegotiationIdx={currNegotiationIdx}
                candidate={candidate}
                queryKey={queryKey}
                handleRefusal={handleRefusal}
                onStageChange={onStageChange}
                negotiationId={negotiationId}
                currStageId={currStageId}
                stages={stages}
                vacancyId={+vacancyId}
            />
        </Box>
    )
}

export default CandidateInfoHolder;