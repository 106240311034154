import { Box, Typography } from "@mui/material";
import { CustomError } from "../styledComponents/CustomErrors";
import { CircularLoading } from "../tapHrStyledComponents";
import CustomAnimatedProgressCircle from "../styledComponents/CustomAnimatedProgressCircle";
import { useMutation } from "@tanstack/react-query";
import { askMoreTariff } from "../../services/profileSettings";

const TargetInformation = ({
    title,
    subtitle,
    count,
    total,
    field,
    askMore,
}) => {
    const handleAskMore = () => {
        askMore({ [field]: true });
        alert("Запрос отправлен");
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                padding: 3,
                flex: 1,
                backgroundColor: "white",
                borderRadius: "8px",
            }}
        >
            <Box>
                <Typography
                    sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        color: "rgba(21, 24, 19, 1)",
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        color: "rgba(116, 116, 115, 1)",
                    }}
                >
                    {subtitle}
                </Typography>
            </Box>
            <CustomAnimatedProgressCircle
                target={count}
                limit={total}
                outerSize={137}
                innerSize={119}
            >
                <Typography
                    sx={{
                        fontSize: "24px",
                        lineHeight: "28px",
                        color: "rgba(46, 64, 82, 1)",
                        fontWeight: 700,
                        mb: "2px",
                    }}
                >
                    {count}
                </Typography>
                <Typography
                    sx={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontWeight: 500,
                        color: "rgba(160, 174, 192, 1)",
                    }}
                >
                    из {total}
                </Typography>
            </CustomAnimatedProgressCircle>
            <Box
                component="button"
                sx={{
                    backgroundColor: "transparent",
                    border: "1px solid rgba(46, 64, 82, 1)",
                    borderRadius: "8px",
                    padding: "13px 21px",
                    color: "rgba(46, 64, 82, 1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "rgba(242, 243, 243, 1)" },
                    "&:active": { backgroundColor: "rgba(226, 228, 231, 1)" },
                    width: "fit-content",
                    margin: "0 auto",
                    fontSize: "16px",
                    lineHeight: "20px",
                }}
                onClick={handleAskMore}
            >
                "Запросить больше"
            </Box>
        </Box>
    );
};

const TariffComponent = ({ data, isLoading, isError, error }) => {
    const askMoreMutation = useMutation(askMoreTariff);

    return (
        <Box
            sx={{
                minHeight: "331px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
            }}
        >
            {isLoading ? (
                <CircularLoading />
            ) : isError ? (
                <CustomError error={error?.message || "Неопознанная ошибка"} />
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        gap: "16px",
                    }}
                >
                    <TargetInformation
                        title="Количество пользователей"
                        subtitle="Количество пользователей"
                        count={data?.managers_count}
                        total={data?.managers_limit}
                        field="managers"
                        askMore={askMoreMutation.mutate}
                    />
                    <TargetInformation
                        title="Количество сообщений Whatsapp bot"
                        subtitle="Количество сообщений Whatsapp bot"
                        count={data?.whatsapp_messages_count}
                        total={data?.whatsapp_messages_limit}
                        field="whatsapp_messages"
                        askMore={askMoreMutation.mutate}
                    />
                </Box>
            )}
        </Box>
    );
};

export default TariffComponent;
