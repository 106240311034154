import React, { memo, useState } from "react";
import { Typography, Box } from "@mui/material";
import {
    EducationUi,
    ExperienceUi,
    KeySkillsUi,
    LanguagesUi,
    SupplementaryUi,
} from "./candidateInfoUi";
import {
    getSourceName,
    SOURCE_CAREER_SITE,
    SOURCE_HEADHUNTER,
    SOURCE_HEADHUNTER_EXTENSION,
    SOURCE_LINKEDIN_EXTENSION,
    SOURCE_TAPHR,
} from "../../utils/manageSources";
import { CustomTab, CustomTabs } from "../styledComponents/CustomTabs";

const ResumeFromSource = ({
    source_id,
    sx,
    resume,
    candidate,
    headHunterBasedSource,
    tapHrBasedSource,
    render = { isFiles: true },
}) => {

    return (
        <Box sx={sx}>
            {headHunterBasedSource && (
                <SupplementaryUi
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                    dateOfBirth={resume?.source_body?.birth_date}
                    citizenship={resume?.source_body?.citizenship}
                    city={resume?.source_body?.area?.name}
                    employments={resume?.source_body?.employments}
                    schedules={resume?.source_body?.schedules}
                    businessTrips={resume?.source_body?.business_trip_readiness}
                    render={{ isSubDividers: true }}
                    source_id={source_id}
                />
            )}

            {tapHrBasedSource && (
                <SupplementaryUi
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                    dateOfBirth={candidate?.birth_date}
                    citizenship={resume?.source_body?.citizenship}
                    city={candidate?.city_name}
                    employments={
                        resume?.source_body?.employments ||
                        resume?.source_body?.employment
                    }
                    schedules={
                        resume?.source_body?.schedules ||
                        resume?.source_body?.schedule
                    }
                    businessTrips={resume?.source_body?.business_trip_readiness}
                    render={{ isSubDividers: true }}
                    source_id={source_id}
                />
            )}

            {headHunterBasedSource && (
                <EducationUi
                    source_id={source_id}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                    education={resume?.source_body?.education?.primary}
                    render={{ isDivider: false }}
                />
            )}

            {tapHrBasedSource && (
                <EducationUi
                    source_id={source_id}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                    education={resume?.source_body?.education?.items}
                    render={{ isDivider: false }}
                />
            )}
            <ExperienceUi
                sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
                experience={resume?.source_body?.experience}
            />

            {headHunterBasedSource && (
                <KeySkillsUi
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                    skills={resume?.source_body?.skill_set}
                />
            )}

            {tapHrBasedSource && (
                <KeySkillsUi
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                    skills={resume?.source_body?.skills}
                />
            )}
            {headHunterBasedSource && (
                <LanguagesUi
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                    languages={resume?.source_body?.language}
                />
            )}

            {tapHrBasedSource && (
                <LanguagesUi
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                    languages={resume?.source_body?.language}
                />
            )}
            {/* {render.isFiles && <FilesUi onClick={() => window.open(resume?.cv_path, '_blank')} sx={{ display: "flex", flexDirection: "column", gap: "24px" }} file={resume?.cv_path} render ={{isDivider : false}} />} */}
        </Box>
    );
};

/**
 * @param {Object} sx - styles for the resume block, if you want to prevent rerender pass sx inside useRef
 * @param {Object} resumes - resume data
 * @returns {JSX.Element} - resume block
 */
const ResumeUi = memo(({ sx, resumes, candidate }) => {
    console.log("resume being render");
    const [currentTab, setCurrentTab] = useState(0);
    const sourceCounter = {};

    const source_id = resumes[currentTab]?.source_id;
    const headHunterBasedSource =
        source_id === SOURCE_HEADHUNTER ||
        source_id === SOURCE_HEADHUNTER_EXTENSION;
    const tapHrBasedSource =
        source_id === SOURCE_TAPHR ||
        source_id === SOURCE_CAREER_SITE ||
        source_id === SOURCE_LINKEDIN_EXTENSION;

    const handleTabChange = (event, value) => {
        setCurrentTab(value);
    };

    if (!resumes || !resumes.length) {
        return (
            <Box
                sx={{
                    ...sx,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        color: "rgba(121, 128, 136, 1)",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    Нет действующих резюме для отоброжения
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={sx}>
            <Box>
                <CustomTabs
                    currentTab={currentTab}
                    handleTabChange={handleTabChange}
                >
                    {resumes.map((source, index) => {
                        return (
                            <CustomTab
                                key={index}
                                index={index}
                                currentTab={currentTab}
                                text={getSourceName(
                                    sourceCounter,
                                    source.source_id
                                )}
                            />
                        );
                    })}
                </CustomTabs>
            </Box>
            {headHunterBasedSource && (
                <ResumeFromSource
                    source_id={resumes[currentTab].source_id}
                    sx={{
                        padding: "24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "32px",
                        mb: 3,
                    }}
                    resume={resumes[currentTab]}
                    headHunterBasedSource={headHunterBasedSource}
                    tapHrBasedSource={tapHrBasedSource}
                />
            )}
            {tapHrBasedSource && (
                <ResumeFromSource
                    candidate={candidate}
                    source_id={resumes[currentTab].source_id}
                    sx={{
                        padding: "24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "32px",
                        mb: 3,
                    }}
                    resume={resumes[currentTab]}
                    headHunterBasedSource={headHunterBasedSource}
                    tapHrBasedSource={tapHrBasedSource}
                />
            )}
        </Box>
    );
});

export default ResumeUi;
