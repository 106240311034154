import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

export const fetchProfessionalRoles = async () => {
    try {
        const response = await axiosClassic.get("/api/hh/professional-roles");
        return responseWrapper(response)
    } catch (error) {
        return error;
    }
};


export const fetchLanguages = async () => {
    try {
        const response = await axiosClassic.get("https://api.hh.ru/languages");
        return responseWrapper(response)
    } catch (error) {
        return error;
    }
};

export const publishToHH = async ({ publishData, company_id, vacancy_id }) => {
    try {
        const response = await axiosClassic.post(`api/company/${company_id}/vacancies/${vacancy_id}/hh`, publishData);
        return responseWrapper(response)
    } catch (error) {
        throw error; // Если нужно обрабатывать ошибку на уровне вызова
    }
};

export const closeHHNotify = async () => {
    try {
        const response = await axiosClassic.post(`api/hh/notified`)
        return responseWrapper(response)
    } catch (error) {
        throw error;
    }
}

