import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

// fetch data
export const getCareerSiteData = async () => {
    const companyId = localStorage.getItem('company_id');
    if (!companyId) throw new Error('ID компании не найден');

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/settings/career-site`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

/**
 * Update career site data
 * @param {Object} data - data to update, name, description and color_scheme
 * @returns {Promise<Object>} - updated data
 */
export const updateCareerSiteData = async (data) => {
    const companyId = localStorage.getItem('company_id');
    if (!companyId) throw new Error('ID компании не найден');

    try {
        const response = await axiosClassic.put(`/api/company/${companyId}/settings/career-site`, data);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

/**
 * Upload career site background image
 * @param {File} file - file to upload
 * @returns {Promise<Object>} - updated data
 */
export const uploadCareerSiteBackgroundImage = async (file) => {
    const companyId = localStorage.getItem('company_id');
    if (!companyId) throw new Error('ID компании не найден');

    if (!file) throw new Error('Файл не найден');

    const formData = new FormData();
    formData.append('background_image', file);

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/settings/career-site/background-image`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

/**
 * Update career site social links
 * @param {Object} data - data with one field social_links that contains array of objects with fields social_network, url, icon
 * @returns {Promise<Object>} - updated data
 */
export const updateCareerSiteSocialLinks = async (data) => {
    const companyId = localStorage.getItem('company_id');
    if (!companyId) throw new Error('ID компании не найден');

    try {
        const response = await axiosClassic.put(`/api/company/${companyId}/settings/career-site/social-links`, data);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}


// delete concrete social link
export const deleteCareerSiteSocialLinks = async (data) => {
    const companyId = localStorage.getItem('company_id');
    if (!companyId) throw new Error('ID компании не найден');

    try {
        const response = await axiosClassic.delete(`/api/company/${companyId}/settings/career-site/social-links` , {
            data
        });
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}
