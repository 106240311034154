import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { CircularLoading } from "../tapHrStyledComponents";
import { CustomError } from "../styledComponents/CustomErrors";
import { useRef, useState } from "react";
import { GeoPlusIcon, PencilIcon, TrashIcon } from "../svgComponents";
import PlaceAddressRow from "./PlaceAddressRow";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    createOflineAdress,
    deleteOflineAdress,
    updateOflineAdress,
} from "../../services/profileSettings";
import CustomizedDeleteModal from "../common/modalWindows/CustomizedDeleteModal";
import CreateNewAddressModal from "../common/modalWindows/CreateNewAddressModal";

const AddressesComponent = ({ data, isLoading, isError, error, queryKey }) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState({
        isOpen: false,
        information: null,
    });
    const queryClient = useQueryClient();

    const deleteMutation = useMutation(deleteOflineAdress, {
        onSuccess: ({data}) => {
            handleDataChange(data);
        },
    });

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
        setDeleteModal(false);
    };

    const handleDataChange = (arr) => {
        queryClient.setQueryData([queryKey], (prevData) => {
            if (!prevData) return { data: arr };

            return { ...prevData, data: arr };
        });

        if (editModal?.isOpen) {
            setEditModal({ isOpen: false, information: null });
        }
    };

    const createMutation = useMutation(createOflineAdress, {
        onSuccess: ({ data }) => {
            handleDataChange(data);
        },
    });

    const updateMutation = useMutation(updateOflineAdress, {
        onSuccess: ({ data }) => {
            handleDataChange(data);
        },
    });

    const handleSave = (body) => {
        if (editModal?.information) {
            updateMutation.mutate({
                id: editModal.information.id,
                ...body,
            });
        } else {
            createMutation.mutate(body);
        }
    };

    const cellStyleRef = useRef({
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 500,
        color: "rgba(21, 24, 19, 1)",
        padding: "12px 16px",
    });

    const lastCellStyleRef = useRef({
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 500,
        color: "rgba(21, 24, 19, 1)",
        padding: "12px 16px",

        display: "flex",
        gap: "4px",
        pr: 3,
        justifyContent: "flex-end",
    });

    if (isLoading) {
        return <CircularLoading sx={{ minHeight: "188px" }} />;
    }

    if (isError) {
        return (
            <CustomError
                error={error?.message || "Неопознанная ошибка"}
                sx={{ minHeight: "188px" }}
            />
        );
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow
                            sx={{ backgroundColor: "rgba(241, 242, 243, 1)" }}
                        >
                            <TableCell sx={{ ...headerStyle, pl: 3 }}>
                                №
                            </TableCell>
                            <TableCell sx={headerStyle}>Город</TableCell>
                            <TableCell sx={headerStyle}>Адрес</TableCell>
                            <TableCell sx={headerStyle}>Название</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{
                                height: 72,
                            }}
                        >
                            <TableCell
                                sx={{ ...cellStyleRef.current, pl: 3 }}
                                colSpan={4}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        lineHeight: "20px",
                                        color: "rgba(192, 198, 204, 1)",
                                    }}
                                >
                                    Чтобы создать филиал компании, нажмите на
                                    кнопку справа
                                </Typography>
                            </TableCell>
                            <TableCell sx={lastCellStyleRef.current}>
                                <Box
                                    component="button"
                                    sx={{
                                        backgroundColor: "transparent",
                                        border: "1px solid rgba(89, 157, 21, 1)",
                                        borderRadius: "8px",
                                        padding: "13px 21px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(244, 247, 242, 1)",
                                        },
                                        "&:active": {
                                            backgroundColor:
                                                "rgba(205, 221, 198, 1)",
                                        },
                                        width: "fit-content",
                                        gap: "8px",
                                    }}
                                    onClick={() =>
                                        setEditModal({
                                            isOpen: true,
                                            information: null,
                                        })
                                    }
                                >
                                    <GeoPlusIcon
                                        sx={{
                                            width: 20,
                                            height: 20,
                                        }}
                                        svgSx={{
                                            width: 20,
                                            height: 20,
                                            color: "rgba(89, 157, 21, 1)",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                            color: "rgba(89, 157, 21, 1)",
                                        }}
                                    >
                                        Добавить
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        {data.map((place, index) => (
                            <PlaceAddressRow
                                key={place.id}
                                place={place}
                                index={index}
                                setDeleteModal={setDeleteModal}
                                setEditModal={setEditModal}
                                cellStyle={cellStyleRef.current}
                                lastCellStyle={lastCellStyleRef.current}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {editModal?.isOpen && (
                <CreateNewAddressModal
                    handleClose={() =>
                        setEditModal({
                            isOpen: false,
                            information: null,
                        })
                    }
                    saveData={handleSave}
                    information={editModal?.information}
                />
            )}
            {deleteModal && (
                <CustomizedDeleteModal
                    title="Удаление филиала"
                    subtitle="Вы уверены, что хотите удалить этот филиал?"
                    handleDelete={() => handleDelete(deleteModal)}
                    handleClose={() => setDeleteModal(null)}
                />
            )}
        </>
    );
};

const headerStyle = {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "rgba(116, 116, 115, 1)",
    padding: "12px 16px",
};

export default AddressesComponent;
