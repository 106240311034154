import React, { memo, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import CustomSelect from "../styledComponents/CustomSelect";
import CustomInput from "../styledComponents/CustomInput";

const StageInformation = memo(({ negotiation, handleRefusal, onStageChange, negotiationId, currStageId, stages, vacancyId }) => {
    const handleStageChange = useCallback((newStageId) => {
        if (newStageId === -1) {
            handleRefusal();
        } else {
            onStageChange(negotiationId, newStageId);
        }
    }, []);
    const currentStage = stages.find(stage => stage.id === currStageId);
    return (
        <>
            <Box sx={{ padding: "24px", backgroundColor: "#FFFFFF" }}>
                <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    >
                        Этап собеседования
                </Typography>
                {negotiation?.id === vacancyId ? (
                    <CustomSelect
                    sx={{ marginTop: "16px" }}
                    value={currentStage?.id}
                    options={[...stages, { id: -1, name: "Отказ" }]}
                    onChange={handleStageChange}
                />
                ) : (
                    <CustomInput
                        value={negotiation?.stage?.name}
                        readOnly
                        sx={{ marginTop: "16px", border: "1px solid rgba(226, 228, 231, 1)", inputBackgroundColor: "white", borderRadius: "8px" }}
                    />
                )}
            </Box>
        </>
    )
})

export default StageInformation;