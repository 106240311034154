import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Button,
} from '@mui/material';
import { WarningIcon, CloseIcon } from '../svgComponents';
import { useMutation } from '@tanstack/react-query';
import { discardCandidate } from '../../services/candidatesApi';
import { fetchStages } from '../../services/stagesApi';
import CustomAutoComplete from '../styledComponents/CustomAutoComplete';


const RefusalModal = ({ open, onClose, reasons, companyId, candidateId, onSuccess }) => {
    const [reason, setReason] = useState(null);

    // Создаем mutation для отказа
    const mutation = useMutation({
        mutationFn: discardCandidate,
        onSuccess: () => {
            onSuccess(); // Вызов callback после успешного выполнения
            onClose(); // Закрыть модалку
        },
        onError: (error) => {
            console.error('Ошибка при отказе кандидату:', error);
        },
    });

    // find the reason inside the reasons array
    const handleSubmit = () => {
        if (reason) {
            const selectedReason = reasons.find((option) => option.name === reason.name);
            const selectedReasonId = selectedReason ? selectedReason.id : 'discard_by_employer';
            mutation.mutate({
                companyId,
                candidateId,
                reason: selectedReasonId,
                discard_justification: reason.name,
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '12px',
                    padding: '10px',
                    position: 'relative',
                    width: '404px'
                },
            }}
        >
            <WarningIcon 
                sx={{
                    position: 'absolute',
                    top: '14px',
                    left: '30px',
                    width: "48px",
                    height: "48px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(254, 228, 226, 1)",
                    border: "8px solid rgba(254, 243, 242, 1)",
                }}
                svgSx ={{
                    width: "20px",
                    height: "20px",
                    color: "rgba(217, 45, 32, 1)",
                }}
            />
            <CloseIcon 
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: '24px',
                    right: '34px',
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                }}
                svgSx ={{
                    width: "16px",
                    height: "16px",
                    color: "rgba(0, 0, 0, 0.5)",
                    hoverColor: "rgba(0, 0, 0, 0.75)",
                    pressedColor: "rgba(0, 0, 0, 1)"
                }}
            />
            <DialogContent
                sx={{
                    textAlign: 'left',
                    paddingTop: '96px',
                    paddingBottom: '24px',

                }}
            >
                <Typography
                    component="span"
                    sx={{
                        color: '#151813',
                        fontSize: '20px',
                        fontWeight: 600
                    }}
                >
                    Причина отказа
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        marginBottom: '16px',
                        color: '#747473',
                        marginTop: '8px'
                    }}
                >
                    Для того, чтобы отправить отказ кандидату, выберите, пожалуйста, причину отказа
                </Typography>
                <CustomAutoComplete
                        value={reason}
                        setValue={setReason}
                        queryKeyStartingValue={["refuseStage"]}
                        fetchFunction = {fetchStages}
                        maxHeight={200}
                        additional={{ purpose: 2 }}
                    />
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'space-between',
                    padding: '0 24px 16px',
                }}
            >
                <Button
                    onClick={onClose}
                    variant="outlined"
                    sx={{
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#6C757D',
                        borderColor: '#E2E4E7',
                        borderRadius: '8px',
                        width: '48%',
                        height: '46px',
                    }}
                >
                    Отменить
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#FFFFFF',
                        backgroundColor: '#FF3B30',
                        borderRadius: '8px',
                        width: '48%',
                        height: '46px',
                        '&:hover': {
                            backgroundColor: '#B71C1C',
                        },
                    }}
                    disabled={!reason}
                >
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RefusalModal;
