import { TableCell, TableRow } from "@mui/material";
import { TrashIcon, PencilIcon } from "../svgComponents";
import { memo } from "react";

const PlaceAddressRow = memo(
    ({
        place,
        index,
        setDeleteModal,
        setEditModal,
        cellStyle,
        lastCellStyle,
    }) => {
        console.log(place?.address + " rendered");
        return (
            <TableRow
                sx={{
                    height: 72,
                }}
            >
                <TableCell sx={{ ...cellStyle, pl: 3 }}>{index + 1}</TableCell>
                <TableCell sx={cellStyle}>{place?.city_name}</TableCell>
                <TableCell sx={cellStyle}>{place?.address}</TableCell>
                <TableCell sx={cellStyle}>{place?.name}</TableCell>
                <TableCell sx={lastCellStyle}>
                    <TrashIcon
                        onClick={() => setDeleteModal(place?.id)}
                        sx={{
                            width: 46,
                            height: 46,
                            cursor: "pointer",
                            borderRadius: "8px",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(226, 228, 231, 1)",
                            },
                        }}
                        svgSx={{
                            width: 24,
                            height: 24,
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    />
                    <PencilIcon
                        onClick={() => setEditModal({isOpen: true, information: {...place, index}})}
                        sx={{
                            width: 46,
                            height: 46,
                            cursor: "pointer",
                            borderRadius: "8px",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(226, 228, 231, 1)",
                            },
                        }}
                        svgSx={{
                            width: 24,
                            height: 24,
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    />
                </TableCell>
            </TableRow>
        );
    }
);

export default PlaceAddressRow;
