import React, { useState, useEffect } from 'react';
import { Box, Typography, List, Collapse } from '@mui/material';
import NavigationItem from './NavigationItem';
import DashboardIcon from "../../assets/icons/Icons.svg";
import IntegrationsIcon from "../../assets/icons/integrationsIcon.svg";
import DepartmentsIcon from "../../assets/icons/departmentsIcon.svg";
import CandidatesIcon from "../../assets/icons/candidatesIcon.svg";
import SettingsIcon from "../../assets/icons/settingsIcon.svg";
import SourcesIcon from "../../assets/icons/sourcesIcon.svg";
import PatternsIcon from "../../assets/icons/patternsIcon.svg";
import ReportsIcon from "../../assets/icons/reportsIcon.svg";
import StagesIcon from "../../assets/icons/stagesIcon.svg";
import TestsIcon from "../../assets/icons/testsIcon.svg";
import vacancyIcon from "../../assets/icons/vacancyIcon.svg";
import calendarIcon from "../../assets/icons/calendarIcon.svg"
import { MenuIcon, TapHrIcon, ArrowUpIcon } from '../svgComponents';
import { useNavigate, useLocation } from 'react-router-dom'; // Добавлено useLocation

const NavigationBar = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Используем для отслеживания текущего пути


    // Инициализируем активный элемент из localStorage
    const [activeItem, setActiveItem] = useState(localStorage.getItem('activeNavItem') || 'Вакансии');
    const [subActiveItem, setSubActiveItem] = useState(localStorage.getItem('subActiveNavItem') || '');
    const [settingsOpen, setSettingsOpen] = useState(location.pathname.startsWith('/settings'));
    const [sidebarOpen, setSidebarOpen] = useState(true); // State for sidebar visibility

    // Сохраняем активный элемент в localStorage при изменении
    useEffect(() => {
        const path = location.pathname;

        if (path === '/' || path.startsWith('/vacancy')) {
            setActiveItem('Вакансии');
        } else if (path === '/candidates') {
            setActiveItem('Кандидаты');
        } else if (path === "/reports") {
            setActiveItem('Отчеты');
        } else if (path === '/calendar') {
            setActiveItem('Календарь');
        } else if (path.startsWith('/settings')) {
            setActiveItem('Настройки');
            if (path === '/settings/integrations') {
                setSubActiveItem('Интеграции');
            } else if (path === '/settings/departments') {
                setSubActiveItem('Отделы');
            } else if (path === '/settings/stages') {
                setSubActiveItem('Этапы');
            } else if (path === '/settings/templates') {
                setSubActiveItem('Шаблоны');
            } else if (path === '/settings/sources') {
                setSubActiveItem('Источники');
            }
        } else {
            setActiveItem('');
        }
    }, [location.pathname]);


    // Обработка клика на элемент навигации
    const handleItemClick = (label) => {

        setActiveItem(label[0]);
        setSubActiveItem(label[1] || '');

        if (label[0] === 'Вакансии') {
            navigate('/');
        }
        if (label[0] === 'Кандидаты') {
            navigate('/candidates');
        }
        if (label[0] === 'Календарь') {
            navigate('/calendar');
        }

        if (label[0] === 'Отчеты') {
            navigate('/reports');
        }

        if (label[1] === 'Интеграции') {
            navigate('/settings/integrations');
        }

        if (label[1] === 'Отделы') {
            navigate('/settings/departments');
        }

        if (label[1] === 'Этапы') {
            navigate('/settings/stages');
        }

        if (label[1] === 'Шаблоны') {
            navigate('/settings/templates');
        }

        if (label[1] === 'Источники') {
            navigate('/settings/sources');
        }

        console.log(`Navigating to ${label[0]}`);
    };

    // Обработка клика на "Настройки"
    const handleSettingsClick = () => {
        setSettingsOpen(prev => !prev);
    };

    // Определяем, какие пункты должны быть отключены
    const isDisabled = (label) => label !== 'Вакансии'; // Все пункты кроме "Вакансии" будут isDisabled

    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev);
    };

    console.log(activeItem)

    return (
        <Box sx={{ width: sidebarOpen ? 292 : 119, backgroundColor: '#fff', height: '100vh', padding: '24px', transition: 'width 0.3s', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: "179px",
                    mb: "32px"
                }}
            >
                <MenuIcon onClick={toggleSidebar} sx={{ margin: sidebarOpen ? "9px" : "9px 21.5px", width: 28, height: 28, cursor: "pointer" }} svgSx={{ width: 15, height: 20, color: "rgba(0,0,0,0.5)", hoverColor: "rgba(0,0,0,0.75)", pressedColor: "rgba(0,0,0,1)" }} />
                <Typography variant="h5" sx={{ color: '#6BA14A', fontWeight: 'bold', fontSize: '36px', lineHeight: '43px', display: sidebarOpen ? 'block' : 'none' }}>
                    tapHR
                </Typography>
            </Box>
            {!sidebarOpen && <TapHrIcon sx={{ width: 62, height: 44, margin: "0 auto 32px auto" }} svgSx={{ width: 62, height: 44, color: "rgba(89, 157, 21, 1)" }} />}
            <List style={{ background: '#fff' }}>
                <NavigationItem
                    icon={DashboardIcon}
                    label={sidebarOpen ? "Дэшборд" : ""}
                    isActive={activeItem === 'Дэшборд'}
                    onClick={() => handleItemClick(['Дэшборд'])}
                    isDisabled={isDisabled('Дэшборд')}
                    hidden
                />
                <NavigationItem
                    icon={vacancyIcon}
                    label={sidebarOpen ? "Вакансии" : ""}
                    isActive={activeItem === 'Вакансии'}
                    onClick={() => handleItemClick(['Вакансии'])}
                    isDisabled={false} // Всегда доступен
                />
                <NavigationItem
                    icon={CandidatesIcon}
                    label={sidebarOpen ? "Кандидаты" : ""}
                    isActive={activeItem === 'Кандидаты'}
                    onClick={() => handleItemClick(['Кандидаты'])}
                    isDisabled={false}
                />
                <NavigationItem
                    icon={calendarIcon}
                    label={sidebarOpen ? "Календарь" : ""}
                    isActive={activeItem === 'Календарь'}
                    onClick={() => handleItemClick(['Календарь'])}
                />
                <NavigationItem
                    icon={TestsIcon}
                    label={sidebarOpen ? "Тесты" : ""}
                    isActive={activeItem === 'Тесты'}
                    onClick={() => handleItemClick(['Тесты'])}
                    isDisabled={isDisabled('Тесты')}
                    hidden
                />
                <NavigationItem
                    icon={ReportsIcon}
                    label={sidebarOpen ? "Отчеты" : ""}
                    isActive={activeItem === 'Отчеты'}
                    onClick={() => handleItemClick(['Отчеты'])}
                    isDisabled={false}
                />

                {/* Настройки с аккордеоном */}
                <NavigationItem
                    icon={SettingsIcon}
                    label={sidebarOpen ? "Настройки" : ""}
                    isActive={activeItem === 'Настройки'}
                    onClick={handleSettingsClick}
                    isDisabled={false}
                    divider={true}
                    arrow={
                        <Box sx={{ marginRight: sidebarOpen ? "47px" : "0", }}>
                            <ArrowUpIcon
                                sx={{
                                    transform: settingsOpen ? 'rotate(180deg)' : 'rotate(90deg)',
                                    transition: 'transform 0.3s',
                                    width: 20,
                                    height: 20,
                                }}
                                svgSx={{
                                    width: 9,
                                    height: 9,
                                    color: activeItem === 'Настройки' ? 'rgba(89, 157, 21, 1)' : 'rgba(0,0,0,1)',
                                }}
                            />
                        </Box>
                    }
                />

                {/* Вложенные элементы под "Настройки" */}
                <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
                    <Box sx={{ paddingLeft: sidebarOpen ? 2 : 0, transition: 'padding-left 0.3s', }}>
                        <NavigationItem
                            icon={SourcesIcon}
                            label={sidebarOpen ? "Источники резюме" : ""}
                            isSubActive={subActiveItem === 'Источники'}
                            onClick={() => handleItemClick(["Настройки", 'Источники'])}
                        />
                        <NavigationItem
                            icon={DepartmentsIcon}
                            label={sidebarOpen ? "Отделы" : ""}
                            isSubActive={subActiveItem === 'Отделы'}
                            onClick={() => handleItemClick(["Настройки", 'Отделы'])}
                        />
                        <NavigationItem
                            icon={StagesIcon}
                            label={sidebarOpen ? "Этапы подбора" : ""}
                            isSubActive={subActiveItem === 'Этапы'}
                            onClick={() => handleItemClick(["Настройки", 'Этапы'])}
                        />
                        <NavigationItem
                            icon={PatternsIcon}
                            label={sidebarOpen ? "Шаблоны" : ""}
                            isSubActive={subActiveItem === 'Шаблоны'}
                            onClick={() => handleItemClick(["Настройки", 'Шаблоны'])}
                        />
                        <NavigationItem
                            icon={IntegrationsIcon}
                            label={sidebarOpen ? "Интеграции" : ""}
                            isSubActive={subActiveItem === 'Интеграции'}
                            onClick={() => handleItemClick(["Настройки", 'Интеграции'])}
                        />
                        <NavigationItem
                            icon={SourcesIcon}
                            label={sidebarOpen ? "FAQ" : ""}
                            isSubActive={subActiveItem === 'FAQ'}
                            onClick={() => handleItemClick(["Настройки", 'FAQ'])}
                            isDisabled={isDisabled('FAQ')}
                            hidden
                        />
                    </Box>
                </Collapse>
            </List>
        </Box>
    );
};

export default NavigationBar;
