import { pVars } from "./templateVariables";

export const processContent = (content) => {
    const spanWrapperRegex = /<span style=['"]color: blue;['"]>(.*?)<\/span>/g;
    const bracketsWrapperRegex = /{{(\s*[^{}]*\s*)}}/g;

    let removedSpansContent = content.replace(spanWrapperRegex, (match, variableName) => {
        return variableName;
    });

    let processedContent = removedSpansContent.replace(bracketsWrapperRegex, (match, variableName) => {
        return pVars.some((v) => v.id === variableName)
        ? `<span style="color: blue;">{{${variableName}}}</span>`
        : `{{${variableName}}}`;
    });

    return processedContent;
}
