import React, { useState, useMemo, useCallback } from 'react';
import { Box, Typography, Avatar, Chip, IconButton, Paper, Divider } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // Горизонтальное троеточие
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useQueryClient } from '@tanstack/react-query';
import { onDragEnd } from '../../utils/dragAndDrop';
import CandidateDrawer from '../candidates/CandidateInfoDrawer';
import RefusalModal from './RefusalModal';
import StagePopUpWindow from '../candidateInfo/candidateStagePopUpWindow';
import { CheckBox, CustomToolTip } from '../tapHrStyledComponents';
import SourceIcon from '../common/SourceIcons';
import { MessageIcon, PhoneIcon, StarIcon } from '../svgComponents';
import { getActioneDoneOnThatDay } from '../../utils/formatedDate';
import { CustomSearch } from '../styledComponents/CustomSearch';
import { AcceptModalWindow } from '../common/modalWindows/AcceptModalWindow';
import { getRelevanceDescription } from '../../utils/aiRelevance';



const OverviewTab = ({ stages, vacancyId, onStageChangeSuccess, refetch }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [targetCandidatePath, setTargetCandidatePath] = useState(false);
    const [refuseCandidate, setRefuseCandidate] = useState(false);
    const [offerCandidate, setOfferCandidate] = useState(false);
    const queryClient = useQueryClient();

    const candidateTotals = useMemo(() => {
        return stages.reduce((total, stage) => total + (stage.candidates?.length || 0), 0);
    }, [stages]);

    const handleDragEnd = ({ columnStartIdx, columnEndIdx, elementStartIdx: candidateIdx }) => {
        queryClient.setQueryData(["KANBAN"], () => {
            return {
                negotiationId: stages[columnStartIdx].candidates[candidateIdx].negotiation_id,
                stageId: stages[columnEndIdx].id,
            }
        });
        onStageChangeSuccess();
    };

    const handleStageChange = useCallback((negotiationId, stageId) => {
        queryClient.setQueryData(["KANBAN"], () => {
            return {
                negotiationId: negotiationId,
                stageId: stageId,
            }
        });
        onStageChangeSuccess();
    }, [onStageChangeSuccess]);

    // Отфильтровать кандидатов на основе значения поиска
    const filteredStages = useMemo(() => {
        if (!searchQuery) return stages;

        const lowerCaseQuery = searchQuery.toLowerCase();

        return stages.map((stage) => ({
            ...stage,
            candidates: stage.candidates.filter((candidate) => {
                const fullName = `${candidate.first_name} ${candidate.last_name}`.toLowerCase();
                return fullName.includes(lowerCaseQuery);
            }),
        }));

    }, [stages, searchQuery]);

    const handleRefusalModalWindow = (candidateId) => {
        setRefuseCandidate(candidateId);
    };

    const handleOfferModalWindow = (negotiationId, stageId) => {
        setOfferCandidate({negotiationId, stageId});
    }

    const handleModalWindow = useCallback(() => {
        setTargetCandidatePath(false);
    }, []);

    const handleCandidateRefusal = useCallback(() => {
        handleRefusalModalWindow(stages[targetCandidatePath.col].candidates[targetCandidatePath.row].negotiation_id)
    }, [stages, targetCandidatePath]);

    const handleCandidateOffer = useCallback(() => {
        handleOfferModalWindow(stages[targetCandidatePath.col].candidates[targetCandidatePath.row].negotiation_id, stages[stages.length - 1].id)
    }, [stages, targetCandidatePath]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", flex: 1, mt: "36px", }}>
            {/* Поле поиска */}
            <CustomSearch setValue={setSearchQuery} sx={{mb: 3,}} />

            {/* Канбан-доска с этапами */}
            <DragDropContext onDragEnd={(e) => onDragEnd(e, handleDragEnd, true)}>
                <Box
                    display="flex"
                    gap={2}
                    className = "custom-scrollbar"
                    sx={{
                        flex: 1,
                        paddingBottom: "8px",
                        overflowX: "auto",
                        maxWidth: "100%",
                        height: "100%",
                    }}
                >
                    {filteredStages.map((stage, index) => (
                        <CandidateColumn
                            refetch={refetch}
                            key={stage.id}
                            index={index}
                            stage={stage}
                            stages={stages}
                            onStageChange={handleStageChange}
                            vacancyId={vacancyId}
                            candidateTotals={candidateTotals}
                            setTargetCandidatePath={setTargetCandidatePath}
                            handleRefusalModalWindow={handleRefusalModalWindow}    
                            handleOfferModalWindow={handleOfferModalWindow}                        
                        />
                    ))}
                </Box>
            </DragDropContext>
            {refuseCandidate && (
                <RefusalModal
                    open={true}
                    onClose={() => setRefuseCandidate(false)}
                    reasons={[
                        {
                            id: "discard_by_employer",
                            name: "Не подходит",
                        },
                        {
                            id: "discard_by_applicant",
                            name: "Кандидат отказался",
                        },
                        {
                            id: "discard_no_interaction",
                            name: "Не выходит на связь",
                        },
                        {
                            id: "discard_vacancy_closed",
                            name: "Вакансия закрыта",
                        },
                        {
                            id: "discard_to_other_vacancy",
                            name: "Перевод на другую вакансию",
                        },
                    ]}
                    companyId={localStorage.getItem("company_id")}
                    candidateId={refuseCandidate}
                    onSuccess={refetch}
                />
            )}
            {offerCandidate && (
                <AcceptModalWindow
                    handleClose = {() => setOfferCandidate(false)}
                    title="Отправить кандидату оффер?"
                    subtitle="Вы уверены, что хотите отправить приглашение на вакансию данному кандидату?"
                    handleAccept={() => {
                        handleStageChange(offerCandidate.negotiationId, offerCandidate.stageId);
                        setOfferCandidate(false);
                    }}
                />
            )}
            {/* Модальное окно */}
            {targetCandidatePath && (
                <CandidateDrawer
                    handleModalWindow={handleModalWindow}
                    candidateId={stages[targetCandidatePath.col].candidates[targetCandidatePath.row].id}
                    vacancyId={vacancyId}
                    handleRefusal={handleCandidateRefusal}
                    handleOffer={handleCandidateOffer}
                    onStageChange={handleStageChange}
                    currStageId={stages[targetCandidatePath.col].id}
                    stages={stages}
                    negotiationId={stages[targetCandidatePath.col].candidates[targetCandidatePath.row].negotiation_id}
                    candidateTotals={candidateTotals}
                    targetCandidatePath={targetCandidatePath}
                    setTargetCandidatePath={setTargetCandidatePath}
                    refetch={refetch}
                />
            )}
        </Box>
    );
};


const CandidateColumn = ({ stage, index, stages, onStageChange, setTargetCandidatePath, handleRefusalModalWindow, handleOfferModalWindow }) => {
    return (
        <Droppable droppableId={index.toString()}>
            {(provided, snapshot) => (
                <Paper
                    sx={{
                        minWidth: "327px",
                        height: "calc(100svh - 32px)",
                        display: "flex",
                        flexDirection: "column",
                        padding: "16px 16px 0 16px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        backgroundColor: "#F1F2F3",
                        border: snapshot.isDraggingOver ? '1px solid rgba(89, 157, 21, 1)' : 'none',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#151813",
                        }}
                    >
                        {stage.name}{" "}
                        {index === 0 && <Typography
                            component="span"
                            sx={{
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "#fff",
                                padding: "0 8px",
                                backgroundColor: "rgba(89, 157, 21, 1)",
                                borderRadius: "99px",
                            }}
                        >
                            {stage.candidates?.length}
                        </Typography>}
                    </Typography>
                    <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        mt={2}
                        sx={{ overflowY: "auto", flex: 1 }}
                    >
                        {/* {index === 0 && (
                            <Box
                                component="button"
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                    py: "13px",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    width: "100%",
                                    justifyContent: "center",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor:
                                            "rgba(244, 247, 242, 1)",
                                    },
                                    "&:active": {
                                        backgroundColor: "transparent",
                                    },
                                    color: "rgba(89, 157, 21, 1)",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                }}
                            >
                                <PlusIcon
                                    sx={{ width: 20, height: 20 }}
                                    svgSx={{
                                        width: 10,
                                        height: 10,
                                        color: "rgba(89, 157, 21, 1)",
                                    }}
                                />
                                Добавить кандидата
                            </Box>
                        )} */}
                        {stage.candidates?.map((candidate, candidateIdx) => (
                            <CandidateCard
                                key={candidate.id}
                                columnIndex={index}
                                index={candidateIdx}
                                candidate={candidate}
                                currStageId={stage.id}
                                stages={stages}
                                onStageChange={onStageChange}
                                setTargetCandidatePath={setTargetCandidatePath}
                                handleRefusalModalWindow={
                                    handleRefusalModalWindow
                                }
                                handleOfferModalWindow={handleOfferModalWindow}
                            />
                        ))}
                        {provided.placeholder}
                    </Box>
                </Paper>
            )}
        </Droppable>
    )
};

const CandidateCard = ({ candidate, index, currStageId, stages, onStageChange, columnIndex, setTargetCandidatePath, handleRefusalModalWindow, handleOfferModalWindow }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const queryClient = useQueryClient();

    const handleMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCandidateModalWindow = () => {
        if (Boolean(anchorEl)) {
            return;
        }
        setTargetCandidatePath(prev => {
            if (prev) {
                queryClient.removeQueries(["active_candidate"]);
            }
            return {col: columnIndex, row: index};
        });
    }

    const rel_score = Math.floor(candidate?.full_relevance_description?.percentage_match || candidate?.relevance_rate || 0);
    const rel_description = getRelevanceDescription(
        rel_score,
        candidate?.full_relevance_description?.criterion_relevance_details,
        candidate?.relevance_description,
    );

    return (
        <Draggable draggableId={candidate.id.toString()} index={index} > 
            {(provided) => (
                <Box
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={handleCandidateModalWindow}
                    className="candidateCard"
                    sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "12px",
                        border: candidate?.is_seen ? "none" : `4px solid #C6E2CE`,
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        maxWidth: "300px",
                        mt: 2,
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "#E0E0E0",
                            "& .avatarImage": {
                                backgroundColor: "#FFFFFF",
                                color: "#E0E0E0",
                            },
                            "& .trippleButton": {
                                backgroundColor: "#F8F9FA",
                            }
                        },
                    }}
                >
                    <Box
                        sx={{
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", gap: "16px", alignItems: "flex-start", mb: 1 }}>
                            {/* Аватар */}
                            <Avatar
                                src={candidate.avatar_small || null}
                                alt={`${candidate.first_name} ${candidate.last_name}`}
                                className="avatarImage"
                                sx={{
                                    width: 45,
                                    height: 45,
                                    backgroundColor: "#E0E0E0",
                                    borderRadius: '50%'
                                }}
                            />
                            {/* Имя и фамилия */}
                            <Box sx={{display: "flex", flex: 1, gap: "8px", alignItems: "flex-start", justifyContent: "space-between"}}>
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        color: "rgba(21, 24, 19, 1)",
                                    }}
                                >
                                    {candidate.first_name}
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                            color: "rgba(21, 24, 19, 1)",
                                            display: "block",
                                        }}
                                    >
                                        {candidate.last_name}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            lineHeight: "16px",
                                            color: "rgba(200, 202, 204, 1)"
                                        }}
                                    >
                                        {getActioneDoneOnThatDay(candidate.created_at, "Откликнулся")}
                                    </Typography>
                                </Typography>
                                <CheckBox checked={false} />
                            </Box>
                        </Box>

                        {/* Контактная информация */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 0.5 }}>
                            <MessageIcon 
                                sx={{width: 16, height: 16}}
                                svgSx={{width: 16, height: 16, color: "rgba(121, 128, 136, 1)"}}
                            />
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    color: "#6C757D",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {candidate.email || "Не указан"}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <PhoneIcon
                                sx={{width: 16, height: 16}}
                                svgSx={{width: 16, height: 16, color: "rgba(121, 128, 136, 1)"}}
                            />
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    color: "#6C757D",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {candidate.phone || "Не указан"}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ backgroundColor: "rgba(162, 161, 168, 0.1)"}} />
                    {/* Рейтинг */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, padding: "8px 16px 16px 16px", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <CustomToolTip title={rel_description} hideIfError={true} >
                                    {rel_score ? (
                                            <Chip
                                                label={<Box sx={{ padding: 0 }}>{rel_score}%</Box>}
                                                sx={{
                                                    "& .MuiChip-label": {
                                                        padding: 0
                                                    },
                                                    backgroundColor: rel_score >= 75 ? "rgba(242, 248, 244, 1)" : (rel_score < 75 && rel_score > 50 ? "rgba(253, 249, 242, 1) " : "#FFC9C8"),
                                                    color: rel_score >= 75 ? "#14AE5C" : (rel_score < 75 && rel_score > 50 ? "#E8B931" : "#FF3B30"),
                                                    fontWeight: 600,
                                                    height: "24px",
                                                    width: "38px",
                                                    fontSize: "12px",
                                                    lineHeight: "18px",
                                                    borderRadius: '4px',
                                                    cursor: rel_description ? 'pointer' : 'grab',
                                                }}
                                            />
                                        ) : null}
                                </CustomToolTip>
                            </Box>
                            {candidate.ai_score_general && <Chip
                                label={
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                        <StarIcon
                                            sx={{width: 14, height: 14}}
                                            svgSx={{width: 14, height: 14, backgroundColor: "rgba(232, 185, 49, 1)"}}
                                        />
                                        <Typography
                                            component="span"
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                lineHeight: "16px",
                                                color: "rgba(155, 162, 169, 1)",
                                                padding:" 0!important",
                                            }}
                                        >
                                            {candidate.ai_score_general}
                                        </Typography>
                                    </Box>
                                }
                                sx={{
                                    backgroundColor: "rgba(248, 249, 249, 1)",
                                    borderRadius: "16px",
                                    height: "20px",
                                    "& span": {
                                        px: "6px",
                                    }
                                }}
                            />}
                        </Box>
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <SourceIcon source={candidate?.negotiation_source_id} />
                            {/* Горизонтальное троеточие */}
                            <IconButton
                                onClick={handleMenuOpen}
                                className="trippleButton"
                                sx={{
                                    padding: "8px",
                                    backgroundColor: "transparent",
                                    borderRadius: "50%",
                                    height: "24px",
                                    width: "24px",
                                    "&:hover": { backgroundColor: "white !important" },
                                }}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    {/* Меню */}
                    <StagePopUpWindow 
                        anchorEl={anchorEl} 
                        onClose={handleMenuClose} 
                        handleRefusal={() => handleRefusalModalWindow(candidate.negotiation_id)} 
                        handleOffer={() => handleOfferModalWindow(candidate.negotiation_id, stages[stages.length - 1].id)}
                        onStageChange={onStageChange} 
                        negotiationId={candidate.negotiation_id} 
                        currStageId={currStageId} 
                        stages = {stages}
                    />
                </Box>
            )}
        </Draggable>
    );
};


export default OverviewTab;
