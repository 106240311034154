import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

// Создание нового кандидата
export const createCandidate = async (candidateData) => {
    const companyId = localStorage.getItem('company_id'); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/candidates`,
            candidateData, // Передаём FormData
            {
                headers: {
                    "Content-Type": "multipart/form-data", // Указываем тип контента для FormData
                },
            }
        );
        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку дальше для обработки
    }
};


// Запрос на получение списка кандидатов
export const fetchCandidates = async ({page = 1, vacancy_id, searchQuery = "",}) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        page,
        ...(vacancy_id ? { vacancy_id } : {}), // Добавляем фильтр по вакансии, если задано
        ...(searchQuery ? { name: searchQuery } : {}), // Добавляем фильтр по имени, если задано
    };

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/candidates`, { params });
        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Получение данных конкретного кандидата
export const fetchCandidateById = async (candidateId, negotiationId) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/candidates/${candidateId}`, {
            params: { ...(negotiationId ? { negotiation_id: negotiationId } : {}) },
        });
        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Смена этапа подбора кандидата
export const updateCandidateStage = async (negotiationId, stageId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");
    if (!negotiationId || !stageId) throw new Error("Не переданы необходимые параметры");

    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/negotiations/${negotiationId}`,
            { stage_id: stageId }
        );
        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

export const discardCandidate = async ({ companyId, candidateId, reason, discard_justification }) => {
    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/candidates/${candidateId}/discard`,
            { reason, discard_justification }
        );
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

export const downloadFile = async (candidateId, fileName, data) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    try {

        const params = {
            source_id: 2, // Статичный параметр, если требуется
            cv_path: data.cvPath, // Путь к файлу CV
        };

        const response = await axiosClassic.get(
            `/api/company/${companyId}/candidates/${candidateId}/resume`,
            {
                params, // Передаем параметры запроса
                responseType: "blob", // Указываем, что ответ будет бинарным (файл)
            }
        );

        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

export const uploadCandidateFile = async (file) => {
    const companyId = localStorage.getItem('company_id'); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const formData = new FormData();
        formData.append("cv", file);

        const response = await axiosClassic.post(
            `/api/company/${companyId}/candidates/file-upload`, // Убедитесь, что путь корректный
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data", // Указываем тип контента для FormData
                },
            }
        );

        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

export const deleteCandidate = async (candidateId) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/candidates/${candidateId}`
        );
        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
}

export const unlinkCandidate = async (negotiation_id) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/candidates/unlink-vacancy`,
            { negotiation_id }
        );
        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
}


// Route::prefix('/user-notifications')->controller(UserNotificationController::class)->group(function () {
//     Route::get('/', 'index');
//     Route::get('/count', 'getUserNotificationsCount');
//     Route::get('/get', 'getUserNotificationByUniqueKey');
// });
