import { Box, Divider, Input, Typography } from "@mui/material";
import {
    ArrowUpIcon,
    PlusIcon,
    TrashIcon,
    PencilIcon,
    CheckIcon,
} from "../svgComponents";
import { memo, useState } from "react";
import CreateDepartment from "./CreateDepartment";
import { Loading } from "../tapHrStyledComponents";

const DepartmentRow = memo(
    ({
        title,
        id,
        nodes,
        setDeleting,
        handleNew,
        handleEdit,
        level,
        maxLevel,
        paddingLeft,
    }) => {
        console.log("DepartmentRow being rendered", title);
        const [open, setOpen] = useState(false);
        const [newChildInput, setNewChildInput] = useState(false);
        const [editInputText, setEditInputText] = useState(title);
        const [editing, setEditing] = useState(null);

        const handleSave = () => {
            if (
                editInputText.trim().length !== 0 &&
                editInputText.trim() !== title
            ) {
                handleEdit(id, editInputText.trim());
            }
            setEditInputText(title);
            setEditing(false);
        };

        const handleKeyDown = (e) => {
            if (e.key === "Enter" && editInputText.trim()) {
                handleSave();
            }
        };

        const handleNewForRow = (name, parentId) => {
            handleNew(name, parentId);
            setNewChildInput(false);
            setOpen(true);
        };

        const handleBlur = () => {
            console.log("unbluring");
            setEditInputText(title);
            setEditing(false);
        };

        return (
            <Box
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {false ? (
                    <Loading
                        sx={{
                            width: "calc(100% - 36px)",
                            ml: "36px",
                            justifyContent: "space-between",
                            height: "72px",
                            borderBottom: "1px solid rgba(226, 228, 231, 1)",
                            color: "rgba(89, 157, 21, 1)",
                        }}
                    />
                ) : (
                    <>
                        {/* Header */}
                        <Box
                            sx={{
                                pl:
                                    level > 1
                                        ? `${(level - 1) * paddingLeft + 16}px`
                                        : "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                height: "72px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    width: "100%",
                                }}
                            >
                                {nodes?.length ? (
                                    <ArrowUpIcon
                                        onClick={() => setOpen((prev) => !prev)}
                                        sx={{
                                            transform:
                                                open || newChildInput
                                                    ? "rotate(180deg)"
                                                    : "rotate(90deg)",
                                            width: 24,
                                            height: 24,
                                            cursor: "pointer",
                                        }}
                                        svgSx={{
                                            width: 10,
                                            height: 8,
                                            color: "rgba(134, 140, 146, 0.5)",
                                            hoverColor:
                                                "rgba(134, 140, 146, 0.75)",
                                            pressedColor:
                                                "rgba(134, 140, 146, 1)",
                                        }}
                                    />
                                ) : (
                                    <Box sx={{ width: 24, height: 24 }} />
                                )}
                                {editing ? (
                                    <Input
                                        value={editInputText}
                                        onChange={(e) =>
                                            setEditInputText(e.target.value)
                                        }
                                        onKeyDown={handleKeyDown}
                                        onBlur={handleBlur}
                                        autoFocus
                                        sx={{
                                            height: "72px",
                                            padding: 0,
                                            "& input": {
                                                fontWeight: 500,
                                                fontSize: "16px",
                                                lineHeight: "20px",
                                            },
                                            "&::after": {
                                                borderBottom:
                                                    "2px solid rgba(89, 157, 21, 1)",
                                            },
                                            width: "100%",
                                        }}
                                    />
                                ) : (
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                        }}
                                    >
                                        {title || "Отделы вашей компании"}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                }}
                            >
                                {level < maxLevel && (
                                    <PlusIcon
                                        onClick={() => setNewChildInput(true)}
                                        sx={{
                                            width: 46,
                                            height: 46,
                                            cursor: "pointer",
                                            borderRadius: "8px",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(242, 243, 243, 1)",
                                            },
                                        }}
                                        svgSx={{
                                            width: 12,
                                            height: 12,
                                            color: "rgba(46, 64, 82, 1)",
                                            hoverColor: "rgba(41, 57, 73, 1)",
                                            pressedColor: "rgba(36, 50, 64, 1)",
                                        }}
                                    />
                                )}
                                {editing ? (
                                    <CheckIcon
                                        onMouseDown={handleSave}
                                        dontPreventDefault={false}
                                        sx={{
                                            cursor: "pointer",
                                            width: 46,
                                            height: 46,
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(242, 243, 243, 1)",
                                            },
                                            borderRadius: "8px",
                                        }}
                                        svgSx={{
                                            width: 18,
                                            height: 18,
                                            color: "rgba(21, 24, 19, 1)",
                                            hoverColor: "rgba(41, 57, 73, 1)",
                                            pressedColor: "rgba(36, 50, 64, 1)",
                                        }}
                                    />
                                ) : (
                                    <PencilIcon
                                        onClick={() => setEditing(true)}
                                        sx={{
                                            width: 46,
                                            height: 46,
                                            cursor: "pointer",
                                            borderRadius: "8px",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(242, 243, 243, 1)",
                                            },
                                        }}
                                        svgSx={{
                                            width: 18,
                                            height: 18,
                                            color: "rgba(46, 64, 82, 1)",
                                            hoverColor: "rgba(41, 57, 73, 1)",
                                            pressedColor: "rgba(36, 50, 64, 1)",
                                        }}
                                    />
                                )}
                                <TrashIcon
                                    onClick={() =>
                                        setDeleting({
                                            idx: id,
                                            name: title,
                                        })
                                    }
                                    sx={{
                                        width: 46,
                                        height: 46,
                                        cursor: "pointer",
                                        borderRadius: "8px",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(242, 243, 243, 1)",
                                        },
                                    }}
                                    svgSx={{
                                        width: 18,
                                        height: 20,
                                        color: "rgba(46, 64, 82, 1)",
                                        hoverColor: "rgba(41, 57, 73, 1)",
                                        pressedColor: "rgba(36, 50, 64, 1)",
                                    }}
                                />
                            </Box>
                        </Box>
                        <Divider
                            sx={{ backgroundColor: "rgba(226, 228, 231, 1)" }}
                        />
                        {newChildInput && (
                            <CreateDepartment
                                setNewChildInput={setNewChildInput}
                                handleNew={handleNewForRow}
                                paddingLeft={`${level * paddingLeft + 16}px`}
                                parentId={id}
                            />
                        )}

                        {/* Departments */}
                        {(open || newChildInput) && (
                            <>
                                {nodes.map((department) => {
                                    return (
                                        <DepartmentRow
                                            key={department.id}
                                            title={department.name}
                                            id={department.id}
                                            nodes={department.children}
                                            setDeleting={setDeleting}
                                            handleNew={handleNew}
                                            handleEdit={handleEdit}
                                            level={level + 1}
                                            maxLevel={maxLevel}
                                            paddingLeft={paddingLeft}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </Box>
        );
    }
);

export default DepartmentRow;
