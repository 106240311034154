import { useEffect, useState } from "react";
import { AiIcon, AiIconLeft } from "../svgComponents";
import { Box } from "@mui/system";

const CustomAiButton = ({ text, disabled, slotProps, ...props }) => {
    const [isActive, setIsActive] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const handleMouseUp = () => setIsActive(false);
        if (isActive && !disabled) {
            document.addEventListener("mouseup", handleMouseUp);
        }
        return () => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isActive, disabled]);

    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-block",
                padding: "1px",
                borderRadius: "8px",
                background: disabled
                    ? "rgba(200, 200, 200, 0.5)"
                    : "linear-gradient(to right, #614385 0%, #516395 51%, #614385 100%)",
                backgroundSize: "200% auto",
                transition: "0.5s ease-in-out",
                boxShadow: disabled
                    ? "none"
                    : "0px 0px 1px 2px rgba(102, 74, 153, 0.1)",
                justifyContent: "center",
                alignItems: "center",
                opacity: disabled ? 0.5 : 1,
                pointerEvents: disabled ? "none" : "auto",
                "&:hover": disabled
                    ? {}
                    : {
                          backgroundPosition: "right center",
                      },
            }}
            {...props}
        >
            <Box
                sx={{
                    borderRadius: "7px",
                    backgroundColor: "white",
                    position: "relative",
                }}
            >
                <Box
                    component="button"
                    sx={{
                        borderRadius: "7px",
                        width: slotProps?.textWidth,
                        fontSize: "16px",
                        padding: "12px 20px",
                        fontWeight: 400,
                        height: "fit-content",
                        lineHeight: "20px",
                        cursor: disabled ? "not-allowed" : "pointer",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: isActive
                            ? "rgba(236, 229, 249, 1)"
                            : "transparent",
                        gap: "8px",
                        opacity: disabled ? 0.6 : 1,
                        color: "white",
                    }}
                >
                    <Box sx={{ minWidth: 20, minHeight: 20 }} />
                    {text}
                </Box>
                <Box
                    sx={{
                        borderRadius: "7px",
                        background:
                            "linear-gradient(90deg, #392D69 0%, #B57BEE 100%)",
                        transition: "0.5s ease-in-out",
                        "&:hover": disabled
                            ? {}
                            : {
                                  background:
                                      "linear-gradient(90deg, #B57BEE 0%, #392D69 100%)",
                                  WebkitBackgroundClip: "text",
                                  WebkitTextFillColor: "transparent",
                              },
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        textWrap: "nowrap",
                        cursor: disabled ? "not-allowed" : "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        gap: "8px",
                        width: "100%",
                    }}
                    onMouseDown={
                        !disabled ? () => setIsActive(true) : undefined
                    }
                    onMouseUp={!disabled ? () => setIsActive(false) : undefined}
                    onBlur={() => setIsActive(false)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {isHovered ? (
                        <AiIconLeft
                            sx={{ width: 20, height: 20 }}
                            svgSx={{ width: 20, height: 20 }}
                        />
                    ) : (
                        <AiIcon
                            sx={{ width: 20, height: 20 }}
                            svgSx={{ width: 20, height: 20 }}
                        />
                    )}
                    {text}
                </Box>
            </Box>
        </Box>
    );
};

export default CustomAiButton;
