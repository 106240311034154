// src/services/channelsApi.js

import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

// Получение списка каналов
export const fetchChannels = async () => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/settings/channel`);
        return responseWrapper(response);
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Создание нового канала
export const createChannel = async (channelData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    // Проверяем обязательные поля
    const { name, utm_source } = channelData;
    if (!name || !utm_source) {
        throw new Error("Название канала и UTM source обязательны для заполнения.");
    }

    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/settings/channel`,
            channelData
        );
    
        return responseWrapper(response); // Возвращаем результат создания
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Обновление канала
export const updateChannel = async (channelData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    if (!channelData.id) throw new Error("ID канала обязателен для обновления.");

    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/settings/channel/${channelData.id}`,
            channelData
        );
        return responseWrapper(response); // Возвращаем результат обновления
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Удаление канала
export const deleteChannel = async (channelId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    if (!channelId) throw new Error("ID канала обязателен для удаления.");

    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/settings/channel/${channelId}`
        );
        return responseWrapper(response); // Возвращаем результат удаления
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};
