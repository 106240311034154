import { useQuery } from "@tanstack/react-query";
import { memo, useCallback, useState } from "react";
import {
    BookQuestionIcon,
    CloseIcon,
    VideoIcon,
    AudioIcon,
    LinkIcon,
    ListIcon,
    TIcon,
} from "../../svgComponents";
import { fetchTestTemplateById } from "../../../services/testApi";
import { CircularLoading } from "../../tapHrStyledComponents";
import { Dialog, Box, Typography, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { TEST_STORAGE_AUDIO, TEST_STORAGE_CHECKBOX, TEST_STORAGE_FILE, TEST_STORAGE_RADIO, TEST_STORAGE_TEXT, TEST_STORAGE_VIDEO } from "../../../utils/testTypesStorage";
import { CustomError } from "../../styledComponents/CustomErrors";

// A small bullet for separating text (e.g., "3 попытки" • "2 минуты")
const DotComponent = () => (
    <Box
        sx={{
            width: 6,
            height: 6,
            borderRadius: "50%",
            backgroundColor: "rgba(173, 181, 189, 1)",
        }}
    />
);

const QuestionCard = memo(
    ({ question, index, isExpandable, isOpen, handleToggle }) => {
        console.log("rendering question", index);
        const icon = getQuestionIcon(question.type);
        return (
            <Box
                key={index}
                onClick={() => {
                    if (isExpandable) {
                        handleToggle(index);
                    }
                }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: "16px",
                    gap: "16px",
                    backgroundColor: "white",
                    border: "1px solid rgba(248, 249, 249, 1)",
                    boxShadow: "0px 5px 50px 0px rgba(0, 0, 0, 0.05)",
                    borderRadius: "8px",
                    cursor: isExpandable ? "pointer" : "default",
                }}
            >
                {/* Top row: icon, question text, arrow toggle */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {/* Left side: icon + text */}
                    <Box
                        sx={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ flexShrink: 0 }}>{icon}</Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ fontWeight: 500 }}
                            >
                                {question.text}
                            </Typography>
                            {question.type !== TEST_STORAGE_RADIO &&
                                question.type !== TEST_STORAGE_CHECKBOX &&
                                question.type !== TEST_STORAGE_FILE && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "16px",
                                            mt: 0.5,
                                            fontSize: 14,
                                            lineHeight: "18px",
                                            color: "rgba(116, 116, 115, 1)",
                                        }}
                                    >
                                        {/* For audio/video: attempts_count */}
                                        {(question.type === TEST_STORAGE_AUDIO ||
                                            question.type === TEST_STORAGE_VIDEO) &&
                                            question.attempts_count && (
                                                <Typography variant="body2">
                                                    {question.attempts_count}{" "}
                                                    попытки
                                                </Typography>
                                            )}
                                        {/* Dot between attempts & duration */}
                                        {question.attempts_count &&
                                            question.duration && (
                                                <DotComponent />
                                            )}
                                        {/* Duration */}
                                        {(question.type === TEST_STORAGE_AUDIO ||
                                            question.type === TEST_STORAGE_VIDEO) &&
                                            question.duration && (
                                                <Typography variant="body2">
                                                    Длительность:{" "}
                                                    {question.duration} мин
                                                </Typography>
                                            )}

                                        {/* Text count for text questions */}
                                        {question.type === TEST_STORAGE_TEXT &&
                                            question.words_limit && (
                                                <Typography variant="body2">
                                                    Количество символов:{" "}
                                                    {question.words_limit}
                                                </Typography>
                                            )}
                                    </Box>
                                )}
                        </Box>
                    </Box>

                    {/* Arrow toggle if radio/checkbox */}
                    {isExpandable && (
                        <IconButton sx={{ padding: 0 }}>
                            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </Box>

                {/* Expandable answers: shown only if isOpen + question.answers exist */}
                {isOpen && question.answers && (
                    <Box sx={{display: "flex", flexDirection: "column", gap: "8px" }}>
                        {question.answers.map((answer, i) => (
                            <Typography
                                key={i}
                                variant="body2"
                                sx={{
                                    color: answer.is_correct ? "rgba(155, 162, 169, 1)" : "rgba(173, 181, 189, 1)",
                                    fontSize: 16,
                                    lineHeight: "20px",
                                    fontWeight: answer.is_correct ? 600 : 400,
                                }}
                            >
                                {i + 1}. {answer.text}
                            </Typography>
                        ))}
                    </Box>
                )}
            </Box>
        );
    }
);

// Map each question.type to the correct icon & color
const getQuestionIcon = (type) => {
    switch (type) {
        case TEST_STORAGE_RADIO:
        case TEST_STORAGE_CHECKBOX:
            return (
                <ListIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "rgba(232, 239, 228, 1)",
                        border: "6px solid rgba(244, 247, 242, 1)",
                        borderRadius: "50%",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(89, 157, 21, 1)",
                    }}
                />
            );
        case TEST_STORAGE_AUDIO:
            return (
                <AudioIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "rgba(244, 222, 255, 1)",
                        border: "6px solid rgba(252, 246, 255, 1)",
                        borderRadius: "50%",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(175, 82, 222, 1)",
                    }}
                />
            );
        case TEST_STORAGE_VIDEO:
            return (
                <VideoIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "rgba(255, 224, 228, 1)",
                        border: "6px solid rgba(255, 241, 243, 1)",
                        borderRadius: "50%",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        backgroundColor: "#EF233C",
                        color: "white",
                    }}
                />
            );
        case TEST_STORAGE_FILE:
            return (
                <LinkIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "rgba(255, 244, 226, 1)",
                        border: "6px solid rgba(255, 252, 246, 1)",
                        borderRadius: "50%",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(251, 153, 7, 1)",
                    }}
                />
            );
        case TEST_STORAGE_TEXT:
            return (
                <TIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "rgba(226, 237, 255, 1)",
                        border: "6px solid rgba(244, 248, 255, 1)",
                        borderRadius: "50%",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        color: "rgba(58, 134, 255, 1)",
                    }}
                />
            );
        default:
            return null;
    }
};

const TestDetailsModal = ({ id, handleClose }) => {
    // Expand state for radio/checkbox questions
    const [expanded, setExpanded] = useState({});

    const handleToggle = useCallback((idx) => {
        setExpanded((prev) => ({ ...prev, [idx]: !prev[idx] }));
    }, []);

    const { data, isLoading, isError, error } = useQuery(
        ["testDetails", id],
        () => fetchTestTemplateById(id),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const test = data?.data || {};
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "715px",
                    maxWidth: "unset",
                    height: "650px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    boxShadow: "0px 5px 50px 0px rgba(0, 0, 0, 0.1)",
                },
            }}
        >
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {/* Header */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 0,
                        marginBottom: "8px",
                        position: "relative",
                    }}
                >
                    {/* Icon Circle */}
                    <Box
                        sx={{
                            width: 56,
                            height: 56,
                            backgroundColor: "rgba(232, 239, 228, 1)",
                            border: "8px solid rgba(244, 247, 242, 1)",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            left: "-4px",
                            top: "-4px",
                        }}
                    >
                        <BookQuestionIcon
                            sx={{
                                width: 24,
                                height: 24,
                                borderRadius: "50%",
                            }}
                            svgSx={{
                                width: 24,
                                height: 24,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                    </Box>

                    {/* Close Button */}
                    <CloseIcon
                        onClick={handleClose}
                        sx={{
                            width: 24,
                            height: 24,
                            cursor: "pointer",
                        }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>

                {/* Title */}
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        fontSize: 20,
                        lineHeight: "24px",
                        marginBottom: "16px",
                    }}
                >
                    {/* If data is loaded, show the actual test name; otherwise fallback */}
                    {test?.name || "Без названия"}
                </Typography>

                {/* Question Count / Additional Info */}
                <Box sx={{ marginBottom: "24px" }}>
                    {isLoading ? null : data ? (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "16px",
                                alignItems: "center",
                            }}
                        >
                            {test.questions_count !== undefined && <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 16,
                                    lineHeight: "20px",
                                    color: "rgba(116, 116, 115, 1)",
                                }}
                            >
                                {test.questions_count} вопросов
                            </Typography>}
                            {test.direction && (
                                <>
                                    <DotComponent />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: 16,
                                            lineHeight: "20px",
                                            color: "rgba(116, 116, 115, 1)",
                                        }}
                                    >
                                        {test.direction}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    ) : null}
                </Box>

                {/* Main Content: Loading / Error / Questions */}
                {isLoading ? (
                    <CircularLoading sx={{ flex: 1, height: "unset" }} />
                ) : isError ? (
                    <CustomError error={error.message} />
                ) : (
                    <Box
                        sx={{
                            flex: 1,
                            overflowY: "auto", // make questions scrollable if many
                            p: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                        }}
                    >
                        {test?.questions?.map((question, index) => {
                            const isExpandable =
                                question.question.type === TEST_STORAGE_RADIO ||
                                question.question.type === TEST_STORAGE_CHECKBOX;

                            return (
                                <QuestionCard
                                    key={index}
                                    question={question.question}
                                    index={index}
                                    // Only pass toggle-related props if it's radio/checkbox
                                    isExpandable={isExpandable}
                                    isOpen={
                                        isExpandable
                                            ? expanded[index] || false
                                            : false
                                    }
                                    handleToggle={
                                        isExpandable ? handleToggle : undefined
                                    }
                                />
                            );
                        })}
                    </Box>
                )}
            </Box>
        </Dialog>
    );
};

export default TestDetailsModal;
