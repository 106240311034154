import { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CustomEditor from "../styledComponents/CustomEditor";
import PopUpWindow from "../popUpWindow";
import { pVars } from "../../utils/templateVariables";
import { processContent } from "../../utils/templateProcessContent";

const TemplateEditor = ({
    label,
    value,
    onChange,
    formats,
    hideToolbar,
    placeholder,
    slotProps,
}) => {
    const [menuOpen, setMenuOpen] = useState(null);
    const quillRef = useRef(null);

    const handleProcessContent = (content) => {
        const updatedContent = processContent(content);
        if (updatedContent === value) {
            return;
        }
        onChange(updatedContent);
    };

    const handleClick = (id) => {
        if (!quillRef.current) return;
        const quillEditor = quillRef.current.getEditor();
        const range = quillEditor.getSelection(true); // Get current cursor position

        if (range) {
            const insertText = `{{${id}}}`;
            quillEditor.insertText(range.index, insertText); // Insert variable at cursor position

            // Delay setting the cursor to ensure Quill updates first
            setTimeout(() => {
                quillEditor.setSelection(range.index + insertText.length);
            }, 0);
        }

        handleMenu();
    };

    const handleMenu = (event) => {
        setMenuOpen((prev) => {
            if (prev || !event) {
                return null;
            } else {
                return event.currentTarget;
            }
        });
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: 500,
                    }}
                >
                    {label} <span style={{ color: "red" }}>*</span>
                </Typography>
                <Typography
                    sx={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontWeight: 500,
                        color: "rgba(31, 107, 197, 1)",
                        cursor: "pointer",
                    }}
                    onClick={handleMenu}
                >
                    Вставить Переменную
                </Typography>
                <PopUpWindow
                    anchorEl={menuOpen}
                    open={Boolean(menuOpen)}
                    onClose={handleMenu}
                    onClick={handleClick}
                    middleItems={pVars}
                    sx={{
                        "& .MuiPaper-root": {
                            width: "238px",
                            border: "1px solid rgba(245, 245, 245, 1)",
                            marginTop: "5px",
                            boxShadow:
                                "0px 4px 6px -2px rgba(10, 13, 18, 0.03),0px 12px 16px -4px rgba(10, 13, 18, 0.08)",
                        },
                        "& .MuiList-root": {
                            padding: "0",
                            height: "320px",
                            overflow: "auto",
                        },
                        "& .MuiMenuItem-root": {
                            padding: "16px",
                            fontSize: "16px",
                            lineHeight: "20px",
                            fontWeight: 500,
                        },
                    }}
                />
            </Box>
            <CustomEditor
                value={value}
                onChange={handleProcessContent}
                placeholder={placeholder}
                hideToolbar={hideToolbar}
                formats={formats}
                slotProps={slotProps}
                quillRef={quillRef}
            />
        </Box>
    );
};

export default TemplateEditor;
