import { Typography, Box } from "@mui/material";

const QuestionPreview = ({ title, subtitle, icon, sx }) => {
    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", gap: "4px", ...sx }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "rgba(21, 24, 19, 1)",
                    
                }}
            >
                {title}
            </Typography>
            <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                {icon}
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "rgba(173, 181, 189, 1)",
                        textWrap: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "100%",
                    }}
                >
                    {subtitle}
                </Typography>
            </Box>
        </Box>
    );
};

export default QuestionPreview;
