import React, { useState } from "react";
import { Checkbox, TextField, FormControlLabel, Collapse, IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const BookingSettings = ({ settings, onChange }) => {
    const [expanded, setExpanded] = useState({
        timeRestrictions: false, // Развёрнуто по умолчанию
        bookingSettings: false,
    });

    const [enabledFields, setEnabledFields] = useState({
        max_book_days: settings?.max_book_days !== undefined,
        min_book_minutes: settings?.min_book_minutes !== undefined,
        limit_day_bookings: settings?.limit_day_bookings !== undefined,
        slot_between_break: settings?.slot_between_break !== 0
    });

    const handleExpandToggle = (section) => {
        setExpanded((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    const handleCheckboxChange = (field) => {
        setEnabledFields((prev) => {
            const newEnabled = !prev[field];
            const newSettings = { ...settings, [field]: newEnabled ? settings[field] || 1 : undefined };
            if (onChange) onChange(newSettings);
            return { ...prev, [field]: newEnabled };
        });
    };

    const handleInputChange = (field, value) => {
        console.log(field, value)
        const numValue = Math.max(0, Number(value));
        onChange({ ...settings, [field]: numValue });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            {/* === Секция 1: Ограничение времени === */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }} onClick={() => handleExpandToggle("timeRestrictions")}>
                <span style={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: "8px" }}>
                    <i className="fas fa-info-circle"></i> Ограничение времени
                </span>
                <IconButton>{expanded.timeRestrictions ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </div>

            <Collapse in={expanded.timeRestrictions}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabledFields?.max_book_days}
                            onChange={() => handleCheckboxChange("max_book_days")}
                        />
                    }
                    label="Максимальный срок предварительного бронирования"
                />
                <TextField
                    type="number"
                    value={settings?.max_book_days || ""}
                    onChange={(e) => handleInputChange("max_book_days", e.target.value)}
                    fullWidth
                    label="Дней"
                    margin="dense"
                    disabled={!enabledFields?.max_book_days}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabledFields?.min_book_minutes}
                            onChange={() => handleCheckboxChange("min_book_minutes")}
                        />
                    }
                    label="Минимальный срок предварительного бронирования"
                />
                <TextField
                    type="number"
                    value={settings?.min_book_minutes || ""}
                    onChange={(e) => handleInputChange("min_book_minutes", e.target.value)}
                    fullWidth
                    label="Часов"
                    margin="dense"
                    disabled={!enabledFields?.min_book_minutes}
                />
            </Collapse>

            {/* === Секция 2: Настройка записей === */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }} onClick={() => handleExpandToggle("bookingSettings")}>
                <span style={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: "8px" }}>
                    <i className="fas fa-info-circle"></i> Настройка записей
                </span>
                <IconButton>{expanded.bookingSettings ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </div>

            <Collapse in={expanded.bookingSettings}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabledFields?.limit_day_bookings}
                            onChange={() => handleCheckboxChange("limit_day_bookings")}
                        />
                    }
                    label="Лимит бронирований в день"
                />
                <TextField
                    type="number"
                    value={settings?.limit_day_bookings || ""}
                    onChange={(e) => handleInputChange("limit_day_bookings", e.target.value)}
                    fullWidth
                    label="Записей"
                    margin="dense"
                    disabled={!enabledFields?.limit_day_bookings}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={enabledFields?.slot_between_break}
                            onChange={() => handleCheckboxChange("slot_between_break")}
                        />
                    }
                    label="Перерыв между слотами"
                />
                <TextField
                    type="number"
                    value={settings?.slot_between_break || ""}
                    onChange={(e) => handleInputChange("slot_between_break", e.target.value)}
                    fullWidth
                    label="Минут"
                    margin="dense"
                    disabled={!enabledFields?.slot_between_break}
                />
            </Collapse>
        </div>
    );
};

export default BookingSettings;
