import { TEST_STORAGE_RADIO, TEST_STORAGE_CHECKBOX, TEST_STORAGE_AUDIO, TEST_STORAGE_VIDEO, TEST_STORAGE_FILE, TEST_STORAGE_TEXT } from "./testTypesStorage";

export const nullQuestion = {
    [TEST_STORAGE_RADIO]: {
        answers: [{ text: "Вопрос 1", image_path: null, is_correct: false }],
    },
    [TEST_STORAGE_CHECKBOX]: {
        answers: [{ text: "Вопрос 1", image_path: null, is_correct: false }],
    },
    [TEST_STORAGE_AUDIO]: { attempts_count: 3, duration: 3 },
    [TEST_STORAGE_VIDEO]: { attempts_count: 3, duration: 3 },
    [TEST_STORAGE_FILE]: {},
    [TEST_STORAGE_TEXT]: { words_limit: 250 },
};