import React, { useState, memo, useEffect, useCallback, useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { ArrowUpIcon, CloseIcon } from "../svgComponents";
import { useInfiniteQuery } from "@tanstack/react-query";
import useDebounce from "../../hooks/useDebounce";

const CustomAutoComplete = memo(
    ({
        value = null,
        setValue = () => {},
        queryKeyStartingValue = [""],
        fetchFunction = () => {},
        maxHeight = 520,
        additional,
        placeholder,
    }) => {
        // useDebounce returns the searchQuery, debouncedValue and a setter.
        const {
            debouncedValue: debouncedSearchQuery,
            value: searchQuery,
            setValue: setSearchQuery,
            isDebouncing,
        } = useDebounce(value?.name || "", 300, queryKeyStartingValue);

        // const [searchQuery, setSearchQuery] = useState(value?.name || "");
        const [open, setOpen] = useState(false);

        // Memoize the infinite query's result
        const {
            data,
            isFetching,
            fetchNextPage,
            hasNextPage,
            isFetchingNextPage,
        } = useInfiniteQuery(
            [queryKeyStartingValue, debouncedSearchQuery],
            ({ pageParam = 1 }) =>
                fetchFunction({searchQuery : debouncedSearchQuery, page: pageParam, perPage : 10, additional}),
            {
                getNextPageParam: (lastPage) => {
                    const target = lastPage?.meta || lastPage;
                    if (target) {
                        const currentPage = parseInt(target.current_page, 10);
                        const totalPages = Math.ceil(
                            target.total / target.per_page
                        );
                        return currentPage < totalPages
                            ? currentPage + 1
                            : undefined;
                    }
                    return undefined;
                },
                refetchOnWindowFocus: false,
                keepPreviousData: true,
            }
        );

        // Flatten the paginated data – only recomputed when data changes.
        const flatData = useMemo(() => {
            return data && data.pages
                ? data.pages.flatMap((page) => page?.data || page)
                : [];
        }, [data]);

        // Memoize the scroll handler so its reference doesn't change.
        const handleScroll = useCallback(
            (event) => {
                const listboxNode = event.currentTarget;
                const bottom =
                    listboxNode.scrollHeight - listboxNode.scrollTop <=
                    listboxNode.clientHeight + 10;
                if (bottom && hasNextPage && !isFetchingNextPage) {
                    fetchNextPage();
                }
            },
            [hasNextPage, isFetchingNextPage, fetchNextPage]
        );

        // Determine whether options should be loaded.
        const isOptionsLoading = useMemo(
            () => (isFetching && !isFetchingNextPage) || isDebouncing,
            [isFetching, isFetchingNextPage, isDebouncing]
        );

        // Memoize the popup icon – changes only if searchQuery changes.
        const popupIcon = useMemo(
            () => (
                <ArrowUpIcon
                    sx={{ width: 24, height: 24, transform: "rotate(180deg)" }}
                    svgSx={{
                        width: 10,
                        height: 4,
                        color: searchQuery
                            ? "rgba(21, 24, 19, 1)"
                            : "rgba(173, 181, 189, 1)",
                    }}
                />
            ),
            [searchQuery]
        );

        // Memoize renderOption so that it doesn't get re-created on every render.
        const renderOption = useCallback((props, option) => {
            const { key, ...otherProps } = props;
            return (
                <li
                    key={option?.id || key}
                    {...otherProps}
                    style={{
                        ...props.style,
                        height: "52px",
                        maxHeight: "52px",
                        padding: "16px",
                    }}
                >
                    {option?.name || "Без названия"}
                </li>
            );
        }, []);

        // Memoize renderInput as well.
        const renderInput = useCallback(
            (params) => (
                <TextField
                    {...params}
                    placeholder={placeholder ?? "Выберите из списка"}
                    variant="outlined"
                    sx={{
                        height: "56px",
                        borderRadius: "10px",
                        backgroundColor: "#FFFFFF",
                        color: "#151813",
                        border: "1px solid #E2E4E7",
                        boxShadow: "0px 1px 2px 0px rgba(10, 13, 18, 0.05)",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        "&:hover": { borderColor: "#599D15" },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        "&.MuiFormControl-root:focus-within": {
                            border: "2px solid rgba(205, 221, 198, 1)",
                        },
                    }}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {searchQuery && (
                                        <CloseIcon
                                            onClick={() => {
                                                setSearchQuery("");
                                                setValue(null);
                                                setOpen(true);
                                            }}
                                            sx={{
                                                cursor: "pointer",
                                                width: 24,
                                                height: 24,
                                            }}
                                            svgSx={{
                                                width: 10,
                                                height: 10,
                                                color: "rgba(0,0,0,0.5)",
                                                hoverColor: "rgba(0,0,0,0.75)",
                                                pressedColor: "rgba(0,0,0,1)",
                                            }}
                                        />
                                    )}
                                    {params.InputProps?.endAdornment}
                                </>
                            ),
                        },
                    }}
                />
            ),
            [placeholder, searchQuery, setSearchQuery, setValue]
        );

        return (
            <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                noOptionsText={"Нет вариантов"}
                disableClearable={true}
                value={value}
                inputValue={searchQuery}
                onChange={(e, newValue) => setValue(newValue)}
                onInputChange={(e, newInputValue) =>
                    setSearchQuery(newInputValue)
                }
                options={isOptionsLoading ? [] : flatData}
                getOptionLabel={(option) => option?.name || "Без названия"}
                filterOptions={(options) => options}
                loading={isOptionsLoading || isFetchingNextPage}
                sx={{ position: "relative", overflow: "hidden" }}
                popupIcon={popupIcon}
                renderOption={renderOption}
                renderInput={renderInput}
                slotProps={{
                    listbox: {
                        style: { maxHeight: maxHeight, overflow: "auto" },
                        onScroll: handleScroll,
                    },
                }}
            />
        );
    }
);

export default CustomAutoComplete;
