import CheckboxComponent from "./CheckboxComponent";
import AudioComponent from "./AudioComponent";
import VideoComponent from "./VideoComponent";
import FileComponent from "./FileComponent";
import TextComponent from "./TextComponent";
import RadioComponent from "./RadioComponent";
import { Box } from "@mui/material";
import { memo } from "react";
import {
    DragIcon,
} from "../../svgComponents";
import ImageWrapper from "./ImageWrapper";
import { TEST_STORAGE_AUDIO, TEST_STORAGE_CHECKBOX, TEST_STORAGE_FILE, TEST_STORAGE_RADIO, TEST_STORAGE_TEXT, TEST_STORAGE_VIDEO } from "../../../utils/testTypesStorage";

const QuestionWrapper = memo(
    ({
        data,
        type,
        onClick,
        index,
        types,
        handleQuestion,
        handleQuestionField,
        provided,
    }) => {
        console.log("Question wrapper rendered")
        return (
            <Box
                {...provided.draggableProps}
                ref={provided.innerRef}
                onClick={() => onClick(index)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    borderRadius: "8px",
                    border: "1px solid rgba(226, 228, 231, 1)",
                    width: "614px",
                    gap: "24px",
                    backgroundColor: "white",
                    cursor: "pointer",
                    position: "relative",
                    transform: "translateZ(0)",
                    willChange: "transform",
                    outline: "none",
                    mt: 2,
                }}
            >
                <Box
                    {...provided.dragHandleProps}
                >
                    <DragIcon
                        onMouseDown={() => onClick(index)}
                        sx={{
                            width: 48,
                            height: 48,
                            position: "absolute",
                            top: 0,
                            left: "-56px",
                        }}
                        svgSx={{
                            width: 24,
                            height: 24,
                            color: "rgba(165, 169, 173, 0.5)",
                            hoverColor: "rgba(173, 165, 165, 0.75)",
                            pressedColor: "rgba(165, 169, 173, 1)",
                            backgroundColor: "rgba(165, 169, 173, 0.5)",
                            hoverBackColor: "rgba(165, 169, 173, 0.75)",
                            pressedBackColor: "rgba(165, 169, 173, 1)",
                        }}
                        noFill={true}
                    />
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        // minHeight: "158px",
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                        }}
                    >
                        {/* Order and Question Input */}
                        {data.image_path && (
                            <Box sx={{ position: "relative" }}>
                                <ImageWrapper
                                    imgKey={data.image_path}
                                    imageFit="cover"
                                    imageRadius={4}
                                    index={index}
                                    onClick={() =>
                                        handleQuestionField(index, "image_path", null)
                                    }
                                />
                            </Box>
                        )}
                        {type === TEST_STORAGE_VIDEO && (
                            <VideoComponent
                                data={data}
                                isActive={false}
                                order={index + 1}
                                types={types}
                                handleQuestion={handleQuestion}
                                handleQuestionField={handleQuestionField}
                            />
                        )}
                        {type === TEST_STORAGE_AUDIO && (
                            <AudioComponent
                                data={data}
                                isActive={false}
                                order={index + 1}
                                types={types}
                                handleQuestion={handleQuestion}
                                handleQuestionField={handleQuestionField}
                            />
                        )}
                        {type === TEST_STORAGE_FILE && (
                            <FileComponent
                                data={data}
                                isActive={false}
                                order={index + 1}
                                types={types}
                                handleQuestion={handleQuestion}
                                handleQuestionField={handleQuestionField}
                            />
                        )}
                        {type === TEST_STORAGE_RADIO && (
                            <RadioComponent
                                data={data}
                                isActive={false}
                                order={index + 1}
                                types={types}
                                handleQuestion={handleQuestion}
                                handleQuestionField={handleQuestionField}
                            />
                        )}
                        {type === TEST_STORAGE_CHECKBOX && (
                            <CheckboxComponent
                                data={data}
                                isActive={false}
                                order={index + 1}
                                types={types}
                                handleQuestion={handleQuestion}
                                handleQuestionField={handleQuestionField}
                            />
                        )}
                        {type === TEST_STORAGE_TEXT && (
                            <TextComponent
                                data={data}
                                isActive={false}
                                order={index + 1}
                                types={types}
                                handleQuestion={handleQuestion}
                                handleQuestionField={handleQuestionField}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        );
    }
);

export default QuestionWrapper;
