import { Box, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { memo, useCallback, useEffect, useState } from "react";
import CustomInput from "../styledComponents/CustomInput";
import { PlusIcon, TrashIcon } from "../svgComponents";
import {
    AI_STATUS_ERROR,
    AI_STATUS_LOADING,
    AI_STATUS_SUCCESS,
} from "../../utils/statusesStorage";
import CustomAiButton from "../styledComponents/CustomAiButton";
import {
    getCriteria,
    passAdditionalCriteria,
    passBaseCriteria,
} from "../../services/aiApi";

const importanceArray = {
    0: "Необязательно",
    1: "Желательно",
    2: "Важно",
    3: "Обязательно",
};

const mapImportance = (importance) => {
    switch (importance) {
        case "required":
            return "Обязательно";
        case "important":
            return "Важно";
        case "desirable":
            return "Желательно";
        default:
            return "Необязательно";
    }
};

const invertMapImportance = (importance) => {
    switch (importance) {
        case "Обязательно":
            return "required";
        case "Важно":
            return "important";
        case "Желательно":
            return "desirable";
        default:
            return "not_required";
    }
};

const ImportanceCard = memo(
    ({ idx, value, onChange, importance, onDelete, ai_created }) => {
        const [dotCount, setDotCount] = useState(0);

        useEffect(() => {
            if (ai_created === AI_STATUS_LOADING) {
                const interval = setInterval(() => {
                    setDotCount((prev) => (prev + 1) % 4); // Cycles through 0, 1, 2, 3
                }, 500); // Adjust the interval (in ms) for speed as needed
                return () => clearInterval(interval);
            }
        }, [ai_created]);

        return (
            <Box
                sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                }}
            >
                <CustomInput
                    value={
                        ai_created === AI_STATUS_LOADING
                            ? `Генерация критерия${".".repeat(dotCount)}`
                            : value
                    }
                    onChange={(value) =>
                        onChange(idx, "name", value, ai_created)
                    }
                    placeholder={
                        ai_created === AI_STATUS_ERROR
                            ? "Ошибка генерации критерия, введите текст вручную"
                            : "Введите критерий"
                    }
                    readOnly={ai_created === AI_STATUS_LOADING}
                    sx={{
                        flex: 1,
                        minWidth: "fit-content",
                    }}
                />
                {Object.values(importanceArray).map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor:
                                ai_created === AI_STATUS_LOADING
                                    ? "default"
                                    : "pointer",
                            padding: "13px 12px",
                            borderRadius: "8px",
                            fontSize: "14px",
                            lineHeight: "18px",
                            width: "140px",
                            justifyContent: "center",
                            color:
                                importance === importanceArray[index]
                                    ? "white"
                                    : "rgba(21, 24, 19, 1)",
                            backgroundColor:
                                importance === importanceArray[index]
                                    ? "rgba(174, 140, 228, 1)"
                                    : "transparent",
                            border: "1px solid",
                            borderColor:
                                importance === importanceArray[index]
                                    ? "rgba(149, 105, 217, 1)"
                                    : "rgba(226, 228, 231, 1)",
                            // Only add hover/active styles if ai_created is false.
                            ...(!(ai_created === AI_STATUS_LOADING) && {
                                "&:hover": {
                                    backgroundColor:
                                        importance === importanceArray[index]
                                            ? "rgba(174, 140, 228, 1)"
                                            : "rgba(220, 207, 243, 1)",
                                    borderColor:
                                        importance === importanceArray[index]
                                            ? "rgba(149, 105, 217, 1)"
                                            : "rgba(189, 162, 233, 1)",
                                },
                                "&:active": {
                                    backgroundColor: "rgba(174, 140, 228, 1)",
                                    borderColor: "rgba(149, 105, 217, 1)",
                                },
                            }),
                        }}
                        onClick={() => {
                            if (!(ai_created === AI_STATUS_LOADING))
                                onChange(
                                    idx,
                                    "importance",
                                    importanceArray[index]
                                );
                        }}
                    >
                        {item}
                    </Box>
                ))}
                <TrashIcon
                    onClick={() => onDelete(idx)}
                    sx={{
                        cursor: "pointer",
                        width: 46,
                        height: 46,
                        "&:hover": {
                            backgroundColor: "rgba(242, 242, 242, 1)",
                        },
                        borderRadius: "8px",
                    }}
                    svgSx={{
                        width: 24,
                        height: 24,
                        color: "rgba(173, 181, 189, 1)",
                    }}
                />
            </Box>
        );
    }
);

const InformationCard = memo(
    ({ description, title, subtitle, data, onChange, field, draftId }) => {
        const [currentAiId, setCurrentAiId] = useState(null);
        const queryClient = useQueryClient();

        const { data: criteriaData, isError } = useQuery(
            ["criteria", title, draftId, currentAiId], // Ensure currentAiId is part of the key
            () => getCriteria(currentAiId), // Pass actual ID, not a string
            {
                refetchInterval: data?.some(
                    (item) => item.ai_created === AI_STATUS_LOADING
                )
                    ? 5000
                    : false,
                initialData: { data: [] },
                enabled: Boolean(currentAiId), // Ensure query runs only when ID exists
                retry: false, // Disable retries
            }
        );

        const askAiBaseMutation = useMutation(
            () => passBaseCriteria({ description, evaluation_stage: field }),
            {
                onSuccess: ({ data }) => {
                    setCurrentAiId(data.request_id);
                },
            }
        );

        const askAiAdditionalMutation = useMutation(
            () =>
                passAdditionalCriteria({
                    description,
                    evaluation_stage: field,
                    previous_criterion: data
                        .filter(
                            (item) =>
                                item.ai_created !== AI_STATUS_LOADING &&
                                item.importance !== "Необязательно"
                        )
                        .map((item) => ({
                            criteria: item.name,
                            importance: invertMapImportance(item.importance),
                        })),
                }),
            {
                onSuccess: ({ data }) => {
                    queryClient.invalidateQueries(["criteria", title, draftId]);
                    setCurrentAiId(data.request_id);
                },
            }
        );

        useEffect(() => {
            if (!criteriaData?.data || criteriaData.data.length === 0) return;

            onChange(field, (prevData) => {
                let newData = [...prevData];
                let criteriaIndex = 0;

                for (let i = 0; i < newData.length; i++) {
                    if (newData[i].ai_created === AI_STATUS_LOADING) {
                        if (criteriaIndex < criteriaData.data.length) {
                            // Replace with generated criteria
                            newData[i] = {
                                ...newData[i],
                                name: criteriaData.data[criteriaIndex].criteria,
                                importance: mapImportance(
                                    criteriaData.data[criteriaIndex].importance
                                ),
                                ai_created: AI_STATUS_SUCCESS, // Mark as completed
                            };
                            criteriaIndex++;
                        } else {
                            // No more criteria available, mark as error
                            newData[i].ai_created = AI_STATUS_ERROR;
                        }
                    }
                }
                return newData;
            });
        }, [criteriaData, onChange, field]);

        useEffect(() => {
            // Check if there's 3 items with AI_STATUS_LOADING
            if (
                data.length === 3 &&
                data.filter((item) => item.ai_created === AI_STATUS_LOADING)
                    .length === 3
            ) {
                askAiBaseMutation.mutate();
            } else if (
                data.some((item) => item.ai_created === AI_STATUS_LOADING)
            ) {
                onChange(field, (prevData) =>
                    prevData.map((item) =>
                        item.ai_created === AI_STATUS_LOADING
                            ? {
                                  ai_created: AI_STATUS_ERROR,
                                  name: "",
                                  importance: "Необязательно",
                              }
                            : item
                    )
                );
            }
        }, []);

        useEffect(() => {
            if (!currentAiId) return;
            setCurrentAiId(null);
        }, [data]);

        useEffect(() => {
            if (isError) {
                onChange(field, (prevData) =>
                    prevData.map((item) =>
                        item.ai_created === AI_STATUS_LOADING
                            ? {
                                  ai_created: AI_STATUS_ERROR,
                                  name: "",
                                  importance: "Необязательно",
                              }
                            : item
                    )
                );
                setCurrentAiId(null);
                return;
            }
        }, [isError, onChange, field]);

        const handleChange = useCallback(
            (index, field2, value, value2) => {
                onChange(field, (prevData) => {
                    const newData = [...prevData];
                    newData[index][field2] = value;
                    if (value2) {
                        newData[index]["ai_created"] = null;
                    }
                    return newData;
                });
            },
            [onChange, field]
        );

        const handleDelete = useCallback(
            (index) => {
                onChange(field, (prevData) => {
                    const newData = [...prevData];
                    newData.splice(index, 1);
                    return newData;
                });
            },
            [onChange, field]
        );

        const handleAdd = useCallback(() => {
            onChange(field, (prevData) => {
                return [...prevData, { name: "", importance: "Необязательно" }];
            });
        }, [onChange, field]);

        const handleNewGenerated = useCallback(() => {
            if (data.some((item) => item.ai_created === AI_STATUS_LOADING)) {
                return;
            }
            onChange(field, (prevData) => {
                // Check if there's already an item with AI_STATUS_LOADING
                return [
                    ...prevData,
                    {
                        name: "",
                        importance: "Необязательно",
                        ai_created: AI_STATUS_LOADING,
                    },
                ];
            });
            // queryClient.invalidateQueries(["criteria", title, draftId]);
            askAiAdditionalMutation.mutate();
        }, [onChange, data, field, title]);

        return (
            <Box
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    gap: "24px",
                    padding: "24px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    overflow: "auto",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "20px",
                        lineHeight: "24px",
                        color: "rgba(21, 24, 19, 1)",
                        fontWeight: 500,
                    }}
                >
                    {title}
                    <Typography
                        component={"span"}
                        sx={{
                            fontSize: "14px",
                            display: "block",
                            lineHeight: "18px",
                            color: "rgba(173, 181, 189, 1)",
                        }}
                    >
                        {subtitle}
                    </Typography>
                </Typography>
                {data.map((item, index) => (
                    <ImportanceCard
                        key={index}
                        idx={index}
                        value={item.name}
                        ai_created={item?.ai_created || false}
                        onChange={handleChange}
                        onDelete={handleDelete}
                        importance={item.importance}
                    />
                ))}
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <CustomAiButton
                        text="Спросить tapAI"
                        onClick={handleNewGenerated}
                        disabled={data.some((item) => item.ai_created === AI_STATUS_LOADING)}
                    />
                    <Box
                        component="button"
                        onClick={handleAdd}
                        sx={{
                            padding: "13px 21px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            borderRadius: "8px",
                            gap: "8px",
                            border: "none",
                            backgroundColor: "transparent",

                            fontSize: "16px",
                            lineHeight: "20px",

                            width: "fit-content",

                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                        }}
                    >
                        <PlusIcon
                            sx={{ width: 20, height: 20 }}
                            svgSx={{
                                width: 10,
                                heigth: 10,
                                color: "rgba(46, 64, 82, 1)",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}
                        >
                            Добавить параметр
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
);

const SecondStep = ({
    vacancyInformation,
    onChange,
    queryKey,
    description,
    draftId,
}) => {
    const queryClient = useQueryClient();

    return (
        <>
            <InformationCard
                title="Резюме"
                subtitle="Введите минимум 3 критерия, которые вы хотите видеть у кандидата в резюме, и задайте их важность."
                data={vacancyInformation?.resume || []}
                onChange={onChange}
                description={description}
                draftId={draftId}
                field="resume"
            />
            <InformationCard
                title="Интервью"
                subtitle="Введите минимум 3 критерия, которые вы хотите оценивать у кандидата на интервью, и задайте их важность."
                field="interview"
                data={vacancyInformation?.interview || []}
                description={description}
                draftId={draftId}
                onChange={onChange}
            />
        </>
    );
};

export default SecondStep;
