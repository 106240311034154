import { Tab, Tabs, Typography } from "@mui/material";

const CustomTabs = ({ children, currentTab, handleTabChange, sx }) => {
    return (
        <Tabs
            variant="scrollable"
            scrollButtons={false}
            value={currentTab}
            onChange={handleTabChange}
            sx={{
                padding: "0 24px",
                gap: "32px",
                height: "80px",
                "& .MuiTabs-scroller": {
                    width: "fit-content",
                    maxWidth: "100%",
                    overflowX: "auto",
                },
                "& .MuiTabs-flexContainer": {
                    my: "15px",
                    height: "47px",
                    backgroundColor: "rgba(248, 249, 249, 1)",
                    borderRadius: "1000px",
                    width: "fit-content",
                    padding: "2px",
                },
                "& .MuiTabs-indicator": {
                    backgroundColor: "rgba(46, 64, 82, 1)",
                    height: "3px",
                    borderTopLeftRadius: "2px",
                    borderTopRightRadius: "2px",
                    display: "none",
                },
                "& .Mui-selected": {
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "1000px",
                    boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.12)",
                },
                ...sx,
            }}
            slotProps={{
                indicator: {
                    style: { transform: "translateY(0px)" },
                },
            }}
        >
            {children}
        </Tabs>
    );
};

const CustomTab = ({index, currentTab, text, ...props}) => {
    const isActive = currentTab === index;
    return (
        <Tab
            value={index}
            label={
                <Typography
                    sx={{
                        color:
                            isActive
                                ? "rgba(46, 64, 82, 1)"
                                : "rgba(121, 128, 136, 1)",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    {text}
                </Typography>
            }
            sx={{
                textTransform: "none",
                minWidth: 100,
                minHeight: 41,
                padding: "9px 8px",
            }}
            {...props}
        />
    );
};

export { CustomTabs, CustomTab };
