import { Box, Divider, Typography } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import CustomSelect from "../../styledComponents/CustomSelect";
import { CircularLoading, CustomToolTip } from "../../tapHrStyledComponents";
import { EyeIcon, InformationIcon } from "../../svgComponents";
import CustomAutoComplete from "../../styledComponents/CustomAutoComplete";
import { fetchWhatsappTemplates } from "../../../services/messengerTemplatesApi";
import { fetchEmailTemplates } from "../../../services/emailTemplatesApi";
import TemplatePreviewModal from "../../common/modalWindows/TemplatePreviewModal";
import { useQuery } from "@tanstack/react-query";
import { fetchDefaultSendings } from "../../../services/testApi";
import { CustomError } from "../../styledComponents/CustomErrors";

const PreviewLabel = memo(({ text, content, handlePreview }) => {
    console.log("rendering label");

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "10px",
            }}
        >
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#151813",
                }}
            >
                {text}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                }}
                onClick={() => handlePreview({ content, title: text })}
            >
                <EyeIcon
                    sx={{ widht: 24, height: 24 }}
                    svgSx={{
                        width: 24,
                        height: 24,
                        color: "rgba(31, 107, 197, 1)",
                    }}
                />
                <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "18px",
                        color: "rgba(31, 107, 197, 1)",
                    }}
                >
                    Превью
                </Typography>
            </Box>
        </Box>
    );
});

const deadlineOptions = [
    { id: 1, name: "1 день" },
    { id: 2, name: "2 дня" },
    { id: 3, name: "3 дня" },
    { id: 4, name: "4 дня" },
    { id: 5, name: "5 дней" },
    { id: 6, name: "6 дней" },
    { id: 7, name: "7 дней" },
];

const TooltipDeadline = () => (
    <CustomToolTip
        title="С момента получения ссылки кандидат должен пройти автособеседование; в противном случае ему будет отказано."
        placement="right"
    >
        <Box>
            <InformationIcon
                sx={{ width: 24, height: 24 }}
                svgSx={{
                    width: 24,
                    height: 24,
                    color: "rgba(155, 162, 169, 1)",
                }}
            />
        </Box>
    </CustomToolTip>
);

const TestSettings = memo(({ quizSettings, setTest }) => {
    const [preview, setPreview] = useState(false);
    const handleTemplateSettingsChange = useCallback((field, value) => {
        setTest((prev) => {
            return {
                ...prev,
                quizSettings: {
                    ...prev.quizSettings,
                    templates: {
                        ...prev.quizSettings.templates,
                        [field]: value,
                    },
                },
            };
        });
    }, []);

    const { isLoading, isError, error } = useQuery(
        ["test_settings"],
        async () => {
            const result = await fetchDefaultSendings();
            if (result?.data) {
                setTest((prev) => {
                    return {
                        ...prev,
                        quizSettings: {
                            ...prev.quizSettings,
                            templates: {
                                ...prev.quizSettings.templates,
                                greetings_email_template: null,
                                greetings_message_template:
                                    result.data?.whatsapp?.invitation,
                                refuse_email_template: null,
                                refuse_message_template: null,
                                notify_email_template: null,
                                notify_message_template:
                                    result.data?.whatsapp?.notification,
                            },
                        },
                    };
                });
            }
            return result;
        },
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const memoizedTooltipDeadline = useMemo(() => <TooltipDeadline />, []);

    const handlePreview = useCallback((content) => {
        if (content) {
            setPreview(content);
        } else {
            setPreview(false);
        }
    }, []);

    const handleSettingsChange = useCallback((field, value) => {
        setTest((prev) => {
            return {
                ...prev,
                quizSettings: { ...prev.quizSettings, [field]: value },
            };
        });
    }, []);

    const handleDeadline = useCallback(
        (value) => handleSettingsChange("quiz_deadline", value),
        []
    );

    console.log("rendering settings");

    return (
        <Box
            sx={{
                width: 590,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                gap: "24px",
                padding: "24px",
                border: "1px solid rgba(241, 242, 243, 1)",
                overflowY: "auto",
            }}
        >
            {isLoading ? (
                <CircularLoading />
            ) : false ? (
                <CustomError error={error?.message || "Неопознанная ошибка"} />
            ) : (
                <>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "24px",
                                lineHeight: "28px",
                                fontWeight: 600,
                                color: "rgba(0, 0, 0, 1)",
                                mt: "12px",
                                mb: "48px",
                            }}
                        >
                            Настройки теста
                        </Typography>
                        <Box
                            sx={{
                                width: "60%",
                            }}
                        >
                            <CustomSelect
                                label="Дедлайн"
                                value={quizSettings.quiz_deadline}
                                placeholder={"Выберите время"}
                                options={deadlineOptions}
                                onChange={handleDeadline}
                            >
                                {memoizedTooltipDeadline}
                            </CustomSelect>
                        </Box>
                    </Box>
                    <Divider
                        sx={{ backgroundColor: "rgba(241, 242, 243, 1)" }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            gap: "36px",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "24px",
                                lineHeight: "28px",
                                fontWeight: 600,
                                color: "black",
                            }}
                        >
                            Автосообщения
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    lineHeight: "22px",
                                    fontWeight: 600,
                                    color: "rgba(46, 64, 82, 1)",
                                }}
                            >
                                Приглашение
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "16px",
                                    width: "100%",
                                }}
                            >
                                {/* <Box sx={{ flex: 1 }}>
                            <PreviewLabel
                                title={"Приглашение через почту"}
                                handlePreview={handlePreview}
                                text="Шаблон письма"
                                content={
                                    quizSettings?.templates?.greetings_email_template
                                        ?.content
                                }
                            />
                            <CustomAutoComplete
                                    additional={{
                                    restrict: true
                                }}
                                value={quizSettings?.templates?.greetings_email_template}
                                setValue={(value) =>
                                    handleTemplateSettingsChange(
                                        "greetings_email_template",
                                        value
                                    )
                                }
                                queryKeyStartingValue={[
                                    "autotest",
                                    "email_templates",
                                ]}
                                fetchFunction={fetchEmailTemplates}
                                placeholder={"по умолчанию"}
                            />
                        </Box> */}
                                <Box sx={{ flex: 1 }}>
                                    <PreviewLabel
                                        title={"Приглашение через сообщение"}
                                        handlePreview={handlePreview}
                                        text="Шаблон сообщения"
                                        content={
                                            quizSettings?.templates
                                                ?.greetings_message_template
                                                ?.content
                                        }
                                    />
                                    <CustomAutoComplete
                                        additional={{
                                            restrict: 1
                                        }}
                                        value={
                                            quizSettings?.templates
                                                ?.greetings_message_template
                                        }
                                        setValue={(value) =>
                                            handleTemplateSettingsChange(
                                                "greetings_message_template",
                                                value
                                            )
                                        }
                                        queryKeyStartingValue={[
                                            "autotest",
                                            "message_templates",
                                        ]}
                                        fetchFunction={fetchWhatsappTemplates}
                                        placeholder={"по умолчанию"}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    lineHeight: "22px",
                                    fontWeight: 600,
                                    color: "rgba(46, 64, 82, 1)",
                                }}
                            >
                                Рассылка при отказе
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "16px",
                                    width: "100%",
                                }}
                            >
                                <Box sx={{ flex: 1 }}>
                                    <PreviewLabel
                                        title={"Отказ через почту"}
                                        handlePreview={handlePreview}
                                        text="Шаблон письма"
                                        content={
                                            quizSettings?.templates
                                                ?.refuse_email_template?.content
                                        }
                                    />
                                    <CustomAutoComplete
                                        additional={{
                                        restrict: true
                        }}
                                        value={
                                            quizSettings?.templates
                                                ?.refuse_email_template
                                        }
                                        setValue={(value) =>
                                            handleTemplateSettingsChange(
                                                "refuse_email_template",
                                                value
                                            )
                                        }
                                        queryKeyStartingValue={[
                                            "autotest",
                                            "email_templates",
                                        ]}
                                        fetchFunction={fetchEmailTemplates}
                                        placeholder={"по умолчанию"}
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <PreviewLabel
                                        title={"Отказ через сообщение"}
                                        handlePreview={handlePreview}
                                        text="Шаблон сообщения"
                                        content={
                                            quizSettings?.templates
                                                ?.refuse_message_template
                                                ?.content
                                        }
                                    />
                                    <CustomAutoComplete
                                        additional={{
                                        restrict: true
}}
                                        value={
                                            quizSettings?.templates
                                                ?.refuse_message_template
                                        }
                                        setValue={(value) =>
                                            handleTemplateSettingsChange(
                                                "refuse_message_template",
                                                value
                                            )
                                        }
                                        queryKeyStartingValue={[
                                            "autotest",
                                            "message_templates",
                                        ]}
                                        fetchFunction={fetchWhatsappTemplates}
                                        placeholder={"по умолчанию"}
                                    />
                                </Box>
                            </Box>
                        </Box> */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    lineHeight: "22px",
                                    fontWeight: 600,
                                    color: "rgba(46, 64, 82, 1)",
                                }}
                            >
                                Рассылка для напоминания
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "16px",
                                    width: "100%",
                                }}
                            >
                                {/* <Box sx={{ flex: 1 }}>
                            <PreviewLabel
                                title={"Рассылка через почту"}
                                handlePreview={handlePreview}
                                text="Шаблон письма"
                                content={
                                    quizSettings?.templates?.notify_email_template?.content
                                }
                            />
                            <CustomAutoComplete
                                    additional={{
                                    restrict: true
                                }}
                                value={quizSettings?.templates?.notify_email_template}
                                setValue={(value) =>
                                    handleTemplateSettingsChange(
                                        "notify_email_template",
                                        value
                                    )
                                }
                                queryKeyStartingValue={[
                                    "autotest",
                                    "email_templates",
                                ]}
                                fetchFunction={fetchEmailTemplates}
                                placeholder={"по умолчанию"}
                            />
                        </Box> */}
                                <Box sx={{ flex: 1 }}>
                                    <PreviewLabel
                                        title={"Рассылка через сообщение"}
                                        handlePreview={handlePreview}
                                        text="Шаблон сообщения"
                                        content={
                                            quizSettings?.templates
                                                ?.notify_message_template
                                                ?.content
                                        }
                                    />
                                    <CustomAutoComplete
                                        additional={{
                                            restrict: 2
                                        }}
                                        value={
                                            quizSettings?.templates
                                                ?.notify_message_template
                                        }
                                        setValue={(value) =>
                                            handleTemplateSettingsChange(
                                                "notify_message_template",
                                                value
                                            )
                                        }
                                        queryKeyStartingValue={[
                                            "autotest",
                                            "message_templates",
                                        ]}
                                        fetchFunction={fetchWhatsappTemplates}
                                        placeholder={"по умолчанию"}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            {preview && (
                <TemplatePreviewModal
                    body={preview}
                    handleClose={() => handlePreview(false)}
                />
            )}
        </Box>
    );
});

export default TestSettings;
