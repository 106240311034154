import { SearchIcon } from "../svgComponents"
import { Box } from "@mui/system"

export const CustomSearch = ({sx, setValue}) => {
    return (
        <Box
            sx={{
                width: "656px",
                backgroundColor: "transparent",
                borderRadius: "10px",
                padding: "18px 16px",
                display: "flex",
                border: "1px solid rgba(213, 215, 218, 1)",
                alignItems: "center",
                ...sx
            }}
        >
            <SearchIcon 
                sx={{ width: 20, height: 20, mr: "8px"}}
                svgSx={{ width: 20, height: 20, color: "rgba(173, 181, 189, 1)"}}
            />
            <input
                onChange={(e) => setValue(e.target.value)}
                type="text"
                placeholder="Поиск по кандидату"
                style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                    fontSize: "16px",
                }}
            />
        </Box>
    )
}