import { memo } from "react";
import { Box, Divider, Link, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { downloadFile } from "../../services/candidatesApi";
import { downloadFileFromUrl } from "../../utils/downloadDocument";
import { DocumentIcon } from "../svgComponents";
import { SOURCE_HEADHUNTER } from "../../utils/manageSources";

const getName = (firstName, lastName) => {
    let fullName = "";
    if (firstName) {
        fullName += firstName;
    }
    if (lastName) {
        fullName += ` ${lastName}`;
    }
    fullName += " резюме.pdf";
    return fullName;
};

const FileDownloadComponent = memo(({ id, firstName, lastName, fileUrl, sourceId }) => {
    const fileName = getName(firstName, lastName);
    const getFileMutation = useMutation(
        (data) => downloadFile(id, fileName, data),
        {
            onSuccess: (data) => {
                // Создаем объект Blob для загрузки файла
                const blob = new Blob([data]);
                const url = window.URL.createObjectURL(blob);
                downloadFileFromUrl(url, fileName);
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    const handleFileClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        if (sourceId !== SOURCE_HEADHUNTER) {
            downloadFileFromUrl(fileUrl, fileName);
            return;
        }
        getFileMutation.mutate({ cvPath: fileUrl });
    };

    return (
        <Box
            sx={{
                padding: "24px",
                backgroundColor: "#fff",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontWeight: "500",
                    fontSize: "20px",
                }}
            >
                Файлы
            </Typography>
            <Divider sx={{ margin: "16px 0" }} />
            {fileUrl ? (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "12px 20px 12px 0",
                        gap: "8px",
                        "&:hover a": {
                            textDecoration: "underline",
                        },
                        borderRadius: "8px",
                        cursor: "pointer",
                    }}
                >
                    <DocumentIcon
                        sx={{ width: "20px", height: "20px" }}
                        svgSx={{
                            width: "20px",
                            height: "20px",
                            color: "rgba(31, 107, 197, 1)",
                        }}
                    />
                    <Link
                        onClick={handleFileClick}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                        sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            color: "rgba(31, 107, 197, 1)",
                        }}
                    >
                        {fileName}
                    </Link>
                </Box>
            ) : (
                <Typography>Данный отклик не содержит файлов</Typography>
            )}
        </Box>
    );
});

export default FileDownloadComponent;