import { memo } from "react";
import { Box, Divider } from "@mui/material";
import { LinkIcon } from "../../svgComponents";
import QuestionPreview from "./QuestionPreview";
import CustomSelect from "../../styledComponents/CustomSelect";

const FileComponent = memo(({ types, data, isActive, order, handleQuestion }) => {
    return isActive ? (
        <Box sx={{ display: "flex", gap: "16px", width: "50%"}}>
            {/* Тип ответа */}
            <CustomSelect 
                label="Тип ответа"
                value={data.type} 
                onChange={(value) => handleQuestion(order - 1, value)}
                options={types.names}
                icons={types.icons}
            />
        </Box>
    ) : (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "16px",
            }}
        >
            <QuestionPreview
                title={`Вопрос №${order}`}
                subtitle={data.text}
            />
            <Divider
                sx={{
                    width: "100%",
                    backgroundColor: "rgba(241, 242, 243, 1)",
                }}
            />
            <QuestionPreview
                title="Тип ответа"
                icon={
                    <LinkIcon
                        sx={{ width: 20, height: 20 }}
                        svgSx={{
                            width: 20,
                            height: 20,
                            color: "rgba(173, 181, 189, 1)",
                        }}
                    />
                }
                subtitle={"Файл"}
                sx={{ width: "314px" }}
            />
        </Box>
    );
});
export default FileComponent;
