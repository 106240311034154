import { Box, Typography, Tabs, Tab, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TabLabel } from "../common/TabComponents";
import { getOflineAdresses, getTariff } from "../../services/profileSettings";
import AddressesComponent from "./AddressesComponent";
import TariffComponent from "./TariffComponent";
import { useQuery } from "@tanstack/react-query";

const CompanyInfo = () => {
    const [activeTab, setActiveTab] = useState(0);
    const tabRef = useRef(null);
    const addressQueryKey = useRef("addresses");

    const {
        data: addressData,
        isLoading: isAddressLoading,
        isError,
        error,
    } = useQuery([addressQueryKey.current], getOflineAdresses, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        enabled: activeTab === 0,
    });

    const {
        data: tariffData,
        isLoading: isTariffLoading,
        isError: isTariffError,
        error: tariffError,
    } = useQuery(["tariff"], getTariff, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        enabled: activeTab === 1,
    });

    useEffect(() => {
        if (tabRef.current) {
            tabRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [activeTab]);

    return (
        <Box
            sx={{
                mt: 2,
            }}
        >
            <Box
                sx={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    backgroundColor: "white",
                    pt: 3,
                    mb: 2,
                }}
            >
                <Typography
                    sx={{
                        fontSize: "24px",
                        fontWeight: 600,
                        lineHeight: "28px",
                        color: "rgba(21, 24, 19, 1)",
                        mb: 3,
                        pl: 3,
                    }}
                >
                    Данные компании
                </Typography>
                <Tabs
                    ref={tabRef}
                    value={activeTab}
                    onChange={(e, newValue) => {
                        setActiveTab(newValue);
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "rgba(89, 157, 21, 1)",
                            height: "3px",
                            borderRadius: "100px 100px 0 0",
                        },
                    }}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        "& .MuiTab-root": {
                            maxWidth: 135,
                            textTransform: "none",
                            padding: "0 8px",
                        },
                        paddingLeft: "24px",
                        mt: 3,
                    }}
                >
                    <Tab
                        label={
                            <TabLabel
                                text={"Филиалы"}
                                activeMainTab={activeTab}
                                index={0}
                            />
                        }
                    />
                    <Tab
                        label={
                            <TabLabel
                                text={"Тариф"}
                                activeMainTab={activeTab}
                                index={1}
                            />
                        }
                    />
                </Tabs>
                <Divider sx={{ backgroundColor: "rgba(226, 228, 231, 1)" }} />
            </Box>
            <Box>
                {activeTab === 0 && <AddressesComponent 
                    data={addressData?.data}
                    isLoading={isAddressLoading}
                    isError={isError}
                    error={error}
                    queryKey={addressQueryKey.current}
                />}
                {activeTab === 1 && (
                    <TariffComponent
                        data={tariffData?.data}
                        isLoading={isTariffLoading}
                        isError={isTariffError}
                        error={tariffError}
                    />
                )}
            </Box>
        </Box>
    );
};

export default CompanyInfo;
