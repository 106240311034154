import React, { useState } from "react";
import { format, startOfWeek, addDays, isToday } from "date-fns";
import { Tooltip, Popover } from "@mui/material";  // ✅ Добавляем Tooltip
import { ru } from "date-fns/locale";
import "./WeekView.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteSlotAction } from "../../services/calendarApi";

const dayShortNames = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

const WeekView = ({ currentDate, slots, onEditSlot }) => {
    const queryClient = useQueryClient();
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [anchorPosition, setAnchorPosition] = useState(null);


    console.log(slots)

    console.log(slots)
    const startWeek = startOfWeek(currentDate, { weekStartsOn: 1 });

    // Формируем массив дней недели (Пн - Вс)
    const weekDays = Array.from({ length: 7 }, (_, i) => addDays(startWeek, i));

    // Формируем массив часов (00:00 - 23:00)
    const hours = Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, "0")}:00`);

    // Если slots - массив, объединяем все intervals в один массив
    const combinedIntervals = Array.isArray(slots)
        ? slots.flatMap(slot => slot.intervals || [])
        : slots?.intervals || [];

    // Создаем объект, где ключ - день недели, а значение - массив интервалов
    const intervalsByDay = combinedIntervals.reduce((acc, slot) => {
        if (!acc[slot.day_of_week]) acc[slot.day_of_week] = [];
        acc[slot.day_of_week].push(slot);
        return acc;
    }, {});

    console.log("intervalsByDay:", intervalsByDay);
    console.log("weekDays:", weekDays.map(d => format(d, "yyyy-MM-dd")));

    console.log(selectedSlot)

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const COLORS = [
        { border: "#599D15", background: "#CDDDC6" },   // зелёный
        { border: "#FF3B30", background: "#FFC9C8" },   // красный
        { border: "#1F6BC5", background: "#C6D1EA" },  // синий
        { border: "#E8B931", background: "#F6E6C8" },  // жёлтый
    ];

    const deleteMutation = useMutation({
        mutationFn: (id) => deleteSlotAction(id),
        onSuccess: () => {
            setSelectedSlot(null);
            setAnchorPosition(null);
            queryClient.invalidateQueries(['slots']); // 👈 название запроса с useQuery
        },
        onError: (error) => {
            console.error("Ошибка при удалении слота:", error);
        },
    });



    const deleteSlot = () => {
        if (!selectedSlot?.slotObjId) return;
        deleteMutation.mutate(selectedSlot.slotObjId);
    };

    // Изменяем обработчик кнопки редактирования
    const handleEditClick = () => {
        if (!selectedSlot) return;

        // Находим полный объект слота, которому принадлежит выбранный интервал
        const fullSlot = Array.isArray(slots)
            ? slots.find(slot => slot.id === selectedSlot.slotObjId)
            : slots;

        if (!fullSlot) return;

        // Формируем данные для редактирования
        const editData = {
            ...fullSlot, // Копируем все свойства слота
            // slot_name: fullSlot.title || "", // Используем title как slot_name
            // slotObjId: fullSlot.id, // ID для обновления
            // intervals: fullSlot.intervals || [], // Все интервалы слота
            // settings: fullSlot.settings || {} // Все настройки слота
        };

        console.log(editData)

        onEditSlot(editData); // Передаем полный слот в CalendarPage
        setAnchorPosition(null); // Закрываем поповер
    };

    return (
        <div className="week-view-container">
            <div className="week-view">
                <div className="week-header">
                    <div className="week-time-label">Время</div>
                    {weekDays.map((day, i) => {
                        const isCurrentDay = isToday(day);

                        return (
                            <div key={i} className={`week-day ${isCurrentDay ? "today" : ""}`}>
                                <span className="week-date">{format(day, "dd", { locale: ru })}</span>
                                <span className="week-day-name">{dayShortNames[i]}</span>
                            </div>
                        );
                    })}
                </div>
                <div className="week-body">
                    <div className="time-column">
                        {hours.map((hour, i) => (
                            <div key={i} className="time-cell">
                                <span>{hour}</span>
                            </div>
                        ))}
                    </div>
                    <div className="week-grid">
                        {weekDays.map((day, dayIndex) => {
                            const jsDayOfWeek = day.getDay();
                            const mappedDayOfWeek = jsDayOfWeek === 0 ? 7 : jsDayOfWeek;

                            // Проверяем, является ли slots массивом
                            const normalizedSlots = Array.isArray(slots) ? slots : [slots];

                            // Группируем интервалы по дню недели
                            const daySlots = normalizedSlots.flatMap(slotObj =>
                                slotObj?.intervals
                                    ?.filter(interval => interval.day_of_week === mappedDayOfWeek)
                                    .map(interval => ({
                                        ...interval,
                                        title: slotObj.title || "Без названия",
                                        slotObjId: slotObj.id, // <-- вот это добавляем
                                    })) || []
                            );


                            return (
                                <div
                                    key={dayIndex}
                                    className={`day-column ${isToday(day) ? "today-column" : ""}`}
                                    style={{ position: "relative" }}
                                >
                                    {/* Отрисовка ячеек сетки */}
                                    {hours.map((_, hourIndex) => (
                                        <div key={hourIndex} className="week-cell"></div>
                                    ))}

                                    {/* Рисуем слоты как блоки поверх сетки */}
                                    {daySlots.slice(0, 4).map((slot, slotIndex) => {
                                        const [startHour] = slot.start_time.split(":").map(Number);
                                        const [endHour] = slot.end_time.split(":").map(Number);

                                        const slotTop = startHour * 80 + 80; // Высота ячейки 80px
                                        const slotHeight = (endHour - startHour) * 80;
                                        const slotLeft = slotIndex * 38; // Фиксированный шаг для каждого слота
                                        const borderColor = `hsl(${slotIndex * 60}, 70%, 60%, 1)`;
                                        const slotColor = `hsl(${slotIndex * 60}, 70%, 60%, 0.5)`;
                                        const color = COLORS[slotIndex % COLORS.length];

                                        return (
                                            <div
                                                key={slotIndex}
                                                className="slot-block"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const slotColor = `hsl(${slotIndex * 60}, 70%, 60%, 0.5)`;
                                                    setSelectedSlot({
                                                        ...slot,
                                                        day,
                                                        slotColor: color.background,
                                                    });
                                                    setAnchorPosition({ top: e.clientY, left: e.clientX });
                                                }}
                                                style={{
                                                    position: "absolute",
                                                    top: `${slotTop}px`,
                                                    height: `${slotHeight}px`,
                                                    left: `${slotLeft}px`,
                                                    width: "30px",
                                                    border: `2px solid ${color.border}`,
                                                    borderRadius: "6px",
                                                    cursor: "pointer",
                                                    backgroundColor: "transparent", // Убираем фон отсюда
                                                    padding: "2px", // Создаёт зазор между border и вложенным контентом
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        borderRadius: "6px",
                                                        backgroundColor: color.background,
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                        justifyContent: "center",
                                                        paddingTop: "4px",
                                                    }}
                                                >
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.2826 8.65886C12.7184 8.22309 13.4249 8.22309 13.8607 8.65886C14.2964 9.09462 14.2964 9.80114 13.8607 10.2369L10.5175 13.5801C10.437 13.6605 10.3374 13.7191 10.228 13.7503L9.05183 14.0856C8.67484 14.1931 8.32643 13.8447 8.43391 13.4677L8.76921 12.2915C8.8004 12.1821 8.85899 12.0825 8.93942 12.0021L12.2826 8.65886Z" fill={color.border} />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.02083 1C6.02083 0.723858 5.79698 0.5 5.52083 0.5C5.24469 0.5 5.02083 0.723858 5.02083 1V2.17281C3.3808 2.67212 2.1875 4.1967 2.1875 6V11.3128C2.1875 12.981 3.53984 14.3333 5.20805 14.3333C5.40832 14.3333 5.60039 14.2538 5.74201 14.1122L13.9442 5.90996C14.1 5.75418 14.1875 5.5429 14.1875 5.3226C14.1875 3.6538 12.9572 2.27235 11.3542 2.03577V1C11.3542 0.723858 11.1303 0.5 10.8542 0.5C10.578 0.5 10.3542 0.723858 10.3542 1V2H6.1875C6.13167 2 6.07611 2.00114 6.02083 2.00341V1ZM9.91551 6.64033C10.085 6.42235 10.0458 6.10822 9.82781 5.93868C9.60983 5.76915 9.2957 5.80841 9.12616 6.02639L8.01038 7.46096C7.95098 7.53733 7.83925 7.54724 7.76733 7.48252L7.18865 6.96171C6.9834 6.77698 6.66725 6.79362 6.48252 6.99887C6.29779 7.20413 6.31443 7.52027 6.51969 7.705L7.09836 8.22581C7.60178 8.67889 8.38392 8.60951 8.79973 8.0749L9.91551 6.64033Z" fill={color.border} />
                                                    </svg>
                                                </div>
                                            </div>

                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Popover
                open={Boolean(anchorPosition)}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
                onClose={() => {
                    setSelectedSlot(null);
                    setAnchorPosition(null);
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{
                    borderRadius: '8px'
                }}
            >
                {selectedSlot && (
                    <div style={{ position: "relative", padding: "16px", width: "519px", height: '152px', padding: '24px' }}>
                        {/* Иконки управления */}
                        <div style={{ position: "absolute", top: 24, right: 24, display: "flex", gap: "20px" }}>
                            {/* Удалить */}
                            <button onClick={deleteSlot} style={{ background: "none", border: "none", cursor: "pointer" }}>
                                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.40627 0.890599C5.7772 0.334202 6.40166 0 7.07037 0H8.92963C9.59834 0 10.2228 0.334202 10.5937 0.8906L11.5 2.25H15.25C15.6642 2.25 16 2.58579 16 3C16 3.41421 15.6642 3.75 15.25 3.75H0.75C0.335786 3.75 0 3.41421 0 3C0 2.58579 0.335786 2.25 0.75 2.25H4.5L5.40627 0.890599ZM11 20H5C2.79086 20 1 18.2091 1 16V5H15V16C15 18.2091 13.2091 20 11 20ZM6 8.25C6.41421 8.25 6.75 8.58579 6.75 9V16C6.75 16.4142 6.41421 16.75 6 16.75C5.58579 16.75 5.25 16.4142 5.25 16L5.25 9C5.25 8.58579 5.58579 8.25 6 8.25ZM10 8.25C10.4142 8.25 10.75 8.58579 10.75 9V16C10.75 16.4142 10.4142 16.75 10 16.75C9.58579 16.75 9.25 16.4142 9.25 16V9C9.25 8.58579 9.58579 8.25 10 8.25Z" fill="gray" />
                                </svg>
                            </button>

                            {/* Редактировать */}
                            <button onClick={handleEditClick} style={{ background: "none", border: "none", cursor: "pointer" }}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="gray" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.75 5C1.75 3.20507 3.20507 1.75 5 1.75H11C11.4142 1.75 11.75 1.41421 11.75 1C11.75 0.585786 11.4142 0.25 11 0.25H5C2.37665 0.25 0.25 2.37665 0.25 5V17C0.25 19.6234 2.37665 21.75 5 21.75H17C19.6234 21.75 21.75 19.6234 21.75 17V11C21.75 10.5858 21.4142 10.25 21 10.25C20.5858 10.25 20.25 10.5858 20.25 11V17C20.25 18.7949 18.7949 20.25 17 20.25H5C3.20507 20.25 1.75 18.7949 1.75 17V5ZM15.419 1.67708C16.3218 0.774305 17.7855 0.774305 18.6883 1.67708L20.3229 3.31171C21.2257 4.21449 21.2257 5.67818 20.3229 6.58096L18.8736 8.03028C18.7598 7.97394 18.6401 7.91302 18.516 7.84767C17.6806 7.40786 16.6892 6.79057 15.9493 6.05069C15.2095 5.31082 14.5922 4.31945 14.1524 3.48403C14.087 3.35989 14.0261 3.24018 13.9697 3.12639L15.419 1.67708ZM14.8887 7.11135C15.7642 7.98687 16.8777 8.67594 17.7595 9.14441L12.06 14.8438C11.7064 15.1975 11.2475 15.4269 10.7523 15.4977L7.31963 15.9881C6.5568 16.097 5.90295 15.4432 6.01193 14.6804L6.50231 11.2477C6.57305 10.7525 6.80248 10.2936 7.15616 9.93996L12.8556 4.24053C13.3241 5.12234 14.0131 6.23582 14.8887 7.11135Z" fill="gray" />
                                </svg>
                            </button>

                            {/* Закрыть */}
                            <button onClick={() => {
                                setAnchorPosition(null);
                                setSelectedSlot(null);
                            }} style={{ background: "none", border: "none", cursor: "pointer" }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.5">
                                        <path d="M4.29279 4.29357C4.48031 4.1061 4.73462 4.00078 4.99979 4.00078C5.26495 4.00078 5.51926 4.1061 5.70679 4.29357L11.9998 10.5866L18.2928 4.29357C18.385 4.19806 18.4954 4.12188 18.6174 4.06947C18.7394 4.01706 18.8706 3.98947 19.0034 3.98832C19.1362 3.98717 19.2678 4.01247 19.3907 4.06275C19.5136 4.11303 19.6253 4.18728 19.7192 4.28117C19.8131 4.37507 19.8873 4.48672 19.9376 4.60962C19.9879 4.73251 20.0132 4.86419 20.012 4.99697C20.0109 5.12975 19.9833 5.26097 19.9309 5.38297C19.8785 5.50498 19.8023 5.61532 19.7068 5.70757L13.4138 12.0006L19.7068 18.2936C19.8889 18.4822 19.9897 18.7348 19.9875 18.997C19.9852 19.2592 19.88 19.51 19.6946 19.6954C19.5092 19.8808 19.2584 19.986 18.9962 19.9882C18.734 19.9905 18.4814 19.8897 18.2928 19.7076L11.9998 13.4146L5.70679 19.7076C5.51818 19.8897 5.26558 19.9905 5.00339 19.9882C4.74119 19.986 4.49038 19.8808 4.30497 19.6954C4.11956 19.51 4.01439 19.2592 4.01211 18.997C4.00983 18.7348 4.11063 18.4822 4.29279 18.2936L10.5858 12.0006L4.29279 5.70757C4.10532 5.52004 4 5.26573 4 5.00057C4 4.73541 4.10532 4.4811 4.29279 4.29357Z" fill="#090B08" />
                                    </g>
                                </svg>
                            </button>
                        </div>

                        {/* Основное содержимое */}
                        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '20px', marginTop: '30px' }}>
                            <div style={{ width: '12px', height: '12px', background: selectedSlot.slotColor, borderRadius: '50%', marginTop: '8px' }}></div>
                            <div>
                                <h3 style={{ margin: "0 0 4px 0", fontSize: '20px', fontWeight: '700' }}>{selectedSlot.title}</h3>
                                <div style={{ fontSize: "16px", color: "#4E5255", width: '100%', display: 'flex', alignItems: 'center', gap: '8px', fontWeight: 400 }}>
                                    {capitalizeFirstLetter(format(selectedSlot.day, "eeee, d MMMM", { locale: ru }))}
                                    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.04688 3.26953C1.28516 3.26953 0.6875 2.68359 0.6875 1.91016C0.6875 1.13672 1.28516 0.550781 2.04688 0.550781C2.80859 0.550781 3.40625 1.13672 3.40625 1.91016C3.40625 2.68359 2.80859 3.26953 2.04688 3.26953Z" fill="#4E5255" />
                                    </svg>
                                    {selectedSlot.start_time.substring(0, 5)} - {selectedSlot.end_time.substring(0, 5)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Popover>
        </div>
    );
};

export default WeekView;