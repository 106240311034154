import { memo, useCallback } from "react";
import { Box } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { createQuestionTemplate } from "../../../services/testApi";
import {
    TEST_STORAGE_AUDIO,
    TEST_STORAGE_CHECKBOX,
    TEST_STORAGE_FILE,
    TEST_STORAGE_RADIO,
    TEST_STORAGE_TEXT,
    TEST_STORAGE_VIDEO,
} from "../../../utils/testTypesStorage";
import { onDragEnd } from "../../../utils/dragAndDrop";
import BacgkroundImage from "../../../assets/images/autoTestBackImage.png";
import {
    AudioIcon,
    LinkIcon,
    ListIcon,
    TIcon,
    PlusIcon,
    VideoIcon2,
} from "../../svgComponents";
import QuestionWrapper from "./QuestionWrapper";
import QuestionWrapperActive from "./QuestionWrapperActive";
import { CircularLoading } from "../../tapHrStyledComponents";
import { useMutation } from "@tanstack/react-query";
import { nullQuestion } from "../../../utils/nullQuestions";

const types = {
    names: [
        { name: "Видео", id: TEST_STORAGE_VIDEO },
        { name: "Аудио", id: TEST_STORAGE_AUDIO },
        { name: "Файл", id: TEST_STORAGE_FILE },
        { name: "Несколько из списка", id: TEST_STORAGE_CHECKBOX },
        { name: "Один из списка", id: TEST_STORAGE_RADIO },
        { name: "Текстовый ответ", id: TEST_STORAGE_TEXT },
    ],
    icons: [
        <VideoIcon2
            sx={{ width: 20, height: 20 }}
            svgSx={{
                width: 20,
                height: 20,
                backgroundColor: "#EF233C",
            }}
        />,
        <AudioIcon
            sx={{ width: 20, height: 20 }}
            svgSx={{ width: 20, height: 20, color: "rgba(175, 82, 222, 1)" }}
        />,
        <LinkIcon
            sx={{ width: 20, height: 20 }}
            svgSx={{ width: 20, height: 20, color: "rgba(251, 153, 7, 1)" }}
        />,
        <ListIcon
            sx={{ width: 20, height: 20 }}
            svgSx={{ width: 20, height: 20, color: "rgba(89, 157, 21, 1)" }}
        />,
        <ListIcon
            sx={{ width: 20, height: 20 }}
            svgSx={{ width: 20, height: 20, color: "rgba(89, 157, 21, 1)" }}
        />,
        <TIcon
            sx={{ width: 20, height: 20 }}
            svgSx={{ width: 20, height: 20, color: "rgba(58, 134, 255, 1)" }}
        />,
    ],
};

const ActiveTestHolder = memo(
    ({ isNull, quizData, active, setTest, activeQuestionRef, isLoading }) => {
        console.log("rendering test");

        const createMutation = useMutation(createQuestionTemplate);

        const handleCreateQuestionTemplate = useCallback(
            (index) => {
                if (quizData[index].text === "") {
                    setTest((prev) => ({
                        ...prev,
                        isNull: true,
                    }));
                    activeQuestionRef?.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                    });
                    return;
                }
                createMutation.mutate({ question: quizData[index] });
            },
            [quizData]
        );

        const handleNewActive = useCallback((index) => {
            setTest((prev) => {
                if (
                    index !== prev.active &&
                    prev.quizData[prev.active].text === ""
                ) {
                    // Mark the state as null
                    const updated = { ...prev, isNull: true };
                    // Scroll the input into view using your activeQuestionRef
                    activeQuestionRef?.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                    });
                    return updated;
                }
                return { ...prev, active: index };
            });
        }, []);

        const handleSwitch = useCallback((index, targetIndex) => {
            setTest((prev) => {
                const newQuizData = [...prev.quizData];
                [newQuizData[index], newQuizData[targetIndex]] = [
                    newQuizData[targetIndex],
                    newQuizData[index],
                ];
                return { ...prev, quizData: newQuizData, active: targetIndex };
            });
        }, []);

        // creates exact duplicate of the question at the next index
        const handleDuplicate = useCallback((index) => {
            setTest((prev) => {
                if (prev.quizData[prev.active].text === "") {
                    // Mark the state as null
                    const updated = { ...prev, isNull: true };
                    // Scroll the input into view using your activeQuestionRef
                    activeQuestionRef?.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                    });
                    return updated;
                }
                const newQuizData = [...prev.quizData];
                newQuizData.splice(index + 1, 0, newQuizData[index]);
                return { ...prev, quizData: newQuizData, active: index + 1 };
            });
        }, []);

        // delete current active element and set the prev element if exist otherwise set the next element
        const handleDelete = useCallback((index) => {
            setTest((prev) => {
                const newQuizData = [...prev.quizData];
                newQuizData.splice(index, 1);
                return {
                    ...prev,
                    quizData: newQuizData,
                    active: index < newQuizData.length ? index : index - 1,
                    isNull: false,
                };
            });
        }, []);

        const handleQuestionField = useCallback((index, field, value) => {
            setTest((prev) => ({
                ...prev,
                quizData: prev.quizData.map((item, i) =>
                    i === index ? { ...item, [field]: value } : item
                ),
                isNull: field === "text" && value !== "" ? false : prev.isNull,
            }));
        }, []);

        const handleQuestion = useCallback((index, type) => {
            setTest((prev) => {
                const newQuizData = [...prev.quizData];
                newQuizData[index] = {
                    text: newQuizData[index].text,
                    type: type,
                    ...structuredClone(nullQuestion[type]),
                    ...(newQuizData[index].answers && (type === TEST_STORAGE_RADIO || type === TEST_STORAGE_CHECKBOX) && { answers: newQuizData[index].answers }),
                };
                return { ...prev, quizData: newQuizData };
            });
        }, []);

        const handleDragEnd = useCallback(
            ({ elementStartIdx: quizStartId, elementEndIdx: quizEndId }) => {
                setTest((prev) => {
                    const newQuizData = [...prev.quizData];

                    // Remove the element from its original position
                    const [movedElement] = newQuizData.splice(quizStartId, 1);

                    // Insert the element at the new position
                    newQuizData.splice(quizEndId, 0, movedElement);

                    if (
                        quizStartId !== prev.active &&
                        prev.quizData[prev.active].text === ""
                    ) {
                        activeQuestionRef?.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "nearest",
                        });
                        return { ...prev, quizData: newQuizData, isNull: true };
                    }

                    return {
                        ...prev,
                        quizData: newQuizData,
                        active: quizEndId,
                    };
                });
            },
            []
        );

        const handleNewQuestion = () => {
            setTest((prev) => {
                if (prev.quizData[prev.active].text === "") {
                    // Mark the state as null
                    const updated = { ...prev, isNull: true };
                    // Scroll the input into view using your activeQuestionRef
                    activeQuestionRef?.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                    });
                    return updated;
                }
                const newQuizData = [...prev.quizData];
                newQuizData.push({
                    text: "",
                    type: TEST_STORAGE_RADIO,
                    ...structuredClone(nullQuestion[TEST_STORAGE_RADIO]),
                });
                return {
                    ...prev,
                    quizData: newQuizData,
                    active: newQuizData.length - 1,
                };
            });
        };

        return (
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${BacgkroundImage})`,
                    backgroundSize: "cover",
                    alignItems: "center",
                    overflowY: "auto",
                    gap: "16px",
                    padding: "8px 0 24px 0px",
                    height: "100%",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                }}
            >
                <DragDropContext onDragEnd={(e) => onDragEnd(e, handleDragEnd)}>
                    <Droppable droppableId="0" direction="vertical">
                        {(provided, snapshot) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="droppable"
                                sx={{
                                    border: snapshot.isDraggingOver
                                        ? "1px solid rgba(89, 157, 21, 1)"
                                        : "1px solid transparent",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {quizData.map((question, index) => (
                                    <Draggable
                                        key={index}
                                        draggableId={index.toString()}
                                        index={index}
                                    >
                                        {(provided) =>
                                            index === active ? (
                                                <QuestionWrapperActive
                                                    provided={provided}
                                                    key={index}
                                                    data={question}
                                                    type={question.type}
                                                    types={types}
                                                    onClick={handleNewActive}
                                                    disableUp={index === 0}
                                                    disableDown={
                                                        index ===
                                                        quizData.length - 1
                                                    }
                                                    isOnly={
                                                        quizData.length === 1
                                                    }
                                                    index={index}
                                                    isActive={index === active}
                                                    handleSwitch={handleSwitch}
                                                    handleDuplicate={
                                                        handleDuplicate
                                                    }
                                                    handleDelete={handleDelete}
                                                    handleQuestion={
                                                        handleQuestion
                                                    }
                                                    handleQuestionField={
                                                        handleQuestionField
                                                    }
                                                    isNull={isNull}
                                                    handleCreateQuestionTemplate={
                                                        handleCreateQuestionTemplate
                                                    }
                                                    questionInputRef={
                                                        activeQuestionRef
                                                    }
                                                />
                                            ) : (
                                                <QuestionWrapper
                                                    provided={provided}
                                                    key={index}
                                                    data={question}
                                                    type={question.type}
                                                    types={types}
                                                    onClick={handleNewActive}
                                                    index={index}
                                                    isActive={index === active}
                                                    handleQuestion={
                                                        handleQuestion
                                                    }
                                                    handleQuestionField={
                                                        handleQuestionField
                                                    }
                                                />
                                            )
                                        }
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
                {isLoading && (
                    <Box>
                        <CircularLoading />
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "614px",
                    }}
                >
                    <Box
                        onClick={handleNewQuestion}
                        component={"button"}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            padding: "16px 24px",
                            borderRadius: "8px",
                            border: "1px solid rgba(46, 64, 82, 1)",
                            fontSize: "18px",
                            lineHeight: "22px",
                            color: "rgba(46, 64, 82, 1)",
                            cursor: "pointer",
                            backgroundColor: "transparent",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(226, 228, 231, 1)",
                            },
                            transform: "translateZ(0)",
                            willChange: "transform",
                            outline: "none",
                        }}
                    >
                        <PlusIcon
                            sx={{ width: 20, height: 20 }}
                            svgSx={{
                                width: 12,
                                height: 12,
                                color: "rgba(46, 64, 82, 1)",
                            }}
                        />
                        Добавить вопрос
                    </Box>
                </Box>
            </Box>
            // <Box>Hello</Box>
        );
    }
);

export default ActiveTestHolder;
