import { memo, useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, Chip } from "@mui/material";
import { MenuIcon, LockIcon, TrashIcon, CheckIcon } from "../svgComponents";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { onDragEnd } from "../../utils/dragAndDrop";
import CustomTestCreation from "./testComponents/CustomTestCreation";
import ActivitySettingsModal from "../common/modalWindows/ActivitySettingsModal";
import { useNavigationBar } from "../main/NavigationBarContext";
import { SettingsIcon } from "../svgComponents";
import useProcesses from "../../hooks/useProcesses";
import {
    ACTIVITY_STATUS_INTERVIEW,
    ACTIVITY_STATUS_TEST,
} from "../../utils/activityStorage";
import { redirectWrapper } from "../../utils/redirectWrapper";

const ThirdStep = memo(
    ({
        stages,
        onChange,
        componentInfo,
        setComponentInfo,
        draftId,
        stashedStageId,
        stashedInterviewToCreate,
    }) => {
        const [activeStageId, setActiveStageId] = useState(
            stashedStageId || null
        );
        const { hideNavigationBar, showNavigationBar } = useNavigationBar();
        const [testToCreate, setTestToCreate] = useState(null);
        const queryKeyProcesses = useRef(["processes"]);
        const [interviewToCreate, setInterviewToCreate] = useState(
            stashedInterviewToCreate || {
                schedule_id: "",
                channels: [],
                slot_duration: 30,
                type: 0,
                meeting_address: null,
                isOnline: false,
            }
        );

        const {
            processesData,
            isProcessesLoading,
            isProcessesError,
            processesError,
        } = useProcesses({
            enabled: Boolean(activeStageId === 0 || activeStageId),
            queryKey: queryKeyProcesses.current,
        });

        useEffect(() => {
            if (
                activeStageId !== null &&
                stages[activeStageId]?.option === ACTIVITY_STATUS_INTERVIEW
            ) {
                setInterviewToCreate({
                    ...stages[activeStageId]?.activity,
                    isOnline: stages[activeStageId]?.activity?.type !== 0,
                });
            }
        }, [activeStageId]);

        const changeIndexes = (stageStartId, stageEndId) => {
            const newStages = [...stages];
            // Remove the element from its original position
            const [movedElement] = newStages.splice(stageStartId, 1);
            // Insert the element at the new position
            newStages.splice(stageEndId, 0, movedElement);
            return newStages;
        };

        useEffect(() => {
            if (componentInfo === "test") {
                hideNavigationBar();
            }
            return () => {
                showNavigationBar();
            };
        }, [componentInfo, hideNavigationBar, showNavigationBar]);

        const handleDragEnd = ({
            elementStartIdx: stageStartId,
            elementEndIdx: stageEndId,
        }) => {
            const newStages = changeIndexes(stageStartId, stageEndId);
            onChange(newStages);
        };

        const handleTest = () => {
            setComponentInfo("test");
        };

        const deleteStage = (index) => {
            const newStages = stages.filter((_, idx) => idx !== index);
            onChange(newStages);
        };

        const handleSaveActivityToStages = (option, activity) => {
            if (!activity || !option) {
                return;
            }
            const newStages = [...stages];
            newStages[activeStageId].activity = activity;
            newStages[activeStageId].option = option;
            onChange(newStages);
            setTestToCreate(null);
            setActiveStageId(null);
        };

        const handleProcessActivation = (provider, link) => {
            if (!provider || !link) return;

            const stateData = {
                interviewToCreate,
                draftId,
                activeStageId,
            };

            window.location.href = redirectWrapper(
                link,
                "/vacancy/create",
                provider,
                stateData
            );
        };

        const handleDeleteTest = () => {
            const newStages = [...stages];
            if (newStages[activeStageId].option === ACTIVITY_STATUS_TEST) {
                delete newStages[activeStageId].activity;
                delete newStages[activeStageId].option;
            }
            onChange(newStages);
            setTestToCreate(null);
        };

        const handleTestSave = (test) => {
            setTestToCreate(test);
            setComponentInfo("step");
        };

        // A small helper to render the chips (if any) for a given stage
        const renderStageChips = (stage) => {
            if (!stage.activity) return null;

            return (
                <Box sx={{ display: "flex", gap: 1, ml: 1 }}>
                    <Chip
                        label={
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 0.5,
                                    alignItems: "center",
                                }}
                            >
                                <CheckIcon
                                    sx={{ width: 16, height: 16 }}
                                    svgSx={{
                                        width: 10,
                                        height: 8,
                                        color: "rgba(18, 156, 82, 1)",
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: "18px",
                                        color: "rgba(18, 156, 82, 1)",
                                    }}
                                >
                                    {stage.option === ACTIVITY_STATUS_TEST
                                        ? "Автособеседование"
                                        : stage.option ===
                                          ACTIVITY_STATUS_INTERVIEW
                                        ? "Календарь"
                                        : null}
                                </Typography>
                            </Box>
                        }
                        sx={{
                            backgroundColor: "rgba(242, 248, 244, 1)",
                        }}
                    />
                </Box>
            );
        };

        return (
            <>
                {componentInfo === "step" && (
                    <>
                        <Typography
                            sx={{
                                color: "rgba(116, 116, 115, 1)",
                                fontSize: "14px",
                                padding: "12px 24px",
                                backgroundColor: "rgba(248, 249, 249, 1)",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                            }}
                        >
                            Название этапа
                        </Typography>

                        {/* FIRST (locked) STAGE */}
                        <Box sx={{ minWidth: "545px", overflowX: "auto" }}>
                            <Box
                                key="first"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    borderBottom: "1px solid #E0E0E0",
                                    padding: "13px 24px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        width: "calc(100% - 116px)",
                                    }}
                                >
                                    <LockIcon
                                        sx={{
                                            minWidth: "40px",
                                            width: "40px!important",
                                            height: "40px",
                                        }}
                                        svgSx={{
                                            width: "24px",
                                            height: "20px",
                                            color: "rgba(21, 24, 19, 1)",
                                        }}
                                    />
                                    <Typography sx={{ width: "65%" }}>
                                        {stages[0].name}
                                    </Typography>
                                    {renderStageChips(stages[0])}
                                    {/* Render the chips for the first stage */}
                                </Box>
                                <SettingsIcon
                                    onClick={() => setActiveStageId(0)}
                                    sx={{
                                        cursor: "pointer",
                                        width: 46,
                                        height: 46,
                                        borderRadius: "8px",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(242, 243, 243, 1)",
                                        },
                                        mr: "70px",
                                    }}
                                    svgSx={{
                                        color: "rgba(21, 24, 19, 1)",
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                            </Box>

                            {/* DRAGGABLE MIDDLE STAGES */}
                            <DragDropContext
                                onDragEnd={(e) => onDragEnd(e, handleDragEnd)}
                            >
                                <Droppable droppableId={"0"}>
                                    {(provided, snapshot) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            sx={{
                                                display: "flex",
                                                border: snapshot.isDraggingOver
                                                    ? "1px solid rgba(89, 157, 21, 1)"
                                                    : "none",
                                                transition:
                                                    "background-color 0.2s ease",
                                                flexDirection: "column",
                                                maxHeight: "401px",
                                                overflowY: "auto",
                                                overflowX: "hidden",
                                            }}
                                        >
                                            {stages.map((stage, index) => {
                                                // Skip the first and last stage for the draggable area
                                                if (
                                                    index === 0 ||
                                                    index === stages.length - 1
                                                ) {
                                                    return null;
                                                }
                                                return (
                                                    <Draggable
                                                        key={index.toString()}
                                                        draggableId={index.toString()}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <Box
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "space-between",
                                                                    alignItems:
                                                                        "center",
                                                                    borderBottom:
                                                                        "1px solid #E0E0E0",
                                                                    padding:
                                                                        "13px 24px",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        gap: "8px",
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <MenuIcon
                                                                        sx={{
                                                                            minWidth:
                                                                                "40px",
                                                                            width: "40px!important",
                                                                            height: "40px",
                                                                        }}
                                                                        svgSx={{
                                                                            width: "16px",
                                                                            height: "14px",
                                                                            color: "rgba(21, 24, 19, 1)",
                                                                        }}
                                                                    />
                                                                    <Typography
                                                                        sx={{
                                                                            width: "65%",
                                                                        }}
                                                                    >
                                                                        {
                                                                            stage.name
                                                                        }
                                                                    </Typography>
                                                                    {/* Render the chips for this stage */}
                                                                    {renderStageChips(
                                                                        stage
                                                                    )}
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        gap: "24px",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <SettingsIcon
                                                                        onClick={() =>
                                                                            setActiveStageId(
                                                                                index
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            width: 46,
                                                                            height: 46,
                                                                            borderRadius:
                                                                                "8px",
                                                                            "&:hover":
                                                                                {
                                                                                    backgroundColor:
                                                                                        "rgba(242, 243, 243, 1)",
                                                                                },
                                                                        }}
                                                                        svgSx={{
                                                                            color: "rgba(21, 24, 19, 1)",
                                                                            width: 24,
                                                                            height: 24,
                                                                        }}
                                                                    />
                                                                    <TrashIcon
                                                                        onClick={() =>
                                                                            deleteStage(
                                                                                index
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            width: 46,
                                                                            height: 46,
                                                                            borderRadius:
                                                                                "8px",
                                                                            "&:hover":
                                                                                {
                                                                                    backgroundColor:
                                                                                        "rgba(242, 243, 243, 1)",
                                                                                },
                                                                        }}
                                                                        svgSx={{
                                                                            color: "rgba(21, 24, 19, 1)",
                                                                            width: 24,
                                                                            height: 24,
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            {/* LAST (locked) STAGE */}
                            <Box
                                key="last"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #E0E0E0",
                                    padding: "13px 24px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        width: "calc(100% - 116px)",
                                    }}
                                >
                                    <LockIcon
                                        sx={{
                                            minWidth: "40px",
                                            width: "40px!important",
                                            height: "40px",
                                        }}
                                        svgSx={{
                                            width: "24px",
                                            height: "20px",
                                            color: "rgba(21, 24, 19, 1)",
                                        }}
                                    />
                                    <Typography sx={{ width: "65%" }}>
                                        {stages[stages.length - 1].name}
                                    </Typography>
                                    {/* Render the chips for the last stage */}
                                    {renderStageChips(
                                        stages[stages.length - 1]
                                    )}
                                </Box>
                                <SettingsIcon
                                    onClick={() =>
                                        setActiveStageId(stages.length - 1)
                                    }
                                    sx={{
                                        cursor: "pointer",
                                        width: 46,
                                        height: 46,
                                        borderRadius: "8px",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(242, 243, 243, 1)",
                                        },
                                        mr: "70px",
                                    }}
                                    svgSx={{
                                        color: "rgba(21, 24, 19, 1)",
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                            </Box>
                        </Box>
                    </>
                )}

                {/* TEST CREATION VIEW */}
                {componentInfo === "test" && (
                    <CustomTestCreation
                        onClose={() => {
                            setComponentInfo("step");
                        }}
                        handleSave={handleTestSave}
                        testInitialData={
                            testToCreate ||
                            (stages[activeStageId].option ===
                                ACTIVITY_STATUS_TEST &&
                                stages[activeStageId]?.activity) ||
                            null
                        }
                    />
                )}

                {/* ACTIVITY SETTINGS MODAL */}
                {componentInfo === "step" && activeStageId !== null && (
                    <ActivitySettingsModal
                        currentOption={stages[activeStageId].option || null}
                        currentActivity={stages[activeStageId].activity || null}
                        testToCreate={testToCreate}
                        handleClose={() => {
                            setActiveStageId(null);
                        }}
                        deleteTest={handleDeleteTest}
                        startTest={handleTest}
                        saveActivity={handleSaveActivityToStages}
                        handleProcessActivation={handleProcessActivation}
                        interviewToCreate={interviewToCreate}
                        setInterviewToCreate={setInterviewToCreate}
                        processesData={processesData}
                        isProcessesLoading={isProcessesLoading}
                        isProcessesError={isProcessesError}
                        processesError={processesError}
                    />
                )}
            </>
        );
    }
);

export default ThirdStep;
