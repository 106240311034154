import { useState, useRef, useCallback } from "react";
import { Typography, Box } from "@mui/material";
import PageContainer from "../../../components/common/PageContainer";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
    Pagination,
    CircularLoading,
} from "../../../components/tapHrStyledComponents";
import { CustomError } from "../../../components/styledComponents/CustomErrors";
import { fetchStages } from "../../../services/stagesApi";
import CreateStagesModalWindow from "../../../components/common/modalWindows/createStageModalWindow";
import { createStage } from "../../../services/stagesApi";
import StagesPanel from "../../../components/stages/StagesPanel";
import { ADD_STATUS } from "../../../utils/statusesStorage";

const StagesPage = () => {
    const [open, setOpen] = useState(false); // Modal window
    const rowsPerPage = useRef(13); // Number of lines
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const queryKey = useRef(["departments"]);
    const queryClient = useQueryClient();

    const { data, isLoading, isError, error } = useQuery(
        [queryKey.current, currentPage, rowsPerPage.current],
        async () => fetchStages({page : currentPage, perPage : rowsPerPage.current}),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true, // Avoid flickering when changing pages
        }
    );

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // status = 1 - add new stage
    // status = 2 - edit stage
    // status = 3 - delete stage
    const handleDataChange = useCallback(
        (newData, status) => {
            if (status === 1 && data?.data?.length === rowsPerPage.current) {
                setCurrentPage((prevPage) => prevPage + 1);
                return;
            }

            queryClient.setQueryData(
                [queryKey.current, currentPage, rowsPerPage.current],
                newData
            );

            if (
                status === 3 &&
                newData?.data?.length === 0 &&
                currentPage !== 1
            ) {
                setCurrentPage((prevPage) => prevPage - 1);
            }
        },
        [queryClient, currentPage, data?.data?.length]
    );

    const createStageMutation = useMutation(
        (data) =>
            createStage({
                page: currentPage,
                perPage: rowsPerPage.current,
                body: data,
            }),
        {
            onSuccess: (data) => {
                handleDataChange(data, ADD_STATUS);
            },
        }
    );

    const handleAddStage = (purpose, name) => {
        createStageMutation.mutate({ purpose, name });
    };

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <PageContainer>
            <Box
                sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: "36px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                }}
            >
                <Box
                    sx={{
                        py: 1,
                        display: "flex",
                        gap: "16px",
                        height: "62px",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            fontSize: "24px",
                            lineHeight: "28px",
                        }}
                    >
                        Этапы
                    </Typography>
                    <Box
                        component={"button"}
                        onClick={handleOpen}
                        sx={{
                            display: "flex",
                            gap: "16px",
                            alignItems: "center",
                            border: "none",
                            padding: "12px 20px",
                            borderRadius: "8px",
                            backgroundColor: "rgba(89, 157, 21, 1)",
                            color: "#fff",
                            cursor: "pointer",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            "&:hover": {
                                backgroundColor: "rgba(80, 140, 19, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(69, 122, 16, 1)",
                            },
                        }}
                    >
                        Создать этап
                    </Box>
                </Box>
                <Box sx={{ minHeight: "990px" }}>
                    {isLoading ? (
                        <CircularLoading />
                    ) : isError ? (
                        <CustomError
                            error={error.message || "Неопознанная причина"}
                        />
                    ) : (
                        <StagesPanel
                            handleDataChange={handleDataChange}
                            stages={data.data || []}
                            currentPage={currentPage}
                            perPage={rowsPerPage.current}
                        />
                    )}
                </Box>
            </Box>
            {/* Пагинация и выбор количества строк */}
            {!isLoading && (
                <Pagination
                    sx={{ my: 3 }}
                    count={data?.meta?.last_page || 1}
                    page={currentPage}
                    onChange={handlePageChange}
                />
            )}
            {open && (
                <CreateStagesModalWindow
                    handleClose={handleOpen}
                    handleAddStage={handleAddStage}
                />
            )}
        </PageContainer>
    );
};

export default StagesPage;
