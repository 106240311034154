import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchVacancyByIdOnEdit, updateVacancy } from "../../services/vacancyApi";
import FirstStep from "../../components/vacancy/FirstStep";
import { Box, CircularProgress, Typography, Container, Paper, Button } from "@mui/material";
import { countCharacters } from "../../utils/textFunctions";

const EditVacancyPage = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Получаем ID вакансии из URL
    const queryClient = useQueryClient();

    // Локальные состояния
    const [vacancyInformation, setVacancyInformation] = useState({
        name : "",
        salaryFrom : "",
        salaryTo : "",
        currency : "KZT",
        experience : "",
        employmentType : "",
        workSchedule : "",
        taxType : true,
        text : "",
        city : null,
        department : null,
        accept_temporary : false,
        category : null
    });

    // Запрос данных вакансии
    const { data: vacancy, isLoading, error } = useQuery(
        ["vacancy", id],
        async () => {
            const data = await fetchVacancyByIdOnEdit(id)
            if (data?.data) {
                setVacancyInformation({
                    name: data.data.name || "",
                    salaryFrom: data.data.salary_from || "",
                    salaryTo: data.data.salary_to || "",
                    currency: data.data.salary_currency || "KZT",
                    experience: data.data.experience || "",
                    employmentType: data.data.employment || "",
                    workSchedule: data.data.schedule || "",
                    taxType: data.data.salary_gross || true,
                    text: data.data.description || "",
                    city: data.data.city_name ? { name: data.data.city_name, id: data.data.hh_area_id } : null,
                    department: data.data.department_name ? { name: data.data.department_name, id: data.data.department_id } : null,
                    accept_temporary: data.data.accept_temporary || false,
                    category: data.data.category || (0 < data.data?.categories.length && data.data?.categories[0]) || null
                })
            }
            return data;
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    const handleFieldChange = (field, value) => {
        setVacancyInformation((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleToggleTemporary = () => {
        setVacancyInformation((prev) => ({
            ...prev,
            accept_temporary: !prev.accept_temporary,
        }));
    };


    // Мутация для обновления вакансии
    const mutation = useMutation(
        (vacancyData) => updateVacancy(id, vacancyData),
        {
            onSuccess: () => {
                // Обновляем кэш вакансий
                queryClient.invalidateQueries(["vacancies"]);
                alert("Вакансия успешно обновлена!");
                navigate(`/vacancy/${id}`);
            },
            onError: (error) => {
                alert("Ошибка при обновлении вакансии:", error.message || error);
            },
        }
    );

    const handleUpdate = () => {
        // Подготовка данных для отправки
        const newSalaryFrom = vacancyInformation?.salaryFrom.replace(/[^0-9]/g, "");
        const newSalaryTo = vacancyInformation?.salaryTo.replace(/[^0-9]/g, "");
        const body = {
            name: vacancyInformation?.name,
            description: vacancyInformation?.text,
            salary_from: newSalaryFrom || null,
            salary_to: newSalaryTo || null,
            salary_gross: Boolean(vacancyInformation?.taxType),
            salary_currency: vacancyInformation?.currency,
            city_name: vacancyInformation?.city?.name,
            hh_area_id: vacancyInformation?.city?.id,
            recruiter_id: localStorage.getItem("manager_id"),
            department_id: vacancyInformation?.department?.id,
            category_ids: [vacancyInformation?.category?.id],
            experience : vacancyInformation?.experience,
            schedule: vacancyInformation?.workSchedule,
            employment: vacancyInformation?.employmentType,
            accept_temporary: vacancyInformation?.accept_temporary,
        };

        mutation.mutate(body); // Отправляем запрос на обновление
    };

    const handleBack = () => {
        navigate(-1); // Возвращаемся на предыдущую страницу
    };

    const characters = countCharacters(vacancyInformation?.text);
    const isNotValid = !vacancyInformation?.name || !vacancyInformation?.city || !vacancyInformation?.department || characters < 150 || !vacancyInformation?.category;

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <Typography color="error">Ошибка при загрузке данных вакансии</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{
            mt: 4, mb: 8, maxWidth: {
                xs: '100%', // Для маленьких экранов
                sm: '100%', // Для мобильных экранов
                md: '960px', // Для средних экранов
                lg: '1200px', // Для больших экранов
                xl: 'calc(100% - 100px)', // Для очень больших экранов
            }
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: 4,
                    borderRadius: 2,
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "calc(90vh - 25px)",
                }}
            >
                <Box sx={{ p: 3 }}>
                    <FirstStep
                        vacancyInformation={vacancyInformation}
                        onChange={handleFieldChange}
                        onToggle={handleToggleTemporary}
                        characters={characters}
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                    <Button
                        variant="outlined"
                        sx={{
                            marginRight: 2,
                            height: "56px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            width: "202px",
                            color: "#599D15",
                            borderColor: "#599D15",
                            fontSize: "16px",
                            fontWeight: "400",
                        }}
                        onClick={handleBack}
                    >
                        Назад
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: isNotValid ? "#E0E0E0" : "#599D15",
                            color: isNotValid ? "#A0A0A0" : "white",
                            height: "56px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            width: "202px",
                            fontSize: "16px",
                            fontWeight: "400",
                        }}
                        onClick={handleUpdate}
                        disabled={isNotValid} // Проверка на заполненность
                    >
                        Обновить
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default EditVacancyPage;
