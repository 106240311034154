import { Box, Button, Typography } from "@mui/material";
import { SettingsIcon } from "../../svgComponents";
import { useRef, useState } from "react";
import TemplatesDrawer from "./TemplatesDrawer";
import {
    createTestTemplate,
    fetchTestTemplatesByIds,
    imagesToPaths,
} from "../../../services/testApi";
import { useMutation } from "@tanstack/react-query";
import { TEST_STORAGE_RADIO } from "../../../utils/testTypesStorage";
import { CheckBox, CircularLoading } from "../../tapHrStyledComponents";
import CustomizedDeleteModal from "../../common/modalWindows/CustomizedDeleteModal";
import SaveTestTemplateModal from "../../common/modalWindows/SaveTestTemplateModal";
import TestSettings from "./TestSettings";
import ActiveTestHolder from "./ActiveTestHolder";
import { nullQuestion } from "../../../utils/nullQuestions";

const CustomTestCreation = ({ onClose, handleSave, testInitialData }) => {
    const [saveIntoTemplate, setSaveIntoTemplate] = useState({
        status: false,
        name: null,
    });
    const [openClosingModal, setOpenClosingModal] = useState(false);
    const [openSavingModal, setOpenSavingModal] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [isTemplatesDrawer, setIsTemplatesDrawer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const activeQuestionRef = useRef(null);
    const [test, setTest] = useState(
        testInitialData
            ? {
                  quizData: testInitialData.quiz_data,
                  quizSettings: testInitialData.quiz_settings,
                  active: testInitialData.size - 1,
              }
            : {
                  active: 0,
                  quizData: [
                      {
                          text: "",
                          type: TEST_STORAGE_RADIO,
                          ...nullQuestion[TEST_STORAGE_RADIO],
                      },
                  ],
                  quizSettings: {
                      templates: {
                          greetings_email_template: null,
                          greetings_message_template: null,
                          refuse_email_template: null,
                          refuse_message_template: null,
                          notify_email_template: null,
                          notify_message_template: null,
                      },
                      quiz_duration: 60,
                      quiz_deadline: 2,
                  },
              }
    );

    const handleCloseModal = () => {
        setOpenClosingModal((prev) => !prev);
    };

    const handleSaveModal = () => {
        setOpenSavingModal((prev) => !prev);
    };

    const saveMutation = useMutation(createTestTemplate, {
        onSuccess: ({ test }) => {
            delete test.name;
            setIsLoading(false);
            handleSave(test);
        },
    });

    const convertMutation = useMutation(imagesToPaths, {
        onSuccess: ({ image_paths, mappings, name }) => {
            const newQuizData = [...test.quizData];
            mappings.forEach((mapping, index) => {
                if (mapping.type === "questionFile") {
                    newQuizData[mapping.questionIndex].image_path =
                        image_paths.data[index][0];
                } else if (mapping.type === "answerImage") {
                    newQuizData[mapping.questionIndex].answers[
                        mapping.answerIndex
                    ].image_path = image_paths.data[index][0];
                }
            });

            handleSavePhase2(newQuizData, name);
        },
    });

    const handleSavePhase2 = (target, name) => {
        const newTest = {
            quiz_data: target || test.quizData,
            quiz_settings: test.quizSettings,
            size: test.quizData.length,
            ...(name && { name: name }),
        };
        if (name) {
            saveMutation.mutate({ test: newTest });
        } else {
            setIsLoading(false);
            handleSave(newTest);
        }
    };

    const isEmptyQuestion = () => {
        if (!test.quizData[test.active].text) {
            setTest((prev) => ({ ...prev, isNull: true }));
            activeQuestionRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
            return true;
        }
        return false;
    };

    const handleSavePhase1 = (name) => {
        if (isEmptyQuestion()) {
            return;
        }

        setIsLoading(true);
        // Array to collect file objects and their positions
        const files = [];
        const mappings = [];

        // Loop through each question in quizData
        test.quizData.forEach((question, qIndex) => {
            // Check if the question itself contains a file (for file upload questions)
            if (question.image_path && question.image_path instanceof File) {
                files.push(question.image_path);
                mappings.push({
                    questionIndex: qIndex,
                    type: "questionFile",
                });
            }

            // Check if the question has answers with an image_path that is a File
            if (question.answers && Array.isArray(question.answers)) {
                question.answers.forEach((answer, aIndex) => {
                    if (
                        answer.image_path &&
                        answer.image_path instanceof File
                    ) {
                        files.push(answer.image_path);
                        mappings.push({
                            questionIndex: qIndex,
                            answerIndex: aIndex,
                            type: "answerImage",
                        });
                    }
                });
            }
        });

        if (files.length) {
            // Create a new FormData instance
            const formData = new FormData();

            // Append each file to the FormData under the "images[]" field
            files.forEach((item) => {
                formData.append("images[]", item);
            });

            // Trigger the mutation with the formData
            convertMutation.mutate({
                formData: formData,
                mappings: mappings,
                name: name,
            });
        } else {
            handleSavePhase2(false, name);
        }
    };

    const handleSettings = () => {
        setIsSettings((prev) => !prev);
    };

    const handleTemplatesDrawerOpen = () => {
        if (isEmptyQuestion()) {
            return;
        }
        setIsTemplatesDrawer(true);
    };

    const addNewTestsMutation = useMutation(fetchTestTemplatesByIds, {
        onSuccess: ({ data }) => {
            const additionalQuestions = [];
            data.forEach((test) => {
                test.questions.forEach((question) => {
                    additionalQuestions.push(question.question);
                });
            });
            setTest((prev) => {
                const newQuizData = [...prev.quizData];
                newQuizData.push(...additionalQuestions);
                return { ...prev, quizData: newQuizData };
            });
        },
    });

    const handleTemplatesDrawerClose = (testIds) => {
        // check if testIds have at least one key
        const ids = Object.keys(testIds)
            .filter((key) => testIds[key] === true) // Keep only keys where value is true
            .map(Number);
        if (ids.length) {
            addNewTestsMutation.mutate(ids);
        }
        setIsTemplatesDrawer(false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                backgroundColor: "white",
            }}
        >
            {isLoading ? (
                <CircularLoading />
            ) : (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 24px",
                            border: "1px solid rgba(241, 242, 243, 1)",
                        }}
                    >
                        <Box
                            onClick={handleTemplatesDrawerOpen}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "13px 21px",
                                gap: "8px",
                                borderRadius: "8px",
                                border: "1px solid rgba(198, 198, 198, 1)",
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "rgba(46, 64, 82, 1)",
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: "rgba(242, 242, 242, 1)",
                                },
                                "&:active": {
                                    borderColor: "rgba(162, 162, 162, 1)",
                                },
                            }}
                        >
                            Добавить из шаблона
                        </Box>
                        <Box
                            onClick={handleSettings}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "13px 21px",
                                gap: "8px",
                                borderRadius: "8px",
                                border: "1px solid rgba(198, 198, 198, 1)",
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "rgba(46, 64, 82, 1)",
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: "rgba(242, 242, 242, 1)",
                                },
                                "&:active": {
                                    borderColor: "rgba(162, 162, 162, 1)",
                                },
                            }}
                        >
                            <SettingsIcon
                                sx={{ width: 20, height: 20 }}
                                svgSx={{
                                    width: 20,
                                    height: 20,
                                    color: "rgba(21, 24, 19, 1)",
                                }}
                            />
                            Настройки теста
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            overflowX: "hidden",
                            position: "relative",
                            flex: 1,
                        }}
                    >
                        <ActiveTestHolder
                            isNull={test?.isNull}
                            quizData={test?.quizData || []}
                            active={test?.active || 0}
                            setTest={setTest}
                            activeQuestionRef={activeQuestionRef}
                            isLoading={addNewTestsMutation.isLoading}
                        />
                        <Box
                            sx={{
                                width: isSettings ? 590 : 0,
                                transition: "width 200ms ease-in-out",
                                // Optionally add a background or other styles as needed
                                backgroundColor: "red",
                            }}
                        >
                            <TestSettings
                                quizSettings={test?.quizSettings || {}}
                                setTest={setTest}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            padding: "16px 24px",
                            gap: "24px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: "16px",
                            }}
                        >
                            <Button
                                onClick={handleCloseModal}
                                variant="outlined"
                                sx={{
                                    width: "198px",
                                    color: "rgba(46, 64, 82, 1)",
                                    borderColor: "rgba(46, 64, 82, 1)",
                                    height: "44px",
                                    borderRadius: "8px",
                                    padding: "17px 20px",
                                    boxShadow: "none",
                                    "&:hover": {
                                        backgroundColor:
                                            "rgba(242, 243, 243, 1)",
                                    },
                                    "&:active": {
                                        backgroundColor:
                                            "rgba(242, 243, 243, 1)",
                                    },
                                }}
                            >
                                Отменить
                            </Button>
                            <Button
                                onClick={() => {
                                    if (saveIntoTemplate.status) {
                                        setOpenSavingModal(true);
                                    } else {
                                        handleSavePhase1();
                                    }
                                }}
                                variant="contained"
                                sx={{
                                    width: "198px",
                                    backgroundColor: "rgba(89, 157, 21, 1)",
                                    color: "#fff",
                                    height: "44px",
                                    borderRadius: "8px",
                                    padding: "17px 20px",
                                    boxShadow: "none",
                                    "&:hover": {
                                        backgroundColor: "rgba(80, 140, 19, 1)",
                                        boxShadow: "none !important",
                                    },
                                    "&:active": {
                                        backgroundColor: "rgba(69, 122, 16, 1)",
                                    },
                                }}
                            >
                                Создать
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setSaveIntoTemplate((prev) => ({
                                    ...prev,
                                    status: !prev.status,
                                }))
                            }
                        >
                            <CheckBox checked={saveIntoTemplate.status} />
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "16px",
                                    textWrap: "nowrap",
                                }}
                            >
                                Сохранить в шаблон
                            </Typography>
                        </Box>
                    </Box>
                    {isTemplatesDrawer && (
                        <TemplatesDrawer
                            open={isTemplatesDrawer}
                            onClose={handleTemplatesDrawerClose}
                            setTest={setTest}
                        />
                    )}
                </>
            )}
            {openClosingModal && (
                <CustomizedDeleteModal
                    title="Вы уверены что хотите выйти?"
                    subtitle="Данные автособеседования не будут сохранены"
                    handleClose={handleCloseModal}
                    handleDelete={onClose}
                    buttonText1="Выйти"
                    buttonText2="Вернуться"
                />
            )}
            {openSavingModal && (
                <SaveTestTemplateModal
                    handleClose={handleSaveModal}
                    handleSave={handleSavePhase1}
                />
            )}
        </Box>
    );
};

export default CustomTestCreation;
