import React, { useState, useEffect } from "react";
import "./SlotsManager.css";
import LabeledInput from "../../components/main/LabeledInput";
import BookingSettings from "./BookingSettings";
import { format, addDays, getDay } from "date-fns";
import { ru } from "date-fns/locale";

const dayMap = {
    "Пн": 1, "Вт": 2, "Ср": 3, "Чт": 4, "Пт": 5, "Сб": 6, "Вс": 7
};

const MIN_TIME = "00:00";
const MAX_TIME = "23:00";

const tomorrow = addDays(new Date(), 1);

const SlotsManager = ({ slots, onChangeSlots, setSlotsOpen, isEditing, handleSaveSlots, handleUpdateSubmit }) => {
    const [topOffset, setTopOffset] = useState(110);
    const [slotName, setSlotName] = useState(slots.title || "");
    const [slotData, setSlotData] = useState(slots.intervals || []);
    const [bookingSettings, setBookingSettings] = useState(slots.settings || {});

    // Обновляем локальное состояние при изменении props
    useEffect(() => {
        setSlotName(slots.title || "");
        setSlotData(slots.intervals || []);
        setBookingSettings(slots.settings || {});
    }, [slots]);

    // Отправляем изменения в родительский компонент
    const updateParent = () => {
        console.log(slots)
        onChangeSlots({
            id: slots.id,
            title: slotName,
            intervals: slotData,
            settings: bookingSettings,
            recurrence_rule: slots.recurrence_rule
        });
    };

    // Обработчики изменений
    const handleNameChange = (e) => {
        setSlotName(e.target.value);
    };

    const handleTimeChange = (id, field, value) => {
        const newIntervals = slotData.map(slot => {
            if (slot.id !== id) return slot;

            let newStart = slot.start_time;
            let newEnd = slot.end_time;

            if (field === "start_time") {
                newStart = value;
                if (newStart >= newEnd) newEnd = newStart;
            } else {
                newEnd = value;
                if (newEnd <= newStart) newStart = newEnd;
            }

            return { ...slot, start_time: newStart, end_time: newEnd };
        });

        setSlotData(newIntervals);
    };

    const addTimeSlot = (day) => {
        const daySlots = slotData.filter(s => s.day_of_week === dayMap[day]);
        let newStart = "09:00";
        let newEnd = "18:00";

        if (daySlots.length > 0) {
            const lastSlot = daySlots[daySlots.length - 1];
            newStart = lastSlot.end_time;
            newEnd = (parseInt(newStart.split(":")[0]) + 1).toString().padStart(2, "0") + ":00";
            if (newEnd > MAX_TIME) newEnd = MAX_TIME;
        }

        const newSlot = {
            id: `${day}-${Date.now()}`,
            day_of_week: dayMap[day],
            start_time: newStart,
            end_time: newEnd
        };

        setSlotData(prev => [...prev, newSlot]);
    };

    const removeTimeSlot = (id) => {
        setSlotData(prev => prev.filter(slot => slot.id !== id));
    };

    const handleBookingSettingsChange = (updatedSettings) => {
        setBookingSettings(updatedSettings);
    };

    // При любом изменении обновляем родительский компонент
    useEffect(() => {
        updateParent();
    }, [slotName, slotData, bookingSettings]);

    const copySlotTimeToAll = (slot) => {
        setSlotData(prev =>
            prev.map(existingSlot => ({
                ...existingSlot,
                start_time: slot.start_time,
                end_time: slot.end_time
            }))
        );
    };

    return (
        <div className="slots-manager" style={{ top: `${topOffset}px` }}>
            <div className="slots-header">
                <h3 className="slots-header-title">{isEditing ? "Редактирование слота" : "Создание слотов"}</h3>
                <button className="close-btn" onClick={() => setSlotsOpen(false)}>✖</button>
            </div>
            <div className="slots-content">
                <LabeledInput
                    label="Название слотов"
                    placeholder="Введите название"
                    value={slotName}
                    onChange={handleNameChange}
                />
                <label style={{ marginTop: '16px' }}>Часовой пояс</label>
                <p className="gmt">(GTM +5:00) Казахстан - Астана</p>

                <label style={{ marginTop: '16px' }}>Стандартный период времени</label>
                <p className="gmt">Еженедельный</p>

                {/* <FormControl fullWidth>
                    <Select
                        value={recurrenceRule}
                        onChange={(e) => setRecurrenceRule(Number(e.target.value))}
                        displayEmpty
                        sx={{ borderRadius: '8px' }}
                    >
                        <MenuItem value={1}>Еженедельный</MenuItem>
                        <MenuItem value={0}>Не повторяется</MenuItem>
                    </Select>
                </FormControl> */}

                {slots.recurrence_rule === 1 && (
                    <div style={{ marginTop: '16px' }} className="slots-times">
                        {Object.keys(dayMap).map((dayName) => {
                            const daySlots = slotData.filter(slot => slot.day_of_week === dayMap[dayName]);

                            return (
                                <div key={dayName} className="slots-row">
                                    <span>{dayName}</span>
                                    {daySlots.length === 0 ? (
                                        <div style={{ display: 'flex', width: '100%', flexGrow: 1 }}>
                                            <div className="disabled-text">Нельзя запланировать</div>
                                            <svg style={{ marginLeft: '10px' }} onClick={() => addTimeSlot(dayName)}
                                                width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 8V16M16 12H8M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                    stroke="#2E4052" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    ) : (
                                        daySlots.map((slot, index) => {
                                            const previousSlotEnd = index > 0 ? daySlots[index - 1].end_time : MIN_TIME;

                                            return (
                                                <div key={slot.id} className="time-slot">
                                                    <input
                                                        type="time"
                                                        className="time-input"
                                                        value={slot.start_time}
                                                        min={previousSlotEnd} // Минимальное значение - конец предыдущего слота
                                                        max={slot.end_time} // Нельзя выбрать позже end_time
                                                        onChange={(e) => handleTimeChange(slot.id, "start_time", e.target.value)}
                                                    />
                                                    <span>-</span>
                                                    <input
                                                        type="time"
                                                        className="time-input"
                                                        value={slot.end_time}
                                                        min={slot.start_time} // Нельзя выбрать end_time раньше start_time
                                                        max={MAX_TIME}
                                                        onChange={(e) => handleTimeChange(slot.id, "end_time", e.target.value)}
                                                    />


                                                    {/* Первый SVG - удаление слота */}
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg" onClick={() => removeTimeSlot(slot.id)}>
                                                        <path d="M4 4L20 20M14 14.2361C13.4692 14.7111 12.7684 15 12 15C10.3431 15 9 13.6569 9 12C9 11.2316 9.28885 10.5308 9.76389 10M19.6078 15.6077C20.1791 15.1103 20.6902 14.6099 21.1303 14.1469C22.2899 12.9268 22.2899 11.0732 21.1303 9.8531C19.1745 7.79533 15.8155 5 12 5C11.1086 5 10.2422 5.15256 9.4127 5.41264M6.5 6.80338C5.04144 7.73444 3.79764 8.87678 2.86971 9.8531C1.7101 11.0732 1.7101 12.9268 2.86971 14.1469C4.82549 16.2047 8.18448 19 12 19C13.8681 19 15.6267 18.3299 17.1648 17.4044"
                                                            stroke="#2E4052" strokeWidth="1.5" strokeLinecap="round" />
                                                    </svg>

                                                    {/* Второй SVG - добавление нового слота */}
                                                    <svg onClick={() => addTimeSlot(dayName)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 8V16M16 12H8M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                            stroke="#2E4052" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>

                                                    <svg onClick={() => copySlotTimeToAll(slot)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8 8V6C8 3.79086 9.79086 2 12 2L18 2C20.2091 2 22 3.79086 22 6V12C22 14.2091 20.2091 16 18 16H16M8 8H6C3.79086 8 2 9.79086 2 12V18C2 20.2091 3.79086 22 6 22H12C14.2091 22 16 20.2091 16 18V16M8 8H12C14.2091 8 16 9.79086 16 12V16" stroke="#2E4052" stroke-width="1.5" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                            )
                                        })
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                <BookingSettings settings={bookingSettings} onChange={handleBookingSettingsChange} />
                {isEditing ? (
                    <button onClick={handleUpdateSubmit} className="save-btn">Обновить</button>
                ) : (
                    <button onClick={handleSaveSlots} className="save-btn">Сохранить</button>
                )}
            </div>
        </div>
    );
};

export default SlotsManager;
