// src/services/dictionariesApi.js
import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

/**
 * 
 * @param {String} searchQuery - search query default empty string
 * @param {Number} page - page number default 1
 * @param {Number} perPage - number of items per page default 10
 * @returns {Object} - object that contains data and meta
 */
export const fetchCities = async ({searchQuery, page = 1, perPage = 10}) => {
    const params = {
        page,
        per_page: perPage,
        ...(searchQuery ? { name: searchQuery } : {}),
    };

    try {
        const response = await axiosClassic.get(`/api/hh/areas`, { params });

        // Убедитесь, что возвращается массив городов
        return responseWrapper(response)
    } catch (error) {
        throw error;
    }
};

// src/services/dictionariesApi.js
export const fetchCurrencies = async () => {
    try {
        const response = await axiosClassic.get(`/api/hh/dictionaries`, {
            params: {
                type: 'currency',
            },
        });
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};



