import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";


export const getAddress = async () => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.get(
            `/api/company/${companyId}/offices`,
        );
        return responseWrapper(response);
    } catch (error) {
        console.error("Ошибка при запросе на адреса:", error);
        throw error;
    }
}


export const createAction = async (payload) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/calendars/booked-slots`, payload
        )
        return responseWrapper(response);
    } catch (error) {
        console.log("Ошибка при создании события:", error)
        throw error
    }
}


export const getCalendarActions = async ({ start_date, end_date }) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.get(
            `/api/company/${companyId}/calendars/booked-slots`, {
            params: {
                start_date,
                end_date
            }
        }
        )
        return responseWrapper(response);
    } catch (error) {
        console.log("Ошибка при получении события:", error)
        throw error
    }
}

export const getSlotsAction = async () => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.get(
            `/api/company/${companyId}/calendars/schedules`
        )
        return responseWrapper(response);
    } catch (error) {
        console.log("Ошибка при получении слотов:", error)
        throw error
    }
}

export const postSlotsAction = async (payload) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/calendars/schedules`,
            payload
        )
        return responseWrapper(response);
    }
    catch (error) {
        console.log("Ошибка при создании события:", error)
        throw error
    }
}

export const deleteSlotAction = async (id) => {
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/calendars/schedules/${id}`
        )
        return response.data
    }
    catch (error) {
        console.log('Ошибка')
        throw error
    }
}

export const updateSlotsAction = async (payload) => {
    console.log(payload)
    const companyId = localStorage.getItem("company_id"); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/calendars/schedules/${payload.id}`,
            payload
        )
        return response.data
    }
    catch (error) {
        console.log("Ошибка при создании события:", error)
        throw error
    }
}