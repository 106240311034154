
import { memo } from "react";
import { Box, Typography, Divider, Link } from "@mui/material";
import { InfoFieldUi } from "./candidateInfoUi";
import { TelegramIcon, WhatsIcon } from '../svgComponents';


const MainInformationUi = memo(({ sx, phone, email, desired_position, salary, isTwoColumned = false, render = { isDivider: true } }) => {
    console.log("MainInformationUi being rendered");

    return (
        <Box sx={{borderRadius: "8px", ...sx}}>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                Персональная информация
            </Typography>
            {render && render.isDivider && <Divider
                sx={{
                    marginTop: "16px",
                    left: "24px",
                    right: "24px",
                    backgroundColor: "rgba(235, 238, 239, 1)",
                }}
            />}
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: isTwoColumned ? "repeat(2, 1fr)" : "1fr",
                    rowGap: isTwoColumned ? "8px" : "16px", // Gap between rows
                    columnGap: "16px", // Gap between columns
                    marginTop: "16px",
                }}
            >
                <InfoFieldUi
                    sx={{ flex: isTwoColumned ? "1 1 50%" : "1 1 auto", gap: isTwoColumned ? "5px" : "8px", paddingBottom: isTwoColumned ? "10px" : "16px", left: "24px", right: "24px" }}
                    title="Контакты"
                    text={phone || "Не указано"}
                >
                    {phone ? (
                        <>
                            <Link
                                href={`https://t.me/${phone.replace(/^\+?/, "+").replace(/[^\d+]/g, "")}`}
                                target="_blank"
                                rel="noreferrer noopener"
                                sx={{
                                    textDecoration: "none",
                                }}
                            >
                                <TelegramIcon
                                    sx={{ width: "24px", height: "24px", cursor: "pointer", borderRadius: "50%", border: "1px solid rgba(229, 229, 229, 1)" }}
                                    svgSx={{ width: "22px", height: "22px" }}
                                />
                            </Link>
                            <Link
                                href={`https://wa.me/${phone.replace(
                                    /^\+|[^\d]/g,
                                    ""
                                )}`}
                                target="_blank"
                                rel="noreferrer noopener"
                                sx={{
                                    textDecoration: "none",
                                }}
                            >
                                <WhatsIcon
                                    sx={{ width: "24px", height: "24px", cursor: "pointer" }}
                                    svgSx={{ width: "24px", height: "24px" }}
                                />
                            </Link>
                        </>
                    ) : null}
                </InfoFieldUi>
                <InfoFieldUi
                    sx={{ flex: isTwoColumned ? "1 1 50%" : "1 1 auto", gap: isTwoColumned ? "5px" : "8px", paddingBottom: isTwoColumned ? "10px" : "16px", left: "24px", right: "24px" }}
                    title="Электронная почта"
                    text={email || "Не указано"}
                />
                <InfoFieldUi
                    sx={{ flex: isTwoColumned ? "1 1 50%" : "1 1 auto", gap: isTwoColumned ? "5px" : "8px", paddingBottom: isTwoColumned ? "10px" : "16px", left: "24px", right: "24px" }}
                    title="Желаемая должность"
                    text={desired_position || "Не указано"}
                />
                <InfoFieldUi
                    sx={{ flex: isTwoColumned ? "1 1 50%" : "1 1 auto", gap: isTwoColumned ? "5px" : "8px", paddingBottom: isTwoColumned ? "10px" : "16px", left: "24px", right: "24px" }}
                    title="Желаемая зарплата"
                    text={salary || "Не указано"}
                />
            </Box>
        </Box>
    );
});


export default MainInformationUi;