import { memo, useMemo } from "react";
import {
    PieChart,
    Pie,
    Cell,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from "recharts";
import { Box, Typography } from "@mui/material";
import DataShortage from "./DataShortage";
import { sourcesStorage } from "../../utils/manageSources";

function buildPieDataStages(data) {
    const sourceTotals = {};
    const namesToSources = {};

    for (const vacancy of data) {
        for (const stage of vacancy.stages) {
            if (stage.name !== "Новый") continue;

            for (const { sourceId, count } of stage.accepted) {
                const sourceName = sourcesStorage[sourceId] || sourcesStorage.unknown;

                if (!(sourceName in namesToSources)) {
                    namesToSources[sourceName] = sourceId;
                }

                const mappedSourceId = namesToSources[sourceName];

                sourceTotals[mappedSourceId] = (sourceTotals[mappedSourceId] || 0) + count;
            }
        }
    }

    return Object.entries(namesToSources).map(([sourceName, sourceId]) => ({
        label: sourceName || "Неизвестный источник",
        value: sourceTotals[sourceId] || 0,
        sourceId,
    }));
}


function buildChartDataRefusals(data) {
    const chartDataMap = {};

    data.forEach(({ stages = [] }) => {
        stages.forEach(({ declined = [] }) => {
            declined.forEach(({ reasons = [] }) => {
                reasons.forEach(({ reason, count }) => {
                    const label = reason || "Не указана";
                    if (chartDataMap[label] === undefined) {
                        chartDataMap[label] = 0;
                    }
                    chartDataMap[label] += count;
                });
            });
        });
    });

    return Object.entries(chartDataMap).map(([label, value]) => ({
        label,
        value,
    }));
}

const COLORS = [
    "rgba(149, 105, 217, 1)",
    "rgba(163, 181, 222, 1)",
    "rgba(237, 201, 122, 1)",
    "rgb(107, 181, 180)",
];

const getColor = (index, total) => {
    const hue = Math.floor((360 / total) * index);
    return `hsl(${hue}, 70%, 50%)`;
};

const PieChartDrawer = memo(({ data, isStages }) => {
    // Build the Pie data
    const pieData = useMemo(() => {
        return isStages
            ? buildPieDataStages(data)
            : buildChartDataRefusals(data);
    }, [data, isStages]);

    // Sum of all slices
    const total = useMemo(
        () => pieData.reduce((acc, curr) => acc + curr.value, 0),
        [pieData]
    );

    // Dynamically calculate container width:
    // Base width is 600px for one column.
    // If there are more legend items than one column can hold, add extra width.
    const maxItemsPerColumn = 4; // Adjust based on your layout
    const extraColumnWidth = 300; // Width to add per extra column
    const numLegendColumns = Math.ceil(pieData.length / maxItemsPerColumn);
    const chartContainerWidth = 600 + (numLegendColumns - 1) * extraColumnWidth;

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <Box
                sx={{
                    maxHeight: "310px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    gap: 3,
                }}
            >
                {payload.map((entry, index) =>
                    entry?.payload?.value ? (
                        <Box
                            key={`item-${index}`}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 0.5,
                                breakInside: "avoid",
                                width: "200px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "6px",
                                }}
                            >
                                <Box
                                    sx={{
                                        height: 20,
                                        width: 4,
                                        borderRadius: 1,
                                        backgroundColor: entry.color,
                                    }}
                                />
                                <Box
                                    sx={{
                                        fontSize: "18px",
                                        lineHeight: "22px",
                                        width: "190px",
                                        color: "rgba(116, 116, 115, 1)",
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                        overflow: "hidden",
                                    }}
                                >
                                    {entry.value}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    pl: "12px",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: "24px",
                                        lineHeight: "28px",
                                        color: "rgba(9, 11, 8, 1)",
                                    }}
                                >
                                    {entry?.payload?.value}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: "18px",
                                        lineHeight: "22px",
                                        color: "rgba(116, 116, 115, 1)",
                                    }}
                                >
                                    кол-во
                                </Typography>
                            </Box>
                        </Box>
                    ) : null
                )}
            </Box>
        );
    };

    return pieData.length > 0 ? (
        <>
            <Box sx={{ display: "flex", gap: "48px", mb: 2 }}>
                <Box>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "rgba(116, 116, 115, 1)",
                            mb: 1,
                        }}
                    >
                        {isStages ? "Всего кандидатов" : "Всего отказов"}
                    </Typography>
                    <Box
                        sx={{
                            fontSize: "24px",
                            lineHeight: "28px",
                            fontWeight: 500,
                            color: "rgba(9, 11, 8, 1)",
                        }}
                    >
                        {total}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box sx={{ width: chartContainerWidth, height: 310 }}>
                    <ResponsiveContainer width="100%" minHeight="100%">
                        <PieChart>
                            <Pie
                                data={pieData}
                                dataKey="value"
                                nameKey="label"
                                outerRadius={150}
                                labelLine={false}
                                isAnimationActive={false}
                                label={({
                                    cx,
                                    cy,
                                    midAngle,
                                    innerRadius,
                                    outerRadius,
                                    value,
                                }) => {
                                    const RADIAN = Math.PI / 180;
                                    const radius =
                                        innerRadius +
                                        (outerRadius - innerRadius) * 0.5;
                                    const x =
                                        cx +
                                        radius * Math.cos(-midAngle * RADIAN);
                                    const y =
                                        cy +
                                        radius * Math.sin(-midAngle * RADIAN);
                                    return value ? (
                                        <foreignObject
                                            x={x - 38.5}
                                            y={y - 13}
                                            width={77}
                                            height={26}
                                        >
                                            <Box
                                                sx={{
                                                    backgroundColor: "white",
                                                    borderRadius: "4px",
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    {value}
                                                </Typography>
                                            </Box>
                                        </foreignObject>
                                    ) : null;
                                }}
                            >
                                {pieData.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={
                                            isStages
                                                ? COLORS[index % COLORS.length]
                                                : getColor(
                                                      index,
                                                      pieData.length
                                                  )
                                        }
                                    />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend
                                verticalAlign="top"
                                layout="vertical"
                                align="right"
                                content={renderLegend}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
        </>
    ) : (
        <DataShortage />
    );
});

export default PieChartDrawer;
