import React, { useRef, useState, useCallback, useEffect } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import PageContainer from "../../../components/common/PageContainer";
import PossibleIntegrations from "../../../components/integrations/PossibleIntegrations";
import { TabPanel, TabLabel } from "../../../components/common/TabComponents";
import { useQuery } from "@tanstack/react-query";
import { getCareerSiteData } from "../../../services/careerSiteApi";
import { CircularLoading } from "../../../components/tapHrStyledComponents";
import { CustomError } from "../../../components/styledComponents/CustomErrors";
import TopPanel from "../../../components/integrations/career_site/TopPanel";
import BottomPanel from "../../../components/integrations/career_site/BottomPanel";
import PossibleProcesses from "../../../components/integrations/PossibleProcesses";
import useProcesses from "../../../hooks/useProcesses";
import { useLocation } from "react-router-dom";

const DEFAULT_SOCIAL_LINKS = [
    { social_network: "telegram", url: "", icon: "telegram.icon" },
    { social_network: "instagram", url: "", icon: "instagram.icon" },
    { social_network: "youtube", url: "", icon: "youtube.icon" },
    { social_network: "facebook", url: "", icon: "facebook.icon" },
    { social_network: "linkedin", url: "", icon: "linkedin.icon" },
    { social_network: "other", url: "", icon: "other.icon" },
];

const IntegrationsPage = () => {
    const [activeMainTab, setActiveMainTab] = useState(0);
    const queryKeyCareerSite = useRef(["integrations_career_site"]);
    const queryKeyProcesses = useRef(["processes"]);
    const [data, setData] = useState(null);
    const location = useLocation();

    const getSocialLinks = (data) => {
        if (!Array.isArray(data.social_links)) {
            data.social_links = DEFAULT_SOCIAL_LINKS;
        } else {
            const linkMap = new Map(
                data.social_links.map((link) => [link.social_network, link])
            );
            data.social_links = DEFAULT_SOCIAL_LINKS.map(
                (defaultLink) =>
                    linkMap.get(defaultLink.social_network) || defaultLink
            );
        }
    };

    const {
        data: oldData,
        isLoading,
        isError,
        error,
    } = useQuery(
        queryKeyCareerSite.current,
        async () => {
            const result = await getCareerSiteData();
            if (result) {
                getSocialLinks(result.data);
                setData(result.data);
            }
            return result;
        },
        {
            enabled: activeMainTab === 2, // Only fetch when activeMainTab is 2
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tab = parseInt(params.get("tab"));
        setActiveMainTab((prev) => {
            if (tab >= 0 && tab <= 3) {
                return tab;
            }
            return prev;
        });
    }, [location]);

    const {
        processesData,
        isProcessesLoading,
        isProcessesError,
        processesError,
    } = useProcesses({
        enabled: activeMainTab === 1,
        queryKey: queryKeyProcesses.current,
    });

    const handleCancel = () => {
        if (oldData.data) {
            getSocialLinks(oldData.data);
            setData(oldData.data);
            window.scrollTo({
                top: 0,
                behavior: "smooth", // Optional for a smooth scrolling effect
            });
        }
    };

    return (
        <PageContainer>
            <Box
                sx={{
                    backgroundColor: "white",
                    height: activeMainTab !== 2 ? "calc(90vh - 48px)" : "unset",
                    minHeight: activeMainTab !== 2 ? "730px" : "360px",
                    minWidth: "1124px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "36px 24px 24px 24px",
                    gap: "36px",
                }}
            >
                {/* Tabs */}
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "rgba(226, 228, 231, 1)",
                    }}
                >
                    <Typography
                        sx={{
                            mb: "16px",
                            fontWeight: 600,
                            fontSize: "24px",
                            lineHeight: "28px",
                            py: 1,
                        }}
                    >
                        Интеграции
                    </Typography>
                    <Tabs
                        value={activeMainTab}
                        onChange={(e, newValue) => {
                            setActiveMainTab(newValue);
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "rgba(89, 157, 21, 1)",
                                height: "3px",
                                borderRadius: "100px 100px 0 0",
                            },
                        }}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            "& .MuiTab-root": {
                                maxWidth: 135,
                                textTransform: "none",
                                padding: "0 8px",
                            },
                            paddingLeft: "24px",
                        }}
                    >
                        <Tab
                            label={
                                <TabLabel
                                    text={"Джоб сайты"}
                                    activeMainTab={activeMainTab}
                                    index={0}
                                />
                            }
                        />
                        <Tab
                            label={
                                <TabLabel
                                    text={"Процессы"}
                                    activeMainTab={activeMainTab}
                                    index={1}
                                />
                            }
                        />
                        <Tab
                            label={
                                <TabLabel
                                    text={"Карьерный сайт"}
                                    activeMainTab={activeMainTab}
                                    index={2}
                                />
                            }
                        />
                    </Tabs>
                </Box>

                {/* Tab Content */}
                <TabPanel value={activeMainTab} index={0}>
                    <PossibleIntegrations />
                </TabPanel>
                <TabPanel value={activeMainTab} index={1}>
                    <PossibleProcesses
                        data={processesData?.data}
                        isLoading={isProcessesLoading}
                        isError={isProcessesError}
                        error={processesError}
                        queryKey={queryKeyProcesses.current}
                    />
                </TabPanel>
                <TabPanel value={activeMainTab} index={2}>
                    {isLoading ? (
                        <CircularLoading />
                    ) : isError || !data ? (
                        <CustomError
                            error={error?.message || "Неопознанная причина"}
                        />
                    ) : (
                        <TopPanel data={data} setData={setData} />
                    )}
                </TabPanel>
            </Box>
            {activeMainTab === 2 && !isLoading && !isError && data && (
                <BottomPanel
                    queryKey={queryKeyCareerSite.current}
                    data={data}
                    setData={setData}
                    handleCancel={handleCancel}
                />
            )}
        </PageContainer>
    );
};

export default IntegrationsPage;
