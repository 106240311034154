import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const isInvited = Boolean(localStorage.getItem('invite_token'));
    const isAuthenticated = Boolean(localStorage.getItem('access_token'));
    const hasManagerId = Boolean(localStorage.getItem('manager_id'));

    // navigate to login if not authenticated and not invited
    const firstAuthenticationCondition = !isAuthenticated && !isInvited;

    // navigate to new password if invited and not on new password page
    const secondInvitationCondition = isInvited && window.location.pathname !== '/new_password';

    // navigate to select account if not invited, no manager id and not on select account page
    const thirdAccountSelectionCondition = !isInvited && !hasManagerId && window.location.pathname !== '/select_account';

    if (firstAuthenticationCondition) {
        return <Navigate to="/login" replace />;
    }

    if (secondInvitationCondition) {
        return <Navigate to="/new_password" replace />;
    }

    if (thirdAccountSelectionCondition) {
        return <Navigate to="/select_account" replace />;
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;
