import React from 'react';
import { Box, Typography, Chip, Divider } from '@mui/material';
import { formatDate, calculateDuration } from '../../utils/formatedDate';

export const ExperienceUi = ({ sx = {}, experience = [] }) => {
    if (!experience) {
        experience = [];
    }

    const TextualBlock = ({ first, second }) => {
        return (
            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813', display: "flex", gap: "4px", flexDirection: "column" }}>
                {first}
                {second && <Typography
                    component="span"
                    sx={{ fontSize: '14px', fontWeight: 400, color: "rgba(116, 116, 115, 1)" }}
                >
                    {second}
                </Typography>}
            </Typography>
        )
    }

    return (
        <Box sx={sx}>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                Опыт работы
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {experience.length !== 0 ? experience.map((el, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                                sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400, mr: 2 }} variant="body1"
                            >
                                {formatDate(el.start, "MMMM YYYY", "ru")} - {el.end ? formatDate(el.end, "MMMM YYYY", "ru") : "по настоящее время"}
                                <Typography
                                    component="span"
                                    sx={{
                                        display: "block",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        color: "rgba(116, 116, 115, 1)",
                                    }}
                                >
                                    {calculateDuration(el.start, el.end || new Date())}
                                </Typography>
                            </Typography>
                            <Box sx={{ flex: 2, display: "flex", flexDirection: "column", gap: "24px" }}>
                                <TextualBlock first={el.company} second={el.industry?.name} />
                                <TextualBlock first={el.position} second={el.description} />
                            </Box>
                        </Box>
                        {index !== experience.length - 1 && <Divider sx={{ backgroundColor: "rgba(241, 242, 243, 1)" }}></Divider>}
                    </Box>
                )) : <EmptyData />}
            </Box>
        </Box>
    );
}

export const KeySkillsUi = ({ sx = {}, skills = [] }) => {
    if (!skills) {
        skills = [];
    }

    return (
        <Box sx={sx}>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                Ключевые навыки
            </Typography>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                {skills.length !== 0 ? skills.map((skill, index) => (
                    <Chip sx={{ borderRadius: "4px", backgroundColor: "rgba(248, 249, 249, 1)" }} key={index} label={skill} />
                )) : <Typography sx={{ color: '#151813', fontSize: '16px', fontWeight: 400 }}>Не указано</Typography>}
            </Box>
        </Box>
    );
}

export const LanguagesUi = ({ sx = {}, languages = [] }) => {
    if (!languages) {
        languages = [];
    }

    return (
        <Box sx={sx}>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                Языки
            </Typography>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                {/* {languages.length !== 0 && languages.map((skill, index) => (
                    <LabeledInput
                        label="Имя"
                        value={skill}
                        placeholder="Введите имя"
                        name="first_name"
                        required
                    />
                ))} */}

                {languages.length !== 0 ? languages.map((skill, index) => (
                    <Chip sx={{ borderRadius: "4px", backgroundColor: "rgba(248, 249, 249, 1)" }} key={index} label={`${skill.name || skill}`} />
                )) : <Typography sx={{ color: '#151813', fontSize: '16px', fontWeight: 400 }}>Не указано</Typography>}
            </Box>
        </Box>
    );
}

export const EducationUi = ({ sx = {}, education = [], source_id }) => {
    if (!education) {
        education = [];
    }

    return (
        <>
            {source_id === 2 && (
                <Box sx={sx}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    >
                        Образование
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        {education.length !== 0 ? education.map((el, index) => (
                            <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400, mr: 2 }} variant="body1">{el.year}</Typography>
                                    <Box sx={{ flex: 2, display: "flex", flexDirection: "column", gap: "4px" }}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>{el.name}</Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(116, 116, 115, 1)' }} variant="body2" color="text.secondary">
                                            {el.result}
                                        </Typography>
                                    </Box>
                                </Box>
                                {index !== education.length - 1 && <Divider sx={{ backgroundColor: "rgba(241, 242, 243, 1)" }}></Divider>}
                            </Box>
                        )) : <Typography sx={{ color: '#151813', fontSize: '16px', fontWeight: 400 }}>Не указано</Typography>}
                    </Box>
                </Box>
            )}
            {source_id === 1 && (
                <Box sx={sx}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    >
                        Образование
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        {education.length !== 0 ? education.map((el, index) => (
                            <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400, mr: 2 }} variant="body1">{el.year}</Typography>
                                    <Box sx={{ flex: 2, display: "flex", flexDirection: "column", gap: "4px" }}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>{el.place}</Typography>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(116, 116, 115, 1)' }} variant="body2" color="text.secondary">
                                            {el.major}
                                        </Typography>
                                    </Box>
                                </Box>
                                {index !== education.length - 1 && <Divider sx={{ backgroundColor: "rgba(241, 242, 243, 1)" }}></Divider>}
                            </Box>
                        )) : <Typography sx={{ color: '#151813', fontSize: '16px', fontWeight: 400 }}>Не указано</Typography>}
                    </Box>
                </Box>
            )}
        </>
    );
}

const EmptyData = () => {
    return (
        <Typography sx={{ color: '#151813', fontSize: '16px', fontWeight: 400 }}>Не указано</Typography>
    )
}

export const FilesUi = ({ sx = {}, file, onClick, render = { isDivider: true } }) => {
    if (!file) {
        return (null);
    }

    return (
        <Box sx={sx}>
            <Typography variant="subtitle1" sx={{ fontWeight: 400, color: '#ADB5BD', fontSize: '14px' }}>
                Файлы
            </Typography>
            <Box>
                <Typography onClick={onClick} sx={{ flex: 1, color: '#1F6BC5', fontSize: '16px', fontWeight: 400, cursor: 'pointer', textDecoration: 'underline' }} variant="body1">Прикрепленный файл</Typography>
                {/* {education.map((el) => (
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">{el.year}</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>{el.name}</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                {el.result}
                            </Typography>
                        </Box>
                    </Box>
                ))} */}
            </Box>
            {render.isDivider && <Divider sx={{ marginTop: "16px", backgroundColor: "rgba(241, 242, 243, 1)" }}></Divider>}
        </Box>
    );
}

export const SupplementaryUi = ({ source_id, sx = {}, dateOfBirth, citizenship, city, employments, schedules, businessTrips, preferences, render = { isMainDivider: true, isSubDividers: true } }) => {
    if (!preferences) {
        preferences = {};
    }

    const mapArrayToString = (arr, wrapper) => {
        console.log(arr);
        if (!arr) {
            return "Не указано";
        }

        if (source_id === 1 && arr?.length && wrapper) {
            return arr?.map((key) => wrapper(String(key))).join(", ")
        } else if (source_id === 2 && arr?.length) {
            return arr.map((el) => el.name).join(", ");
        }

        return "Не указано";
    }

    const toRussian = (tripness, source) => {
        if (!tripness) {
            return "Не указано";
        }

        if (source === 1) {
            return tripness ? "Готов" : "Не готов";
        }

        switch (tripness?.id) {
            case "ready":
                return "Готов";
            case "sometimes":
                return "Иногда";
            case "never":
                return "Не готов";
            default:
                return "Не указано";
        }
    }

    const returnEmploymentTypeText = (key) => {
        switch (key) {
            case '1':
                return 'Полная занятость'
            case '2':
                return 'Частичная занятость'
            case '3':
                return 'Проектная работа'
            case '4':
                return 'Волонтерство'
            case '5':
                return 'Стажировка'
            default:
                return 'Полная занятость'
        }
    }

    const returnScheduleText = (key) => {
        switch (key) {
            case '1':
                return 'Полный день'
            case '2':
                return 'Сменный график'
            case '3':
                return 'Гибкий график'
            case '4':
                return 'Удаленная работа'
            case '5':
                return 'Вахтовый метод'
            default:
                return 'Полный день'
        }
    }

    return (
        <>
            {source_id === 2 && (
                <Box sx={sx}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    >
                        Дополнительная информация
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"Дата рождения"} text={dateOfBirth || "Не указано"} />
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"Гражданство"} text={(citizenship && Array.isArray(citizenship) && 0 < citizenship.length && citizenship[0].name) || "Не указано"} />
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"Город"} text={city || "Не указано"} />
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"Тип занятости"} text={mapArrayToString(employments)} />
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"График работы"} text={mapArrayToString(schedules)} />
                        <InfoFieldUi title={"Командировки"} text={toRussian(businessTrips, source_id)} render={{ isDivider: false }} />
                    </Box>
                    {render.isMainDivider && <Divider sx={{ marginTop: "16px", backgroundColor: "rgba(241, 242, 243, 1)" }}></Divider>}
                </Box>
            )}
            {source_id === 1 && (
                <Box sx={sx}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    >
                        Дополнительная информация
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"Дата рождения"} text={formatDate(dateOfBirth) || "Не указано"} />
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"Гражданство"} text={citizenship || "Не указано"} />
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"Город"} text={city || "Не указано"} />
                        <InfoFieldUi
                            sx={{ paddingBottom: "16px" }}
                            title={"Тип занятости"}
                            text={mapArrayToString(employments, returnEmploymentTypeText)}
                        />
                        <InfoFieldUi sx={{ paddingBottom: "16px" }} title={"График работы"} text={mapArrayToString(schedules, returnScheduleText)} />
                        <InfoFieldUi title={"Командировки"} text={toRussian(businessTrips, source_id)} render={{ isDivider: false }} />
                    </Box>
                    {render.isMainDivider && <Divider sx={{ marginTop: "16px", backgroundColor: "rgba(241, 242, 243, 1)" }}></Divider>}
                </Box>
            )}
        </>
    );
}

export const InfoFieldUi = ({ sx = {}, title, text, children, render = { isDivider: true } }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", borderBottom: render.isDivider ? "1px solid rgba(241, 242, 243, 1)" : "none", gap: "8px", ...sx }}>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "rgba(173, 181, 189, 1)",
                }}
            >
                {title}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "rgba(21, 24, 19, 1)",
                    }}
                >
                    {text}
                </Typography>
                {children}
            </Box>

        </Box>
    )
}

{/* Повышение квалификации */ }
{/* <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: 2, color: '#ADB5BD', fontSize: '14px' }}>
                    Повышение квалификации, курсы
                </Typography>
                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">2020</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>Changzhou Institute of Technology</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                Bachelor of economics, - BE, Sales
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">2017</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>NIS</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                Astana
                            </Typography>
                        </Box>
                    </Box>
                </Box> */}

{/* <Divider sx={{ mt: 2, mb: 2 }} /> */ }

{/* Тесты, экзамены
                <Typography variant="subtitle1" sx={{ fontWeight: 400, mb: 2, color: '#ADB5BD', fontSize: '14px' }}>
                    Тесты, экзамены
                </Typography>
                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">2020</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>Changzhou Institute of Technology</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                Bachelor of economics, - BE, Sales
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                        <Typography sx={{ flex: 1, color: '#151813', fontSize: '16px', fontWeight: 400 }} variant="body1">2017</Typography>
                        <Box sx={{ flex: 2 }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#151813' }}>NIS</Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#151813' }} variant="body2" color="text.secondary">
                                Astana
                            </Typography>
                        </Box>
                    </Box>
                </Box> */}