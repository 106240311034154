// short for predifined variables
export const pVars = [
    {id: "Кандидат.имя", name: "Имя кандидата"}, 
    {id: "Кандидат.фамилия", name: "Фамилия кандидата"},
    {id: "Вакансия.название", name: "Название вакансии"},
    {id: "Вакансия.ссылка", name: "Ссылка на вакансию"}, // https://{domain}.taphr.kz/vacancy/{id}
    {id: "Компания.имя", name: "Название компании"},
    {id: "Компания.ссылка", name: "Ссылка на компанию"}, // https://{domain}.taphr.kz/
    {id: "Рекрутер.имя", name: "Имя рекрутера"},
    {id: "Рекрутер.почта", name: "Почта рекрутера"},
    {id: "Рекрутер.телефон", name: "Телефон рекрутера"},
    {id: "Интервью.ссылка", name: "Ссылка на интервью"}, // https://{domain}.taphr.kz/select-slot/{hash}
    {id: "Интервью.дата", name: "Дата интервью"},
    {id: "Интервью.время", name: "Время интервью"},
    {id: "Интервью.длительность", name: "Длительность интервью"},
    {id: "Интервью.примечание", name: "Примечание к интервью"},
    {id: "Интервью.расположение", name: "Расположение интервью"},
    {id: "Этап.название", name: "Название этапа"},
    {id: "Тест.длительность", name: "Длительность теста"}, // duration in hours and minutes
    {id: "Тест.ссылка", name: "Ссылка на тест"}, // https://{domain}.taphr.kz/interview/{hash}
    {id: "Тест.дедлайн", name: "Время до конца теста"}, // date until end of the submission
]; // Add valid variables here