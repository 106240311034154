export const mapScoreToText = (score) => {
    switch (score) {
        case 1:
            return {text: "Наиболее подходящий", primaryColor: "rgba(232, 185, 49, 1)", secondaryColor: "rgba(251, 243, 229, 1)"};
        case 2:
            return {text: "Подходящий", primaryColor: "rgba(20, 174, 92, 1)", secondaryColor: "rgba(228, 241, 232, 1)"};
        case 3:
            return {text: "Нейтрально", primaryColor: "rgba(165, 169, 173, 1)", secondaryColor: "rgba(241, 242, 243, 1)"};
        case 4:
            return {text: "Неподходящий", primaryColor: "rgba(255, 126, 122, 1)", secondaryColor: "rgba(255, 230, 229, 1)"};
        default:
            return {text: "Неизвестно", primaryColor: "rgb(0, 0, 0)", secondaryColor: "rgba(232, 239, 228, 1)"};
    }
};