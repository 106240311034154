import { createContext, useContext, useState } from 'react';

const NavigationBarContext = createContext();

export const useNavigationBar = () => useContext(NavigationBarContext);

export const NavigationBarProvider = ({ children }) => {
  const [isNavigationBarVisible, setIsNavigationBarVisible] = useState(true);

  const hideNavigationBar = () => setIsNavigationBarVisible(false);
  const showNavigationBar = () => setIsNavigationBarVisible(true);

  return (
    <NavigationBarContext.Provider value={{ isNavigationBarVisible, hideNavigationBar, showNavigationBar }}>
      {children}
    </NavigationBarContext.Provider>
  );
};