import React, { useState, useEffect, memo, useCallback, useRef } from "react";
import {
    Container,
    Box,
    Button,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Tooltip,
} from "@mui/material";
import CustomStepper from "../../components/main/CustomStepper";
import FirstStep from "../../components/vacancy/FirstStep";
import SecondStep from "../../components/vacancy/SecondStep";
import ThirdStep from "../../components/vacancy/ThirdStep";
import FourthStep from "../../components/vacancy/FourthStep";
import { useLocation } from "react-router-dom";
import { countCharacters } from "../../utils/textFunctions";
import { PlusIcon } from "../../components/svgComponents";
import StagesModalWindow from "../../components/common/modalWindows/stagesModalWindow";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    createDraft,
    fetchDraftById,
    updateDraft,
} from "../../services/draftsApi";
import { CircularLoading } from "../../components/tapHrStyledComponents";
import {
    AI_STATUS_ERROR,
    AI_STATUS_LOADING,
    AI_STATUS_SUCCESS,
} from "../../utils/statusesStorage";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../components/styledComponents/CustomErrors";

const steps = [
    "Основная информация",
    "Настройка критериев",
    "Создание этапов",
    "Подтверждение вакансии",
];

const updateGptStatus = (item) => {
    // Only update if ai_created is defined (not null or undefined)
    if (item.ai_created != null) {
        return {
            ...item,
            ai_created:
                item.ai_created === AI_STATUS_LOADING && item.name
                    ? AI_STATUS_SUCCESS
                    : AI_STATUS_ERROR,
        };
    }
    return item;
};

const CreateVacancyPage = () => {
    const location = useLocation();
    const externalVacancyData = location.state; // Получаем данные, переданные через navigate
    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false); // Состояние для модального окна
    const [includeExternalFields, setIncludeExternalFields] = useState(false); // Флаг для включения external полей
    const [componentInfo, setComponentInfo] = useState("step");
    const [activeStep, setActiveStep] = useState(0);
    const [id, setId] = useState(location?.state?.draftId || null);
    const stashedStageId = location?.state?.activeStageId || null;
    const stashedInterviewToCreate = location?.state?.interviewToCreate || null;
    const queryClient = useQueryClient();
    const queryKey = useRef(["draft"]);
    const [vacancyInformation, setVacancyInformation] = useState({
        step1: {
            name: externalVacancyData?.data?.name || "",
            text:
                externalVacancyData?.data?.description ||
                "<p><strong>Обязанности:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Требования:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Условия:</strong></p><ul><li><br></li><li><br></li></ul>",
            salaryFrom: externalVacancyData?.data?.salary_from || "",
            salaryTo: externalVacancyData?.data?.salary_to || "",
            currency: externalVacancyData?.data?.salary_currency || "KZT",
            experience: externalVacancyData?.data?.experience || "noExperience",
            employmentType: externalVacancyData?.data?.employment || "full",
            workSchedule: externalVacancyData?.data?.schedule || "fullDay",
            taxType: externalVacancyData?.data?.salary_gross || true,
            city: externalVacancyData?.data?.city_name
                ? {
                      name: externalVacancyData?.data?.city_name,
                      id: externalVacancyData?.data?.hh_area_id,
                  }
                : null,
            category: null,
            department: null,
            accept_temporary: externalVacancyData?.data?.accept_temporary || false,
            is_completed: false,
        },
        step2: null,
        step3: null,
        step4: null,
    });

    const navigate = useNavigate();
    const createVacancyMutation = useMutation((data) => updateDraft(id, data), {
        onSuccess: (data) => {
            // Обновляем кэш вакансий после успешного создания
            queryClient.invalidateQueries(["vacancies"]);
            alert("Вакансия успешно создана!");
            if (data?.data?.vacancy_id) {
                navigate(`/vacancy/${data.data.vacancy_id}?tab=3`);
            } else {
                navigate("/");
            }
        },
        onError: (error) => {
            alert("Ошибка при создании вакансии:", error);
        },
    });

    const {
        isFetching: isDraftFetching,
        isError: isDraftError,
        error: draftError,
    } = useQuery(
        queryKey.current,
        async () => {
            const result = await fetchDraftById(id);
            if (result.data) {
                setVacancyInformation((prev) => ({ ...prev, ...result.data }));
                // result.data contains multiple steps each step has field is_completed, I will check for each key, and see if the field is_completed is false, then I will set the active step to that key
                for (let i = 1; i <= 4; i++) {
                    if (
                        i === 1 &&
                        (!result.data["step1"]?.name ||
                            !result.data["step1"]?.city ||
                            !result.data["step1"]?.department ||
                            countCharacters(result.data["step1"]?.text)  < 150 ||
                            !result.data["step1"]?.category)
                    ) {
                        setActiveStep(i - 1);
                        break;
                    }
                    if (!result.data[`step${i}`].is_completed) {
                        setActiveStep(i - 1);
                        break;
                    }
                }
            }
            return result;
        },
        {
            enabled: !!location?.state?.draftId,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const createMutation = useMutation(createDraft, {
        onSuccess: (data) => {
            const oldData = queryClient.getQueryData(["draft"]);
            if (JSON.stringify(data.data) !== JSON.stringify(oldData)) {
                queryClient.setQueryData(["draft"], data.data);
                setId(data.data.id);
            }
        },
    });

    const updateByButtonMutation = useMutation(
        (data) => updateDraft(id, data),
        {
            onSuccess: (data) => {
                const oldData = queryClient.getQueryData(["draft"]);
                if (JSON.stringify(data.data) !== JSON.stringify(oldData)) {
                    queryClient.setQueryData(["draft"], (prev) => ({
                        ...prev,
                        ...data.data,
                    }));
                }
            },
        }
    );

    const createOnUpdateMutation = useMutation(createDraft, {
        onSuccess: (data) => {
            const oldData = queryClient.getQueryData(["draft"]);
            if (JSON.stringify(data.data) !== JSON.stringify(oldData)) {
                queryClient.setQueryData(["draft"], data.data);
                setId(data.data.id);
            }
        },
    });

    const autoUpdateMutation = useMutation((data) => updateDraft(id, data), {
        onSuccess: (data) => {
            const oldData = queryClient.getQueryData(["draft"]);
            if (JSON.stringify(data.data) !== JSON.stringify(oldData)) {
                queryClient.setQueryData(["draft"], (prev) => ({
                    ...prev,
                    ...data.data,
                }));
            }
        },
    });

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!id && vacancyInformation.step1.name) {
                createOnUpdateMutation.mutate(vacancyInformation);
            }

            if (id) {
                autoUpdateMutation.mutate(vacancyInformation);
            }
        }, 1000);

        return () => clearTimeout(timeout);
    }, [vacancyInformation, id]);

    const handleNext = () => {
        // Create a new vacancy object based on the current state
        const newVacancy = { ...vacancyInformation };

        // If not the last step, initialize the next step as not completed
        if (activeStep !== 3) {
            const nextStepKey = `step${activeStep + 2}`;
            newVacancy[nextStepKey] = newVacancy[nextStepKey]
                ? newVacancy[nextStepKey]
                : { is_completed: false };
        }

        // For the first step, set up default resume and interview fields
        if (activeStep === 0 && !newVacancy.step1.is_completed) {
            newVacancy.step2 = {
                ...newVacancy.step2,
                resume: [
                    { name: "", importance: "Необязательно", ai_created: AI_STATUS_LOADING },
                    { name: "", importance: "Необязательно", ai_created: AI_STATUS_LOADING },
                    { name: "", importance: "Необязательно", ai_created: AI_STATUS_LOADING },
                ],
                interview: [
                    { name: "", importance: "Необязательно", ai_created: AI_STATUS_LOADING },
                    { name: "", importance: "Необязательно", ai_created: AI_STATUS_LOADING },
                    { name: "", importance: "Необязательно", ai_created: AI_STATUS_LOADING },
                ],
            };
        }

        if (activeStep === 1) {
            if (!newVacancy.step2.is_completed) {
                newVacancy.step3.stages = [
                    { name: "Новый", flag: "IS_NEW" },
                    { name: "Принял оффер", flag: "IS_LAST" },
                ];
            }

            newVacancy.step2.resume =
                newVacancy.step2.resume.map(updateGptStatus);
            newVacancy.step2.interview =
                newVacancy.step2.interview.map(updateGptStatus);
        }

        newVacancy[`step${activeStep + 1}`].is_completed = true;

        // Update the state with the new vacancy object
        setVacancyInformation(newVacancy);

        // Trigger the appropriate mutation with the new data
        if (id) {
            updateByButtonMutation.mutate(newVacancy);
        } else {
            createMutation.mutate(newVacancy);
        }

        // Advance to the next step
        setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
    };

    const handleBack = () => {
        const newVacancy = { ...vacancyInformation };
        
        if (activeStep === 1) {
            newVacancy.step2.resume =
                newVacancy.step2.resume.map(updateGptStatus);
            newVacancy.step2.interview =
                newVacancy.step2.interview.map(updateGptStatus);
        }

        setActiveStep((prev) => Math.max(prev - 1, 0));
    };

    const handleInformationChange = useCallback((field1, field2, value) => {
        setVacancyInformation((prev) => ({
            ...prev,
            [field1]: {
                ...prev[field1],
                [field2]: value,
            },
        }));
    }, []);

    const handleFirstStep = useCallback(
        (field, value) => {
            handleInformationChange("step1", field, value);
        },
        [handleInformationChange]
    );

    const handleFirstStepToggle = useCallback((field) => {
        setVacancyInformation((prev) => {
            const newVacancyInformation = { ...prev };
            newVacancyInformation["step1"][field] =
                !newVacancyInformation["step1"][field];
            return newVacancyInformation;
        });
    }, []);

    const handleSecondStep = useCallback((field, value) => {
        setVacancyInformation((prev) => {
            const currentStep2 = prev.step2 || {};
            return {
                ...prev,
                step2: {
                    ...currentStep2,
                    [field]:
                        typeof value === "function"
                            ? value(currentStep2[field] || [])
                            : value,
                },
            };
        });
    }, []);

    const handleThirdStep = useCallback(
        (value) => {
            handleInformationChange("step3", "stages", value);
        },
        [handleInformationChange]
    );

    // append new array to old array
    const handleAddStages = useCallback((value) => {
        setVacancyInformation((prev) => {
            const oldStages = prev.step3?.stages || [];
            const newStages = [
                ...oldStages.slice(0, oldStages.length - 1), // all except last element
                ...value, // inserted value array
                oldStages[oldStages.length - 1], // the last element
            ];

            return {
                ...prev,
                step3: {
                    ...prev.step3,
                    stages: newStages,
                },
            };
        });
    }, []);

    const isLoading =
        createMutation.isLoading ||
        updateByButtonMutation.isLoading ||
        createVacancyMutation.isLoading;
    const isError =
        createMutation.isError ||
        updateByButtonMutation.isError ||
        createVacancyMutation.isError;
    const error =
        createMutation.error ||
        updateByButtonMutation.error ||
        createVacancyMutation.error;

    // Открытие модального окна, если is_vacancy_owner === true
    useEffect(() => {
        if (externalVacancyData?.data?.is_vacancy_owner) {
            setIsLinkModalOpen(true);
        }
    }, [externalVacancyData]);

    const handleLinkVacancy = () => {
        setIncludeExternalFields(true); // Устанавливаем флаг для включения external полей
        setIsLinkModalOpen(false); // Закрываем модальное окно
    };

    const characters = countCharacters(vacancyInformation["step1"]?.text);
    const checkValidity = () => {
        if (activeStep === 0) {
            return (
                !vacancyInformation["step1"]?.name ||
                !vacancyInformation["step1"]?.city ||
                !vacancyInformation["step1"]?.department ||
                characters < 150 ||
                !vacancyInformation["step1"]?.category
            );
        } else if (activeStep === 1) {
            return !vacancyInformation["step2"];
        } else if (activeStep === 2) {
            return !vacancyInformation["step3"];
        }
    };
    const isNotValid = checkValidity();

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <FirstStep
                        vacancyInformation={vacancyInformation["step1"] || {}}
                        onChange={handleFirstStep}
                        onToggle={handleFirstStepToggle}
                        characters={characters}
                    />
                );
            case 1:
                return (
                    <SecondStep
                        vacancyInformation={vacancyInformation["step2"] || {}}
                        onChange={handleSecondStep}
                        queryKey={queryKey.current}
                        description={vacancyInformation["step1"]?.text}
                        draftId={id}
                    />
                );
            case 2:
                return (
                    <>
                        {componentInfo === "step" && (
                            <AddStageButton
                                open={open}
                                handleOpen={handleOpen}
                            />
                        )}
                        <ThirdStep
                            stages={vacancyInformation["step3"]?.stages || []}
                            onChange={handleThirdStep}
                            componentInfo={componentInfo}
                            setComponentInfo={setComponentInfo}
                            draftId={id}
                            stashedStageId={stashedStageId}
                            stashedInterviewToCreate={stashedInterviewToCreate}
                        />
                    </>
                );
            case 3:
                return (
                    <FourthStep
                        vacancyInfo={vacancyInformation}
                        externalAdditionalFields={
                            includeExternalFields
                                ? {
                                      external_source: externalVacancyData?.data?.external_source,
                                      external_id: externalVacancyData?.data?.external_id,
                                  }
                                : {}
                        }
                        handleBack={handleBack}
                        id={id}
                        createMutation={createVacancyMutation}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Container
            maxWidth="none"
            sx={{
                margin: componentInfo === "step" ? "24px 0" : "unset",
                px:
                    componentInfo === "step"
                        ? "24px"
                        : "unset!important",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {isDraftFetching ? (
                <CircularLoading />
            ) : isDraftError ? (
                <CustomError error={draftError.message} />
            ) : (
                <Box
                    sx={{
                        maxWidth:
                            componentInfo === "step"
                                ? "1200px"
                                : "unset",
                        width: "100%",
                        minHeight: "calc(90vh - 25px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        {componentInfo === "step" && (
                            <CustomStepper
                                steps={steps}
                                activeStep={activeStep}
                            />
                        )}
                        <Paper
                            elevation={0}
                            sx={{
                                py: activeStep !== 2 ? 3 : 0,
                                px:
                                    activeStep === 2 ||
                                    componentInfo === "test"
                                        ? 0
                                        : 3,
                                borderRadius:
                                    componentInfo === "step" ? 2 : 0,
                                backgroundColor:
                                    activeStep === 2
                                        ? "transparent"
                                        : "#FFFFFF",
                                border: "none",
                                display: "flex",
                                flexDirection: "column",
                                minHeight:
                                    componentInfo === "step"
                                        ? "662px"
                                        : "unset",
                                height:
                                    componentInfo === "step"
                                        ? "auto"
                                        : "calc(100vh - 88px)",
                            }}
                        >
                            {isLoading ? (
                                <CircularLoading />
                            ) : isError ? (
                                <CustomError error={error.message} />
                            ) : (
                                renderStepContent(activeStep)
                            )}
                        </Paper>
                    </Box>

                    {open && (
                        <StagesModalWindow
                            handleAddStages={handleAddStages}
                            handleClose={handleClose}
                        />
                    )}

                    {componentInfo === "step" &&
                        activeStep < steps.length - 1 && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mt: 3,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    sx={{
                                        marginRight: 2,
                                        height: "56px",
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        width: "202px",
                                        color: "#599D15",
                                        borderColor: "#599D15",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                    }}
                                    onClick={handleBack}
                                    disabled={activeStep === 0}
                                >
                                    Назад
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: isNotValid
                                            ? "#E0E0E0"
                                            : "#599D15",
                                        color: isNotValid ? "#A0A0A0" : "white",
                                        height: "56px",
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        width: "202px",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                    }}
                                    onClick={handleNext}
                                    disabled={isNotValid} // Проверка на заполненность
                                >
                                    Перейти к {activeStep + 2} шагу
                                </Button>
                            </Box>
                        )}

                    <Dialog
                        open={isLinkModalOpen}
                        onClose={() => setIsLinkModalOpen(false)}
                        fullWidth
                        maxWidth="xs"
                        PaperProps={{
                            sx: {
                                borderRadius: "12px",
                                width: "352px",
                                padding: "24px",
                            },
                        }}
                    >
                        <DialogTitle
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <svg
                                    style={{ marginRight: "16px" }}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.1213 9.87891L9.8787 14.1215"
                                        stroke="#151813"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M10.3332 7.00003L11.9999 5.33336C13.8409 3.49239 16.8256 3.49239 18.6666 5.33335V5.33335C20.5076 7.17431 20.5076 10.1591 18.6666 12.0001L16.9999 13.6667"
                                        stroke="#151813"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M13.6665 17L11.9999 18.6666C10.1589 20.5076 7.17411 20.5076 5.33315 18.6666V18.6666C3.49219 16.8257 3.49219 13.8409 5.33315 11.9999L6.99983 10.3333"
                                        stroke="#151813"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                    />
                                </svg>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: "20px",
                                        lineHeight: "30px",
                                        padding: 0,
                                    }}
                                >
                                    Связать вакансию?
                                </Typography>
                            </div>
                            <Tooltip
                                title="Все новые отклики будут автоматически перенаправляться на платформу tapHR"
                                arrow
                            >
                                <IconButton>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_1183_11326)">
                                            <circle
                                                cx="9"
                                                cy="5.25"
                                                r="0.75"
                                                fill="#868C92"
                                            />
                                            <path
                                                d="M8.25 7.5H9V12.75M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                                                stroke="#868C92"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1183_11326">
                                                <rect
                                                    width="18"
                                                    height="18"
                                                    fill="white"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </IconButton>
                            </Tooltip>
                        </DialogTitle>
                        <DialogContent
                            sx={{ marginTop: 2, marginBottom: 3, padding: 0 }}
                        >
                            <Typography
                                sx={{
                                    color: "#868C92",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                }}
                            >
                                Мы обнаружили, что вакансия из HeadHunter
                                принадлежит вашей компании. Хотите связать её с
                                текущей вакансией?
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ padding: 0 }}>
                            <Button
                                variant="outlined"
                                onClick={() => setIsLinkModalOpen(false)}
                                sx={{
                                    color: "#599D15",
                                    borderColor: "#599D15",
                                    width: "148px",
                                    height: "46px",
                                    borderRadius: "8px",
                                }}
                            >
                                Отменить
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleLinkVacancy}
                                sx={{
                                    backgroundColor: "#599D15",
                                    color: "white",
                                    width: "148px",
                                    height: "46px",
                                    borderRadius: "8px",
                                }}
                            >
                                Связать
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
        </Container>
    );
};

const AddStageButton = memo(({ open, handleOpen }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
                px: 3,
            }}
        >
            <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: 20, lineHeight: "24px" }}
            >
                Этапы собеседования
            </Typography>
            <Box
                component="button"
                variant="outlined"
                key={open}
                onClick={handleOpen}
                sx={{
                    color: "#fff",
                    backgroundColor: "rgba(89, 157, 21, 1)",
                    border: "1px solid transparent",
                    minWidth: "174px",
                    height: "46px",
                    borderRadius: "8px",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    textTransform: "none",
                    display: "flex",
                    gap: "8px",
                    padding: "12px 20px",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "rgba(80, 140, 19, 1)",
                    },
                    "&:active": {
                        backgroundColor: "rgba(69, 122, 16, 1)",
                    },
                }}
            >
                <PlusIcon
                    sx={{
                        width: 20,
                        height: 20,
                    }}
                    svgSx={{
                        width: 10,
                        height: 10,
                        color: "#fff",
                    }}
                />
                Добавить этап
            </Box>
        </Box>
    );
});

export default CreateVacancyPage;
