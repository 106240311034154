import { memo, useCallback } from "react";
import { Box, Divider } from "@mui/material";
import { AudioIcon } from "../../svgComponents";
import QuestionPreview from "./QuestionPreview";
import CustomSelect from "../../styledComponents/CustomSelect";

const allowed_attempts_count = [{name: 1, id: 1}, {name: 2, id: 2}, {name: 3, id: 3}, {name: 4, id: 4}, {name: 5, id: 5}];
const duration_count = [{name: 1, id: 1}, {name: 2, id: 2}, {name: 3, id: 3}, {name: 5, id: 5}, {name: 10, id: 10}];

const AudioComponent = memo(({ types, data, isActive, order, handleQuestion, handleQuestionField}) => {

    const handleQuestionType = useCallback((value) => {
        handleQuestion(order - 1, value);
    }, [handleQuestion, order]);

    const handleAttemptsCount = useCallback((value) => {
        handleQuestionField(order - 1, "attempts_count", value);
    }, [handleQuestionField, order]);

    const handleDuration = useCallback((value) => {
        handleQuestionField(order - 1, "duration", value);
    }, [handleQuestionField, order]);
    
    return isActive ? (
        <>
            {/* Select Fields */}
            <Box sx={{ display: "flex", gap: "16px" }}>
                {/* Тип ответа */}
                <CustomSelect 
                    label="Тип ответа"
                    value={data.type} 
                    onChange={handleQuestionType}
                    options={types.names}
                    icons={types.icons}
                />

                <CustomSelect
                    label="Количество попыток"
                    value={data.attempts_count}
                    onChange={handleAttemptsCount}
                    options={allowed_attempts_count}
                />
            </Box>

            {/* Длительность */}
            <Box sx={{ width: "65%" }}>
                <CustomSelect
                    label="Длительность"
                    value={data.duration}
                    onChange={handleDuration}
                    options={duration_count}
                />
            </Box>
        </>
    ) : (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "16px",
            }}
        >
            <QuestionPreview
                title={`Вопрос №${order}`}
                subtitle={data.text}
            />
            <Divider
                sx={{
                    width: "100%",
                    backgroundColor: "rgba(241, 242, 243, 1)",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    flexWrap: "wrap",
                }}
            >
                <QuestionPreview
                    title="Тип ответа"
                    icon={
                        <AudioIcon
                            sx={{ width: 20, height: 20 }}
                            svgSx={{
                                width: 20,
                                height: 20,
                                color: "rgba(173, 181, 189, 1)",
                            }}
                        />
                    }
                    subtitle={"Аудио"}
                    sx={{ width: "314px" }}
                />
                <QuestionPreview
                    title="Количество попыток"
                    subtitle={data.attempts_count}
                />
                <QuestionPreview
                    title="Длительность"
                    subtitle={data.duration}
                    sx={{ width: "314px" }}
                />
            </Box>
        </Box>
    );
});

export default AudioComponent;
