// Импортируйте axiosClassic из конфигурации
import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

const createFormDataForQuestion = (question) => {
    const formData = new FormData();

    // Clone the question object to avoid mutating the original.
    const questionCopy = { ...question };

    // 1. Extract and remove preview image from the question object if it is a File.
    if ("image_path" in questionCopy) {
        if (questionCopy.image_path instanceof File) {
            formData.append("preview_image", questionCopy.image_path);
        }
        delete questionCopy.image_path;
    }

    // 2. Extract and remove each answer's image_path if it is a File.
    let count = 0;
    if (Array.isArray(questionCopy.answers)) {
        questionCopy.answers = questionCopy.answers.map((answer) => {
            // Clone each answer to avoid mutating the original.
            const answerCopy = { ...answer };
            if ("image_path" in answerCopy) {
                if (answerCopy.image_path instanceof File) {
                    formData.append(`images[${count}]`, answerCopy.image_path);
                    delete answerCopy.image_path;
                }
            }
            count++;
            return answerCopy;
        });
    }

    // 3. Convert the modified question object into a JSON string.
    const questionJson = JSON.stringify(questionCopy);

    // 4. Create the FormData and append the fields.
    formData.append("question", questionJson);

    return formData;
};

export const createQuestionTemplate = async ({ question }) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const formData = createFormDataForQuestion(question);
    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/auto-interviews/template/questions`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

export const fetchTestQuestionTemplates = async ({
    page = 1,
    perPage = 10,
    name = "",
    types = [],
    template,
    direction,
    theme,
}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        page,
        per_page: perPage,
        ...(name && { name }),
        ...(types.length && { types }),
        ...(template && { template }),
        ...(direction || theme ? { category_ids: [direction, theme].filter(Boolean) } : {}),
    };

    const response = await axiosClassic.get(
        `/api/company/${companyId}/auto-interviews/template/questions`,
        { params }
    );
    return responseWrapper(response);
};

export const imagesToPaths = async ({ formData, mappings, name }) => {
    const company_id = localStorage.getItem("company_id");
    if (!company_id) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(
            `/api/company/${company_id}/auto-interviews/template/create-images`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return { image_paths: responseWrapper(response), mappings, name };
    } catch (error) {
        throw error;
    }
};

export const createTestTemplate = async ({ test }) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(
            `/api/company/${companyId}/auto-interviews/template/tests`,
            test
        );
        return { response: responseWrapper(response), test: test };
    } catch (error) {
        throw error;
    }
};

// Imitate fetching with filtering
export const fetchTestTemplates = async ({
    page = 1,
    perPage = 10,
    name = "",
    template = "",
    direction = "",
}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(
            `/api/company/${companyId}/auto-interviews/template/tests`,
            {
                params: {
                    page,
                    per_page: perPage,
                    ...(name && { name }),
                    ...(template && { template }),
                    ...(direction && { category_id: direction }),
                },
            }
        );
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

// Imitate fetching a test template by id over infoTests
export const fetchTestTemplateById = async (id) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(
            `/api/company/${companyId}/auto-interviews/template/tests/${id}`
        );
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

export const fetchTestTemplatesByIds = async (ids) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(
            `/api/company/${companyId}/auto-interviews/template/tests`,
            { params: { ids } }
        );
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

export const deleteTestTemplate = async (id) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/auto-interviews/template/tests/${id}`
        );
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

export const fetchDefaultSendings = async () => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(
            `/api/company/${companyId}/templates/defaults`
        );
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}