import { forwardRef, memo } from "react";
import { Box, TextField } from "@mui/material";

const CustomInput = forwardRef(
    (
        {
            label,
            value,
            placeholder,
            readOnly,
            multiline,
            rows,
            onChange = () => {},
            sx = {},
            renderOutput,
            renderInput,
            required,
        },
        ref
    ) => {
        console.log("CustomInput rendered");
        return (
            <Box sx={sx}>
                {label && (
                    <label
                        style={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            fontWeight: 500,
                            marginBottom: "8px",
                            display: "block",
                        }}
                    >
                        {label}{" "}
                        {required && <span style={{ color: "red" }}>*</span>}
                    </label>
                )}
                <TextField
                    ref={ref}
                    sx={{
                        width: "100%",
                        backgroundColor: sx.inputBackgroundColor || "rgba(248, 249, 249, 1)",
                        borderRadius: "8px",
                        border: sx.inputBorder || "none",
                        "& fieldset": { border: "none" },
                        "& input": {
                            padding: sx.inputPadding || "16px",
                            fontSize: "16px",
                            lineHeight: "20px",
                            height: "20px",
                            cursor: readOnly ? "default" : "text",
                        },
                    }}
                    value={renderOutput ? renderOutput(value) : value}
                    onChange={(e) =>
                        onChange(
                            renderOutput
                                ? renderInput(e.target.value)
                                : e.target.value
                        )
                    }
                    placeholder={placeholder || undefined}
                    multiline={Boolean(multiline)}
                    rows={rows}
                    slotProps={{
                        input: {
                            readOnly: Boolean(readOnly),
                        },
                        htmlInput: {
                            autoComplete: "off",
                        },
                    }}
                />
            </Box>
        );
    }
);

export default memo(CustomInput);
