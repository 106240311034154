import React from "react";
import { Autocomplete, CircularProgress, TextField, Box, Typography } from "@mui/material";

const CustomAutocomplete = ({
    label,
    value,
    onChange,
    searchQuery,
    setSearchQuery,
    options,
    isLoading,
    hasNextPage,
    fetchNextPage,
    placeholder = "Выберите из списка",
    width = '356px',
    required,
    multiple = false, // Поддержка множественного выбора
    sx = {}, // Дополнительные стили
    disabled = false,
    ...props
}) => {
    const handleScrollEnd = (event) => {
        const listboxNode = event.currentTarget;
        // Добавляем небольшую зону (10px) до конца для предзагрузки
        const isNearBottom = listboxNode.scrollHeight - listboxNode.scrollTop <= listboxNode.clientHeight + 10;

        if (isNearBottom && hasNextPage && !isLoading) {
            fetchNextPage();
        }
    };
    console.log(disabled)

    return (
        <Box sx={{ mb: 3, width: width, ...sx }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: '#151813', mb: 1 }}>
                {label} {required && (
                    <Typography
                        component="span"
                        sx={{
                            color: "#FF3B30",
                            fontWeight: 500,
                            marginLeft: "4px",
                        }}
                    >
                        *
                    </Typography>
                )}
            </Typography>
            <Autocomplete
                disabled={disabled}
                multiple={multiple} // Включение множественного выбора
                noOptionsText={"Нет вариантов"}
                value={value}
                onChange={(event, newValue) => onChange(newValue)}
                onInputChange={(event, newValue) => setSearchQuery(newValue)}
                options={options}
                getOptionLabel={(option) => option?.name ? option.name : (option?.first_name ? option?.first_name + " " + option?.last_name : "")}
                isOptionEqualToValue={(option, value) => option?.id === value?.id} // Сравнение по id
                filterOptions={(options) => options} // Отключение встроенного фильтра
                loading={isLoading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        variant="outlined"
                        sx={{
                            backgroundColor: "#F8F9F9",
                            borderRadius: "8px",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "transparent",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#BDBDBD",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#599D15",
                                },
                            },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading && (
                                        <CircularProgress color="inherit" size={20} />
                                    )}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                ListboxProps={{
                    onScroll: handleScrollEnd,
                    sx: {
                        maxHeight: "200px",
                        overflowY: "auto",
                    },
                }}
            />
        </Box>
    );
};

export default CustomAutocomplete;
