import { Box } from "@mui/material";
import { useEffect, useState } from "react";

const easeOutQuad = (t) => t * (2 - t);

const CustomAnimatedProgressCircle = ({target, limit, outerSize, innerSize, children}) => {

    const [animatedScore, setAnimatedScore] = useState(0);

    useEffect(() => {
        // Reset animated score on score change
        setAnimatedScore(0);
        let startTime;
        const duration = 300; // Animation duration in ms

        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progressRatio = Math.min(elapsed / duration, 1);
            const easedProgress = easeOutQuad(progressRatio); 
            const currentScore = Math.round(easedProgress * target * 100) / 100; 
            setAnimatedScore(currentScore);

            if (elapsed < duration) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [target]);

    const angle = (animatedScore / limit) * 360;
    const size = outerSize; // Outer container diameter in px
    const outerRadius = size / 2; // 103px
    const innerDiameter = innerSize;
    const innerRadius = innerDiameter / 2; // ≈82.4px
    const ringThickness = outerRadius - innerRadius;
    const midRadius = (outerRadius + innerRadius) / 2;
    const rad = (angle * Math.PI) / 180;
    const center = outerRadius;
    const capX = center + midRadius * Math.sin(rad);
    const capY = center - midRadius * Math.cos(rad);
    const startX = center + midRadius * Math.sin(0);
    const startY = center - midRadius * Math.cos(0);

    return (
        <Box
            sx={{
                width: outerSize,
                height: outerSize,
                borderRadius: "50%",
                backgroundColor: "rgba(241, 242, 243, 1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                overflow: "hidden",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: `conic-gradient(rgba(149,105,217,1) 0deg, rgba(149,105,217,1) ${angle}deg, transparent ${angle}deg, transparent 360deg)`,
                    position: "relative",
                }}
            >
                {target ? (
                    <>
                        <Box
                            sx={{
                                position: "absolute",
                                left: capX,
                                top: capY,
                                width: ringThickness,
                                height: ringThickness,
                                borderRadius: "50%",
                                backgroundColor: "rgba(149,105,217,1)",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                            }}
                        />
                        <Box
                            sx={{
                                position: "absolute",
                                left: startX,
                                top: startY,
                                width: ringThickness,
                                height: ringThickness,
                                borderRadius: "50%",
                                backgroundColor: "rgba(149,105,217,1)",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                            }}
                        />
                    </>
                ) : null}
                <Box
                    sx={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 2,
                        width: innerSize,
                        height: innerSize,
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default CustomAnimatedProgressCircle;