import { Box, Typography } from "@mui/material";

export const TabPanel = ({ children, value, index, sx }) => {
    return (
        <div hidden={value !== index} role="tabpanel">
            {children && value === index && <Box sx={{ paddingTop: "20px", backgroundColor: "white", overflowX: "auto", ...sx }}>{children}</Box>}
        </div>
    );
};

export const TabLabel = ({ text, activeMainTab, index }) => {
    return (
        <Typography
            variant="body2"
            noWrap 
            sx={{ color: activeMainTab === index ? "rgba(89, 157, 21, 1)" : "#ADB5BD", fontWeight: 500, fontSize: "16px", lineHeight: "20px" }}
        >
            {text}
        </Typography>
    )
}
