import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";


export const fetchCandidateEmailMessages = async (candidateId) => {
    try {
        const response = await axiosClassic.get(`/api/messages/${candidateId}`)
        return responseWrapper(response)
    } catch (error) {
        throw error
    }
}

export const sendMailMessage = async (payload) => {
    try {
        const response = await axiosClassic.post(`/api/mail/send-message`, payload, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return responseWrapper(response)
    }
    catch (error) {
        throw error
    }
}