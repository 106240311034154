import React from "react";
import {
    Box,
    Drawer,
    Typography,
    Slider,
} from "@mui/material";
import CustomRadioList from "../styledComponents/CustomRadioList";

// Same definitions used in InterviewTab
const STATUS_OPTIONS = [{text: "Закончил", value: 2}, {text: "Начал", value: 1}, {text : "Авто-отказ", value: 3}];
const RECRUITER_SCORE_OPTIONS = [
    { value: 1, text: "Наиболее подходящий" },
    { value: 2, text: "Подходящий" },
    { value: 3, text: "Нейтрально" },
    { value: 4, text: "Неподходящий" },
];

const marks = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
];

export default function FilterDrawer({
    open,
    onClose,
    filters,
    handleClearFilters,
    handleStatusChange,
    handleField,
}) {
    // Slider callback
    const handleSliderChange = (e, newValue) => {
        // newValue is the [min, max] array
        handleField("ratingRange", newValue);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <Box
                sx={{
                    width: 280,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                }}
                role="presentation"
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: "24px",
                            lineHeight: "28px",
                            fontWeight: 700,
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    >
                        Фильтр
                    </Typography>
                    <Typography
                        sx={{
                            color: "rgba(89, 157, 21, 1)",
                            cursor: "pointer",
                            padding: "13px 8.5px",
                            borderRadius: "6px",
                            "&:hover": {
                                backgroundColor: "rgba(244, 247, 242, 1)",
                            },
                            "&:active": { backgroundColor: "transparent" },
                        }}
                        onClick={handleClearFilters}
                    >
                        Очистить
                    </Typography>
                </Box>

                {/* Статус */}
                <Typography
                    sx={{
                        fontWeight: 600,
                        mb: 2,
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    Статус
                </Typography>
                <CustomRadioList
                    multiInputs={true}
                    isCheckBox={true}
                    defaultValue={filters.selectedStatuses}
                    inputs={STATUS_OPTIONS}
                    onClick={(value) =>
                        handleStatusChange(
                            "selectedStatuses",
                            value,
                            !filters.selectedStatuses.includes(value)
                        )
                    }
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: 2,
                        mb: "36px",
                    }}
                />

                {/* Рейтинг (slider) */}
                <Typography sx={{ fontWeight: 500, mb: 1 }}>Рейтинг</Typography>
                <Slider
                    // The current min/max range in your state, e.g. [1, 3]
                    value={filters.ratingRange}
                    onChange={handleSliderChange}
                    // We want discrete steps from 1 to 5
                    min={1}
                    max={5}
                    step={1}
                    marks={marks}
                    // Hide default value labels (we're using marks instead)
                    valueLabelDisplay="off"
                    sx={{
                        // The "active" portion of the track (between thumbs) will use this color
                        color: "rgba(149, 105, 217, 1)",
                        width: "90%",
                        margin: "0 auto",

                        // Remove default border on the track
                        "& .MuiSlider-track": {
                            border: "none",
                            height: 8,
                        },

                        // Style the "thumb" (the draggable circle)
                        "& .MuiSlider-thumb": {
                            height: 16,
                            width: 16,
                            backgroundColor: "rgba(149, 105, 217, 1)",
                        },

                        // Style the unselected portion of the track
                        "& .MuiSlider-rail": {
                            height: 8,
                            borderRadius: 4,
                            color: "rgba(226, 228, 231, 1)",
                        },

                        // Style the round marks (the little dots at each step)
                        "& .MuiSlider-mark": {
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            marginLeft: "-6px", // half of 12
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            border: "2px solid rgba(226, 228, 231, 1)",
                            "&.MuiSlider-markActive": {
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                borderColor: "rgba(174, 140, 228, 1)",
                            },
                        },

                        // Style the mark labels (the numbers under each mark)
                        "& .MuiSlider-markLabel": {
                            fontSize: 14,
                            lineHeight: "18px",
                            color: "rgba(21, 24, 19, 1)",
                            mt: 1,
                        },
                        mb: "62px",
                    }}
                />

                {/* Оценка (recruiter_score) */}
                <Typography sx={{ fontWeight: 500, mb: 1 }}>Оценка</Typography>
                <CustomRadioList
                    multiInputs={true}
                    isCheckBox={true}
                    defaultValue={filters.selectedScores}
                    inputs={RECRUITER_SCORE_OPTIONS}
                    onClick={(value) =>
                        handleStatusChange(
                            "selectedScores",
                            value,
                            !filters.selectedScores.includes(value)
                        )
                    }
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: 2,
                        mb: 3,
                    }}
                />

                {/* <Button variant="contained" onClick={onClose}
                    sx={{
                        py: "13px",
                        backgroundColor: "rgba(21, 24, 19, 1)",
                        borderRadius: "8px",
                        color: "white",
                        "&:hover": { backgroundColor: "rgba(19, 21, 17, 1)" },
                        "&:active": { backgroundColor: "rgba(16, 19, 15, 1)" },
                    }}
                >
                    Применить
                </Button> */}
            </Box>
        </Drawer>
    );
}
