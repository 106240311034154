import React, { memo, useEffect, useState } from "react";
import { Box, Typography, Divider, Chip } from "@mui/material";
import { AiIcon } from "../svgComponents";
import CustomAnimatedProgressCircle from "../styledComponents/CustomAnimatedProgressCircle";

const RatingsUi = memo(({ score, description }) => {
    return (
        <Box
            sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                padding: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                borderRadius: "8px",
                transition: "all 0.3s",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <AiIcon
                    sx={{ width: 24, height: 24 }}
                    svgSx={{ width: 19, height: 22 }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "24px",
                        background:
                            "linear-gradient(90deg, #392D69 0%, #B57BEE 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        mr: 1,
                    }}
                >
                    Рейтинг кандидата
                </Typography>
            </Box>
            <CustomAnimatedProgressCircle
                target={score}
                limit={100}
                outerSize={206}
                innerSize={180}
            >
                <Typography
                    sx={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "rgba(160, 174, 192, 1)",
                        fontWeight: 500,
                    }}
                >
                    рейтинг
                </Typography>
                {score ? (
                    <>
                        <Typography
                            sx={{
                                fontSize: "48px",
                                lineHeight: "52px",
                                color: "rgba(46, 64, 82, 1)",
                                fontWeight: 700,
                            }}
                        >
                            {score}%
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                lineHeight: "18px",
                                color: "rgba(160, 174, 192, 1)",
                                fontWeight: 500,
                            }}
                        >
                            На основе ИИ
                        </Typography>
                    </>
                ) : (
                    <Typography
                        sx={{
                            fontSize: "24px",
                            lineHeight: "28px",
                            color: "rgba(46, 64, 82, 1)",
                            fontWeight: 700,
                        }}
                    >
                        Отсутствует
                    </Typography>
                )}
            </CustomAnimatedProgressCircle>
            <Typography
                sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "rgba(21, 24, 19, 1)",
                    display: "flex",
                    padding: "16px",
                    alignItems: "flexstart",
                    backgroundColor: "rgba(248, 249, 249, 1)", // Background for the inner content
                    color: description ? "rgba(21, 24, 19, 1)" : "#ADB5BD",
                    border: "2px solid rgba(174, 140, 228, 1)",
                    borderRadius: "6px",
                }}
            >
                {description || "Рейтинг для этого кандидата недоступен"}
            </Typography>
        </Box>
    );
});

export default RatingsUi;
