import { memo } from "react";
import { Box, Typography, TextField } from "@mui/material";
import InputMask from "react-input-mask";

const LabeledInput = memo(({ label, value, onChange, sx, required, mask, ...props }) => {
    return (
        <Box sx={{ ...sx }}>
            <Typography
                sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#151813",
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {label}
                {required && (
                    <Typography
                        component="span"
                        sx={{
                            color: "#FF3B30",
                            fontWeight: 500,
                            marginLeft: "4px",
                            lineHeight: "20px",
                        }}
                    >
                        *
                    </Typography>
                )}
            </Typography>
            {mask ? (
                <InputMask
                    mask={mask}
                    value={value}
                    onChange={onChange}
                >
                    {(inputProps) => (
                        <TextField
                            {...inputProps}
                            fullWidth
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            {...props}
                            slotProps={{
                                input: {
                                    autoComplete: "off",
                                },
                            }}
                            sx={{
                                backgroundColor: "#F8F9F9",
                                border: "1px solid rgba(226, 228, 231, 1)",
                                borderRadius: "8px",
                                width: "100%",
                                "&:hover": {
                                    borderColor: "#599D15", // Hover border color
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                "& .MuiOutlinedInput-root": {
                                    height: "54px",
                                    borderRadius: "8px",
                                },
                                "&:has(.Mui-focused)": {
                                    border: "2px solid rgba(205, 221, 198, 1)", // Focused border color
                                    "& .MuiOutlinedInput-root": {
                                        height: "52px", // Height when focused
                                    },
                                },
                            }}
                        />
                    )}
                </InputMask>
            ) : (
                <TextField
                    fullWidth
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    {...props}
                    slotProps={{
                        input: {
                            autoComplete: "off",
                        },
                    }}
                    sx={{
                        backgroundColor: "#F8F9F9",
                        border: "1px solid rgba(226, 228, 231, 1)",
                        borderRadius: "8px",
                        width: "100%",
                        "&:hover": {
                            borderColor: "#599D15", // Hover border color
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        "& .MuiOutlinedInput-root": {
                            height: "54px",
                            borderRadius: "8px",
                        },
                        "&:has(.Mui-focused)": {
                            border: "2px solid rgba(205, 221, 198, 1)", // Focused border color
                            "& .MuiOutlinedInput-root": {
                                height: "52px", // Height when focused
                            },
                        },
                    }}
                />
            )}
        </Box>
    );
});

export default LabeledInput;
