import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Modal,
    Radio,
    FormControlLabel,
    Paper,
    Divider,
    IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import backupIcon from '../../assets/icons/backup.png';
import { uploadCandidateFile } from '../../services/candidatesApi';
import { CloseIcon, AddPersonIcon } from '../svgComponents';
import CustomRadioList from '../styledComponents/CustomRadioList';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 403,
    backgroundColor: 'white',
    border: 'none',
    borderRadius: 5,
    boxShadow: 24,
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden', // Ensure no content spills outside during transition
    outline: "none", // Remove focus ring
};

const CreateCandidateModal = ({ open, handleClose }) => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('manual');
    const [file, setFile] = useState(null);

    const handleCreate = async () => {
        if (selectedOption === 'file') {
            if (!file) {
                alert('Выберите файл перед сохранением!');
                return;
            }

            try {
                const response = await uploadCandidateFile(file);
                console.log("Файл успешно загружен:", response);
                alert("Файл успешно загружен");
                handleClose();
            } catch (error) {
                console.error("Ошибка при загрузке файла:", error);
                alert("Ошибка при загрузке файла");
            }
        } else {
            navigate('/candidates/create'); // Переход на форму ручного создания
        }
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                handleClose();
                setFile(null);
                setSelectedOption("manual");
            }}
        >
            <Paper sx={{
                ...style,
                height: selectedOption === "file" ? 472 : 278,
                transition: "height 0.3s ease",
            }}>
                <Box
                    sx={{
                        display: "flex",
                        height: 48,
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 0,
                        marginBottom: "8px",
                        position: "relative",
                        mb: "24px",
                    }}
                >
                    <Box
                        sx={{
                            width: 56,
                            height: 56,
                            backgroundColor: "rgba(232, 239, 228, 1)",
                            border: "8px solid rgba(244, 247, 242, 1)",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <AddPersonIcon
                            sx={{
                                width: 48,
                                height: 48,
                            }}
                            svgSx={{
                                width: 24,
                                height: 24,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                    </Box>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>
                <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 20,
                        lineHeight: "24px",
                        mb: 2,
                    }}
                >
                    Создать кандидата
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "rgba(116, 116, 115, 1)",
                        mb: 2,
                    }}
                >
                    Выберите способ
                </Typography>
                <CustomRadioList
                    sx={{ display: "flex", gap: "16px", mb: 2 }}
                    isHor={true}
                    defaultValue={selectedOption}
                    inputs={[
                        { value: "manual", text: "Вручную" },
                        { value: "file", text: "Из файла" },
                    ]}
                    onClick={setSelectedOption}
                />
                <Box
                    sx={{
                        mb: selectedOption === "file" ? 2 : 0,
                        opacity: selectedOption === "file" ? "1" : "0", // Control visibility
                        transform: selectedOption === "file" ? "scale(1)" : "scale(0)", // Scale to full size or hide
                        transformOrigin: "center", // Anchor scaling at the center
                        transition: "transform 0.3s ease, opacity 0.3s ease", // Smooth scaling and fading
                        display: "flex",
                        flexDirection: "column",
                        gap: selectedOption === "file" ? 2 : 0, // Scale to full size or hide
                    }}
                >
                    <Box
                        sx={{
                            border:
                                selectedOption === "file"
                                    ? "2px dashed #1F6BC5"
                                    : "none",
                            borderRadius: "8px",
                            padding: selectedOption === "file" ? "20px" : "0px",
                            textAlign: "center",
                            cursor: "pointer",
                            height: selectedOption === "file" ? "142px" : "0px",
                            overflow: "hidden", // Hide content when height is reduced
                        }}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                    >
                        <img
                            style={{ width: 36, height: 36 }}
                            src={backupIcon}
                            alt=""
                            loading='lazy' 
                        />
                        <Typography>
                            Перетащите ваш файл сюда или{" "}
                            <label
                                htmlFor="file-upload"
                                style={{ color: "#1F6BC5", cursor: "pointer" }}
                            >
                                выберите файл
                            </label>
                        </Typography>
                        <Typography sx={{ color: "#747473", fontSize: "14px" }}>
                            Максимальный размер файла 2 MB
                        </Typography>
                        <input
                            id="file-upload"
                            type="file"
                            accept=".pdf,.doc,.docx,.png,.jpg"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                        />
                        {file && (
                            <Typography sx={{ mt: 2, mb: 2 }}>
                                Выбран файл: {file.name}
                            </Typography>
                        )}
                    </Box>
                    <Typography sx={{color: "rgba(109, 109, 109, 1)", fontSize: "14px", lineHeight: selectedOption === "file" ? "20px" : "0"}}>{file ? `Выбран файл: ${file.name}` : "Поддерживает только .pdf, .doc, .docx, .png, .jpg" }</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                    <Button
                        onClick={() => {
                            handleClose();
                            setFile(null);
                            setSelectedOption("manual");
                        }}
                        variant="outlined"
                        sx={{
                            flex: 1,
                            color: "rgba(46, 64, 82, 1)",
                            borderColor: "rgba(46, 64, 82, 1)",
                            padding: "13px 20px",
                            height: "44px",
                            borderRadius: "8px",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                        }}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={handleCreate}
                        variant="contained"
                        sx={{
                            flex: 1,
                            backgroundColor: "rgba(89, 157, 21, 1)",
                            color: "#fff",
                            height: "44px",
                            borderRadius: "8px",
                            boxShadow: "none",
                            padding: "13px 20px",
                            "&:hover": {
                                backgroundColor: "rgba(80, 140, 19, 1)",
                                boxShadow: "none !important",
                            },
                            "&:active": {
                                backgroundColor: "rgba(69, 122, 16, 1)",
                            },
                        }}
                    >
                        Создать
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
};

export default CreateCandidateModal;
