import { Box, Skeleton } from "@mui/material";
import { useMemo, memo, useState } from "react";
import { TrashIcon } from "../../svgComponents";

const ImageWrapper = memo(({ imgKey, sx, imageWidth, imageHeight, imageFit, index, imageRadius, onClick }) => {
    console.log("ImageWrapper rendered");
    const [imageLoaded, setImageLoaded] = useState(false);

    const imageSrc = useMemo(() => {
        if (imgKey instanceof File) {
            return URL.createObjectURL(imgKey);
        }
        return imgKey;
    }, [imgKey]);

    return (
        <Box
            sx={{
                position: "relative",
                ...sx,
                width: imageWidth || "60%",
                height: imageHeight || "unset",
            }}
        >
            {!imageLoaded && (
                <Skeleton
                    variant="rectangular"
                    width={imageWidth || "100%"}
                    height={imageHeight || 150}
                    sx={{ borderRadius: imageRadius }}
                />
            )}
            <img
                src={imageSrc}
                alt={`option ${index}`}
                style={{
                    display: imageLoaded ? "block" : "none",
                    width: imageWidth || "100%",
                    height: imageHeight || "unset",
                    objectFit: "cover",
                    objectPosition: "top",
                    borderRadius: imageRadius,
                }}
                onLoad={() => setImageLoaded(true)}
            />
            <TrashIcon
                onClick={() => onClick(index)}
                sx={{
                    width: imageWidth || "100%",
                    height: imageHeight || "100%",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    borderRadius: `${imageRadius}px`,
                }}
                svgSx={{
                    width: 18,
                    height: 20,
                    color: "transparent",
                    hoverColor: "white",
                    pressedColor: "white",
                }}
            />
        </Box>
    );
});

export default ImageWrapper;
