import { Box, Typography } from "@mui/material";
import { SearchFolderIcon } from "../svgComponents";

const DataShortage = () => {
    return (
        <Box sx= {{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 2, height: 500}}>
            <SearchFolderIcon
                sx={{width: 80, height: 80, backgroundColor : "rgba(89, 157, 21, 1)", borderRadius: "50%"}}
                svgSx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    width: 44,
                    height: 44,
                }}
            />
            <Typography
                sx={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    fontWeight: 500,
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                Нет данных
            </Typography>
        </Box>
    )
}

export default DataShortage;