// services/authApi.js
import axiosClassic from '../api/interceptors';
import { responseWrapper } from './responseWrapper';

// Функция для авторизации
export const login = async (credentials) => {
    try {
        const response = await axiosClassic.post('/api/auth/login', credentials);
        
        return responseWrapper(response);
    } catch (error) {
        throw responseWrapper(error?.response);
    }
};

// Функция для установки нового пароля
export const setNewPassword = async (newPassword) => {
    const token = localStorage.getItem('invite_token'); // Получаем invite токен из локального хранилища

    try {
        const response = await axiosClassic.post(
            '/api/auth/set-new-password',
            { new_password: newPassword },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    
        const { access_token, refresh_token } = response.data.data;
    
        if (access_token) {
            localStorage.setItem('access_token', access_token);
        }
        if (refresh_token) {
            localStorage.setItem('refresh_token', refresh_token);
        }
        localStorage.removeItem('invite_token'); // Удаляем invite_token после установки нового пароля

        return responseWrapper(response);
    } catch (error) {
        throw responseWrapper(error?.response);
    }
};

export const logout = async () => {
    try {
        const response = await axiosClassic.post('/api/auth/logout');
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};
