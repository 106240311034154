import {
    Box,
    Button,
    Dialog,
    DialogActions,
    Typography,
    Chip,
} from "@mui/material";
import {
    CheckUpIcon,
    CheckUpIcon2,
    CloseIcon,
    ConnectionsIcon,
    GoogleMeetIcon,
    PencilIcon,
    SettingsIcon,
    TrashIcon,
    WarningIcon2,
    ZoomIcon,
} from "../../svgComponents";
import { useCallback, useEffect, useState } from "react";
import { getAddress, getSlotsAction } from "../../../services/calendarApi";
import { useQuery } from "@tanstack/react-query";
import { CircularLoading } from "../../tapHrStyledComponents";
import { CustomError } from "../../styledComponents/CustomErrors";
import CustomSelect from "../../styledComponents/CustomSelect";
import CustomRadioList from "../../styledComponents/CustomRadioList";
import {
    ACTIVITY_STATUS_INTERVIEW,
    ACTIVITY_STATUS_TEST,
} from "../../../utils/activityStorage";

const ActivitySettingsModal = ({
    currentOption,
    currentActivity,
    handleClose,
    startTest,
    deleteTest,
    testToCreate,
    interviewToCreate,
    setInterviewToCreate,
    saveActivity,
    handleProcessActivation,
    processesData,
    isProcessesLoading,
    isProcessesError,
    processesError,
}) => {
    const activeTest =
        (currentOption === ACTIVITY_STATUS_TEST && currentActivity) ||
        testToCreate;
    const [activeTab, setActiveTab] = useState(
        currentOption === ACTIVITY_STATUS_INTERVIEW ? 2 : 1
    );
    const [isPlatformDisconnected, setIsPlatformDisconnected] = useState(false);

    const handleSaveActivity = () => {
        if (activeTab === 2 && interviewToCreate.isOnline !== undefined) {
            delete interviewToCreate.isOnline;
        }
        let option =
            activeTab === 1
                ? ACTIVITY_STATUS_TEST
                : activeTab === 2
                ? ACTIVITY_STATUS_INTERVIEW
                : null;
        saveActivity(
            option,
            activeTab === 1
                ? testToCreate
                : activeTab === 2
                ? interviewToCreate
                : null
        );
    };

    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    };

    const isDisabled = () => {
        if (activeTab === 1) {
            return !activeTest;
        } else if (activeTab === 2) {
            return (
                !(
                    interviewToCreate.schedule_id &&
                    !(
                        interviewToCreate.isOnline &&
                        interviewToCreate.type === 0
                    )
                ) || isPlatformDisconnected
            );
        }
        return true;
    };

    const buttonDisabled = Boolean(isDisabled());

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "670px",
                    maxWidth: "unset",
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "hidden",
                    gap: "36px",
                    padding: "24px",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    position: "relative",
                    gap: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                            position: "relative",
                            height: 48,
                        }}
                    >
                        <SettingsIcon
                            sx={{
                                width: 56,
                                height: 56,
                                borderRadius: "50%",
                                backgroundColor: "rgba(232, 239, 228, 1)",
                                border: "8px solid rgba(244, 247, 242, 1)",
                                position: "relative",
                                left: "-4px",
                            }}
                            svgSx={{
                                width: 24,
                                height: 24,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "600",
                                fontSize: "20px",
                                lineHeight: "24px",
                            }}
                        >
                            Настройка действий
                        </Typography>
                    </Box>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>

                <Box
                    sx={{
                        width: "100%",
                        padding: "4px",
                        border: "1px solid rgba(210, 214, 218, 1)",
                        borderRadius: "8px",
                        display: "flex",
                        gap: "8px",
                    }}
                >
                    <Box
                        onClick={() => handleActiveTab(1)}
                        component="button"
                        sx={{
                            cursor: "pointer",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor:
                                activeTab === 1
                                    ? "rgba(46, 64, 82, 1)"
                                    : "transparent",
                            borderRadius: "8px",
                            border: "none",
                            padding: "16px",
                            gap: "8px",
                            fontSize: "14px",
                            lineHeight: "18px",
                            color:
                                activeTab === 1
                                    ? "white"
                                    : "rgba(162, 162, 162, 1)",
                            "&:hover": {
                                backgroundColor:
                                    activeTab === 1
                                        ? "rgba(46, 64, 82, 1)"
                                        : "rgba(242, 243, 243, 1)",
                            },
                        }}
                    >
                        {activeTab === 1 ? (
                            <CheckUpIcon
                                sx={{ width: 24, height: 24 }}
                                svgSx={{
                                    width: 20,
                                    height: 20,
                                    color: "transparent",
                                }}
                            />
                        ) : (
                            <CheckUpIcon2
                                sx={{ width: 24, height: 24 }}
                                svgSx={{
                                    width: 20,
                                    height: 20,
                                    color: "rgba(162, 162, 162, 1)",
                                }}
                            />
                        )}
                        Автособеседование
                    </Box>
                    <Box
                        onClick={() => handleActiveTab(2)}
                        component="button"
                        sx={{
                            cursor: "pointer",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor:
                                activeTab === 2
                                    ? "rgba(46, 64, 82, 1)"
                                    : "transparent",
                            borderRadius: "8px",
                            border: "none",
                            padding: "16px",
                            gap: "8px",
                            fontSize: "14px",
                            lineHeight: "18px",
                            color:
                                activeTab === 2
                                    ? "white"
                                    : "rgba(162, 162, 162, 1)",
                            "&:hover": {
                                backgroundColor:
                                    activeTab === 2
                                        ? "rgba(46, 64, 82, 1)"
                                        : "rgba(242, 243, 243, 1)",
                            },
                        }}
                    >
                        <ConnectionsIcon
                            sx={{ width: 24, height: 24 }}
                            svgSx={{
                                width: 20,
                                height: 20,
                                color:
                                    activeTab === 2
                                        ? "white"
                                        : "rgba(162, 162, 162, 1)",
                            }}
                        />
                        Ссылка на запись интервью
                    </Box>
                </Box>

                {activeTab === 1 && (
                    <Test
                        activeTest={activeTest}
                        startTest={startTest}
                        deleteTest={deleteTest}
                    />
                )}
                {activeTab === 2 && (
                    <Interview
                        interviewInfo={interviewToCreate}
                        setInterviewInfo={setInterviewToCreate}
                        handleProcessActivation={handleProcessActivation}
                        processesData={processesData}
                        isProcessesLoading={isProcessesLoading}
                        isProcessesError={isProcessesError}
                        processesError={processesError}
                        setIsPlatformDisconnected={setIsPlatformDisconnected}
                        isPlatformDisconnected={isPlatformDisconnected}
                    />
                )}
            </Box>

            <DialogActions sx={{ padding: 0 }}>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        flex: 1,
                        color: "rgba(46, 64, 82, 1)",
                        borderColor: "rgba(46, 64, 82, 1)",
                        height: "44px",
                        borderRadius: "8px",
                        padding: "12px 20px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                    }}
                >
                    Отменить
                </Button>

                <Button
                    variant="contained"
                    onClick={handleSaveActivity}
                    disabled={buttonDisabled}
                    sx={{
                        flex: 1,
                        backgroundColor: "rgba(89, 157, 21, 1)",
                        color: "#fff",
                        height: "44px",
                        borderRadius: "8px",
                        padding: "12px 20px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                            boxShadow: "none !important",
                        },
                        "&:active": {
                            backgroundColor: "rgba(69, 122, 16, 1)",
                        },
                    }}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Test = ({ activeTest, startTest, deleteTest }) => {
    return (
        <Box
            sx={{
                display: "flex",
                padding: "24px",
                gap: activeTest ? "12px" : "52px",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: "rgba(241, 242, 243, 1)",
                padding: "24px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "20px",
                            lineHeight: "24px",
                            fontWeight: "600",
                            textWrap: "nowrap",
                        }}
                    >
                        Настройка собеседования
                    </Typography>
                    {activeTest && (
                        <Chip
                            label="Тест создан"
                            sx={{
                                backgroundColor: "rgba(242, 248, 244, 1)",
                                color: "rgba(18, 156, 82, 1)",
                                fontSize: "14px",
                                lineHeight: "18px",
                                fontWeight: 500,
                            }}
                        />
                    )}
                </Box>
                <Typography
                    sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    Настройте одностороннее собеседование, анкету или тест.
                    Платформа автоматически отправит кандидату ссылку для
                    прохождения.
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Box
                    component="button"
                    onClick={startTest}
                    sx={{
                        padding: "13px 21px",
                        width: "fit-content",
                        backgroundColor: "rgba(46, 64, 82, 1)",
                        borderRadius: "8px",
                        fontSize: "16px",
                        color: "white",
                        border: "none",
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "rgba(29, 40, 52, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(21, 29, 37, 1)",
                        },
                    }}
                >
                    {activeTest ? (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                            }}
                        >
                            <PencilIcon
                                sx={{ width: 20, height: 20 }}
                                svgSx={{
                                    width: 20,
                                    height: 20,
                                    color: "white",
                                }}
                            />
                            Изменить тест
                        </Box>
                    ) : (
                        "Создать тест"
                    )}
                </Box>
                {activeTest && (
                    <Box
                        component="button"
                        onClick={deleteTest}
                        variant="outlined"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            flex: 1,
                            color: "rgba(255, 59, 48, 1)",
                            border: "1px solid",
                            borderColor: "rgba(255, 59, 48, 1)",
                            height: "46px",
                            borderRadius: "8px",
                            padding: "12px 20px",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "rgba(255, 243, 242, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(255, 201, 200, 1)",
                            },
                            fontSize: "16px",
                            lineHeight: "20px",
                            cursor: "pointer",
                        }}
                    >
                        Удалить
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const channelList = [
    {
        text: "Whatsapp",
        subtext: "Кандидат получит ссылку через whatsapp bot",
        value: 1,
    },
    // {text: "E-mail", value: 2},
];

const durationList = [
    { name: "15 минут", id: 15 },
    { name: "30 минут", id: 30 },
    { name: "45 минут", id: 45 },
    { name: "60 минут", id: 60 },
    { name: "75 минут", id: 75 },
    { name: "90 минут", id: 90 },
    { name: "120 минут", id: 120 },
];

const typeList = [
    { text: "Офлайн", value: false },
    { text: "Онлайн", value: true },
];

const mapPlatform = (id) => {
    switch (id) {
        case 1:
            return "google";
        case 2:
            return "zoom";
        default:
            return null;
    }
};

const platformList = [
    { name: "Google meet", id: 1 },
    { name: "Zoom", id: 2 },
];

const Interview = ({
    interviewInfo,
    setInterviewInfo,
    processesData,
    isProcessesLoading,
    isProcessesError,
    processesError,
    handleProcessActivation,
    setIsPlatformDisconnected,
    isPlatformDisconnected,
}) => {
    const { data, isLoading, isError, error } = useQuery(
        ["slots"],
        getSlotsAction,
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const {
        data: addresses,
        isLoading: isLoadingAddresses,
        isError: isErrorAddresses,
        error: adressError,
    } = useQuery(
        ["addressData"],
        async () => {
            const result = await getAddress();
            if (result?.data?.length && !interviewInfo.meeting_address) {
                handleInterviewPlace(result.data[0]?.address);
            }
            return result;
        },
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const handleSelectedChannel = useCallback((value) => {
        setInterviewInfo((prev) => ({
            ...prev,
            channels: prev.channels.includes(value)
                ? prev.channels.filter((channel) => channel !== value)
                : [...prev.channels, value],
        }));
    }, []);

    const handleInterviewPlace = useCallback((value) => {
        setInterviewInfo((prev) => ({
            ...prev,
            meeting_address: value,
        }));
    }, []);

    const handleTypeSelection = useCallback(
        (value) => {
            setInterviewInfo((prev) => ({
                ...prev,
                type: value,
                meeting_address:
                    value === 0
                        ? 0 < addresses?.data.length
                            ? addresses.data[0]?.address
                            : null
                        : null,
            }));
        },
        [addresses]
    );

    const handleIsOnline = useCallback(
        (isOnline) => {
            setInterviewInfo((prev) => ({
                ...prev,
                isOnline: isOnline,
            }));
            if (!isOnline) {
                handleTypeSelection(0);
            }
        },
        [handleTypeSelection]
    );

    const handleScheduleSelection = useCallback((value) => {
        setInterviewInfo((prev) => ({
            ...prev,
            schedule_id: value,
        }));
    }, []);

    const handleDurationSelection = useCallback((value) => {
        setInterviewInfo((prev) => ({
            ...prev,
            slot_duration: value,
        }));
    }, []);

    const schedules = data?.data || [];
    const processes = processesData?.data || {};

    useEffect(() => {
        if (interviewInfo.type === 1 && interviewInfo.type === 2) {
            const platform = processes[mapPlatform(interviewInfo.type)];
            setIsPlatformDisconnected(!platform?.connected && platform?.auth_url);
        }
    }, [interviewInfo.type, processes]);

    return (
        <Box
            sx={{
                minHeight: "194px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            {isLoading || isLoadingAddresses || isProcessesLoading ? (
                <CircularLoading />
            ) : isError || isErrorAddresses || isProcessesError ? (
                <CustomError
                    error={
                        error?.message ||
                        adressError?.message ||
                        processesError?.message ||
                        "Неопознанная ошибка"
                    }
                />
            ) : (
                <>
                    <CustomSelect
                        label="График"
                        required={true}
                        value={interviewInfo.schedule_id}
                        options={schedules}
                        placeholder="Выберите график"
                        onChange={handleScheduleSelection}
                        targetRender="title"
                    />
                    {interviewInfo.schedule_id && (
                        <>
                            {/* Channels */}
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "rgba(21, 24, 19, 1)",
                                    fontWeight: 500,
                                    mt: 3,
                                    mb: 2,
                                }}
                            >
                                Каналы отправки
                            </Typography>

                            <CustomRadioList
                                defaultValue={interviewInfo.channels}
                                inputs={channelList}
                                onClick={handleSelectedChannel}
                                multiInputs={true}
                                isCheckBox={true}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: "16px",
                                    mb: 3,
                                }}
                            />

                            {/* Duration */}
                            <CustomSelect
                                label="Длительность интервью"
                                value={interviewInfo.slot_duration}
                                options={durationList}
                                onChange={handleDurationSelection}
                            />
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "rgba(116, 116, 115, 1)",
                                    mt: "6px",
                                    mb: 2,
                                }}
                            >
                                Укажите сколько будет длиться интервью на этом
                                этапе
                            </Typography>

                            {/* Type */}
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "rgba(21, 24, 19, 1)",
                                    fontWeight: 500,
                                    mt: 3,
                                    mb: 2,
                                }}
                            >
                                Тип интервью
                            </Typography>

                            <CustomRadioList
                                defaultValue={interviewInfo.isOnline}
                                isHor={true}
                                inputs={typeList}
                                onClick={handleIsOnline}
                                sx={{ display: "flex", gap: "16px", mb: 2 }}
                            />

                            {/* Place */}
                            {interviewInfo.isOnline === false && (
                                <CustomSelect
                                    label="Адрес"
                                    value={interviewInfo.meeting_address || ""}
                                    options={addresses?.data}
                                    onChange={handleInterviewPlace}
                                    targetRender="address"
                                    targetSave="address"
                                />
                            )}
                            {interviewInfo.isOnline === true && (
                                <>
                                    <CustomSelect
                                        label="Платформа"
                                        value={
                                            interviewInfo.type === 0
                                                ? ""
                                                : interviewInfo.type
                                        }
                                        options={platformList}
                                        onChange={handleTypeSelection}
                                        placeholder="Выберите платформу"
                                        icons={[
                                            <GoogleMeetIcon
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                                svgSx={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                            />,
                                            <ZoomIcon
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                                svgSx={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                            />,
                                        ]}
                                    />
                                    {isPlatformDisconnected && (
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                color: "rgba(255, 59, 48, 1)",
                                                mt: "6px",
                                            }}
                                        >
                                            Интеграция с Google Meet не
                                            активирована,{" "}
                                            <Typography
                                                onClick={() =>
                                                    handleProcessActivation(
                                                        mapPlatform(interviewInfo.type),
                                                        processes[
                                                            mapPlatform(
                                                                interviewInfo.type
                                                            )
                                                        ].auth_url
                                                    )
                                                }
                                                variant="inherit"
                                                component="span"
                                                sx={{
                                                    color: "rgba(31, 107, 197, 1)",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                нужно подключить сервис
                                            </Typography>
                                        </Typography>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    <Box
                        sx={{
                            padding: "16px",
                            display: "flex",
                            gap: "8px",
                            borderRadius: "8px",
                            backgroundColor: "rgba(251, 243, 229, 1)",
                            mt: 2,
                        }}
                    >
                        <WarningIcon2
                            sx={{
                                width: 24,
                                height: 24,
                            }}
                            svgSx={{
                                width: 24,
                                height: 24,
                                color: "rgba(232, 185, 49, 1)",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "rgba(21, 24, 19, 1)",
                            }}
                        >
                            Кандидату автоматически отправляется инструкция и
                            ссылка для записи. Расписание учитывает длительность
                            интервью.
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ActivitySettingsModal;
