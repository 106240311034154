import React, { memo, useCallback, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { AiIcon, MinusIcon, StarIcon, ThumbUpIcon } from "../svgComponents";
import TestResultsPreview from "../vacancy/testComponents/TestResultsPreview";
import CustomSelect from "../styledComponents/CustomSelect";
import { CustomTab, CustomTabs } from "../styledComponents/CustomTabs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeRecruiterScore } from "../../services/resultsApi";
import ResultsComment from "../common/ResultsComment";
import { createComment } from "../../services/commentsApi";
import { mapScoreToText } from "../../utils/aiScore";

const ScoreButton = ({ onClick, text, icon, sx }) => {
    return (
        <Box
            component="button"
            onClick={onClick}
            sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                border: "1px solid rgba(226, 228, 231, 1)",
                borderRadius: "8px",
                backgroundColor: "transparent",
                width: "211px",
                py: "13px",
                justifyContent: "center",
                cursor: "pointer",
                ...sx,
            }}
        >
            {icon}
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "rgba(21, 24, 19, 1)",
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

const StarRating = ({ value }) => {
    return (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            {[1, 2, 3, 4, 5].map((i) => (
                <StarIcon
                    key={i}
                    sx={{ width: 24, height: 24 }}
                    svgSx={{
                        width: 24,
                        height: 24,
                        backgroundColor:
                            i <= value
                                ? "rgba(149, 105, 217, 1)"
                                : "rgba(226, 228, 231, 1)",
                    }}
                />
            ))}
        </Box>
    );
};

// Component to render the test results preview with navigation controls
const ResultsPreview = ({ answers, test }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const currentQuestion = test?.questions[currentQuestionIndex];
    const userAnswer =
        answers[currentQuestionIndex] &&
        0 < answers[currentQuestionIndex].length
            ? answers[currentQuestionIndex][0]
            : "";

    const handleNext = () => {
        if (currentQuestionIndex < answers.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };
    return (
        <Box
            sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "8px",
                mt: 3,
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "rgba(21, 24, 19, 1)",
                    mb: 2,
                }}
            >
                Ответы тестирования
            </Typography>
            {/* <Box sx={{minHeight: "543px", overflow: "visible"}}> */}
            <TestResultsPreview
                questionData={currentQuestion}
                candidateAnswer={userAnswer}
                selectedIndices={userAnswer
                    .split(",")
                    .map((idx) => parseInt(idx))
                    .map((idx) => {
                        return currentQuestion.answers.findIndex(
                            (answer) => answer.id === idx
                        );
                    })}
            />
            {/* </Box> */}
            <Box
                sx={{
                    borderTopLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                    backgroundColor: "rgba(241, 242, 243, 1)",
                    p: "29px 24px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRight: "1px solid rgba(162, 161, 168, 0.1)",
                    mt: 2,
                }}
            >
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Box
                        onClick={handlePrev}
                        sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            py: "13px",
                            color: "rgba(21, 24, 19, 1)",
                            border: "1px solid rgba(162, 161, 168, 1)",
                            backgroundColor: "white",
                            width: "165px",
                            borderRadius: "8px",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                    >
                        Предыдущий
                    </Box>
                    <Box
                        onClick={handleNext}
                        sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            py: "13px",
                            color: "rgba(21, 24, 19, 1)",
                            border: "1px solid rgba(162, 161, 168, 1)",
                            backgroundColor: "white",
                            width: "165px",
                            borderRadius: "8px",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                    >
                        Следующий
                    </Box>
                </Box>
                <CustomSelect
                    value={currentQuestionIndex}
                    onChange={(value) => setCurrentQuestionIndex(value)}
                    options={answers.map((q, i) => ({
                        id: i,
                        name: `Вопрос ${i + 1} из ${answers.length}`,
                        preview: `Вопрос ${i + 1}: ${test?.questions[i]?.text}`,
                    }))}
                    sx={{
                        maxWidth: "320px",
                    }}
                    targetRender="preview"
                />
            </Box>
        </Box>
    );
};

const RecruiterScore = memo(({ score, resultId, queryKey, candidateId }) => {
    const queryClient = useQueryClient();
    const latestMutation = useRef(null);
    const [newComment, setNewComment] = useState({
        anchorEl: null,
        score: null,
    });

    const updateScoreMutation = useMutation(changeRecruiterScore, {
        // Optimistic update before the API call
        onMutate: async ({ resultId, recruiter_score }) => {
            await queryClient.cancelQueries(queryKey);

            const previousData = queryClient.getQueryData(queryKey);

            latestMutation.current = recruiter_score; // Track the latest mutation

            queryClient.setQueryData(queryKey, (oldData) => {
                if (!oldData) return oldData;

                return {
                    ...oldData,
                    data: {
                        ...oldData.data,
                        test_results: oldData.data.test_results.map((test) =>
                            test.result_id === resultId
                                ? { ...test, recruiter_score }
                                : test
                        ),
                    },
                };
            });

            return { previousData };
        },

        // If mutation fails, rollback to previous state
        onError: (err, variables, context) => {
            if (context?.previousData) {
                queryClient.setQueryData(queryKey, context.previousData);
            }
        },

        // When mutation succeeds, update with the actual response
        onSuccess: ({ updatedScore, resultId }) => {
            if (updatedScore === latestMutation.current) {
                queryClient.setQueryData(queryKey, (oldData) => {
                    if (!oldData) return oldData;

                    return {
                        ...oldData,
                        data: {
                            ...oldData.data,
                            test_results: oldData.data.test_results.map(
                                (test) =>
                                    test.result_id === resultId
                                        ? {
                                              ...test,
                                              recruiter_score: updatedScore,
                                          }
                                        : test
                            ),
                        },
                    };
                });
            }
        },
    });

    const createMutation = useMutation(createComment, {
        onSuccess: ({ data }) => {
            queryClient.setQueryData(queryKey, (oldData) => {
                if (!oldData || !data) return oldData;
                return {
                    ...oldData,
                    data: {
                        ...oldData.data,
                        comments: data,
                    },
                };
            });
        },
    });

    const handleUpdateScorePhase1 = (event, newScore) => {
        if (newScore === score) {
            return;
        }
        setNewComment({ score: newScore, anchorEl: event.currentTarget });
    };

    const handleUpdateScorePhase2 = (text) => {
        updateScoreMutation.mutate({
            resultId,
            recruiter_score: newComment.score,
        });
        if (text) {
            createMutation.mutate({
                candidateId: candidateId,
                text: text,
                is_visible: true,
                score: newComment.score,
            });
        }
        handleCloseNewComment();
    };

    const handleCloseNewComment = () => {
        setNewComment((prev) => ({ score: prev?.score, anchorEl: null }));
    };

    return (
        <>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "26px",
                    color: "rgba(21, 24, 19, 1)",
                    mb: 3,
                }}
            >
                Оценка кандидата
            </Typography>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <ScoreButton
                    onClick={(e) => handleUpdateScorePhase1(e, 1)}
                    icon={
                        <StarIcon
                            sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                backgroundColor:
                                    score === 1
                                        ? "white"
                                        : "rgba(173, 181, 189, 1)",
                            }}
                            svgSx={{
                                width: 11.4,
                                height: 11.4,
                                backgroundColor:
                                    score === 1
                                        ? "rgba(232, 185, 49, 1)"
                                        : "rgba(253, 249, 242, 1)",
                            }}
                        />
                    }
                    text={"Наиболее подходящий"}
                    sx={{
                        backgroundColor:
                            score === 1 ? "rgba(232, 185, 49, 1)" : "white",
                        borderColor:
                            score === 1
                                ? "rgba(232, 185, 49, 1)"
                                : "rgba(173, 181, 189, 1)",
                    }}
                />
                <ScoreButton
                    onClick={(e) => handleUpdateScorePhase1(e, 2)}
                    icon={
                        <ThumbUpIcon
                            sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                backgroundColor:
                                    score === 2
                                        ? "white"
                                        : "rgba(173, 181, 189, 1)",
                            }}
                            svgSx={{
                                width: 11.4,
                                height: 11.4,
                                backgroundColor:
                                    score === 2
                                        ? "rgba(20, 174, 92, 1)"
                                        : "rgba(253, 249, 242, 1)",
                            }}
                        />
                    }
                    text={"Подходящий"}
                    sx={{
                        backgroundColor:
                            score === 2 ? "rgba(20, 174, 92, 1)" : "white",
                        borderColor:
                            score === 2
                                ? "rgba(20, 174, 92, 1)"
                                : "rgba(173, 181, 189, 1)",
                    }}
                />
                <ScoreButton
                    onClick={(e) => handleUpdateScorePhase1(e, 3)}
                    icon={
                        <MinusIcon
                            sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                backgroundColor:
                                    score === 3
                                        ? "white"
                                        : "rgba(173, 181, 189, 1)",
                            }}
                            svgSx={{
                                width: 11.4,
                                height: 11.4,
                                backgroundColor:
                                    score === 3
                                        ? "rgba(165, 169, 173, 1)"
                                        : "rgba(253, 249, 242, 1)",
                            }}
                        />
                    }
                    text={"Нейтрально"}
                    sx={{
                        backgroundColor:
                            score === 3 ? "rgba(165, 169, 173, 1)" : "white",
                        borderColor:
                            score === 3
                                ? "rgba(165, 169, 173, 1)"
                                : "rgba(173, 181, 189, 1)",
                    }}
                />
                <ScoreButton
                    onClick={(e) => handleUpdateScorePhase1(e, 4)}
                    icon={
                        <ThumbUpIcon
                            sx={{
                                width: 20,
                                height: 20,
                                borderRadius: "50%",
                                backgroundColor:
                                    score === 4
                                        ? "white"
                                        : "rgba(173, 181, 189, 1)",
                                transform: "scale(1, -1)",
                            }}
                            svgSx={{
                                width: 11.4,
                                height: 11.4,
                                backgroundColor:
                                    score === 4
                                        ? "rgba(255, 126, 122, 1)"
                                        : "rgba(253, 249, 242, 1)",
                            }}
                        />
                    }
                    text={"Неподходящий"}
                    sx={{
                        backgroundColor:
                            score === 4 ? "rgba(255, 126, 122, 1)" : "white",
                        borderColor:
                            score === 4
                                ? "rgba(255, 126, 122, 1)"
                                : "rgba(173, 181, 189, 1)",
                    }}
                />
            </Box>
            {Boolean(newComment.anchorEl) && (
                <ResultsComment
                    open={
                        Boolean(newComment.anchorEl) &&
                        newComment.score >= 1 &&
                        newComment.score <= 4
                    }
                    anchorEl={newComment.anchorEl}
                    handleClose={handleCloseNewComment}
                    handleSave={handleUpdateScorePhase2}
                    scoreInfo={mapScoreToText(newComment.score)}
                />
            )}
        </>
    );
});

const CandidateInterviewHolder = ({ results, queryKey, candidateId }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const stage = results?.[currentTab];

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{ p: 3, backgroundColor: "white" }}>
                <CustomTabs
                    currentTab={currentTab}
                    handleTabChange={handleTabChange}
                    sx={{
                        padding: 0,
                    }}
                >
                    {results.map((stage, index) => (
                        <CustomTab
                            key={index}
                            index={index}
                            currentTab={currentTab}
                            text={stage.stage_name}
                        />
                    ))}
                </CustomTabs>
                <RecruiterScore
                    score={parseInt(stage?.recruiter_score)}
                    resultId={stage?.result_id}
                    queryKey={queryKey}
                    candidateId={candidateId}
                />
            </Box>
            <AiResultsPreview ai_score={stage?.ai_score} />
            {/* Render test answers preview with navigation controls */}
            <ResultsPreview answers={stage?.answers} test={stage?.test} />
        </Box>
    );
};

const AiResultsPreview = ({ ai_score }) => {
    const general = Math.min(5, Math.max(0, ai_score?.general || 0));
    const criteria_grades = ai_score?.criteria_grades?.slice(0, 6);
    const conclusion = ai_score?.conclusion;
    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: "white",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "36px",
            }}
        >
            {/* Overall rating row */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <StarIcon
                        sx={{ width: 24, height: 24 }}
                        svgSx={{
                            width: 20,
                            height: 20,
                            backgroundColor: "url(#starGradient)",
                        }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "23px",
                            background:
                                "linear-gradient(90deg, #392D69 0%, #B57BEE 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Рейтинг кандидата
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "36px",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            width: "33.63%",
                            display: "flex",
                            gap: 3,
                            alignItems: "center",
                            backgroundColor: "rgba(248, 249, 249, 1)",
                            borderRadius: "8px",
                            p: 3,
                        }}
                    >
                        <Box
                            sx={{
                                position: "relative",
                                width: "100px",
                                height: "100px",
                            }}
                        >
                            <StarIcon
                                sx={{ width: "100px", height: "100px" }}
                                svgSx={{
                                    width: "86px",
                                    height: "86px",
                                    backgroundColor: "rgba(149, 105, 217, 1)",
                                }}
                            />
                            <StarIcon
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100px",
                                    height: "100px",
                                    clipPath: `polygon(${
                                        (general * 100) / 5 ?? 0
                                    }% 0, 100% 0, 100% 100%, ${
                                        (general * 100) / 5 ?? 0
                                    }% 100%)`,
                                }}
                                svgSx={{
                                    width: "86px",
                                    height: "86px",
                                    backgroundColor: "rgba(226, 228, 231, 1)",
                                }}
                            />
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "rgba(21, 24, 19, 1)",
                                    fontWeight: 600,
                                    mb: 0.5,
                                }}
                            >
                                Общий рейтинг
                            </Typography>
                            {general ? (
                                <Typography
                                    sx={{
                                        fontSize: "60px",
                                        lineHeight: "64px",
                                        color: "rgba(21, 24, 19, 1)",
                                        fontWeight: 500,
                                    }}
                                >
                                    {general}
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: "30px",
                                        lineHeight: "34px",
                                        color: "rgba(21, 24, 19, 1)",
                                        fontWeight: 500,
                                    }}
                                >
                                    Отсутствует
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                        }}
                    >
                        {criteria_grades?.map((grade, index) => (
                            <Box
                                key={index} // Added a key to avoid React warnings
                                sx={{
                                    flex: 1,
                                    borderBottom:
                                        "1px solid rgba(241, 242, 243, 1)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        color: "rgba(21,24,19,1)",
                                    }}
                                >
                                    {grade.criteria}
                                </Typography>
                                <StarRating value={grade.score} readOnly />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <AiIcon
                        sx={{ width: 24, height: 24 }}
                        svgSx={{ width: 20, height: 22 }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "23px",
                            background:
                                "linear-gradient(90deg, #392D69 0%, #B57BEE 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Заключение по кандидату
                    </Typography>
                </Box>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "rgba(21, 24, 19, 1)",
                    }}
                >
                    {conclusion || "Заключение по кандидату отсутствует"}
                </Typography>
            </Box>
        </Box>
    );
};

export default CandidateInterviewHolder;
