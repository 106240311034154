export const SOURCE_HEADHUNTER_EXTENSION = 6; // Headhunter extension
export const SOURCE_LINKEDIN_EXTENSION = 7; // linkedIn extension
export const SOURCE_TAPHR = 1; // TapHR site
export const SOURCE_HEADHUNTER = 2; // Headhunter site
export const SOURCE_CAREER_SITE = 5; // Career site


export const sourcesStorage = {
    [SOURCE_LINKEDIN_EXTENSION]: "Волшебная кнопка",
    [SOURCE_HEADHUNTER_EXTENSION]: "Волшебная кнопка",
    [SOURCE_CAREER_SITE]: "Карьерный cайт",
    [SOURCE_HEADHUNTER]: "HeadHunter",
    [SOURCE_TAPHR]: "tapHR",
    unknown: "ИсточникНеизвестен",
};

/**
 * Gets the source name for a given source ID.
 *
 * @param {Object} sourceCounter - Object that tracks occurrences of each source.
 * @param {Number} sourceId - Source ID to retrieve the name for.
 * @returns {String} - Formatted source name with occurrence count (if greater than 1).
 */
export const getSourceName = (sourceCounter, sourceId) => {
    // Ensure sourceId is valid; default to "unknown" if not found
    const validSourceId = sourcesStorage[sourceId] ? sourceId : "unknown";

    // Update occurrence counter for the sourceId
    sourceCounter[validSourceId] = (sourceCounter[validSourceId] || 0) + 1;

    // Generate the source name with occurrence count if applicable
    const count = sourceCounter[validSourceId];
    return `${sourcesStorage[validSourceId]}${count > 1 ? ` ${count}` : ""}`;
};
