import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";
import { fetchDepartments } from "./departmentsApi";


export const fetchDrafts = async (page = 1, perPage = 10, searchParams = {}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    // Utility to filter searchParams
    const filterSearchParams = (params) => {
        return Object.fromEntries(
            Object.entries(params).filter(([_, value]) => value !== undefined && value !== "")
        );
    };

    try {
        const filteredSearchParams = filterSearchParams(searchParams);

        if (filteredSearchParams.department_name) {
            const data = await fetchDepartments({searchQuery : filteredSearchParams.department_name});
            const department_ids = data && data.data && data.data.length > 0 ? data.data.map(department => department.id) : [];
            delete filteredSearchParams.department_name;
            if (department_ids.length <= 0){
                return {data: [], meta: {}};
            }
            filteredSearchParams.department_ids = department_ids;
        }

        const response = await axiosClassic.get(`/api/company/${companyId}/vacancy-drafts`, {
            params: { 
                page, 
                per_page: perPage,
                ...filteredSearchParams, // Add additional searching filters
            },
        });

        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

// get concrete draft by id
export const fetchDraftById = async (id) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/vacancy-drafts/${id}`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};


// delete vacancies with the ids from the object data called draft_ids, and return updated storage
// draft_ids is the key and value is the array of ids to delete
export const deleteMassDraftVacancies = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/vacancy-drafts/mass-delete`,
            {
                data: data,
                headers: {
                    "Content-Type": "application/json", // Specify JSON content type
                },
            }
        );
    
        return responseWrapper(response); // Возвращаем обновлённые данные вакансии
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
}

// imitate new draft creation
export const createDraft = async (body) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/vacancy-drafts`, body);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

// imitate draft update
export const updateDraft = async (id, data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/vacancy-drafts/${id}`,
            data
        );
    
        return responseWrapper(response); // Возвращаем обновлённые данные вакансии
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
}

// delete draft by id
export const deleteDraft = async (draftId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");
    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/vacancy-drafts/${draftId}`
        );
    
        return responseWrapper(response); // Возвращаем обновлённые данные вакансии
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};