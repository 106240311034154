import { Box, Chip, Typography, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import 'dayjs/locale/ru';
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { CalendarIcon } from "../svgComponents";
import { memo, useState } from "react";
import MiniCalendar from "./MiniCalendar";

dayjs.extend(isBetween);

// Optionally, set the locale globally if not already done:
// import "dayjs/locale/ru";
// dayjs.locale("ru");

// Days of the week labels (adjust as needed)
const daysOfWeek = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

// Helper to capitalize first letter
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const PeriodSelector = memo(({ label, start, end, handleField }) => {
    const [active, setActive] = useState(false);

    // dayjs references
    const today = dayjs();
    const yesterday = today.subtract(1, "day");
    const oneWeekAgo = today.subtract(6, "day");
    const oneMonthAgo = today.subtract(1, "month");

    // Convert the passed start/end to dayjs objects
    const startDay = start ? dayjs(start) : null;
    const endDay = end ? dayjs(end) : null;

    // Determine which preset is active
    const isCurrentSituation = !start && !end;
    const isToday =
        startDay &&
        endDay &&
        startDay.isSame(today, "day") &&
        endDay.isSame(today, "day");
    const isYesterday =
        startDay &&
        endDay &&
        startDay.isSame(yesterday, "day") &&
        endDay.isSame(yesterday, "day");
    const isWeek =
        startDay &&
        endDay &&
        startDay.isSame(oneWeekAgo, "day") &&
        endDay.isSame(today, "day");
    const isMonth =
        startDay &&
        endDay &&
        startDay.isSame(oneMonthAgo, "day") &&
        endDay.isSame(today, "day");

    // Compute the display label for the clickable "input" row.
    // If none of the presets match, use the custom range format.
    let displayLabel = "";
    if (isCurrentSituation) {
        displayLabel = "Текущая ситуация";
    } else if (isToday) {
        displayLabel = "Сегодня";
    } else if (isYesterday) {
        displayLabel = "Вчера";
    } else if (isWeek) {
        displayLabel = "За неделю";
    } else if (isMonth) {
        displayLabel = "За месяц";
    } else if (start && end) {
        // Use dayjs with Russian locale and capitalize the first letter
        const startFormatted = capitalize(
            dayjs(start).locale("ru").format("dddd, MMMM D")
        );
        const endFormatted = capitalize(
            dayjs(end).locale("ru").format("dddd, MMMM D")
        );
        displayLabel = `${startFormatted} - ${endFormatted}`;
    } else {
        displayLabel = "Выберите период";
    }

    // Handlers for quick-pick chips (using ISO strings with proper boundaries)
    const handleCurrentSituationClick = () => {
        handleField("date", { startDate: null, endDate: null });
        setActive(false);
    };
    const handleTodayClick = () => {
        handleField("date", {
            startDate: today.startOf("day").toISOString(),
            endDate: today.endOf("day").toISOString(),
        });
        setActive(false);
    };
    const handleYesterdayClick = () => {
        handleField("date", {
            startDate: yesterday.startOf("day").toISOString(),
            endDate: yesterday.endOf("day").toISOString(),
        });
        setActive(false);
    };
    const handleWeekClick = () => {
        handleField("date", {
            startDate: oneWeekAgo.startOf("day").toISOString(),
            endDate: today.endOf("day").toISOString(),
        });
        setActive(false);
    };
    const handleMonthClick = () => {
        handleField("date", {
            startDate: oneMonthAgo.startOf("day").toISOString(),
            endDate: today.endOf("day").toISOString(),
        });
        setActive(false);
    };

    return (
        <Box sx={{ position: "relative" }}>
            {label && (
                <Typography
                    sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: 500,
                        mb: 1,
                    }}
                >
                    {label}
                </Typography>
            )}
            {/* The clickable "input" row */}
            <Box
                onClick={() => setActive((prev) => !prev)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    padding: active ? "13px" : "15px",
                    borderRadius: "8px",
                    border: active
                        ? "3px solid rgba(205, 221, 198, 1)"
                        : "1px solid rgba(226, 228, 231, 1)",
                    flex: 1,
                    justifyContent: "space-between",
                    cursor: "pointer",
                    height: "56px",
                }}
            >
                <Typography>{displayLabel}</Typography>
                <CalendarIcon
                    sx={{ width: 24, height: 24 }}
                    svgSx={{
                        width: 24,
                        height: 24,
                        color: "rgba(89, 157, 21, 1)",
                    }}
                />
            </Box>

            {/* The dropdown with chips + calendar */}
            {active && (
                <Box
                    sx={{
                        width: "100%",
                        backgroundColor: "white",
                        position: "absolute",
                        top: "calc(100% + 5px)",
                        zIndex: 1,
                        borderRadius: "12px",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
                    }}
                >
                    {/* Quick pick chips */}
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 2,
                            padding: "16px 16px 8px 16px",
                            borderBottom: "1px solid rgba(226, 228, 231, 1)",
                        }}
                    >
                        <QuickChip
                            label="Текущая ситуация"
                            active={isCurrentSituation}
                            onClick={handleCurrentSituationClick}
                        />
                        <QuickChip
                            label="Сегодня"
                            active={isToday}
                            onClick={handleTodayClick}
                        />
                        <QuickChip
                            label="Вчера"
                            active={isYesterday}
                            onClick={handleYesterdayClick}
                        />
                        <QuickChip
                            label="За неделю"
                            active={isWeek}
                            onClick={handleWeekClick}
                        />
                        <QuickChip
                            label="За месяц"
                            active={isMonth}
                            onClick={handleMonthClick}
                        />
                    </Box>

                    {/* Inline custom calendar */}
                    <MiniCalendar today={today} handleField={handleField} />
                </Box>
            )}
        </Box>
    );
});

/** Reusable Chip style for quick picks. */
function QuickChip({ label, active, onClick }) {
    return (
        <Box onClick={onClick} sx={{ cursor: "pointer" }}>
            <Box
                component="span"
                sx={{
                    backgroundColor: active
                        ? "rgba(89, 157, 21, 1)"
                        : "rgba(232, 239, 228, 1)",
                    color: active
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(21, 24, 19, 1)",
                    borderRadius: "8px",
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWeight: 600,
                    display: "inline-block",
                    padding: "6px 8px",
                }}
            >
                {label}
            </Box>
        </Box>
    );
}

export default PeriodSelector;
