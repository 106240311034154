import { Box, Typography, Link, Chip } from "@mui/material";
import { CheckIcon, CloseIcon } from "../../svgComponents";
import { downloadFileFromUrl } from "../../../utils/downloadDocument";
import CustomRadio from "../../styledComponents/CustomRadio";
import { CheckBox } from "../../tapHrStyledComponents";
import {
    TEST_STORAGE_AUDIO,
    TEST_STORAGE_CHECKBOX,
    TEST_STORAGE_FILE,
    TEST_STORAGE_RADIO,
    TEST_STORAGE_TEXT,
    TEST_STORAGE_VIDEO,
} from "../../../utils/testTypesStorage";
import VideoPlayer from "./VideoPlayer";
import { Skeleton } from "@mui/material";
import { useState } from "react";

const AnswerImage = ({ src, alt, width, height }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [error, setError] = useState(false);

    return (
        <>
            {!imageLoaded && !error && (
                <Skeleton
                    variant="rectangular"
                    width={width || 207}
                    height={height || 135}
                    sx={{ borderRadius: "8px" }}
                />
            )}
            {!error ? (
                <img
                    src={src}
                    alt={alt}
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setError(true)}
                    style={{
                        width: width || 207,
                        height: height || 135,
                        objectFit: "cover",
                        objectPosition: "top",
                        borderRadius: "8px",
                        display: imageLoaded ? "block" : "none",
                    }}
                />
            ) : (
                <Box
                    sx={{
                        width: width || 207,
                        height: height || 135,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f1f1f1",
                        borderRadius: "8px",
                        color: "#888",
                    }}
                >
                    Ошибка загрузки
                </Box>
            )}
        </>
    );
};

/** Helper to extract just the filename from a URL */
const parseFileName = (fileUrl = "") => {
    try {
        const url = new URL(fileUrl);
        const parts = url.pathname.split("/");
        return decodeURIComponent(parts[parts.length - 1]) || "Файл";
    } catch {
        // If URL parsing fails or it's not a valid URL
        return "Файл";
    }
};

const CorrectAnswerCount = ({ weight, indices, answers }) => {
    // For radio/checkbox questions with weight, determine if all selected indexes are correct
    const overallCorrect = answers.every(
        (answer, idx) =>
            (answer.is_correct && indices.includes(idx)) ||
            (!answer.is_correct && !indices.includes(idx))
    );
    return (
        <>
            <Chip
                label={
                    overallCorrect ? "Правильный ответ" : "Неправильный ответ"
                }
                sx={{
                    ml: 2,
                    backgroundColor: overallCorrect
                        ? "rgba(242, 248, 244, 1)"
                        : "rgba(255, 243, 242, 1)",
                    color: overallCorrect
                        ? "rgba(18, 156, 82, 1)"
                        : "rgba(228, 53, 43, 1)",
                }}
            />
            <Chip
                label={`${overallCorrect ? weight : 0} баллов`}
                sx={{
                    ml: 1,
                    backgroundColor: overallCorrect
                        ? "rgba(242, 244, 250, 1)"
                        : "rgba(242, 243, 243, 1)",
                    color: overallCorrect
                        ? "rgba(28, 96, 176, 1)"
                        : "rgba(41, 57, 73, 1)",
                }}
            />
        </>
    );
};

/**
 * TestResultsPreview
 *
 * @param {object} questionData - The current question object.
 * @param {any} candidateAnswer - The candidate's answer for the question.
 * @param {number[]} selectedIndices - For radio/checkbox, indexes selected by the candidate.
 */
const TestResultsPreview = ({
    questionData,
    candidateAnswer,
    selectedIndices,
}) => {
    const renderAnswers = () => {
        // 1) For radio/checkbox questions with an 'answers' array
        if (
            questionData.type === TEST_STORAGE_RADIO ||
            questionData.type === TEST_STORAGE_CHECKBOX
        ) {
            return (
                questionData.answers &&
                questionData.answers.map((ans, idx) => {
                    const isSelected = selectedIndices.includes(idx);
                    return (
                        <Box
                            key={idx}
                            sx={{
                                display: "flex",
                                width: "100%",
                                alignItems: ans.image_path ? "flex-start" : "center",
                                padding: "12px 16px",
                                mb: 1,
                                borderRadius: "8px",
                                cursor: "pointer",
                                border: `1px solid ${
                                    isSelected
                                        ? ans.is_correct
                                            ? "rgba(20, 174, 92, 1)"
                                            : "rgba(255, 59, 48, 1)"
                                        : "rgba(241, 242, 243, 1)"
                                }`,
                                backgroundColor: isSelected
                                    ? ans.is_correct
                                        ? "rgba(242, 248, 244, 1)"
                                        : "rgba(255, 243, 242, 1)"
                                    : "rgba(248, 249, 249, 1)",
                                gap: "8px",
                            }}
                        >
                            {ans?.image_path && <AnswerImage src={ans.image_path} alt="Answer preview"/>}
                            {/* Custom visuals based on question type */}
                            {!questionData.weight &&
                                questionData.type === TEST_STORAGE_RADIO && (
                                    <CustomRadio checked={isSelected} />
                                )}
                            {!questionData.weight &&
                                questionData.type === TEST_STORAGE_CHECKBOX && (
                                    <CheckBox checked={isSelected} />
                                )}

                            {questionData.weight && !ans.is_correct && (
                                <CloseIcon
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        border: "2px solid rgba(255, 59, 48, 1)",
                                        borderRadius: "50%",
                                    }}
                                    svgSx={{
                                        width: 10,
                                        height: 10,
                                        color: "rgba(255, 59, 48, 1)",
                                    }}
                                />
                            )}
                            {questionData.weight && ans.is_correct && (
                                <CheckIcon
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        border: "2px solid rgba(20, 174, 92, 1)",
                                        borderRadius: "50%",
                                    }}
                                    svgSx={{
                                        width: 10,
                                        height: 10,
                                        color: "rgba(20, 174, 92, 1)",
                                    }}
                                />
                            )}

                            <Typography
                                sx={{ fontSize: "16px", lineHeight: "20px" }}
                            >
                                {ans.text}
                            </Typography>
                        </Box>
                    );
                })
            );
        }

        // 2) If no 'answers' array, handle other question types

        // For text questions
        if (questionData.type === TEST_STORAGE_TEXT) {
            return (
                <Box
                    sx={{
                        p: 2,
                        backgroundColor: "rgba(248, 249, 249, 1)",
                        borderRadius: "8px",
                        border: "1px solid rgba(173, 181, 189, 1)",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    >
                        {typeof candidateAnswer === "string"
                            ? candidateAnswer
                            : "Нет ответа"}
                    </Typography>
                </Box>
            );
        }

        // For audio
        if (questionData.type === TEST_STORAGE_AUDIO) {
            return typeof candidateAnswer === "string" && candidateAnswer ? (
                <audio controls style={{ width: "100%" }} src={candidateAnswer}>
                    Your browser does not support the audio element.
                </audio>
            ) : (
                <Typography>Нет ответа</Typography>
            );
        }

        // For video
        if (questionData.type === TEST_STORAGE_VIDEO) {
            return typeof candidateAnswer === "string" && candidateAnswer ? (
                <VideoPlayer src={candidateAnswer} />
            ) : (
                <Typography>Нет ответа</Typography>
            );
        }

        // For file uploads
        if (questionData.type === TEST_STORAGE_FILE) {
            return (
                <Box
                    sx={{
                        p: 2,
                        backgroundColor: "rgba(248, 249, 249, 1)",
                        borderRadius: "8px",
                        border: "1px solid rgba(173, 181, 189, 1)",
                    }}
                >
                    {typeof candidateAnswer === "string" && candidateAnswer ? (
                        <Box>
                            <Link
                                onClick={() =>
                                    downloadFileFromUrl(
                                        candidateAnswer,
                                        parseFileName(candidateAnswer)
                                    )
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="hover"
                                sx={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    color: "rgba(31, 107, 197, 1)",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                            >
                                {parseFileName(candidateAnswer)}
                            </Link>
                            <Typography
                                component="span"
                                sx={{
                                    ml: 1,
                                    fontSize: "14px",
                                    color: "rgba(46, 64, 82, 1)",
                                }}
                            >
                                (200КБ)
                            </Typography>
                        </Box>
                    ) : (
                        <Typography>Нет ответа</Typography>
                    )}
                </Box>
            );
        }

        // Fallback for unsupported types
        return (
            <Typography
                sx={{
                    mt: 2,
                    color: "red",
                    fontSize: "26px",
                    border: "1px solid gray",
                    p: 2,
                    borderRadius: "8px",
                }}
            >
                Данный тип вопроса не поддерживается в просмотре
            </Typography>
        );
    };

    return (
        <Box
            sx={{
                flex: 1,
                p: 3,
                display: "flex",
                flexDirection: "column",
                gap: 3,
                overflowY: "auto",
                minHeight: "543px",
            }}
        >
            <Typography
                sx={{
                    fontSize: "24px",
                    lineHeight: "28px",
                    color: "rgba(46, 64, 82, 1)",
                    fontWeight: 600,
                }}
            >
                {questionData.text || "Нет вопроса"}
            </Typography>
            {questionData?.image_path && <AnswerImage src={questionData?.image_path} alt="Question image preview" width={"60%"} height={"60%"}/>}
            <Box>
                <Typography
                    sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "rgba(46, 64, 82, 1)",
                        mb: 2,
                    }}
                >
                    Ответ:
                    {(questionData.type === TEST_STORAGE_RADIO ||
                        questionData.type === TEST_STORAGE_CHECKBOX) &&
                        questionData.weight && (
                            <CorrectAnswerCount
                                indices={selectedIndices}
                                answers={questionData.answers}
                                weight={questionData.weight}
                            />
                        )}
                </Typography>
                {renderAnswers()}
            </Box>
        </Box>
    );
};

export default TestResultsPreview;
