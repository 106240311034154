import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid2 as Grid, Typography, Modal } from '@mui/material'; // Импортируем Grid2
import hhLogo from '../../assets/icons/hh.png';
import telegramLogo from '../../assets/icons/telegram_logo.png';
import axiosClassic from '../../api/interceptors';
import { useLocation } from 'react-router-dom';
import PublicationModalHH from './PublicationModalHH';
import PublicationModalTelegram from './PublicationModalTelegram'; // Новый компонент модального окна для Telegram
import PublicationSettingsModal from './PublicationSettingsModal';
import { fetchTelegramFields } from '../../services/telegramApi'; // Запрос для получения полей
import { useQuery } from '@tanstack/react-query';
import { navigateToHH } from '../../utils/connections';
const APP_URI = process.env.REACT_APP_REDIRECT_URI;
const API_URL = process.env.REACT_APP_API_URI;
// import habrLogo from '../../assets/icons/habr.png';



const JobPlatforms = [
    { id: 2, name: "HeadHunter", description: "Биржа вакансий", logo: hhLogo, action: "Подключить", variant: "outlined" },
    { id: 3, name: "Telegram", description: "Мессенджер", logo: telegramLogo, action: "Подключить", variant: "outlined" },
];

const sendAuthRequest = async (code, redirectUri, managerId) => {
    try {
        const response = await axiosClassic.post(API_URL + '/api/hh/auth', {
            code,
            redirect_uri: redirectUri,
            manager_id: managerId,
        });
        console.log('Запрос успешно отправлен:', response.data);
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
    }
};

const PublicationTab = ({ sources, vacancyId, category_id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false);
    const [step, setStep] = useState(1); // 1 - Выбор канала, 2 - Настройки публикации
    const [selectedChannel, setSelectedChannel] = useState(null);

    // Получение полей после выбора канала
    const { data: channelFields, isLoading: fieldsLoading, error: fieldsError } = useQuery({
        queryKey: ['telegramFields', selectedChannel],
        queryFn: () => fetchTelegramFields(selectedChannel.id),
        enabled: !!selectedChannel, // Запрос выполняется только если есть выбранный канал
    });


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const openTelegramModal = () => setIsTelegramModalOpen(true);
    const closeTelegramModal = () => {
        setIsTelegramModalOpen(false)
        setStep(1); // Сбрасываем шаг при закрытии
    }

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        // Получаем нужные параметры
        const code = params.get('code');
        const managerId = location.search.split('manager_id=')[1]?.split('&')[0];
        // const redirectUri = `${window.location.origin}${location.pathname}`;
        const redirectUri = APP_URI + `${location.pathname}?manager_id=${managerId}`;

        console.log(redirectUri)
        console.log(managerId)
        console.log(code)

        if (code && managerId) {
            // Отправляем запрос, если code и manager_id существуют
            sendAuthRequest(code, redirectUri, managerId);
        }
    }, [location]);

    const createPublicationStorage = () => {
        if (!sources) {
            return {};
        }
        const storage = {}
        sources.map((source) => {
            storage[source.source_id] = true;
        });
        return storage;
        // idea to get 
    }

    const publicationsStorage = createPublicationStorage();


    console.log(category_id)

    return (
        <Box mt={3}>
            <Grid container spacing={3} justifyContent="flex-start">
                {JobPlatforms.filter(platform => platform.id !== 3 || category_id === 3).map((platform) => (
                    <Grid xs={12} sm={6} md={4} key={platform.id}>
                        <Card
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 3,
                                borderRadius: 2,
                                width: '231px',
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={2} sx={{ mb: 2 }}>
                                <Box component="img" src={platform.logo} alt={platform.name} sx={{ width: 48, height: 48 }} loading='lazy'/>
                                <Box>
                                    <Typography sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '18px', color: '#151813' }} variant="h5">
                                        {platform.name}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 400, fontSize: '12px', lineHeight: '16px', color: '#9BA2A9' }} variant="body2">
                                        {platform.description}
                                    </Typography>
                                </Box>
                            </Box>

                            {platform.id === 2 && (
                                <>
                                    {localStorage.getItem('is_hh_connected') ? (
                                        <Button
                                            disabled={!!publicationsStorage[platform.id]}
                                            onClick={openModal}
                                            variant={platform.variant}
                                            sx={{
                                                marginTop: 'auto',
                                                color: "#FFFFFF",
                                                borderColor: "#599D15",
                                                backgroundColor: publicationsStorage[platform.id] ? "rgba(232, 239, 228, 1)" : "#599D15",
                                                border: "none !important",
                                                textTransform: 'none',
                                                width: '100%',
                                                height: '34px',
                                                borderRadius: '8px',
                                                paddingY: '10px',
                                                paddingX: '16px',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    boxShadow: '0 0 5px 2px #ccc'
                                                },
                                            }}
                                        >
                                            {publicationsStorage[platform.id] ? "Опубликовано" : "Опубликовать"}
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={navigateToHH}
                                            variant={platform.variant}
                                            sx={{
                                                marginTop: 'auto',
                                                color: platform.variant === "outlined" ? "#599D15" : "#FFFFFF",
                                                borderColor: "#599D15",
                                                textTransform: 'none',
                                                backgroundColor: platform.variant === "contained" ? "#599D15" : "transparent",
                                                width: '100%',
                                                height: '34px',
                                                borderRadius: '8px',
                                                paddingY: '10px',
                                                paddingX: '16px',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    backgroundColor: platform.variant === "contained" ? "#4A8512" : "rgba(89, 157, 21, 0.1)",
                                                },
                                            }}
                                        >
                                            Подключить
                                        </Button>
                                    )}
                                </>
                            )}

                            {platform.id === 3 && (
                                <Button
                                    onClick={openTelegramModal}
                                    variant={platform.variant}
                                    sx={{
                                        marginTop: 'auto',
                                        color: platform.variant === "outlined" ? "#599D15" : "#FFFFFF",
                                        borderColor: "#599D15",
                                        textTransform: 'none',
                                        backgroundColor: platform.variant === "contained" ? "#599D15" : "transparent",
                                        width: '100%',
                                        height: '34px',
                                        borderRadius: '8px',
                                        paddingY: '10px',
                                        paddingX: '16px',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: platform.variant === "contained" ? "#4A8512" : "rgba(89, 157, 21, 0.1)",
                                        },
                                    }}
                                >
                                    Подключить
                                </Button>
                            )}
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <PublicationModalHH isModalOpen={isModalOpen} closeModal={closeModal} />
            {step === 1 && (
                <PublicationModalTelegram
                    isModalOpen={isTelegramModalOpen}
                    closeModal={closeTelegramModal}
                    onNext={(channel) => {
                        setSelectedChannel(channel);
                        setStep(2);
                    }}
                />
            )}
            {step === 2 && (
                <PublicationSettingsModal
                    isModalOpen={isTelegramModalOpen}
                    closeModal={closeTelegramModal}
                    onBack={() => setStep(1)}
                    selectedChannel={selectedChannel}
                    channelFields={channelFields?.data}
                    fieldsLoading={fieldsLoading}
                    fieldsError={fieldsError}
                    vacancyId={vacancyId}
                />
            )}
        </Box>
    );
};

export default PublicationTab;
