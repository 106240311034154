// Import axios
import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

// Get department list
/**
 * 
 * @param {String} searchQuery - search query default empty string
 * @param {Number} page - page number default 1
 * @param {Number} perPage - number of items per page default 10
 * 
 * @returns {Object} - object that contains data and meta
 */
export const fetchDepartments = async ({searchQuery = ""}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        ...(searchQuery && { name: searchQuery }),
    }

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/departments`, {params});

        // Check if the response has data
        return responseWrapper(response)
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

/**
 * 
 * @param {*} body - body of request that contains {name, parent_id}
 * @returns 
 */
export const createDepartment = async ({name, parent_id}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const body = {
        name,
        ...(parent_id && { parent_id }), // Add parent_id only if it exists
    }
    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/departments`, body);

        // Check if the response has data
        return responseWrapper(response)
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
}

// Delete department
export const deleteDepartment = async (id) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(`/api/company/${companyId}/departments/${id}`);

        // Check if the response has data
        return responseWrapper(response)
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
}

// update department
export const updateDepartment = async ({id, name}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.put(`/api/company/${companyId}/departments/${id}`, {name});
    
        // Check if the response has data
        return {
            data: responseWrapper(response),
            id: id
        };
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
}