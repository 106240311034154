import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import VacanciesPage from './pages/vacancy/VacanciesPage';
import VacancyDetail from './pages/vacancy/VacancyDetail';
import SignInPage from './pages/auth/SignInPage';
import NewPasswordPage from './pages/auth/NewPasswordPage';
import NotFoundPage from './pages/NotFoundPage';
import AccountSelectionPage from './pages/auth/AccountSelectionPage';
import CreateVacancyPage from './pages/vacancy/CreateVacancyPage';
import NavigationBar from './components/main/NavigationBar';
import Header from './components/main/Header';
import ProtectedRoute from './components/main/ProtectedRoute';
import CandidatesPage from './pages/candidates/CandidatesPage';
import IntegrationsPage from './pages/settings/integrations/IntegrationsPage';
import DepartmentsPage from './pages/settings/departments/DepartmentsPage';
import CreateCandidatesPage from './pages/candidates/CreateCandidatesPage';
import EditVacancyPage from './pages/vacancy/EditVacancyPage';
import StagesPage from './pages/settings/stages/StagesPage';
import SourcesPage from './pages/settings/sources/SourcesPage';
import TemplatesPage from './pages/settings/templates/TemplatesPage';
import ProfileSettingsPage from './pages/profile/ProfileSettingsPage';
import CalendarPage from './pages/calendar/CalendarPage';
import ReportsPage from './pages/reports/ReportsPage';
import { NavigationBarProvider, useNavigationBar } from './components/main/NavigationBarContext';
import AdvancedActionSettings from './pages/calendar/AdvancedActionSettings';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IntegrationsRedirect from './pages/settings/integrations/Redirect';

const theme = createTheme({
  typography: {
    fontFamily: 'SFPro, sans-serif',
  },
});

function PublicRoute({ element: Component, ...rest }) {
  const isAuthenticated = Boolean(localStorage.getItem('access_token'));
  return isAuthenticated ? <Navigate to="/" replace /> : <Component {...rest} />;
}

function App() {
  const location = useLocation();
  const { isNavigationBarVisible } = useNavigationBar();

  // Определение страниц, где не отображаются Header и NavigationBar
  const isAuthPage = ['/login', '/new_password', '/select_account'].includes(location.pathname);
  const isNotFoundPage = !location.pathname.match(
    /^\/(vacancy\/\d+|vacancy\/create|reports|vacancy\/edit\/\d+|candidates|candidates\/create|login|new_password|select_account|calendar|settings\/integrations|settings\/departments|settings\/stages|settings\/sources|calendar\/action|profile_settings|settings\/templates|settings\/integrations\/redirect)?$/
  );
  const isRedirected = location.pathname.includes('/settings/integrations/redirect')
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* NavigationBar отображается везде, кроме страниц авторизации и ошибок */}
      {!isAuthPage && !isNotFoundPage && isNavigationBarVisible && !isRedirected && <NavigationBar />}

      <Box sx={{ flex: 1, overflowY: 'auto', background: "#f8f8f8" }}>
        {/* Header отображается везде, кроме страниц авторизации и ошибок */}
        {!isAuthPage && !isNotFoundPage && !isRedirected && <Header />}

        <Routes>
          {/* Публичные маршруты */}
          <Route path="/login" element={<PublicRoute element={SignInPage} />} />
          <Route path="/new_password" element={<ProtectedRoute element={NewPasswordPage} />} />

          {/* Защищенные маршруты */}
          <Route path="/select_account" element={<ProtectedRoute element={AccountSelectionPage} />} />
          <Route path="/" element={<ProtectedRoute element={VacanciesPage} />} />
          <Route path="/vacancy/:id" element={<ProtectedRoute element={VacancyDetail} />} />
          <Route path="/vacancy/create" element={<ProtectedRoute element={CreateVacancyPage} />} />
          <Route path="/vacancy/edit/:id" element={<ProtectedRoute element={EditVacancyPage} />} />
          <Route path="/candidates" element={<ProtectedRoute element={CandidatesPage} />} />
          <Route path="/candidates/create" element={<ProtectedRoute element={CreateCandidatesPage} />} />
          <Route path="/settings/integrations" element={<ProtectedRoute element={IntegrationsPage} />} />
          <Route path="/settings/departments" element={<ProtectedRoute element={DepartmentsPage} />} />
          <Route path="/settings/stages" element={<ProtectedRoute element={StagesPage} />} />
          <Route path="/settings/sources" element={<ProtectedRoute element={SourcesPage} />} />
          <Route path="/profile_settings" element={<ProtectedRoute element={ProfileSettingsPage} />} />
          <Route path="/settings/templates" element={<ProtectedRoute element={TemplatesPage} />} />
          <Route path="/calendar" element={<ProtectedRoute element={CalendarPage} />} />
          <Route path="/reports" element={<ProtectedRoute element={ReportsPage} />} />
          <Route path="/calendar/action" element={<ProtectedRoute element={AdvancedActionSettings} />} />
          <Route path="/settings/integrations/redirect" element={<ProtectedRoute element={IntegrationsRedirect} />} />

          {/* Страница 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Box>
    </Box>
  );
}


function AppWrapper() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <NavigationBarProvider>
          <App />
        </NavigationBarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default AppWrapper;
