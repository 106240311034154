import { useMutation, useQuery } from "@tanstack/react-query";
import { memo, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { CircularLoading } from "../../components/tapHrStyledComponents";
import { CustomError } from "../../components/styledComponents/CustomErrors";
import { fetchReports, fetchXLSXReport } from "../../services/reportsApi";
import {
    CheckIcon,
    DownloadCloudIcon,
    ReportIcon,
} from "../../components/svgComponents";
import HBarDrawer from "../../components/reports/HBar";
import PieChartDrawer from "../../components/reports/PieChart";
import PeriodSelector from "../../components/reports/PeriodSelector";
import CustomSelect from "../../components/styledComponents/CustomSelect";

const getLists = (data, filter) => {
    // Build departmentList from data filtered by vacancy and recruiter (but not department)
    const deptData = data.filter((item) => {
        let valid = true;
        if (filter.vacancies !== "all") {
            valid = valid && item.name === filter.vacancies;
        }
        if (filter.recruiters !== "all") {
            valid = valid && item.manager_name === filter.recruiters;
        }
        return valid;
    });
    const departmentList = deptData.reduce((acc, item) => {
        const dept = item.department_name;
        if (dept && !acc.includes(dept)) {
            acc.push(dept);
        }
        return acc;
    }, []);

    // Build vacanciesList from data filtered by department and recruiter (but not vacancy)
    const vacData = data.filter((item) => {
        let valid = true;
        if (filter.department !== "all") {
            valid = valid && item.department_name === filter.department;
        }
        if (filter.recruiters !== "all") {
            valid = valid && item.manager_name === filter.recruiters;
        }
        return valid;
    });
    const vacanciesList = vacData.reduce((acc, item) => {
        const vacancy = item.name;
        if (vacancy && !acc.includes(vacancy)) {
            acc.push(vacancy);
        }
        return acc;
    }, []);

    // Build recruiterList from data filtered by department and vacancy (but not recruiter)
    const recData = data.filter((item) => {
        let valid = true;
        if (filter.department !== "all") {
            valid = valid && item.department_name === filter.department;
        }
        if (filter.vacancies !== "all") {
            valid = valid && item.name === filter.vacancies;
        }
        return valid;
    });
    const recruiterList = recData.reduce((acc, item) => {
        const recruiter = item.manager_name;
        if (recruiter && !acc.includes(recruiter)) {
            acc.push(recruiter);
        }
        return acc;
    }, []);

    return { departmentList, vacanciesList, recruiterList };
};

const FilterArea = memo(({ filter, handleField, filteredLists }) => {
    const { departmentList, recruiterList, vacanciesList } = filteredLists;
    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, minmax(180px, 1fr))",
                columnGap: "25px",
                rowGap: "16px",
                mb: 3,
                p: "16px",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: "16px",
            }}
        >
            {/* Объект отчёта -> filter.target */}
            <CustomSelect 
                label="Объект отчёта"
                value={filter.target}
                onChange={(value) => handleField("target", value)}
                options={[{id : "vacancies", name: "Вакансии"}]}
            />

            {/* Тип отчёта -> filter.type */}
            <CustomSelect
                label="Тип отчёта"
                value={filter.type}
                onChange={(value) => handleField("type", value)}
                options={[
                    { id: "funnel", name: "Воронка" },
                    { id: "pieSources", name: "Источники резюме" },
                    { id: "pieRefuses", name: "Причины отказа" },
                ]}
            />

            {/* Период -> does NOT update filter for now */}
            <PeriodSelector start={filter.date.startDate} end={filter.date.endDate} handleField={handleField} label={"Период"} />

            {/* Отдел -> filter.department */}
            <CustomSelect
                label="Отдел"
                value={filter.department}
                onChange={(value) => handleField("department", value)}
                options={[{id: "all", name: "Все"}, ...departmentList.map((department) => ({id: department, name: department}))]}
            />

            {/* Вакансии -> filter.vacancies */}
            <CustomSelect
                label="Вакансии"
                value={filter.vacancies}
                onChange={(value) => handleField("vacancies", value)}
                options={[{id: "all", name: "Все"}, ...vacanciesList.map((vacancy) => ({id: vacancy, name: vacancy}))]}
            />

            {/* Вакансии -> filter.vacancies */}
            <CustomSelect
                label="Рекрутеры"
                value={filter.recruiters}
                onChange={(value) => handleField("recruiters", value)}
                options={[{id: "all", name: "Все"}, ...recruiterList.map((recruiter) => ({id: recruiter, name: recruiter}))]}
            />
        </Box>
    );
});

const ReportsPage = () => {
    const [filter, setFilter] = useState({
        target: "vacancies",
        type: "funnel",
        department: "all",
        vacancies: "all",
        recruiters: "all",
        informationDirection: "stages",
        date : {
            startDate: null,
            endDate: null,
        }
    });

    // Example: pass date range to fetchReports
    const { data, isLoading, isError, error } = useQuery(
        ["reports", filter.date],
        () => fetchReports({ start_date: filter.date.startDate, end_date: filter.date.endDate }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const handleField = useCallback((field, value) => {
        setFilter((prev) => {
            if (prev[field] === value) {
                return prev;
            }
            return { ...prev, [field]: value };
        });
    }, []);

    const exportXLSXmutation = useMutation(fetchXLSXReport, {
        onSuccess: (data) => {
          // Create a blob from the data (assuming data is binary)
          const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          // Create an object URL from the blob
          const url = window.URL.createObjectURL(blob);
          // Create a temporary <a> element
          const link = document.createElement("a");
          link.href = url;
          // Set the desired file name
          link.setAttribute("download", "report.xlsx");
          // Append the link to the body (not strictly necessary in all browsers)
          document.body.appendChild(link);
          // Programmatically click the link to trigger the download
          link.click();
          // Clean up and remove the link
          document.body.removeChild(link);
          // Release the object URL
          window.URL.revokeObjectURL(url);
        },
      });
      

    const handleXLSXExport = () => {
        exportXLSXmutation.mutate({
            start_date: filter.date.startDate,
            end_date: filter.date.endDate,
            department_name: filter.department,
            vacancy_name: filter.vacancies,
            manager_name: filter.recruiters,
        });
    }

    const reportData = data?.data || [];

    const filteredData = reportData.filter((item) => {
        let valid = true;
        if (filter.department !== "all") {
            valid = valid && item.department_name === filter.department;
        }
        if (filter.vacancies !== "all") {
            valid = valid && item.name === filter.vacancies;
        }
        if (filter.recruiters !== "all") {
            valid = valid && item.manager_name === filter.recruiters;
        }
        return valid;
    });
    const filteredLists = getLists(reportData, filter);

    if (isLoading) {
        return <CircularLoading sx={{ height: "80vh" }} />;
    }

    if (isError) {
        return <CustomError error={error?.message || "Неопознонная причина"} />;
    }

    return (
        <Box sx={{ width: "100%", minHeight: "100%", padding: "24px 24px 50px 24px" }}>
            {/* Filter Bar (placeholder) */}
            <FilterArea
                filter={filter}
                handleField={handleField}
                filteredLists={filteredLists}
            />

            {/* Summary Row (placeholder) */}
            <Box
                sx={{
                    display: "flex",
                    p: "24px",
                    backgroundColor: "white",
                    borderRadius: "16px",
                    flexDirection: "column",
                    position: "relative",
                }}
            >
                {/* Placeholder for summary data */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 3,
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ display: "flex", gap: "32px" }}>
                        <Box
                            sx={{
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "8px",
                                fontSize: "20px",
                                fontWeight: 600,
                                lineHeight: "24px",
                                color: "rgba(9, 11, 8, 1),",
                            }}
                        >
                            <ReportIcon
                                sx={{
                                    width: "24px",
                                    height: "24px",
                                }}
                                svgSx={{
                                    width: "24px",
                                    height: "24px",
                                    backgroundColor: "rgba(89, 157, 21, 1)",
                                }}
                            />
                            {filter.type === "funnel"
                                ? "Воронка"
                                : filter.type === "pieSources" ? "Источники резюме" : "Отказы"}
                        </Box>
                        {filter.type === "funnel" && (
                            <Box sx={{ display: "flex", gap: "16px" }}>
                                <Box
                                    component="button"
                                    onClick={() =>
                                        handleField(
                                            "informationDirection",
                                            "stages"
                                        )
                                    }
                                    sx={{
                                        display: "flex",
                                        gap: "8px",
                                        border: "1px solid",
                                        borderColor:
                                            filter.informationDirection ===
                                            "stages"
                                                ? "transparent"
                                                : "rgba(198, 198, 198, 1)",
                                        backgroundColor:
                                            filter.informationDirection ===
                                            "stages"
                                                ? "rgba(89, 157, 21, 1)"
                                                : "transparent",
                                        color:
                                            filter.informationDirection ===
                                            "stages"
                                                ? "white"
                                                : "rgba(9, 11, 8, 1)",
                                        alignItems: "center",
                                        borderRadius: "8px",
                                        padding: "6px 16px",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        fontWeight: 500,
                                        cursor: "pointer",
                                        height: "fit-content",
                                    }}
                                >
                                    {filter.informationDirection ===
                                        "stages" && (
                                        <CheckIcon
                                            sx={{ width: 18, height: 18 }}
                                            svgSx={{
                                                width: 12,
                                                height: 9,
                                                color: "white",
                                            }}
                                        />
                                    )}
                                    Воронка этапов
                                </Box>
                                <Box
                                    component="button"
                                    onClick={() =>
                                        handleField(
                                            "informationDirection",
                                            "refusals"
                                        )
                                    }
                                    sx={{
                                        display: "flex",
                                        gap: "8px",
                                        border: "1px solid",
                                        borderColor:
                                            filter.informationDirection ===
                                            "refusals"
                                                ? "transparent"
                                                : "rgba(198, 198, 198, 1)",
                                        backgroundColor:
                                            filter.informationDirection ===
                                            "refusals"
                                                ? "rgba(89, 157, 21, 1)"
                                                : "transparent",
                                        color:
                                            filter.informationDirection ===
                                            "refusals"
                                                ? "white"
                                                : "rgba(9, 11, 8, 1)",
                                        alignItems: "center",
                                        borderRadius: "8px",
                                        padding: "6px 16px",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        fontWeight: 500,
                                        cursor: "pointer",
                                        height: "fit-content",
                                    }}
                                >
                                    {filter.informationDirection ===
                                        "refusals" && (
                                        <CheckIcon
                                            sx={{ width: 18, height: 18 }}
                                            svgSx={{
                                                width: 12,
                                                height: 9,
                                                color: "white",
                                            }}
                                        />
                                    )}
                                    Причины отказа
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box
                        onClick={() => handleXLSXExport()}
                        sx={{
                            border: "none",
                            backgroundColor: "rgba(89, 157, 21, 1)",
                            color: "white",
                            fontSize: "16px",
                            lineHeight: "20px",
                            padding: "13px 21px",
                            borderRadius: "8px",
                            "&:hover": {
                                backgroundColor: "rgba(80, 140, 19, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(69, 122, 16, 1)",
                            },
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                            gap: "8px",
                        }}
                    >
                        <DownloadCloudIcon
                            sx={{ width: 20, height: 20 }}
                            svgSx={{
                                width: 20,
                                height: 20,
                                backgroundColor: "white",
                            }}
                        />
                        Скачать эксель
                    </Box>
                </Box>

                {/* Stacked Bar Chart */}
                {filter.type === "funnel" ? (
                    <HBarDrawer
                        data={filteredData}
                        isStages={filter.informationDirection === "stages"}
                    />
                ) : (
                    <PieChartDrawer
                        data={filteredData}
                        isStages={filter.type === "pieSources"}
                    />
                )}
            </Box>
        </Box>
    );
};

export default ReportsPage;
