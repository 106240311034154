export const downloadFileFromUrl = (url, fileName) => {
    console.log(url, fileName);
    const link = document.createElement("a");
    link.href = url;

    // Set the download attribute to specify the file name
    link.download = fileName;

    // Set target to "_blank" to open the link in a new tab
    link.target = "_blank"
    link.click();
    window.URL.revokeObjectURL(url);
}

// export const downloadFileFromUrl = (url, fileName) => {
//     fetch(url, { mode: "cors" })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         return response.blob();
//       })
//       .then((blob) => {
//         const blobUrl = window.URL.createObjectURL(blob);
//         const link = document.createElement("a");
//         link.style.display = "none";
//         link.href = blobUrl;
//         link.download = fileName; // fileName will be used as the downloaded file's name
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//         window.URL.revokeObjectURL(blobUrl);
//       })
//       .catch((error) => {
//         console.error("Download failed:", error);
//       });
//   };