import React, { useEffect, useState, useCallback } from "react";
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay, getWeeksInMonth } from "date-fns";
import { ru } from 'date-fns/locale';
import "./CalendarPage.css";
import CalendarControls from "./CalendarControls";
import ViewModeSelect from "./ViewModeSelect";
import WeekView from "./WeekView";
import { getCalendarActions } from "../../services/calendarApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import SlotsManager from "./SlotsManager";
import { postSlotsAction, getSlotsAction, updateSlotsAction } from "../../services/calendarApi";
import { useMutation } from "@tanstack/react-query";
import { Snackbar, Alert } from "@mui/material";

const CalendarPage = () => {
    const queryClient = useQueryClient()

    const [editingSlot, setEditingSlot] = useState(null); // Добавляем состояние для редактируемого слота



    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // error (красный) или success (зелёный)

    const [isSlotsOpen, setSlotsOpen] = useState(false)
    const [viewMode, setViewMode] = useState("week");
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const startMonth = startOfMonth(currentMonth);
    const endMonth = endOfMonth(currentMonth);
    const startWeek = startOfWeek(startMonth, { weekStartsOn: 1 });
    const endWeek = endOfWeek(endMonth, { weekStartsOn: 1 });

    const totalWeeks = getWeeksInMonth(currentMonth, { locale: ru });
    const rows = totalWeeks === 6 ? 6 : 5;

    const days = [];
    let day = startWeek;
    while (day <= endWeek) {
        days.push(day);
        day = addDays(day, 1);
    }

    const start_date = format(startMonth, "yyyy-MM-dd 00:00:00");
    const end_date = format(endMonth, "yyyy-MM-dd 23:59:59");

    // Запрос с параметрами start_date и end_date
    const { data, isLoading, isError } = useQuery({
        queryKey: ['actions', start_date, end_date],
        queryFn: () => getCalendarActions({ start_date, end_date }),
    });

    // Запрос с параметрами start_date и end_date
    const { data: slotsData, isLoading: slotsIsLoading, isError: slotsIsError } = useQuery({
        queryKey: ['slots'],
        queryFn: () => getSlotsAction(),
    });

    const handlePrevMonth = () => {
        setCurrentMonth(prevDate =>
            viewMode === "week"
                ? addDays(prevDate, -7) // Перемещение на неделю назад
                : addDays(startOfMonth(prevDate), -1) // Перемещение на месяц назад
        );
    };

    const handleNextMonth = () => {
        setCurrentMonth(prevDate =>
            viewMode === "week"
                ? addDays(prevDate, 7) // Перемещение на неделю вперёд
                : addDays(endOfMonth(prevDate), 1) // Перемещение на месяц вперёд
        );
    };



    const calendarActionsData = data?.data || [];

    const initialSlots = {
        title: "",
        recurrence_rule: 1,
        intervals: [
            { id: "1", day_of_week: 1, start_time: "09:00", end_time: "18:00" },
            { id: "2", day_of_week: 2, start_time: "09:00", end_time: "18:00" },
            { id: "3", day_of_week: 3, start_time: "09:00", end_time: "18:00" },
            { id: "4", day_of_week: 4, start_time: "09:00", end_time: "18:00" },
            { id: "5", day_of_week: 5, start_time: "09:00", end_time: "18:00" },
        ],
        settings: {
            max_book_days: undefined,
            min_book_minutes: undefined,
            limit_day_bookings: undefined,
            slot_between_break: 0,
        }
    };

    const [slots, setSlots] = useState(slotsData?.data);

    useEffect(() => {
        if (slotsData?.data) {
            setSlots(slotsData.data);
        }
    }, [slotsData]);

    const handleSlotManagerOpen = () => {
        setSlotsOpen(true);
        setSlots(initialSlots);
        setEditingSlot(null);
    };

    const handleSlotsManagerClose = () => {
        setSlotsOpen(false);
        setEditingSlot(null);
    };
    const handleSlotsChange = useCallback((updatedSlots) => {
        console.log(updatedSlots)
        if (editingSlot) {
            setEditingSlot(updatedSlots);
        } else {
            setSlots(updatedSlots);
        }
    }, [editingSlot]);




    const showSnackbar = (message, severity = "error") => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const mutation = useMutation(postSlotsAction, {
        onSuccess: () => {
            queryClient.invalidateQueries(["slots"]); // Обновляем список кандидатов
            handleSlotsManagerClose()
            showSnackbar("Слот успешно создан!", "success"); // Успешное сообщение (зелёный)
        },
        onError: (error) => {
            console.error("Ошибка при создании слота:", error);
            showSnackbar("Ошибка при создании слота", "error"); // Сообщение об ошибке (красный)
        },
    });

    const updateSlotMutation = useMutation(updateSlotsAction, {
        onSuccess: () => {
            queryClient.invalidateQueries(["slots"]); // Обновляем список кандидатов
            handleSlotsManagerClose()
            showSnackbar("Слот успешно создан!", "success"); // Успешное сообщение (зелёный)
        },
        onError: (error) => {
            console.error("Ошибка при создании слота:", error);
            showSnackbar("Ошибка при создании слота", "error"); // Сообщение об ошибке (красный)
        },
    })

    const handleSaveSlots = () => {
        const now = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(now.getFullYear() + 1);

        const payload = {
            title: slots.title,
            timezone: 'UTC+5',
            recurrence_rule: slots.recurrence_rule,
            start_date: format(now, "yyyy-MM-dd HH:mm:ss"),
            end_date: format(nextYear, "yyyy-MM-dd HH:mm:ss"),
            intervals: slots.intervals,
            ...slots.settings
        };

        mutation.mutate(payload);
    };
    console.log(editingSlot)

    const handleUpdateSubmit = () => {
        console.log(editingSlot)
        if (!editingSlot || !editingSlot.id) { // Добавляем проверку на id
            console.error("Не удалось обновить слот: отсутствует ID");
            return;
        }

        const now = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(now.getFullYear() + 1);

        const payload = {
            id: editingSlot.id, // Обязательно включаем id
            title: editingSlot.title,
            timezone: 'UTC+5',
            recurrence_rule: editingSlot.recurrence_rule,
            start_date: format(now, "yyyy-MM-dd HH:mm:ss"),
            end_date: format(nextYear, "yyyy-MM-dd HH:mm:ss"),
            intervals: editingSlot.intervals,
            slot_between_break: editingSlot.settings?.slot_between_break || 0,
            // Добавляем все настройки
            ...(editingSlot.settings || {})
        };

        console.log("Отправка данных на обновление:", payload);
        updateSlotMutation.mutate(payload);
    };

    const handleEditSlot = (slotData) => {
        console.log(slotData)
        setEditingSlot({
            ...slotData,
            id: slotData.id, // Убедимся, что id передается
            settings: {
                max_book_days: slotData.max_book_days || undefined,        // Максимальный срок предварительного бронирования (дни)
                min_book_minutes: slotData.min_book_minutes || undefined,    // Минимальный срок предварительного бронирования (минуты)
                limit_day_bookings: slotData.limit_day_bookings || undefined,   // Лимит бронирований в день
                slot_between_break: slotData.slot_between_break || 0,
            }
        });
        setSlotsOpen(true);
    };

    return (
        <div style={{ padding: '24px', display: 'flex', gap: '24px', position: 'relative' }}>
            {isSlotsOpen && (
                <SlotsManager
                    setSlotsOpen={handleSlotsManagerClose}
                    handleSaveSlots={handleSaveSlots}
                    slots={editingSlot || slots}
                    onChangeSlots={handleSlotsChange}
                    onSave={handleSlotsChange}
                    isEditing={editingSlot ? true : false}
                    handleUpdateSubmit={handleUpdateSubmit}
                />
            )}
            <div className="calendar-container full-width">
                <div className="calendar-header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2 className="calendar-month">
                            <span className="month">{format(currentMonth, "LLLL", { locale: ru })}</span>
                            <span className="year">{format(currentMonth, "yyyy")}</span>
                        </h2>
                        <ViewModeSelect viewMode={viewMode} setViewMode={setViewMode} />
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: '24px' }}>
                            <div style={{ cursor: 'pointer' }} onClick={handlePrevMonth}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.4685 6.41438C14.7919 6.67313 14.8444 7.1451 14.5856 7.46855L10.9604 12L14.5856 16.5315C14.8444 16.855 14.7919 17.3269 14.4685 17.5857C14.145 17.8444 13.6731 17.792 13.4143 17.4685L9.41432 12.4685C9.19519 12.1946 9.19519 11.8054 9.41432 11.5315L13.4143 6.53151C13.6731 6.20806 14.145 6.15562 14.4685 6.41438Z" fill="#151813" />
                                </svg>
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={handleNextMonth}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.53151 17.5856C9.20806 17.3269 9.15562 16.8549 9.41438 16.5315L13.0396 12L9.41438 7.46849C9.15562 7.14505 9.20806 6.67308 9.53151 6.41432C9.85495 6.15556 10.3269 6.208 10.5857 6.53145L14.5857 11.5315C14.8048 11.8054 14.8048 12.1946 14.5857 12.4685L10.5857 17.4685C10.3269 17.7919 9.85495 17.8444 9.53151 17.5856Z" fill="#151813" />
                                </svg>
                            </div>
                        </div>

                    </div>

                    <CalendarControls setSlotsOpen={handleSlotManagerOpen} />
                </div>

                {viewMode === "month" ? (
                    <div className="calendar-grid" style={{ gridTemplateRows: `34px repeat(${rows}, 1fr)` }}>
                        {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map(day => (
                            <div key={day} className="calendar-day-header">{day}</div>
                        ))}

                        {days.map((day, index) => {
                            const formattedDay = format(day, "yyyy-MM-dd");
                            const dayEvents = calendarActionsData.filter(e =>
                                formattedDay === e.start_time.split("T")[0]
                            );
                            return (
                                <div key={index} className={`calendar-day ${!isSameMonth(day, currentMonth) ? "inactive" : ""}`}>
                                    <span className={`day-number ${isSameDay(day, new Date()) ? "today" : ""}`}>
                                        {format(day, "d")}
                                    </span>

                                    {dayEvents.length > 0 && (
                                        <div className="event-container">
                                            {dayEvents.map((event, i) => (
                                                <div
                                                    key={i}
                                                    style={{
                                                        "--event-color": event.color_scheme || "#1d4ed8",
                                                        background: event.color_scheme ? `${event.color_scheme}4d` : "#e7effb", // 50% opacity
                                                        color: event.color_scheme || "#1d4ed8",
                                                    }}
                                                    className="event"
                                                >
                                                    {event.title}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <WeekView
                        slots={editingSlot || slots}
                        isSlotsOpen={isSlotsOpen}
                        currentDate={currentMonth}
                        onEditSlot={handleEditSlot} // Передаем функцию для редактирования
                    />
                )}
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity} // Используем динамический цвет (error или success)
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default CalendarPage;