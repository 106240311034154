import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

export const fetchReports = async ({start_date, end_date}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        ...(start_date && { start_date }),
        ...(end_date && { end_date }),
    };
    
    try {        
        const response = await axiosClassic.get(`/api/company/${companyId}/reports`, {
            params,
        });
        return responseWrapper(response);
    } catch (error) {
        throw error; // Re-throw the error for the caller to handle
    }
}

//api/company/{id}/export-report
export const fetchXLSXReport = async ({start_date, end_date, department_name, vacancy_name, manager_name}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");
    
    const params = {
        ...(start_date && { start_date }),
        ...(end_date && { end_date }),
        ...(department_name && department_name !=="all"  && { department_name }),
        ...(vacancy_name && vacancy_name !=="all"  && { vacancy_name }),
        ...(manager_name && manager_name !=="all"  && { manager_name }),
    };

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/export-report`, {
            params,
            responseType: 'blob',
        });
        return responseWrapper(response);
    } catch (error) {
        throw error; // Re-throw the error for the caller to handle
    }
}