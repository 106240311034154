import React from 'react';
import { Tabs, Tab, TableContainer, Paper, Divider, Typography } from '@mui/material';
import { TabPanel, TabLabel } from '../common/TabComponents';
import VacanciesByStage from './VacanciesByStage';
import { CustomError } from '../styledComponents/CustomErrors';
import { DotsLoader } from '../styledComponents/CustomLoaders';

const VacancyTable = ({ vacancies, queryKey, activeTab, setActiveTab, setCurrentPage, isLoading, isError, error }) => {

    const handleChange = (event, newValue) => {
        setCurrentPage(1);
        setActiveTab(newValue + 1);
    }

    return (
        <TableContainer
            component={Paper}
            sx={{ boxShadow: 'none', borderRadius: "8px", minHeight: "846px", height: "100%", display: "flex", flexDirection: "column" }}
        >

            {/* Tabs */}

            <Tabs
                value={activeTab}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: { backgroundColor: 'rgba(89, 157, 21, 1)', height: '3px', borderRadius: "100px 100px 0 0" },
                }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    '& .MuiTab-root': {
                        textTransform: 'none',
                        padding: "0 8px",
                    },
                    backgroundColor: "white",
                    paddingLeft: "24px",
                    width: "100%",
                }}
            >
                <Tab label={<TabLabel text={"Активные"} activeTab={activeTab} index={0} />} />
                <Tab label={<TabLabel text={"Черновики"} activeTab={activeTab} index={1} />} />
                <Tab label={<TabLabel text={"Архивные"} activeTab={activeTab} index={2} />} />
            </Tabs>
            <Divider sx={{ backgroundColor: 'rgba(226, 228, 231, 1)', width: "100%" }} />

            {isLoading ? (
                <DotsLoader align="semiTop"/>
            ) : isError ? (
                <CustomError
                    error = {error?.message || "Неопознанная причина"}
                    align='semiTop'
                />
            ) : (
                <>
                    <TabPanel value={activeTab} index={0}>
                        <VacanciesByStage vacancies={vacancies} queryKey={queryKey} tables={{ isActive: true, targetStageId: 3 }} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <VacanciesByStage vacancies={vacancies} queryKey={queryKey} tables={{ isDraft: true }} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <VacanciesByStage vacancies={vacancies} queryKey={queryKey} tables={{ isArchive: true, targetStageId: 1, notDepartment: true }} />
                    </TabPanel>
                </>
            )}
        </TableContainer>
    );
};

export default VacancyTable;
