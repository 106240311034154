import React, { useState } from "react";
import {
    Box,
    Dialog,
    Typography,
    useTheme,
} from "@mui/material";
import {
    ArrowUpIcon,
    ProfileIcon,
    CloseIcon,
} from "../../svgComponents";
import TestResultsPreview from "../../vacancy/testComponents/TestResultsPreview";
import { TEST_STORAGE_CHECKBOX, TEST_STORAGE_RADIO } from "../../../utils/testTypesStorage";

/**
 * TestResultsPreviewModal
 *
 * @param {Object[]} quizData - Array of question objects
 * @param {Object} candidate - Candidate object containing answers, name, etc.
 * @param {number} questionIdx - Index of the current question to preview
 * @param {string} vacancyName - (Optional) Name of the vacancy or position
 * @param {function} handleClose - Function to close the dialog
 */
const TestResultsPreviewModal = ({
    quizData,
    candidate,
    questionIdx,
    vacancyName,
    handleClose,
}) => {
    const theme = useTheme();
    const [currIndex, setCurrIndex] = useState(questionIdx);

    const handleNext = () => {
        if (currIndex < candidate.answers.length - 1) {
            setCurrIndex((prev) => prev + 1);
        }
    };

    const handlePrev = () => {
        if (currIndex > 0) {
            setCurrIndex((prev) => prev - 1);
        }
    };

    // Current question data
    const questionData = quizData[currIndex] || {};

    // Candidate's answer for the current question
    const candidateAnswer = candidate?.answers[currIndex] && 0 < candidate.answers[currIndex].length ? candidate.answers[currIndex][0] : "";

    // For radio/checkbox, we expect an array of selected indexes
    let selectedIndices = [];
    if (questionData.type === TEST_STORAGE_RADIO || questionData.type === TEST_STORAGE_CHECKBOX) {
        selectedIndices = candidateAnswer.split(",").map((idx) => parseInt(idx)).map((idx) => {
            return questionData.answers.findIndex((answer) => answer.id === idx);
        });
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "8px",
                    width: "754px",
                    maxWidth: "unset",
                    height: "615px",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                },
            }}
        >
            {/* Main container */}
            <Box sx={{ flex: 1, display: "flex" }}>
                {/* Left navigation for question index / next / prev */}
                <Box
                    sx={{
                        borderRight: "1px solid rgba(162, 161, 168, 0.1)",
                        backgroundColor: "rgba(248, 249, 249, 1)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        p: 3,
                        width: "94px",
                    }}
                >
                    <ArrowUpIcon
                        onClick={handlePrev}
                        sx={{
                            width: 46,
                            height: 46,
                            cursor: currIndex > 0 ? "pointer" : "default",
                            borderRadius: "8px",
                            border: "1px solid rgba(198, 198, 198, 1)",
                            transform: "rotate(-90deg)",
                            mb: 1,
                            "&:hover": {
                                backgroundColor:
                                    currIndex > 0
                                        ? "rgb(190, 190, 190)"
                                        : "transparent",
                            },
                            "&:active": {
                                borderColor:
                                    currIndex > 0
                                        ? "rgb(102, 102, 102)"
                                        : "rgba(198, 198, 198, 1)",
                            },
                        }}
                        svgSx={{ width: 10, height: 4 }}
                    />
                    <ArrowUpIcon
                        onClick={handleNext}
                        sx={{
                            width: 46,
                            height: 46,
                            cursor:
                                currIndex < candidate.answers.length - 1
                                    ? "pointer"
                                    : "default",
                            borderRadius: "8px",
                            border: "1px solid rgba(198, 198, 198, 1)",
                            transform: "rotate(90deg)",
                            mb: 2,
                            "&:hover": {
                                backgroundColor:
                                    currIndex < candidate.answers.length - 1
                                        ? "rgb(190, 190, 190)"
                                        : "transparent",
                            },
                            "&:active": {
                                borderColor:
                                    currIndex < candidate.answers.length - 1
                                        ? "rgb(102, 102, 102)"
                                        : "rgba(198, 198, 198, 1)",
                            },
                        }}
                        svgSx={{ width: 10, height: 4 }}
                    />
                    <Typography
                        sx={{
                            fontSize: "14px",
                            color: theme.palette.grey[600],
                        }}
                    >
                        {currIndex + 1} из {candidate.answers.length}
                    </Typography>
                </Box>

                {/* Right side: Header + question content */}
                <Box sx={{ flex: 1 }}>
                    {/* Header with candidate info */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            height: "72px",
                            backgroundColor: "rgba(248, 249, 249, 1)",
                            justifyContent: "space-between",
                            p: "16px 24px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            {candidate?.image ? (
                                <img
                                    style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: "50%",
                                    }}
                                    src={candidate?.image}
                                    alt="User avatar"
                                    loading='lazy'
                                />
                            ) : (
                                <ProfileIcon
                                    sx={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        backgroundColor:
                                            "rgba(232, 239, 228, 1)",
                                    }}
                                    svgSx={{
                                        width: "20px",
                                        height: "20px",
                                        color: "rgba(89, 157, 21, 1)",
                                    }}
                                />
                            )}
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        color: "rgba(21, 24, 19, 1)",
                                        fontWeight: 600,
                                    }}
                                >
                                    {candidate?.full_name ||
                                        "Имя кандидата отсутствует"}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        color: "rgba(116, 116, 115, 1)",
                                    }}
                                >
                                    {vacancyName ||
                                        "Название вакансии отсутствует"}
                                </Typography>
                            </Box>
                        </Box>
                        <CloseIcon
                            onClick={handleClose}
                            sx={{ width: 24, height: 24, cursor: "pointer" }}
                            svgSx={{
                                width: 20,
                                height: 20,
                                color: "rgba(0, 0, 0, 0.5)",
                                hoverColor: "rgba(0, 0, 0, 0.75)",
                                pressedColor: "rgba(0, 0, 0, 1)",
                            }}
                        />
                    </Box>
                    
                    {/* Question content */}
                    <TestResultsPreview
                        questionData={questionData}
                        candidateAnswer={candidateAnswer}
                        selectedIndices={selectedIndices}
                    />
                </Box>
            </Box>
        </Dialog>
    );
};

export default TestResultsPreviewModal;
