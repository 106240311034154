import { Box } from "@mui/material";
import { CircularLoading } from "../../../components/tapHrStyledComponents";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getProcess } from "../../../services/processesApi";
import { useEffect, useRef } from "react";

const IntegrationsRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const targetInfo = useRef(null);

    const handleNavigate = () => {
        if (!targetInfo.current || !targetInfo.current.path) {
            return;
        }
        navigate(targetInfo.current.path, { state: targetInfo.current?.data });
    }

    const handleNavigateHome = () => {
        navigate("/");
    }

    const getUpdatedMutation = useMutation(getProcess, {
        onSuccess: () => {
            handleNavigate();
        },
        onError: (error) => {
            handleNavigateHome();
        },
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const code = params.get("code");
        const state = JSON.parse(decodeURIComponent(params.get("state")));
        const provider = state?.provider;
        
        if (state) {
            try {
                targetInfo.current = state;
            } catch (error) {
                console.error("Failed to parse targetInfo:", error);
            }
        }

        if (code && provider) {
            getUpdatedMutation.mutate({ name: provider, code: code });
        } else {
            handleNavigateHome();
        }

    }, [location]);

    return (
        <Box
            sx= {{
                width : "100%svw",
                height : "100svh",
                backgroundColor : "white",
            }}
        >
            <CircularLoading/>
        </Box>
    )
}

export default IntegrationsRedirect;