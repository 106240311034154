// src/services/telegramApi.js

import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

// Получение списка Telegram-каналов
export const fetchTelegramChannels = async () => {
    try {
        const response = await axiosClassic.get(`/api/telegram/channels`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

// Получение полей для конкретного Telegram-канала
export const fetchTelegramFields = async (channelId) => {
    if (!channelId) throw new Error("ID канала обязателен");

    try {
        const response = await axiosClassic.get(`/api/telegram/fields`, {
            params: { channel_id: channelId },
        });
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

// Отправка данных о вакансии в Telegram-канал
export const sendTelegramPost = async (vacancyId, channelId, fields) => {
    try {
        const payload = {
            vacancy_id: vacancyId,
            channel_id: channelId,
            fields: fields,
        };
        const response = await axiosClassic.post(`/api/telegram/send`, payload);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};
