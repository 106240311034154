import { Dialog, Box, Typography } from '@mui/material';
import { CloseIcon } from '../../svgComponents';

const TemplatePreviewModal = ({ handleClose, body }) => {
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "403px",
                    maxWidth: "unset",
                    maxHeight: "500px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowX: "hidden",
                    overflowY: "auto",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    gap: "24px",
                    position: "relative",
                    p: 3,
                }}>
                    <Box
                        sx = {{
                            display: "flex",
                            top: 0, 
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                        }}
                    >
                        <Typography
                            variant='h2'
                            sx={{
                                fontSize: "30px",
                                lineHeight: "34px",
                                color: "rgba(0, 0, 0, 1)",
                                fontWeight: "600",
                                textAlign: "center",
                            }}
                        >
                            {body?.title}
                        </Typography>
                        <CloseIcon
                            onClick={handleClose}
                            sx={{ width: 24, height: 24, cursor: "pointer"}}
                            svgSx={{
                                width: 22,
                                height: 22,
                                color: "rgba(0,0,0, 0.5)",
                                hoverColor: "rgba(0,0,0, 0.75)",
                                pressedColor: "rgba(0,0,0, 1)",
                            }}
                        />
                    </Box>
                    <Box>
                        <Box
                            dangerouslySetInnerHTML={{ __html: body?.content || "Здравствуйте, {{Кандидат.имя}}! Спасибо за интерес к вакансии {{Вакансия.название}} в компании {{Компания.имя}}. Предлагаем Вам пройти онлайн собеседование, чтобы мы могли лучше узнать Вас. Это быстро и просто: перейдите по ссылке {{Интервью.ссылка}} и ответьте на несколько коротких вопросов. После прохождения мы свяжемся с Вами в ближайшее время!" }}
                            sx={{
                                color: "rgba(116, 116, 115, 1)",
                                fontSize: "16px",
                                lineHeight: "20px",
                                width: "100%",
                                overflowX: "hidden",
                                "& ul": {
                                    margin: "16px 0",
                                    paddingLeft: "1.5rem", // space for the bullet points
                                    listStyleType: "disc",
                                },
                                "& li": {
                                    marginBottom: "0.5rem",
                                },
                                "& span": {
                                    color: "rgba(116, 116, 115, 1)!important",
                                }
                            }}
                        />
                    </Box>
            </Box>
        </Dialog>
    );
}

export default TemplatePreviewModal;