import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Typography,
    Paper,
    Container,
    IconButton,
    Divider,
    Snackbar,
    Alert,
    TextField,
    MenuItem,
} from "@mui/material";
import LabeledInput from "../../components/main/LabeledInput";
import OldCustomAutoComplete from "../../components/main/CustomAutocomplete";
import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchCities, fetchCurrencies } from "../../services/dictionariesApi";
import { fetchVacancies } from "../../services/vacancyApi";
import { createCandidate } from "../../services/candidatesApi";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { CameraIcon } from "../../components/svgComponents";
import WorkExperienceForm from "../../components/candidates/WorkExperienceForm";
import KeySkills from "../../components/candidates/KeySkills";
import EducationForm from "../../components/candidates/EducationForm";
import LanguageSection from "../../components/vacancy/LanguagesSection";
import LinksForm from "../../components/candidates/LinksForm";
import AdditionalInformation from "./AdditionalInformation";
import CustomAutocomplete from "../../components/styledComponents/CustomAutoComplete"
import { fetchSources } from "../../services/sourcesApi";
import CustomRadioList from "../../components/styledComponents/CustomRadioList";




const CreateCandidatesPage = () => {
    const location = useLocation();
    const [photo, setPhoto] = useState(null); // Состояние для фотографии
    const [photoPreview, setPhotoPreview] = useState(null); // Состояние для превью фото
    const [attachedFile, setAttachedFile] = useState(null);


    const queryKey = location.state?.uniqueKey;

    const { data: notificationData, isLoading, isError } = useQuery(
        ['notification', queryKey],
        {
            enabled: !!queryKey, // Выполняем запрос только если `queryKey` существует
            queryFn: () => queryClient.getQueryData(['notification', queryKey]), // Получение данных из кэша
        }
    );

    console.log(notificationData);

    const [body, setBody] = useState({
        first_name: "",
        last_name: "",
        middle_name: "",
        birth_date: "",
        phone: "",
        email: "",
        city: null,
        resume_source: null,
        citizenship: "",
        title: "",
        salary_amount: "",
        salary_currency: "KZT",
        business_trip_readiness: null,
        description: ""
    })

    const [employment, setEmployment] = useState([])
    const [schedule, setSchedule] = useState([])



    const [experience, setExperience] = useState([
        {
            company: "",
            position: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            description: "",
        }
    ])


    const [education, setEducation] = useState(
        {
            level: "",
            items: [
                {
                    place: "",
                    major: "",
                    faculty: "",
                    year: ""
                }
            ]
        })


    const navigate = useNavigate()
    const queryClient = useQueryClient();

    const [vacancy, setVacancy] = useState([]); // Множественный выбор требует массива
    const [skills, setSkills] = useState([])
    const [languagesData, setLanguagesData] = useState(['']);
    const [links, setLinks] = useState([""]);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Количество строк на странице
    const [currentPage, setCurrentPage] = useState(1);
    const [citySearchQuery, setCitySearchQuery] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // error (красный) или success (зелёный)



    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (!file) return;

        // Ограничения на размер и форматы
        const maxFileSize = 10 * 1024 * 1024; // 10 MB
        const allowedFormats = [
            "application/pdf",
            "application/msword", // .doc
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
            "image/jpeg",
            "image/png",
        ];

        // Проверка размера
        if (file.size > maxFileSize) {
            showSnackbar("Размер файла не должен превышать 10MB.");
            event.target.value = ""; // Сбрасываем выбор
            return;
        }

        // Проверка формата
        if (!allowedFormats.includes(file.type)) {
            showSnackbar("Допустимые форматы: PDF, DOC, DOCX, JPG, PNG.");
            event.target.value = ""; // Сбрасываем выбор
            return;
        }

        // Если всё ок, сохраняем файл
        setAttachedFile(file);
        console.log("Файл прикреплен:", file.name);
    };

    const handleRemoveFile = () => {
        setAttachedFile(null); // Удаляем файл
    };

    // Запрос на города с пагинацией
    const {
        data: cityPages,
        fetchNextPage: fetchNextCityPage,
        hasNextPage: hasNextCityPage,
        isFetchingNextPage: isFetchingNextCityPage,
    } = useInfiniteQuery(
        ["cities", citySearchQuery],
        ({ pageParam = 1 }) => fetchCities({searchQuery : citySearchQuery, page : pageParam, perPage: 10}),
        {
            getNextPageParam: (lastPage) => {
                const currentPage = parseInt(lastPage.current_page, 10);
                const totalPages = Math.ceil(lastPage.total / lastPage.per_page);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
        }
    );

    console.log(cityPages)

    const cityOptions = cityPages?.pages.flatMap((page) => page.data) || [];

    // Запрос на вакансии
    const {
        data: vacancyOptions = [],
        fetchNextPage: fetchNextVacanciesPage,
        hasNextPage: hasNextVacanciesPage,
        isFetchingNextPage: isFetchingNextVacanciesPage,
        isLoading: isVacanciesLoading
    } = useQuery(['vacancies', currentPage, rowsPerPage], () =>
        fetchVacancies(currentPage, rowsPerPage)
    );


    // Запрос на валюты
    const { data: currencyOptions, isLoading: isCurrenciesLoading } = useQuery(
        ["currencies"],
        fetchCurrencies
    );

    console.log(currencyOptions)

    // Мутация для создания кандидата
    const mutation = useMutation(createCandidate, {
        onSuccess: () => {
            queryClient.invalidateQueries(["candidates"]); // Обновляем список кандидатов
            showSnackbar("Кандидат успешно создан!", "success"); // Успешное сообщение (зелёный)
            navigate("/candidates")
        },
        onError: (error) => {
            console.error("Ошибка при создании кандидата:", error);
            showSnackbar("Ошибка при создании кандидата", "error"); // Сообщение об ошибке (красный)
        },
    });

    const isFormValid = () => {
        return (
            body.first_name.trim() &&
            body.last_name.trim() &&
            body.city &&
            body.title.trim()
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isFormValid()) {
            alert("Пожалуйста, заполните все обязательные поля.");
            return;
        }

        // Создаем объект FormData
        const formData = new FormData();

        const source_body = {

        }

        // Добавляем обязательные поля
        formData.append("first_name", body.first_name.trim());
        formData.append("last_name", body.last_name.trim());

        // Добавляем условные поля
        if (body.middle_name.trim()) {
            formData.append("middle_name", body.middle_name.trim());
        }

        if (body.phone.trim()) {
            formData.append("phone", body.phone.trim());
        }

        if (body.email.trim()) {
            formData.append("email", body.email.trim());
        }

        if (body.city?.name) {
            formData.append("city", body.city.name);
        }

        if (photo) {
            formData.append("avatar", photo)
        }

        if (attachedFile) {
            formData.append("cv", attachedFile)
        }

        if (vacancy?.length) {
            vacancy.forEach((el) => {
                formData.append("vacancy_ids[]", el.id); // Массив вакансий
            });
        }

        if (body.birth_date) {
            formData.append("birth_date", body.birth_date);
        }

        console.log(body.salary_amount)
        if (String(body.salary_amount).trim()) {
            source_body.salary_amount = String(body.salary_amount).trim()
        }


        if (body.business_trip_readiness) {
            source_body.business_trip_readiness = body.business_trip_readiness
        }


        if (body.salary_currency) {
            source_body.salary_currency = body.salary_currency?.code
        }

        if (body.title.trim()) {
            source_body.title = body.title.trim()
        }

        if (body.citizenship.trim()) {
            source_body.citizenship = body.citizenship.trim();
        }

        if (schedule) {
            source_body.schedule = schedule
        }

        if (employment) {
            source_body.employment = employment
        }

        if (employment) {
            source_body.language = languagesData
        }

        if (experience) {
            const formattedExperience = experience.map(({ startMonth, startYear, endMonth, endYear, ...rest }) => ({
                ...rest,
                start: startYear && startMonth ? `${startYear}-${startMonth}` : "",
                end: endYear && endMonth ? `${endYear}-${endMonth}` : "",
            }));
            source_body.experience = formattedExperience
        }

        if (education) {
            source_body.education = education
        }

        if (skills) {
            source_body.skills = skills
        }

        if (links) {
            source_body.links = links
        }

        if (body.description.trim()) {
            source_body.description = body.description
        }

        console.log("Submitting candidate data via FormData:");


        formData.append('source_body', JSON.stringify(source_body))
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }
        // Отправка данных через мутацию
        console.log(source_body)
        mutation.mutate(formData);
    };

    const handleCityScrollEnd = (event) => {
        const listboxNode = event.currentTarget;
        const bottom =
            listboxNode.scrollHeight - listboxNode.scrollTop <=
            listboxNode.clientHeight + 10;
        if (bottom && hasNextCityPage && !isFetchingNextCityPage) {
            fetchNextCityPage();
        }
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];

        if (!file) return;

        const maxFileSize = 2 * 1024 * 1024; // 2 MB
        const allowedFormats = ["image/jpeg", "image/png", "image/gif"];

        if (file.size > maxFileSize) {
            showSnackbar("Размер файла не должен превышать 2MB.");
            event.target.value = ""; // Сбрасываем выбор файла
            return;
        }

        if (!allowedFormats.includes(file.type)) {
            showSnackbar("Допустимые форматы: JPG, JPEG, PNG, GIF.");
            event.target.value = ""; // Сбрасываем выбор файла
            return;
        }

        // Если размер и формат в пределах допустимого, сохраняем файл
        setPhoto(file);
        setPhotoPreview(URL.createObjectURL(file)); // Генерируем превью
    };

    const handlePhotoRemove = () => {
        setPhoto(null);
        setPhotoPreview(null);
    };

    const showSnackbar = (message, severity = "error") => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleBusinessTrips = (value) => {
        setBody({
            ...body,
            business_trip_readiness: value,
        })
    }


    const handleChange = (event) => {
        const { name, value, type } = event.target;
        setBody({
            ...body,
            [name]: value
        });
    }

    const handleCheckboxChange = (value) => {
        setEmployment((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value) // Удаляем, если уже выбрано
                : [...prev, value] // Добавляем, если еще не выбрано
        );
    };

    const handleScheduleCheckboxChange = (value) => {
        setSchedule((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value) // Удаляем, если уже выбрано
                : [...prev, value] // Добавляем, если еще не выбрано
        );
    };

    useEffect(() => {
        if (notificationData) {
            // Заполняем состояние данными из уведомления
            setBody((prev) => ({
                ...prev,
                first_name: notificationData?.data?.first_name || "",
                last_name: notificationData?.data?.last_name || "",
                middle_name: notificationData?.data?.middle_name || "",
                birth_date: notificationData?.data?.birth_date || "",
                phone: notificationData?.data?.phone || "",
                email: notificationData?.data?.email || "",
                city: { name: notificationData?.data?.city } || null,

                citizenship: notificationData?.data?.citizenship || "",
                title: notificationData?.data?.title || "",
                salary_amount: notificationData?.data?.salary?.amount || "",
                salary_currency: currencyOptions?.data.find(item => item.code === notificationData?.data?.salary?.currency) || "KZT",
                business_trip_readiness: notificationData?.data?.business_trip_readiness || null,
                description: notificationData?.data?.additional_info || "",
            }));

            setEmployment(notificationData?.data?.employment || []);
            setSchedule(notificationData?.data?.schedule || []);
            setExperience(notificationData?.data?.experience || []);
            setEducation({
                level: notificationData?.data?.education?.level || "",
                items: notificationData?.data?.education?.items || [],
            });
            setSkills(notificationData?.data?.skills || []);
            setLanguagesData(notificationData?.data?.language || []);
            setLinks(notificationData?.data?.site || []);
        }
    }, [notificationData]);

    // if (isLoading) {
    //     return <div>Загрузка данных...</div>;
    // }

    // if (isError || !queryKey) {
    //     return <div>Ошибка загрузки данных. Пожалуйста, попробуйте снова.</div>;
    // }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, display: "flex", flexDirection: "column", paddingBottom: 10 }}>
            <Paper
                elevation={0}
                sx={{
                    p: "36px 24px 24px 24px",
                    borderRadius: 2,
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "calc(90vh - 27px)",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 1, // Отступ снизу
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "28px" }}>
                        Информация о кандидате
                    </Typography>

                    {/* Кнопка "Прикрепить файл" */}
                    {attachedFile ? (
                        // Отображаем название файла
                        <Box sx={{ display: 'flex', alignItems: 'center', color: '#1F6BC5' }}>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '16px',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "200px", // Ограничение ширины текста
                                    borderBottom: "1px solid #1F6BC5",
                                    lineHeight: '16px'
                                }}
                            >
                                {attachedFile.name}
                            </Typography>
                            <Button
                                onClick={handleRemoveFile}
                                sx={{
                                    minWidth: "auto",
                                    padding: 0,
                                    color: "#1F6BC5",
                                    width: '20px',
                                    height: '20px'
                                }}
                            >
                                <CloseIcon sx={{ width: '20px' }} />
                            </Button>
                        </Box>
                    ) : (
                        // Кнопка для прикрепления файла
                        <Button
                            component="label"
                            variant="outlined"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "#6BA14A",
                                borderColor: "#6BA14A",
                                padding: "12px 20px",
                                textTransform: "none",
                                height: "46px",
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor: "#F4F7F2",
                                    borderColor: "#508C13",
                                },
                                "&:active": {
                                    backgroundColor: "#CDDDC6",
                                    borderColor: "#457A10",
                                }
                            }}
                        >
                            Прикрепить резюме
                            <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                            />
                        </Button>
                    )}
                </Box>
                <Divider sx={{ mt: 2, mb: 4 }} />

                {/* Загрузка фотографии */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: 'fit-content',
                        gap: '4px',
                        mb: "36px",
                    }}
                >
                    <Box
                        sx={{
                            width: 100,
                            height: 100,
                            borderRadius: "10px",
                            border: "1px solid #A2A1A833",
                            backgroundColor: "rgba(162, 161, 168, 0.05)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                            position: "relative",
                            cursor: "pointer",
                        }}
                    >
                        {photoPreview ? (
                            <>
                                <img
                                    src={photoPreview}
                                    alt="Фото кандидата"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                    loading='lazy'
                                />
                                <IconButton
                                    onClick={handlePhotoRemove}
                                    sx={{
                                        position: "absolute",
                                        top: 4,
                                        right: 4,
                                        backgroundColor: "#fff",
                                        "&:hover": { backgroundColor: "#f5f5f5" },
                                        width: "20px",
                                        height: "20px",
                                    }}
                                >
                                    <CloseIcon sx={{ width: "14px" }} />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <CameraIcon
                                    sx={{ width: 24, height: 24 }}
                                    svgSx={{
                                        width: 24,
                                        height: 24,
                                        color: "rgba(22, 21, 28, 1)",
                                    }}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handlePhotoChange}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        opacity: 0,
                                        cursor: "pointer",
                                    }}
                                />
                            </>
                        )}
                    </Box>
                    <Typography sx={{ fontSize: '14px', lineHeight: "18px", width: '100%', textAlign: 'center', mt: "4px" }}>Изменить фото</Typography>
                </Box>

                <Box
                    component="form"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                    }}
                >
                    {/* Поля формы */}
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "36px",
                        }}
                    >
                        <LabeledInput
                            label="Имя"
                            value={body.first_name}
                            onChange={handleChange}
                            placeholder="Введите имя"
                            name="first_name"
                            required
                        />
                        <LabeledInput
                            label="Фамилия"
                            value={body.last_name}
                            onChange={handleChange}
                            placeholder="Введите фамилию"
                            name="last_name"
                            required
                        />
                        <LabeledInput
                            label="Отчество"
                            value={body.middle_name}
                            onChange={handleChange}
                            placeholder="Введите отчество"
                            name="middle_name"
                        />
                        <LabeledInput
                            label="Дата рождения"
                            value={body.birth_date}
                            onChange={handleChange}
                            type="date"
                            name="birth_date"
                        />
                        <LabeledInput
                            label="Номер телефона"
                            value={body.phone}
                            onChange={handleChange}
                            placeholder="+7 ___-___-__-__"
                            name="phone"
                            mask="+7 (999) 999-99-99" // Маска для телефона
                        />

                        <LabeledInput
                            label="Электронная почта"
                            value={body.email}
                            onChange={handleChange}
                            placeholder="Введите электронную почту"
                            name="email"
                        />

                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    color: "#151813",
                                    mb: 1,
                                }}
                            >
                                Город <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <CustomAutocomplete
                                value={body.city}
                                setValue={(value) => setBody({ ...body, city: value })}
                                queryKeyStartingValue={["cities"]}
                                fetchFunction={fetchCities}
                            />
                        </Box>

                        <LabeledInput
                            label="Гражданство"
                            value={body.citizenship}
                            onChange={handleChange}
                            placeholder="Введите гражданство"
                            name="citizenship"
                        />

                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    color: "#151813",
                                    mb: 1,
                                }}
                            >
                                Источник резюме
                            </Typography>
                            <CustomAutocomplete
                                value={body.resume_source}
                                setValue={(value) => setBody({ ...body, resume_source: value })}
                                queryKeyStartingValue={["resume_source"]}
                                fetchFunction={fetchSources}
                            />
                        </Box>

                        <LabeledInput
                            label="Желаемая должность"
                            value={body.title}
                            onChange={handleChange}
                            placeholder="Введите должность"
                            name="title"
                            width={'356px'}
                            required
                        />

                        <Box sx={{ display: "flex", gap: "16px", justifyContent: "space-between" }}>
                            <Box sx={{ flex: 1 }}>
                                <LabeledInput
                                    label="Желаемая зарплата"
                                    value={body.salary_amount}
                                    onChange={handleChange}
                                    placeholder="Введите размер зарплаты"
                                    name="salary_amount"
                                    type="number"
                                    width={'219px'}
                                />
                            </Box>

                            {/* Автокомплит для валют */}
                            <Box sx={{ width: "94px" }}>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        lineHeight: "20px",
                                        color: "#151813",
                                        mb: 1,
                                    }}
                                >
                                    Валюта
                                </Typography>
                                <TextField
                                    select
                                    placeholder="₸"
                                    value={body.salary_currency}
                                    onChange={(value) => setBody({ ...body, salary_currency: value })}
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: "#fff",
                                        border: "1px solid rgba(226, 228, 231, 1)",
                                        borderRadius: "8px",
                                        width: "100%",
                                        "&:hover": {
                                            borderColor: "#599D15", // Hover border color
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            height: "54px",
                                            borderRadius: "8px",
                                        },
                                        "&:has(.Mui-focused)": {
                                            border: "2px solid rgba(205, 221, 198, 1)", // Focused border color
                                            "& .MuiOutlinedInput-root": {
                                                height: "52px", // Height when focused
                                            },
                                        },
                                    }}
                                >
                                    {isCurrenciesLoading ? (
                                        <MenuItem>Загрузка...</MenuItem>
                                    ) : (
                                        currencyOptions?.data.map((cur) => (
                                            <MenuItem key={cur.code} value={cur.code}>
                                                {cur.name}
                                            </MenuItem>
                                        ))
                                    )}
                                </TextField>
                            </Box>
                        </Box>

                        <OldCustomAutoComplete
                            label="Связать с вакансией"
                            value={vacancy}
                            onChange={setVacancy}
                            fetchNextPage={fetchNextVacanciesPage}
                            hasNextPage={hasNextVacanciesPage}
                            options={vacancyOptions.data}
                            isLoading={isVacanciesLoading}
                            searchQuery={''}
                            setSearchQuery={() => { }}
                            multiple={true}
                            sx={{ mb: 0 }}
                        />
                    </Box>

                    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "20px" }}>
                        <Box>
                            <Typography sx={{ mb: 2 }}>Тип занятости</Typography>
                            <CustomRadioList
                                sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px" }}
                                isCheckBox={true}
                                multiInputs={true}
                                defaultValue={employment}
                                inputs={[
                                    { value: "full", text: "Полная занятость" },
                                    { value: "part", text: "Частичная занятость" },
                                    { value: "project", text: "Проектная работа" },
                                    { value: "volunteer", text: "Волонтерство" },
                                    { value: "probation", text: "Стажировка" },
                                ]}
                                onClick={handleCheckboxChange}
                            />
                        </Box>
                        <Box>
                            <Typography sx={{ mb: 2 }}>График работы</Typography>
                            <CustomRadioList
                                sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px" }}
                                isCheckBox={true}
                                multiInputs={true}
                                defaultValue={schedule}
                                inputs={[
                                    { value: "fullDay", text: "Полный день" },
                                    { value: "shift", text: "Сменный график" },
                                    { value: "project", text: "Гибкий график" },
                                    { value: "remote", text: "Удаленная работа" },
                                    { value: "flyInFlyOut", text: "Вахтовый метод" },
                                ]}
                                onClick={handleScheduleCheckboxChange}
                            />
                        </Box>
                        <Box>
                            <Typography sx={{ mb: 2 }}>Командировки</Typography>
                            <CustomRadioList
                                sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px" }}
                                defaultValue={body.business_trip_readiness}
                                inputs={[
                                    { value: true, text: "Готов" },
                                    { value: false, text: "Не готов" },
                                ]}
                                onClick={handleBusinessTrips}
                            />
                        </Box>
                    </Box>

                    <WorkExperienceForm experience={experience} setExperience={setExperience} />
                    <EducationForm education={education} setEducation={setEducation} />
                    <KeySkills skills={skills} setSkills={setSkills} />
                    <LanguageSection languagesData={languagesData} setLanguagesData={setLanguagesData} />
                    <LinksForm links={links} setLinks={setLinks} />
                    {/* Кнопка отправки */}


                </Box>
            </Paper>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity} // Используем динамический цвет (error или success)
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <AdditionalInformation description={body.description} handleChange={handleChange} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "16px", // Отступ между кнопками
                    mt: 3, // Отступ сверху
                }}
            >
                {/* Кнопка "Назад" */}
                <Button
                    variant="outlined"
                    sx={{
                        width: "136px",
                        height: "56px",
                        borderRadius: "8px",
                        color: "#5A5A5A",
                        borderColor: "#D1D1D1",
                        "&:hover": {
                            backgroundColor: "#F5F5F5",
                            borderColor: "#BDBDBD",
                        },
                    }}
                    onClick={() => navigate('/candidates')} // Действие для кнопки "Назад"
                >
                    Назад
                </Button>

                {/* Кнопка "Сохранить" */}
                <Button
                    // type="submit"
                    variant="contained"
                    disabled={!isFormValid()} // Деактивируем кнопку, если форма невалидна
                    onClick={handleSubmit}
                    sx={{
                        width: "136px",
                        height: "56px",
                        borderRadius: "8px",
                        backgroundColor: "#599D15",
                        color: "#FFFFFF",
                        "&:hover": {
                            backgroundColor: "#4B8511",
                        },
                        "&.Mui-disabled": {
                            backgroundColor: "#D1D1D1",
                            color: "#FFFFFF",
                        },
                    }}
                >
                    Сохранить
                </Button>
            </Box>
        </Container>

    );
};

export default CreateCandidatesPage;
