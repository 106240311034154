import { useState } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { Box, Typography, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

dayjs.extend(isBetween);

// Days of the week labels (adjust as needed)
const daysOfWeek = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
];

/**
 * Generate a 6-row (42-day) calendar grid for the given month.
 * 1. Find the first day of the month and move back to the start of its week.
 * 2. Collect exactly 42 consecutive days.
 * 3. Chunk those days into 6 rows of 7 days each.
 */
function generateCalendarGrid(currentMonth) {
    const startOfMonth = currentMonth.startOf("month");
    const startOfCalendar = startOfMonth.startOf("week");
    const days = [];
    let day = startOfCalendar;
    for (let i = 0; i < 42; i++) {
        days.push(day);
        day = day.add(1, "day");
    }
    const weeks = [];
    for (let i = 0; i < 42; i += 7) {
        weeks.push(days.slice(i, i + 7));
    }
    return weeks;
}

export default function MiniCalendar({ today = dayjs(), handleField }) {
    const [displayMonth, setDisplayMonth] = useState(dayjs());
    // Track the selected range: start and end as dayjs objects (or null)
    const [range, setRange] = useState({ start: null, end: null });

    // Determine if we can navigate to the next month
    const disableNext =
        displayMonth.isSame(today, "month") ||
        displayMonth.isAfter(today, "month");

    const handlePrevMonth = () => {
        setDisplayMonth((prev) => prev.subtract(1, "month"));
    };

    const handleNextMonth = () => {
        if (!disableNext) {
            setDisplayMonth((prev) => prev.add(1, "month"));
        }
    };

    // Handle day click:
    // - Only days that are today or in the past are clickable.
    // - First click sets the start date.
    // - Second click sets the end date (if on/after start) or resets the start.
    const handleDayClick = (dayObj) => {
        if (dayObj.isAfter(today, "day")) return; // Do nothing for future days

        if (!range.start) {
            setRange({ start: dayObj, end: null });
        } else if (!range.end) {
            if (dayObj.isBefore(range.start, "day")) {
                setRange((prev) => ({ start: dayObj, end: prev.start }));
                handleField("date", {
                    startDate: dayObj.startOf("day").toISOString(),
                    endDate: range.start.endOf("day").toISOString(),
                });
            } else {
                setRange({ start: range.start, end: dayObj });
                handleField("date", {
                    startDate: range.start.startOf("day").toISOString(),
                    endDate: dayObj.endOf("day").toISOString(),
                });
            }
        } else {
            // If both start and end are set, start a new selection
            setRange({ start: dayObj, end: null });
        }
    };

    const weeks = generateCalendarGrid(displayMonth);

    return (
        <Box sx={{ p: 2 }}>
            {/* Calendar header with month name and arrows */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                }}
            >
                <Typography sx={{ fontWeight: 600, cursor: "pointer" }}>
                    {months[displayMonth.month()]} {displayMonth.year()}
                </Typography>
                <Box sx={{display: "flex", gap: "16px", alignItems: "center"}}>
                    <IconButton onClick={handlePrevMonth} size="small">
                        <ArrowBackIos fontSize="inherit" />
                    </IconButton>
                    <IconButton
                        onClick={handleNextMonth}
                        size="small"
                        disabled={disableNext}
                    >
                        <ArrowForwardIos fontSize="inherit" />
                    </IconButton>
                </Box>
            </Box>

            {/* Days of the week row */}
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(7, 1fr)",
                    height: 40,
                    alignItems: "center",
                }}
            >
                {daysOfWeek.map((dow) => (
                    <Typography
                        key={dow}
                        sx={{
                            textAlign: "center",
                            fontWeight: 500,
                            fontSize: 12,
                            lineHeight: "16px",
                            color: "rgba(155, 162, 169, 1)",
                        }}
                    >
                        {dow}
                    </Typography>
                ))}
            </Box>

            {/* 6 rows of days (42 total) */}
            {weeks.map((week, wIndex) => (
                <Box
                    key={`week-${wIndex}`}
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(7, 1fr)",
                        height: 36,
                        alignItems: "center",
                    }}
                >
                    {week.map((dayObj) => {
                        const isSameMonth = dayObj.isSame(
                            displayMonth,
                            "month"
                        );
                        const isFuture = dayObj.isAfter(today, "day");
                        const isDayClickable = !isFuture;
                        const isToday = dayObj.isSame(today, "day");

                        // Highlight range selection for start and end cells
                        let bgColor = "transparent";
                        if (range.start && dayObj.isSame(range.start, "day")) {
                            bgColor = "rgba(89,157,21,0.5)";
                        } else if (
                            range.end &&
                            dayObj.isSame(range.end, "day")
                        ) {
                            bgColor = "rgba(89,157,21,0.5)";
                        }

                        return (
                            <Box
                                key={dayObj.format("DD-MM-YYYY")}
                                onClick={() => {
                                    if (isDayClickable) handleDayClick(dayObj);
                                }}
                                sx={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: isToday
                                        ? "1px solid rgba(89, 157, 21, 1)"
                                        : "none",
                                    cursor: isDayClickable
                                        ? "pointer"
                                        : "default",
                                    backgroundColor: bgColor,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: "18px",
                                        textAlign: "center",
                                        color: isFuture
                                            ? "rgba(134, 140, 146, 1)"
                                            : isSameMonth
                                            ? "rgba(16, 19, 15, 1)"
                                            : "rgba(134, 140, 146, 1)",
                                    }}
                                >
                                    {dayObj.format("D")}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            ))}

            {/* Debug: Display selected range */}
            <Box
                sx={{
                    mt: 2,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-around",
                }}
            >
                <Typography variant="body2">
                    <strong>Начало</strong>:{" "}
                    {range.start
                        ? range.start.format("YYYY-MM-DD")
                        : "Не выбрано"}
                </Typography>
                <Typography variant="body2">
                    <strong>Конец</strong>:{" "}
                    {range.end ? range.end.format("YYYY-MM-DD") : "Не выбрано"}
                </Typography>
            </Box>
        </Box>
    );
}
