import PageContainer from "../../components/common/PageContainer";
import CompanyInfo from "../../components/profile/CompanyInfo";
import ProfileBaseInfo from "../../components/profile/ProfileBaseInfo";

const ProfileSettingsPage = () => {
    return (
        <PageContainer>
            <ProfileBaseInfo />
            <CompanyInfo />
        </PageContainer>
    );
};

export default ProfileSettingsPage;