import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { memo } from "react";
import { Box, Typography } from "@mui/material";
import DataShortage from "./DataShortage";
import { SOURCE_CAREER_SITE, SOURCE_HEADHUNTER, SOURCE_HEADHUNTER_EXTENSION, SOURCE_LINKEDIN_EXTENSION, SOURCE_TAPHR, sourcesStorage } from "../../utils/manageSources";

const formatNumber = (value) => {
    if (value >= 1000) {
        return `${value / 1000}K`;
    }
    return value;
};

// CUSTOM LEGEND COMPONENT
const CustomLegend = (props) => {
    const { payload } = props; // array of legend items
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "center",
                gap: 2, // spacing between legend items
                flexWrap: "wrap",
                margin: "24px auto 0 auto",
            }}
        >
            {payload.map(
                (entry, index) => (
                    (
                        <Box
                            key={`legend-item-${index}`}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                            }}
                        >
                            {/* Square with border-radius: 4px */}
                            <Box
                                sx={{
                                    width: 14,
                                    height: 14,
                                    backgroundColor: entry.color,
                                    borderRadius: "4px",
                                }}
                            />
                            <Typography
                                variant="body2"
                                sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: 16,
                                    lineHeight: "20px",
                                }}
                            >
                                {entry.value}
                            </Typography>
                        </Box>
                    )
                )
            )}
        </Box>
    );
};

const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || payload.length === 0) return null;

    // Sort payload to process sources in order (source1, source2, source5)
    const sortedPayload = [...payload].sort(
        (a, b) => Number(a.dataKey.slice(6)) - Number(b.dataKey.slice(6))
    );

    let prevValue = 0; // Store previous cumulative value
    return (
        <Box
            sx={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Typography sx={{ fontWeight: 600 }}>
                {payload[0].payload.label}
            </Typography>
            {sortedPayload.map((entry, index) => {
                const rawValue =
                    entry.payload[`${entry.dataKey}_original`] || 0;
                const difference = entry.value - prevValue; // Calculate incremental difference
                prevValue = entry.value; // Update previous cumulative value

                return (
                    <Typography key={index} sx={{ color: entry.color }}>
                        {entry.name}: {difference}
                    </Typography>
                );
            })}
        </Box>
    );
};

// Your color map
const sourceColors = {
    1: "rgba(149, 105, 217, 1)", // e.g. color for sourceId=1
    2: "rgba(163, 181, 222, 1)", // e.g. color for sourceId=2
    3: "rgba(237, 201, 122, 1)", // e.g. color for sourceId=3
    4: "rgb(107, 181, 180)", // e.g. color for sourceId=4
};

// Helper: flatten your API data into a chart-friendly array for stages.
// Initial output: { label: "StageName", source1: 5, source2: 3, ... }
function buildChartDataStages(data) {
    const chartDataMap = new Map();
    const namesToSources = {};

    for (const vacancy of data) {
        for (const stage of vacancy.stages) {
            let row = chartDataMap.get(stage.name);
            if (!row) {
                row = { label: stage.name };
                chartDataMap.set(stage.name, row);
            }
            // Accumulate counts by source.
            for (const acc of stage.accepted) {
                const sourceName = sourcesStorage[acc.sourceId] || sourcesStorage.unknown;

                if (!(sourceName in namesToSources)) {
                    namesToSources[sourceName] = acc.sourceId;
                }

                const key = `source${namesToSources[sourceName]}`;
                row[key] = (row[key] || 0) + acc.count;
            }
        }
    }

    const chartData = Array.from(chartDataMap.values());

    // Transform each row into cumulative values.
    for (const row of chartData) {
        const sourceKeys = Object.keys(row)
            .filter((key) => key.startsWith("source"))
            .sort((a, b) => Number(a.slice(6)) - Number(b.slice(6)));
        let cumulative = 0;
        for (const key of sourceKeys) {
            cumulative += row[key];
            row[key] = cumulative;
        }
    }
    return {chartData, namesToSources};
}

// Helper: flatten your API data into a chart-friendly array for refusals.
// Here we group refusals by reason (using a fallback) and store counts under "refusals".
function buildChartDataRefusals(data) {
    const chartDataMap = new Map();
    const namesToSources = {};

    for (const vacancy of data) {
        for (const stage of vacancy.stages) {
            for (const sources of stage.declined) {
                for (const refusal of sources.reasons) {
                    const reason = refusal.reason || "Не указана";
                    let row = chartDataMap.get(reason);
                    if (!row) {
                        row = { label: reason };
                        chartDataMap.set(reason, row);
                    }

                    const sourceName = sourcesStorage[sources.sourceId] || sourcesStorage.unknown;

                    // Ensure consistent mapping of source names to IDs.
                    if (!(sourceName in namesToSources)) {
                        namesToSources[sourceName] = sources.sourceId;
                    }
    
                    const key = `source${namesToSources[sourceName]}`;
                    row[key] = (row[key] || 0) + refusal.count;
                }
            }
        }
    }

    const chartData = Array.from(chartDataMap.values());

    // Transform each row into cumulative values.
    for (const row of chartData) {
        const sourceKeys = Object.keys(row)
            .filter((key) => key.startsWith("source"))
            .sort((a, b) => Number(a.slice(6)) - Number(b.slice(6)));
        let cumulative = 0;
        for (const key of sourceKeys) {
            cumulative += row[key];
            row[key] = cumulative;
        }
    }
    return {chartData, namesToSources};
}

const CustomYAxisTick = ({ x, y, payload }) => {
    return (
        <text
            x={x}
            y={y}
            dy={4} // adjust as needed to vertically center the label
            textAnchor="end"
            fill="#666"
            style={{ whiteSpace: "nowrap", fontSize: 16 }}
        >
            {payload.value}
        </text>
    );
};

// Helper: get the cumulative count for a given label.
const getStageInfo = (label, chartData) => {
    const stage = chartData.find((item) => item.label === label);
    if (!stage) return 0;

    // Find the maximum value among all sources dynamically
    return Object.keys(stage)
        .filter((key) => key.startsWith("source")) // Get all `sourceX` keys
        .map((key) => stage[key] || 0) // Extract values, default to 0
        .reduce((max, value) => Math.max(max, value), 0); // Get the max value
};

const getList = (mappedSources) => {
    const list = [];
    if (mappedSources[sourcesStorage[SOURCE_LINKEDIN_EXTENSION]] === SOURCE_LINKEDIN_EXTENSION) {
        console.log(mappedSources[sourcesStorage[SOURCE_LINKEDIN_EXTENSION]])
        list.push(SOURCE_LINKEDIN_EXTENSION);
    }
    if (mappedSources[sourcesStorage[SOURCE_HEADHUNTER_EXTENSION]] === SOURCE_HEADHUNTER_EXTENSION || list.length === 0) {
        list.push(SOURCE_HEADHUNTER_EXTENSION);
    }
    list.push(SOURCE_CAREER_SITE);
    list.push(SOURCE_HEADHUNTER);
    list.push(SOURCE_TAPHR);
    return list;
}

const HBarDrawer = memo(({ data, isStages }) => {
    const {chartData, namesToSources : mappedSources} = isStages
        ? buildChartDataStages(data)
        : buildChartDataRefusals(data);

    const tickHeight = 24;
    const gapHeight = 16; // adjust as needed
    const containerHeight = Math.max(
        400,
        chartData.length * tickHeight + (chartData.length - 1) * gapHeight
    );
    const createMappedSourcesList = getList(mappedSources);

    return chartData.length > 0 ? (
        <>
            <Box sx={{ display: "flex", gap: "48px", mb: 2 }}>
                {isStages ? (
                    <>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "rgba(116, 116, 115, 1)",
                                    mb: 1,
                                }}
                            >
                                Всего кандидатов
                            </Typography>
                            <Box
                                sx={{
                                    fontSize: "24px",
                                    lineHeight: "28px",
                                    fontWeight: 500,
                                    color: "rgba(9, 11, 8, 1)",
                                }}
                            >
                                {getStageInfo("Новый", chartData)}
                            </Box>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "rgba(116, 116, 115, 1)",
                                    mb: 1,
                                }}
                            >
                                Вышли на работу
                            </Typography>
                            <Box
                                sx={{
                                    fontSize: "24px",
                                    lineHeight: "28px",
                                    fontWeight: 500,
                                    color: "rgba(9, 11, 8, 1)",
                                }}
                            >
                                {getStageInfo("Принял оффер", chartData)}
                            </Box>
                        </Box>
                    </>
                ) : (
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "rgba(116, 116, 115, 1)",
                                mb: 1,
                            }}
                        >
                            Всего отказов
                        </Typography>
                        <Box
                            sx={{
                                fontSize: "24px",
                                lineHeight: "28px",
                                fontWeight: 500,
                                color: "rgba(9, 11, 8, 1)",
                            }}
                        >
                            {
                                // Sum total refusals across all reasons.
                                chartData.reduce((acc, row) => {
                                    return (
                                        acc +
                                        (Object.keys(row)
                                            .filter((key) =>
                                                key.startsWith("source")
                                            ) // Get all source keys dynamically
                                            .map((key) => row[key] || 0) // Extract values
                                            .reduce(
                                                (max, value) =>
                                                    Math.max(max, value),
                                                0
                                            ) || 0) // Get max per row
                                    );
                                }, 0)
                            }
                        </Box>
                    </Box>
                )}
            </Box>
            <Box sx={{ width: "100%", height: containerHeight }}>
                <ResponsiveContainer width="100%" minHeight="100%">
                    <BarChart
                        data={chartData}
                        layout="vertical"
                        margin={{ top: 20, right: 30, left: 24, bottom: 24 }}
                        barCategoryGap="8%"
                    >
                        <CartesianGrid
                            stroke="rgba(228, 228, 228, 1)"
                            strokeDasharray="0"
                            horizontal={false}
                            vertical
                        />
                        <XAxis
                            type="number"
                            tickFormatter={formatNumber}
                            axisLine={false}
                            tickLine={false}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend
                            verticalAlign="bottom"
                            content={<CustomLegend />}
                        />
                        {createMappedSourcesList
                            .filter((sourceId) => sourceId !== "unknown") // Exclude "unknown"
                            .sort((a, b) => Number(b) - Number(a)) // Sort in descending order
                            .map((sourceId, index) => {
                                const sourceKey = `source${sourceId}`;
                                return (
                                    <Bar
                                        key={sourceId}
                                        dataKey={sourceKey}
                                        fill={
                                            sourceColors[
                                                Object.keys(sourceColors)
                                                    .length - index
                                            ] || "rgba(200, 200, 200, 1)"
                                        } // Default gray if missing
                                        name={
                                            sourcesStorage[sourceId] ||
                                            "Неизвестный источник"
                                        }
                                        barSize={24}
                                        yAxisId={sourceId}
                                        radius={[4, 4, 4, 4]}
                                    />
                                );
                            })}
                        {createMappedSourcesList
                            .filter((sourceId) => sourceId !== "unknown") // Exclude "unknown"
                            .sort((a, b) => Number(b) - Number(a)) // Sort in descending order
                            .map((sourceId) => (
                                <YAxis
                                    key={`yAxis-${sourceId}`}
                                    dataKey="label"
                                    type="category"
                                    width={180}
                                    axisLine
                                    tickLine={false}
                                    yAxisId={sourceId}
                                    tick={
                                        sourceId === SOURCE_TAPHR
                                            ? CustomYAxisTick
                                            : undefined
                                    }
                                    hide={sourceId !== SOURCE_TAPHR}
                                />
                            ))}
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </>
    ) : (
        <DataShortage />
    );
});

export default HBarDrawer;
