import { useState } from "react";
import { Snackbar, Alert, Typography, Box } from "@mui/material";
import { CloseIcon } from "../../svgComponents";

const ErrorAlert = ({ handleClose, message, title, additionInformation }) => {
    return (
        <Snackbar
            open={true}
            autoHideDuration={5000} // Auto hides after 5s (adjustable)
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }} // Centered horizontally, bottom position
            sx={{ marginTop: "88px", marginRight: "22px" }} // Ensures margin-bottom of 89px
        >
            <Alert
                severity="error"
                onClose={handleClose}
                sx={{
                    width: "400px",
                    backgroundColor: "rgba(255, 243, 242, 1)",
                    border: "1px solid rgba(255, 201, 200, 1)",
                    borderRadius: "4px",
                    boxShadow: "0px 5px 15px 0px rgba(149, 157, 165, 0.2)",
                    padding: "16px",
                    "& .MuiAlert-action": {
                        padding: 0,
                        width: 20,
                        minWidth: 20,
                        marginRight: 0,
                    },
                    "& .MuiAlert-message": {
                        overflow: "hidden",
                    },
                }}
                icon={
                    <CloseIcon
                        sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: "rgba(198, 46, 37, 1)",
                            borderRadius: "50%",
                        }}
                        svgSx={{
                            width: 9,
                            height: 9,
                            color: "white",
                        }}
                    />
                }
                action={
                    <CloseIcon
                        sx={{
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                        }}
                        onClick={handleClose}
                        svgSx={{
                            width: 18,
                            height: 18,
                            color: "rgba(121, 128, 136, 1)",
                            hoverColor: "rgba(121, 128, 136, 0.75)",
                            pressedColor: "rgba(121, 128, 136, 0.5)",
                        }}
                    />
                }
            >
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "rgba(46, 64, 82, 1)",
                            mb: 1,
                        }}
                    >
                        {title || "Ошибка"}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "rgba(46, 64, 82, 1)",
                            textWrap: "wrap",
                        }}
                    >
                        {message || "Неопознанная ошибка"}
                    </Typography>
                    {additionInformation?.target && additionInformation?.name && (
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "18px",
                                color: "rgba(46, 64, 82, 1)",
                                textWrap: "wrap",
                            }}
                        >
                            {additionInformation?.target + ": "}
                            <Typography
                                onClick={
                                    additionInformation?.clickable
                                        ? additionInformation?.onClick
                                        : null
                                }
                                component="span"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    cursor: additionInformation?.clickable ? "pointer" : "default",
                                    color: additionInformation?.clickable
                                        ? "rgba(31, 107, 197, 1)"
                                        : "rgba(46, 64, 82, 1)",
                                    textWrap: "wrap",
                                    textDecoration: additionInformation?.clickable ? "underline" : "none",
                                }}
                            >
                                {additionInformation?.name}
                            </Typography>
                        </Typography>
                    )}
                </Box>
            </Alert>
        </Snackbar>
    );
};
export default ErrorAlert;
