import {
    Box,
    Card,
    CardContent,
    CardActions,
    Typography,
    Divider,
} from "@mui/material";
import { useState, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    deleteTestTemplate,
    fetchTestTemplates,
} from "../../../services/testApi";
import {
    CircularLoading,
    CustomToolTip,
    Pagination,
} from "../../tapHrStyledComponents";
import { CustomError } from "../../styledComponents/CustomErrors";
import TemplateChip from "./TemplateChip";
import { TrashIcon } from "../../svgComponents";
import TestDetailsModal from "../../common/modalWindows/TestDetailsModal";
import CustomizedDeleteModal from "../../common/modalWindows/CustomizedDeleteModal";

const TestTemplatesHolder = ({ filter, addTest, testsStorage }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = useRef(13);
    const queryTestKey = useRef(["testTemplates"]);
    const [showDetails, setShowDetails] = useState(false);
    const [toDelete, setToDelete] = useState(null);
    const queryClient = useQueryClient();

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Fetch tests from your mock API
    const {
        data: testsData,
        isLoading: isTestLoading,
        isError: isTestError,
        error: testError,
    } = useQuery(
        [queryTestKey.current, currentPage, rowsPerPage.current, filter],
        () =>
            fetchTestTemplates({
                page: currentPage,
                perPage: rowsPerPage.current,
                name: filter.name,
                template: filter.template,
                direction: filter.direction?.id,
            }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const deleteMutation = useMutation(deleteTestTemplate, {
        onSettled: () => {
            queryClient.invalidateQueries([queryTestKey.current, currentPage, rowsPerPage.current, filter]);
        },
        onMutate: (id) => {
            queryClient.setQueryData(
                [queryTestKey.current, currentPage, rowsPerPage.current, filter], (oldData) => {
                    return {
                        data: oldData.data.filter((test) => test.id !== id),
                    };
                }
            );
        },
        onError: (error, variables, context) => {
            queryClient.setQueryData(
                [queryTestKey.current, currentPage, rowsPerPage.current, filter],
                context.previousData
            );
        }
    });

    const handleDelete = (id) => {
        deleteMutation.mutate(id);
        setToDelete(null);
    };

    if (isTestLoading || deleteMutation.isLoading) {
        return <CircularLoading sx={{ height: "80vh" }} />;
    }

    if (isTestError || deleteMutation.isError) {
        return <CustomError error={testError?.message || deleteMutation?.error?.message || "Неопознанная ошибка"} />;
    }

    // Our tests array
    const tests = testsData?.data || [];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "36px",
                justifyContent: "space-between",
                height: "100%",
            }}
        >
            {/* Cards Container */}
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns:
                        "repeat(auto-fill, minmax(300px, 1fr))",
                    gap: "8px",
                }}
            >
                {tests.map((test) => {
                    const isAdded = testsStorage[test.id];
                    return (
                        <Card
                            key={test.id}
                            variant="outlined"
                            sx={{
                                borderRadius: "12px",
                                gap: "16px",
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: "8px",
                                alignItems: "center",
                                padding: "16px",
                                border: "1px solid rgba(248, 249, 249, 1)",
                                boxShadow:
                                    "0px 5px 50px 0px rgba(0, 0, 0, 0.05)",
                            }}
                        >
                            <CardContent sx={{ width: "100%" }}>
                                {/* Test Name */}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: "18px",
                                        lineHeight: "22px",
                                        fontWeight: 500,
                                        color: "rgba(0, 0, 0, 1)",
                                        mb: 2,
                                    }}
                                >
                                    {test.name}
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        color="text.secondary"
                                        sx={{
                                            mt: 0.5,
                                            display: "block",
                                            fontSize: "14px",
                                            lineHeight: "18px",
                                            color: "rgba(162, 162, 162, 1)",
                                        }}
                                    >
                                        {test.questions_count} вопросов
                                    </Typography>
                                </Typography>

                                {/* Template Type Chip */}
                                <TemplateChip template={test.company_id} />
                            </CardContent>

                            <Divider
                                sx={{
                                    backgroundColor: "rgba(241, 242, 243, 1)",
                                    width: "100%",
                                }}
                            />

                            <CardActions
                                sx={{
                                    display: "flex",
                                    gap: "8px",
                                    padding: "0px!important",
                                    width: "100%",
                                }}
                            >
                                {/* Add/Remove Button */}
                                <Box
                                    component="button"
                                    sx={{
                                        flex: 1,
                                        border: "none",
                                        backgroundColor: isAdded
                                            ? "rgba(255, 59, 48, 1)"
                                            : "rgba(46, 64, 82, 1)",
                                        borderRadius: "8px",
                                        padding: "13px 24.5px",
                                        boxShadow: "none",
                                        color: "white",
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor: isAdded
                                                ? "rgba(228, 53, 43, 1)"
                                                : "rgba(29, 40, 52, 1)",
                                        },
                                        "&:active": {
                                            backgroundColor: isAdded
                                                ? "rgba(198, 46, 37, 1)"
                                                : "rgba(21, 29, 37, 1)",
                                        },
                                    }}
                                    onClick={() => addTest(test?.id)}
                                >
                                    {isAdded ? "Убрать" : "Добавить"}
                                </Box>

                                {/* Details Button */}
                                <Box
                                    component="button"
                                    sx={{
                                        flex: 1,
                                        border: "none",
                                        backgroundColor: "transparent",
                                        borderRadius: "8px",
                                        padding: "13px 12px",
                                        boxShadow: "none",
                                        color: "rgba(46, 64, 82, 1)",
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        cursor: "pointer",
                                        margin: "0!important",
                                        textWrap: "nowrap",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(242, 243, 243, 1)",
                                        },
                                        "&:active": {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                    onClick={() => setShowDetails(test.id)}
                                >
                                    Детали теста
                                </Box>

                                {/* Delete Icon */}
                                {test.company_id && (
                                    <CustomToolTip
                                        title="Удалить"
                                        placement="top"
                                    >
                                        <Box
                                            sx={{ margin: "0!important" }}
                                            onClick={() => setToDelete(test.id)}
                                        >
                                            <TrashIcon
                                                sx={{
                                                    width: 46,
                                                    borderRadius: "8px",
                                                    height: 46,
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            "rgba(242, 242, 242, 1)",
                                                    },
                                                    "&:active": {
                                                        backgroundColor:
                                                            "transparent",
                                                    },
                                                }}
                                                svgSx={{
                                                    width: 24,
                                                    height: 24,
                                                    color: "rgba(173, 181, 189, 1)",
                                                }}
                                            />
                                        </Box>
                                    </CustomToolTip>
                                )}
                            </CardActions>
                        </Card>
                    );
                })}
            </Box>

            {/* Test Details Modal */}
            {showDetails && (
                <TestDetailsModal
                    id={showDetails}
                    handleClose={() => setShowDetails(false)}
                />
            )}

            {/* Delete Modal */}
            {toDelete && (
                <CustomizedDeleteModal
                    title="Вы уверены, что хотите удалить?"
                    subtitle="Данный тест будет удален навсегда"
                    handleDelete={() => handleDelete(toDelete)}
                    handleClose={() => setToDelete(null)}
                />
            )}

            {/* Pagination */}
            <Box>
                <Pagination
                    count={testsData?.meta?.lastPage || 1}
                    page={currentPage}
                    onChange={handlePageChange}
                />
            </Box>
        </Box>
    );
};

export default TestTemplatesHolder;
