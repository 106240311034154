import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/themes/fantasy/index.css"; // Optional theme
import { Box } from "@mui/material";

const VideoPlayer = ({ src }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && !playerRef.current) {
            playerRef.current = videojs(videoRef.current, {
                controls: true,
                autoplay: false,
                preload: "auto",
                responsive: true,
                fluid: true,
            });
        }

        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.src({ src, type: "video/mp4" });
            playerRef.current.load(); // Force reload
        }
    }, [src]);

    return (
        <Box sx={{ width: "100%", maxWidth: "640px", margin: "auto", backgroundColor: "black" }}>
            <video
                ref={videoRef}
                className="video-js vjs-theme-fantasy"
                style={{ backgroundColor: "black" }}
            >
                <source src={src} type="video/mp4" />
                <source src={src} type="video/webm" />
                <p className="vjs-no-js">
                    Что бы посмотреть видео, пожалуйста, включите JavaScript и обновите
                    браузер до
                    <a href="https://videojs.com/html5-video-support/" target="_blank">
                        поддерживаемого HTML5 видео браузера
                    </a>.
                </p>
            </video>
        </Box>
    );
};

export default VideoPlayer;
