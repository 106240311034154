import { memo, useCallback } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { ImageIcon, ListIcon } from "../../svgComponents";
import QuestionPreview from "./QuestionPreview";
import CustomSelect from "../../styledComponents/CustomSelect";
import OptionsInputList from "./OptionsInputList";
import CustomNumericInput from "../../styledComponents/CustomNumericInput";

const CheckboxComponent = memo(({ types, data, isActive, order, handleQuestion, handleQuestionField }) => {

    const handleQuestionType = useCallback((value) => {
        handleQuestion(order - 1, value);
    }, [handleQuestion, order]);

    const handleOptions = useCallback((newList) => {
        handleQuestionField(order - 1, "answers", newList);
    }, [handleQuestionField, order]);

    const handleNumeric = useCallback((value) => {
        const numericValue = Number(value);
        if (isNaN(numericValue) || numericValue < 0) return;
        handleQuestionField(order - 1, "weight", numericValue);
    }, [handleQuestionField, order]);

    return isActive ? (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Box sx={{ display: "flex", gap: "16px", width: "50%"}}>
                    {/* Тип ответа */}
                    <CustomSelect 
                    label="Тип ответа"
                    value={data.type} 
                    onChange={handleQuestionType}
                    options={types.names}
                    icons={types.icons}
                />
                </Box>
                {data.weight ? (
                    <Box sx={{ display: "flex", gap: "4px", alignItems: "flex-end" }}>
                        <CustomNumericInput sx={{border: "1px solid rgba(226, 228, 231, 1)", borderRadius: "8px"}} value={data.weight} onChange={handleNumeric}/>
                        <Typography sx={{fontSize: "14px", lineHeight: "18px", color: "rgba(21, 24, 19, 1)"}}>
                            балл
                        </Typography>
                    </Box>
                ) : null}
            </Box>
            <OptionsInputList
                options={data.answers}
                isRadio={false}
                updateOptions={handleOptions}
            />
        </>
    ) : (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "16px",
            }}
        >
            <QuestionPreview
                title={`Вопрос №${order}`}
                subtitle={data.text}
            />
            <Divider
                sx={{
                    width: "100%",
                    backgroundColor: "rgba(241, 242, 243, 1)",
                }}
            />
            <QuestionPreview
                title="Тип ответа"
                icon={
                    <ListIcon
                        sx={{ width: 20, height: 20 }}
                        svgSx={{
                            width: 20,
                            height: 20,
                            color: "rgba(173, 181, 189, 1)",
                        }}
                    />
                }
                subtitle={"Несколько из списка"}
                sx={{ width: "314px" }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                }}
            >
                {data.answers.map((answer, index) => (
                    <Box
                        key={index}
                        sx={{
                            color: answer.is_correct
                                ? "rgba(155, 162, 169, 1)"
                                : "rgba(173, 181, 189, 1)",
                            display: "flex",
                            gap: "8px",
                        }}
                    >
                        <Typography
                            component={"span"}
                            sx={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                fontWeight: answer.is_correct ? 600 : 400,
                                textWrap: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "100%",
                            }}
                        >
                            {`${index + 1}. ${answer.text}`}
                        </Typography>
                        {answer.image && (
                            <Typography
                                component={"span"}
                                sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    px: "4px",
                                    borderRadius: "4px",
                                    border: "1px solid rgba(226, 228, 231, 1)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "18px",
                                    color: "rgba(173, 181, 189, 1)",
                                    gap: "4px",
                                }}
                            >
                                <ImageIcon
                                    sx={{ width: 14, height: 14 }}
                                    svgSx={{
                                        width: 14,
                                        height: 14,
                                        color: "rgba(173, 181, 189, 1)",
                                    }}
                                />
                                Фото
                            </Typography>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
});

export default CheckboxComponent;
