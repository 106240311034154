import axios from 'axios';
import { errorCatch } from './error';
import { responseWrapper } from '../services/responseWrapper';
const API_URL = process.env.REACT_APP_API_URI;

const options = {
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
};

const axiosClassic = axios.create(options);

let isRefreshing = false; // Флаг для отслеживания процесса обновления токена
let refreshSubscribers = []; // Массив для хранения запросов, ожидающих обновления токена

// Функция для подписки на обновление токена
const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
};

// Функция для уведомления подписчиков о новом токене
const onRefreshed = (newToken) => {
	refreshSubscribers.forEach((cb) => cb(newToken));
	refreshSubscribers = [];
};

// Функция для обновления токена
const refreshAccessToken = async () => {
	const refreshToken = localStorage.getItem('refresh_token');
	if (!refreshToken) throw new Error('Отсутствует refresh token');

	try {
		const response = await axios.post(`${options.baseURL}/api/auth/refresh`, null, {
			headers: {
				Authorization: `Bearer ${refreshToken}`,
			},
		});
		const result = responseWrapper(response);
		const { access_token, refresh_token } = result?.data;

		// Сохраняем новые токены
		localStorage.setItem('access_token', access_token);
		localStorage.setItem('refresh_token', refresh_token);

		return access_token;
	} catch (error) {
		throw error;
	}
};

// Функция для инициализации без автоматического выбора manager_id
const initFetchManagerId = async () => {
	// Проверяем наличие токена и ждем его обновления при необходимости
	if (!localStorage.getItem('access_token')) {
		await new Promise((resolve) => {
			const checkToken = setInterval(() => {
				if (localStorage.getItem('access_token')) {
					clearInterval(checkToken);
					resolve();
				}
			}, 100); // Проверка каждые 100 мс
		});
	}
};

// Запрашиваем manager_id при запуске (только инициализация токена)
initFetchManagerId();

// Перехватчик для добавления access_token и manager_id к каждому запросу
axiosClassic.interceptors.request.use(
	async (config) => {
		const accessToken = localStorage.getItem('access_token');
		const managerId = localStorage.getItem('manager_id'); // Получаем manager_id из localStorage, если он был установлен

		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}

		if (managerId) {
			config.headers['X-Manager-Id'] = managerId; // Устанавливаем X-Manager-Id, если он доступен
		}

		return config;
	},
	(error) => Promise.reject(error)
);

// Перехватчик для обработки истекших токенов
axiosClassic.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;

		// Если ошибка из-за истечения токена, запрос не был повторным, и это не запрос авторизации
		if (error.response?.status === 401 && !originalRequest._retry && originalRequest.url !== '/api/auth/login') {
			originalRequest._retry = true;

			if (!isRefreshing) {
				isRefreshing = true;

				try {
					const newAccessToken = await refreshAccessToken();
					isRefreshing = false;
					onRefreshed(newAccessToken); // Уведомляем все ожидающие запросы
					return axiosClassic(originalRequest);
				} catch (refreshError) {
					console.error('Ошибка при обновлении токена:', refreshError);
					localStorage.removeItem('access_token');
					localStorage.removeItem('refresh_token');
					// window.location.href = '/login'; // Перенаправляем только если не удалось обновить токен
					return Promise.reject(refreshError);
				}
			}

			// Если уже идет процесс обновления токена, ждем его завершения
			return new Promise((resolve) => {
				subscribeTokenRefresh((newToken) => {
					originalRequest.headers.Authorization = `Bearer ${newToken}`;
					resolve(axiosClassic(originalRequest));
				});
			});
		}

		// Обработка других ошибок через `errorCatch`, чтобы избежать перезагрузки страницы
		errorCatch(error);
		return Promise.reject(error);
	}
);

export default axiosClassic;
