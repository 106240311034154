import { Modal, Box, Typography } from "@mui/material";
import { TrashIcon, CloseIcon, AiIconInverted } from "../../svgComponents";
import CustomInput from "../../styledComponents/CustomInput";
import { useState } from "react";

const StyledButton = ({ text, onClick = () => {}, isDangerous }) => {
    return (
        <Box
            component="button"
            onClick={onClick}
            sx={{
                borderRadius: "8px",
                border: isDangerous ? "none" : "1px solid rgba(46, 64, 82, 1)",
                padding: isDangerous ? "13px 20px" : "12px 20px",
                color: isDangerous ? "white" : "rgba(46, 64, 82, 1)",
                backgroundColor: "transparent",
                background: isDangerous
                    ? "linear-gradient(90deg, #392D69 0%, #B57BEE 100%)"

                    : "transparent",
                fontSize: "16px",
                lineHeight: "20px",
                cursor: "pointer",
                outline: "none",
                transition: isDangerous ? "0.5s" : "none",
                backgroundSize: isDangerous ? "200% auto" : "auto",
                "&:hover": {
                    backgroundColor: "transparent",
                    background: isDangerous
                        ? "linear-gradient(90deg, #B57BEE 0%, #392D69 100%)"
                        : "transparent",
                    backgroundPosition: isDangerous
                        ? "right center"
                        : "initial",
                },
                "&:active": {
                    backgroundColor: "transparent",
                },
                flex: 1,
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {isDangerous ? (
                <AiIconInverted
                    sx={{ width: 20, height: 20 }}
                    svgSx={{ width: 20, height: 20 }}
                />
            ) : null}
            {text}
        </Box>
    );
};

export const GenerateVacancyDescriptionModal = ({
    title,
    subtitle,
    handleGenerate,
    handleClose,
}) => {
    const [text, setText] = useState("");

    return (
        <Modal open={true} onClose={handleClose}>
            <Box
                onClick={(e) => e.stopPropagation()}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    border: "none !important",
                    borderRadius: "8px",
                    maxWidth: 491,
                    maxHeight: 386,
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: "24px",
                    outline: "none",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <TrashIcon
                        sx={{
                            width: 48,
                            height: 48,
                            backgroundColor: "rgba(255, 230, 229, 1)",
                            border: "8px solid rgba(255, 243, 242, 1)",
                            borderRadius: "50%",
                        }}
                        svgSx={{
                            width: 18,
                            height: 20,
                            color: "rgba(228, 53, 43, 1)",
                        }}
                    />
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 20,
                            height: 20,
                            color: "rgba(0, 0, 0, 0.5)",
                            hoverColor: "rgba(0, 0, 0, 0.75)",
                            pressedColor: "rgba(0, 0, 0, 1)",
                        }}
                    />
                </Box>
                <Typography
                    sx={{
                        fontWeight: "600",
                        color: "rgba(0, 0, 0, 1)",
                        fontSize: "20px",
                        lineHeight: "24px",
                    }}
                >
                    {title || "Без названия"}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: "400",
                        color: "rgba(116, 116, 115, 1)",
                        fontSize: "16px",
                        lineHeight: "20px",
                        mb: 1,
                    }}
                >
                    {subtitle || "Без описания"}
                </Typography>
                <CustomInput
                    placeholder="Опишите вакансию несколькими словами..."
                    value={text}
                    onChange={(value) => setText(value)}
                    multiline={true}
                    rows={4}
                />
                <Box
                    sx={{ display: "flex", gap: "16px", alignItems: "center" }}
                >
                    <StyledButton text="Отменить" onClick={handleClose} />
                    <StyledButton
                        text="Сгенерировать"
                        onClick={() => {
                            if (!text) return;
                            handleGenerate(text);
                        }}
                        isDangerous={true}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default GenerateVacancyDescriptionModal;
