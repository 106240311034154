import React, { useState } from "react";
import EventModal from "./EventModal";

const CalendarControls = ({ setSlotsOpen }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <div className="calendar-controls">
            {/* <div
                style={{
                    marginRight: '16px',
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    padding: "0 16px",
                    display: "flex",
                    alignItems: "center",
                    border: '1px solid #D5D7DA'

                }}
            >
                <svg style={{ marginRight: '8px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.2915 9.58464C2.2915 13.6117 5.55609 16.8763 9.58317 16.8763C13.6102 16.8763 16.8748 13.6117 16.8748 9.58464C16.8748 5.55756 13.6102 2.29297 9.58317 2.29297C5.55609 2.29297 2.2915 5.55756 2.2915 9.58464ZM9.58317 18.1263C4.86574 18.1263 1.0415 14.3021 1.0415 9.58464C1.0415 4.8672 4.86574 1.04297 9.58317 1.04297C14.3006 1.04297 18.1248 4.8672 18.1248 9.58464C18.1248 11.7184 17.3424 13.6694 16.0489 15.1665L18.7751 17.8927C19.0192 18.1368 19.0192 18.5325 18.7751 18.7766C18.531 19.0207 18.1353 19.0207 17.8912 18.7766L15.165 16.0504C13.668 17.3439 11.7169 18.1263 9.58317 18.1263Z" fill="#ADB5BD" />
                </svg>
                <input
                    className="custom-input"
                    type="text"
                    placeholder="Поиск"
                    style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        backgroundColor: "transparent",
                        color: "#000",
                        fontSize: "16px",
                        height: '46px',
                    }}
                />
            </div> */}
            <div
                className="create-dropdown"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
            >
                <button className="create-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4911_33095)">
                            <path d="M10 6.66797V13.3346M13.3333 10.0013H6.66667M10 18.3346C14.6024 18.3346 18.3333 14.6037 18.3333 10.0013C18.3333 5.39893 14.6024 1.66797 10 1.66797C5.39763 1.66797 1.66667 5.39893 1.66667 10.0013C1.66667 14.6037 5.39763 18.3346 10 18.3346Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4911_33095">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    Создать
                </button>
                {isDropdownOpen && (
                    <div className="dropdown-menu">
                        <div onClick={() => setIsModalOpen(true)} className="dropdown-item">Событие</div>
                        <div onClick={() => setSlotsOpen(true)} className="dropdown-item">Слоты</div>
                    </div>
                )}
            </div>
            <EventModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
    );
};

export default CalendarControls;
