import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import {
    QuestionIcon,
    BookIcon,
    CloseIcon,
    QuestionIcon2,
    BookIcon2,
} from "../../svgComponents";
import CustomInput from "../../styledComponents/CustomInput";
import CustomSelect from "../../styledComponents/CustomSelect";
import CustomRadioList from "../../styledComponents/CustomRadioList";
import QuestionTemplatesHolder from "./QuestionTemplatesHolder";
import TestTemplatesHolder from "./TestTemplatesHolder";
import { fetchCategories } from "../../../services/categoriesApi";
import CustomAutoComplete from "../../styledComponents/CustomAutoComplete";
import {
    TEST_STORAGE_AUDIO,
    TEST_STORAGE_CHECKBOX,
    TEST_STORAGE_FILE,
    TEST_STORAGE_RADIO,
    TEST_STORAGE_TEXT,
    TEST_STORAGE_VIDEO,
} from "../../../utils/testTypesStorage";
import { CustomSearch } from "../../styledComponents/CustomSearch";

// 1 is question templates
// 2 is test templates

const templateOptions = [
    { id: 0, name: "Все" },
    { id: 1, name: "Системные" },
    { id: 2, name: "Не системный" },
];

const TemplatesDrawer = ({ open, onClose, setTest }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [testsStorage, setTestsStorage] = useState({});
    const [filter, setFilter] = useState({
        prevActiveTab: 1,
        name: "",
        template: 0,
        direction: null,
        theme: null,
        types: [],
    });
    const selectStyles = useRef({
        marginBottom: "24px",
        labelMarginBottom: "6px",
    });

    const inputStyles = useRef({
        marginBottom: "24px",
        border: "1px solid rgba(213, 215, 218, 1)",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px 0px rgba(10, 13, 18, 0.05)",
        width: "unset",
        flex: 1,
        backgroundColor: "white",
    });

    const handleNewQuestion = useCallback((question) => {
        setTest((prev) => ({
            ...prev,
            quizData: [...prev.quizData, question],
            active: prev.quizData.length,
        }));
    }, []);

    const handleName = useCallback((value) => {
        setFilter((prev) => ({ ...prev, name: value }));
    }, []);

    const handleTemplate = useCallback((value) => {
        setFilter((prev) => ({ ...prev, template: value }));
    }, []);

    const handleDirection = useCallback((value) => {
        setFilter((prev) => ({ ...prev, direction: value }));
    }, []);

    const handleTheme = useCallback((value) => {
        setFilter((prev) => ({ ...prev, theme: value }));
    }, []);

    const handleTypeField = useCallback((value) => {
        setFilter((prev) => ({
            ...prev,
            types: prev.types.includes(value)
                ? prev.types.filter((type) => type !== value)
                : [...prev.types, value],
        }));
    }, []);

    const handleNewTest = useCallback((testId) => {
        setTestsStorage((prev) => {
            if (prev[testId]) {
                return {
                    ...prev,
                    [testId]: false,
                };
            }
            return {
                ...prev,
                [testId]: true,
            };
        });
    }, []);

    useEffect(() => {
        setFilter((prev) => {
            if (prev.prevActiveTab !== activeTab) {
                return {
                    prevActiveTab: activeTab,
                    name: "",
                    template: 0,
                    direction: null,
                    types: [],
                };
            }
            return prev;
        });
    }, [activeTab]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => onClose(testsStorage)}
            PaperProps={{
                sx: {
                    backgroundColor: "rgba(248, 249, 249, 1)",
                    boxShadow: "0px 5px 50px 0px rgba(0, 0, 0, 0.1)",
                    scrollbarGutter: "stable",
                },
            }}
        >
            <Box
                sx={{
                    width: 1124,
                    p: 3,
                    display: "flex",
                }}
                role="presentation"
            >
                <Box
                    sx={{
                        width: 324,
                        p: "24px 24px 24px 0",
                        minHeight: "calc(100svh - 48px)",
                    }}
                >
                    <Box sx={{ mb: "128px" }}>
                        <Box
                            onClick={() => setActiveTab(1)}
                            component="button"
                            sx={{
                                display: "flex",
                                border: "none",
                                width: "100%",
                                borderRadius: "8px",
                                marginBottom: "16px",
                                gap: 1,
                                fontSize: 16,
                                lineHeight: "20px",
                                fontWeight: 600,
                                backgroundColor:
                                    activeTab === 1
                                        ? "rgba(232, 239, 228, 1)"
                                        : "transparent",
                                color:
                                    activeTab === 1
                                        ? "rgba(89, 157, 21, 1)"
                                        : "rgba(21, 24, 19, 1)",
                                "&:hover": {
                                    color: "rgba(89, 157, 21, 1)",
                                },
                                "&:hover path": {
                                    stroke:
                                        activeTab === 1
                                            ? "transparent"
                                            : "rgba(89, 157, 21, 1)",
                                },
                                cursor: "pointer",
                                padding: "15px 19px",
                            }}
                        >
                            {activeTab === 1 ? (
                                <QuestionIcon2
                                    sx={{
                                        width: 20,
                                        height: 20,
                                    }}
                                    svgSx={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: "rgba(89, 157, 21, 1)",
                                    }}
                                />
                            ) : (
                                <QuestionIcon
                                    sx={{
                                        width: 20,
                                        height: 20,
                                    }}
                                    svgSx={{
                                        width: 20,
                                        height: 20,
                                        color: "rgba(21, 24, 19, 1)",
                                    }}
                                />
                            )}
                            Вопросы
                        </Box>
                        <Box
                            onClick={() => setActiveTab(2)}
                            component="button"
                            sx={{
                                display: "flex",
                                border: "none",
                                width: "100%",
                                borderRadius: "8px",
                                gap: 1,
                                fontSize: 16,
                                lineHeight: "20px",
                                fontWeight: 600,
                                "&:hover": {
                                    color: "rgba(89, 157, 21, 1)",
                                },
                                "&:hover path": {
                                    stroke:
                                        activeTab === 2
                                            ? "transparent"
                                            : "rgba(89, 157, 21, 1)",
                                },
                                backgroundColor:
                                    activeTab === 2
                                        ? "rgba(232, 239, 228, 1)"
                                        : "transparent",
                                color:
                                    activeTab === 2
                                        ? "rgba(89, 157, 21, 1)"
                                        : "rgba(21, 24, 19, 1)",
                                cursor: "pointer",
                                padding: "15px 19px",
                            }}
                        >
                            {activeTab === 2 ? (
                                <BookIcon2
                                    sx={{
                                        width: 20,
                                        height: 20,
                                    }}
                                    svgSx={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: "rgba(89, 157, 21, 1)",
                                    }}
                                />
                            ) : (
                                <BookIcon
                                    sx={{
                                        width: 20,
                                        height: 20,
                                    }}
                                    svgSx={{
                                        width: 20,
                                        height: 20,
                                        color: "black",
                                    }}
                                />
                            )}
                            Тесты
                        </Box>
                    </Box>
                    <CustomSearch
                        placeholder="Поиск вопроса"
                        sx={inputStyles.current}
                        value={filter.name}
                        setValue={handleName}
                    />
                    <CustomSelect
                        label="Шаблоны"
                        value={filter.template}
                        options={templateOptions}
                        onChange={handleTemplate}
                        sx={selectStyles.current}
                    />
                    <Typography
                        sx={{
                            mb: "6px",
                            lineHeight: "20px",
                            fontSize: "16px",
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    >
                        Направление
                    </Typography>
                    <CustomAutoComplete
                        value={filter.direction}
                        setValue={handleDirection}
                        queryKeyStartingValue={"directions"}
                        fetchFunction={fetchCategories}
                        additional={{ is_theme: 0}}
                    />
                    {activeTab === 1 && (
                        <>
                            <Typography
                                sx={{
                                    mt: "24px",
                                    mb: "6px",
                                    lineHeight: "20px",
                                    fontSize: "16px",
                                    color: "rgba(21, 24, 19, 1)",
                                }}
                            >
                                Темы
                            </Typography>
                            <CustomAutoComplete
                                value={filter.theme}
                                setValue={handleTheme}
                                queryKeyStartingValue={"themes"}
                                fetchFunction={fetchCategories}
                                additional={{ is_theme: 1}}
                            />
                            <Typography
                                sx={{
                                    my: "24px",
                                    lineHeight: "20px",
                                    fontSize: "16px",
                                    color: "rgba(21, 24, 19, 1)",
                                }}
                            >
                                Тип вопроса
                            </Typography>
                            <CustomRadioList
                                defaultValue={filter.types}
                                isCheckBox={true}
                                isMultiInputs={true}
                                inputs={[
                                    {
                                        value: TEST_STORAGE_RADIO,
                                        text: "Один из списка",
                                    },
                                    {
                                        value: TEST_STORAGE_CHECKBOX,
                                        text: "Несколько из списка",
                                    },
                                    {
                                        value: TEST_STORAGE_VIDEO,
                                        text: "Видео ответ",
                                    },
                                    {
                                        value: TEST_STORAGE_AUDIO,
                                        text: "Аудио ответ",
                                    },
                                    {
                                        value: TEST_STORAGE_TEXT,
                                        text: "Текстовый ответ",
                                    },
                                    { value: TEST_STORAGE_FILE, text: "Файл" },
                                ]}
                                onClick={(value) => handleTypeField(value)}
                                multiInputs
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                    alignItems: "flex-start",
                                    pb: "148px",
                                }}
                            />
                        </>
                    )}
                </Box>
                <Box
                    sx={{
                        p: "24px",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: "24px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "30px",
                                lineHeight: "34px",
                                color: "rgba(0, 0, 0, 1)",
                                fontWeight: 600,
                            }}
                        >
                            Шаблоны вопросов и тестов
                        </Typography>
                        <CloseIcon
                            onClick={() => onClose(testsStorage)}
                            sx={{
                                width: 24,
                                height: 24,
                                cursor: "pointer",
                            }}
                            svgSx={{
                                width: 16,
                                height: 16,
                                color: "rgba(0, 0, 0, 0.5)",
                                hoverColor: "rgba(0, 0, 0, 0.75)",
                                pressedColor: "rgba(0, 0, 0, 1)",
                            }}
                        />
                    </Box>
                    {activeTab === 1 && (
                        <QuestionTemplatesHolder
                            filter={filter}
                            handleNewQuestion={handleNewQuestion}
                        />
                    )}
                    {activeTab === 2 && (
                        <TestTemplatesHolder
                            filter={filter}
                            addTest={handleNewTest}
                            testsStorage={testsStorage}
                        />
                    )}
                </Box>
            </Box>
        </Drawer>
    );
};

export default TemplatesDrawer;
