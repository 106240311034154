import React, { useState, useEffect, useCallback } from 'react';
import { Container, Box, Typography, Paper, IconButton, Tab, Tabs, CircularProgress } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { fetchVacancyById, updateVacanciesStatus } from '../../services/vacancyApi';
import { updateCandidateStage } from "../../services/candidatesApi";
import DescriptionTab from '../../components/vacancy/DescriptionTab';
import PublicationTab from '../../components/vacancy/PublicationTab';
import CareerChannelSettings from '../../components/vacancy/CareerChannelSettings';
import OverviewTab from '../../components/vacancy/OverviewTab';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateIcon, FilterIcon, GeolocationIcon, PencilIcon2, ProfileIcon2, TargetIcon, TextIcon, VacancyIcon, VacancyIcon2 } from '../../components/svgComponents';
import InterviewTab from '../../components/vacancy/InterviewTab';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // Горизонтальное троеточие
import PopUpWindow from '../../components/popUpWindow';
import { set } from 'date-fns';


const VacancyDetail = () => {
    const navigate = useNavigate()
    const { id: vacancyId } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const queryClient = useQueryClient();
    const location = useLocation();

    // Запрос данных вакансии
    const { data: vacancy, isFetching, isError, refetch } = useQuery
        (['vacancy', vacancyId],
            async () => {
                const kanbanData = queryClient.getQueryData(['KANBAN']);
                if (kanbanData && !kanbanData.updated) {
                    await updateCandidateStage(kanbanData.negotiationId, kanbanData.stageId);
                    queryClient.removeQueries(['KANBAN']);
                }
                const result = await fetchVacancyById(vacancyId);
                return result;
            },
            { refetchOnWindowFocus: false }
        );

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const tab = parseInt(params.get('tab'));

        if (tab >= 0 && tab <= 4) {
            setActiveTab(tab);
        }
    }, [location]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const archivateMutation = useMutation(updateVacanciesStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['vacancies'],
            });
        },
    });

    const handleVacancyAction = (action) => {
        if (action === 'archive') {
            archivateMutation.mutate({ status_id: 3, vacancy_ids: [vacancyId] }); 
            handleMenuClose();
            navigate('/'); 
            console.log('Архивировать');
        }
    };

    const handleMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleInvalidation = useCallback(() => {
        queryClient.invalidateQueries(['vacancy', vacancyId])
    }, [queryClient, vacancyId]);

    if (isFetching) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (isError) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography color="error">Ошибка при загрузке данных вакансии.</Typography>
            </Box>
        );
    }

    const handleEdit = () => {
        navigate(`/vacancy/edit/${vacancyId}`);
    }

    return (
        <Container maxWidth="none" sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
            <Paper
                elevation={0}
                sx={{
                    flex: 1,
                    borderRadius: 2,
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(90vh - 25px)',
                    padding: '24px',
                }}
            >
                <div>
                    <Box display="flex" alignItems="center" sx={{py: "6px", mb: 2}} >
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#16151C',
                            }}
                        >
                            {vacancy?.data.name}
                        </Typography>
                        <PencilIcon2
                            onClick={handleEdit}
                            sx={{width: 24, height: 24, cursor: 'pointer', marginLeft: '8px'}}
                            svgSx={{width: 18, height: 18, color: "white", backgroundColor: "rgba(31, 107, 197, 1)"}}
                        />
                        <IconButton
                                onClick={handleMenuOpen}
                                className="trippleButton"
                                sx={{
                                    padding: "8px",
                                    backgroundColor: "transparent",
                                    borderRadius: "50%",
                                    "&:hover": { backgroundColor: "white !important" },
                                }}
                            >
                                <MoreHorizIcon sx={{color: "rgba(31, 107, 197, 1)"}} />
                        </IconButton>
                    </Box>

                    <Box display="flex" gap={2} color="#A2A1A8">
                        <Box display="flex" alignItems="center" gap={1}>
                            <VacancyIcon2 sx={{width: 24, height: 24}} svgSx={{width: 20, height: 20, color: "rgba(116, 116, 115, 1)"}}/>
                            <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px', color: "rgba(116, 116, 115, 1)" }}>
                                {vacancy?.data.department_name}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            <GeolocationIcon sx={{width: 24, height: 24}} svgSx={{width: 24, height: 24, color: "rgba(116, 116, 115, 1)"}}/>
                            <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px', color: "rgba(116, 116, 115, 1)" }}>
                                {vacancy?.data.city_name}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            <DateIcon sx={{width: 24, height: 24}} svgSx={{width: 18, height: 20, color: "rgba(116, 116, 115, 1)"}}/>
                            <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px', color: "rgba(116, 116, 115, 1)" }}>
                                Дата создания: {new Date(vacancy?.data.created_at).toLocaleDateString()}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3 }}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            sx={{
                                borderBottom: '1px solid #E6E6E6', width: '100%',
                                width: "fit-content",
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#599D15',
                                    height: '3px',
                                    borderTopLeftRadius: '2px',
                                    borderTopRightRadius: '2px',
                                },
                            }}
                            TabIndicatorProps={{
                                style: { transform: 'translateY(0px)' },
                            }}
                        >
                            <Tab
                                icon={
                                    <ProfileIcon2 sx={{width: 20, height: 20, mr: 1}} svgSx={{width: 20, height: 20, backgroundColor: activeTab === 0 ? "rgba(89, 157, 21, 1)" : "rgba(121, 128, 136, 1)"}}/>
                                }
                                iconPosition="start"
                                label={<Typography color={activeTab === 0 ? 'rgba(89, 157, 21, 1)' : 'rgba(121, 128, 136, 1)'}>Кандидаты</Typography>}
                                sx={{
                                    textTransform: 'none',
                                    minWidth: 100,
                                    minHeight: 41,
                                    padding: '6px 16px',
                                }}
                            />

                            <Tab
                                icon={
                                    <TargetIcon sx={{width: 20, height: 20, mr: 1}} svgSx={{width: 20, height: 20, color: activeTab === 1 ? "rgba(89, 157, 21, 1)" : "rgba(121, 128, 136, 1)"}}/>
                                }
                                iconPosition="start"
                                label={<Typography color={activeTab === 1 ? '#599D15' : 'rgba(121, 128, 136, 1)'}>Собеседования</Typography>}
                                sx={{
                                    textTransform: 'none',
                                    minWidth: 100,
                                    minHeight: 41,
                                    padding: '6px 16px',
                                }}
                            />

                            <Tab
                                icon={
                                    <TextIcon sx={{width: 20, height: 20, mr: 1}} svgSx={{width: 20, height: 20, color: activeTab === 2 ? "rgba(89, 157, 21, 1)" : "rgba(121, 128, 136, 1)"}}/>
                                }
                                iconPosition="start"
                                label={<Typography color={activeTab === 2 ? 'rgba(89, 157, 21, 1)' : 'rgba(121, 128, 136, 1)'}>Описание</Typography>}
                                sx={{
                                    textTransform: 'none',
                                    minWidth: 100,
                                    minHeight: 41,
                                    padding: '6px 16px',
                                }}
                            />

                            <Tab
                                icon={ <VacancyIcon sx={{width: 20, height: 20, mr: 1}} svgSx={{width: 20, height: 20, color: activeTab === 3 ? "rgba(89, 157, 21, 1)" : "rgba(121, 128, 136, 1)"}}/>
                                }
                                iconPosition="start"
                                label={<Typography color={activeTab === 3 ? 'rgba(89, 157, 21, 1)' : 'rgba(121, 128, 136, 1)'}>Публикация</Typography>}
                                sx={{
                                    textTransform: 'none',
                                    minWidth: 100,
                                    minHeight: 41,
                                    padding: '6px 16px',
                                }}
                            />

                            <Tab
                                icon={ <VacancyIcon sx={{width: 20, height: 20, mr: 1}} svgSx={{width: 20, height: 20, color: activeTab === 4 ? "rgba(89, 157, 21, 1)" : "rgba(121, 128, 136, 1)"}}/>
                                }
                                iconPosition="start"
                                label={<Typography color={activeTab === 4 ? 'rgba(89, 157, 21, 1)' : 'rgba(121, 128, 136, 1)'}>Карьерная страница</Typography>}
                                sx={{
                                    textTransform: 'none',
                                    minWidth: 100,
                                    minHeight: 41,
                                    padding: '6px 16px',
                                }}
                            />
                        </Tabs>
                    </Box>
                </div>

                {activeTab === 0 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <OverviewTab
                            refetch={refetch}
                            stages={vacancy?.data.stages}
                            vacancyId={vacancyId} // Передаем vacancyId
                            onStageChangeSuccess={handleInvalidation} // Передаем функцию инвалидации
                        />
                    </Box>
                )}
                {activeTab === 1 && <InterviewTab vacancyName={vacancy?.data.name} vacancyId={vacancyId} />}
                {activeTab === 2 && <span style={{
                    padding: '0 24px',
                }}><DescriptionTab vacancy={vacancy?.data} /></span>}
                {activeTab === 3 && <span style={{
                    padding: '0 24px',
                }}><PublicationTab category_id={vacancy?.data.category_id} vacancyId={vacancyId} sources={vacancy?.sources} /></span>}
                {activeTab === 4 && <span style={{
                    padding: '0 24px',
                }}><CareerChannelSettings is_visible={vacancy?.is_visible} vacancyId={vacancyId} /></span>}

                <PopUpWindow 
                    anchorEl={anchorEl} 
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose} 
                    middleItems={[
                        {name: 'Архивировать', id: 'archive'},
                    ]}
                    onClick={handleVacancyAction}
                />
            </Paper>
        </Container >
    );
};

export default VacancyDetail;
