import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

// Получение уведомления по уникальному ключу
export const fetchNotificationByUniqueKey = async (managerId, uniqueKey) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/user-notifications/get`, {
            params: {
                manager_id: managerId,
                unique_key: uniqueKey,
            },
        });
        return responseWrapper(response) // Возвращаем данные уведомления
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Получение списка уведомлений
export const fetchUserNotifications = async () => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/user-notifications`);
        return responseWrapper(response) // Возвращаем массив уведомлений
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Получение количества непрочитанных уведомлений
export const fetchUnreadNotificationsCount = async () => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/user-notifications/count`);

        return responseWrapper(response) // Предполагаем, что возвращается число
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Просмотр конкретного уведомления по уникальному ключу
export const markNotificationAsRead = async (managerId, uniqueKey) => {
    try {
        const response = await axiosClassic.post("/user-notifications/mark-as-read", {
            manager_id: managerId,
            unique_key: uniqueKey,
        });
        return responseWrapper(response) // Возвращаем результат обновления
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};
