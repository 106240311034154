import { RadioIcon } from "../svgComponents";
import { Box } from "@mui/material";

const CustomRadio = ({checked, onClick}) => {
    return (
        <Box sx={{width: 20, height: 20}}>
            <RadioIcon
                checked={checked}
                onClick={onClick}
                sx={{
                    width: 20,
                    height: 20,
                    border: "1px solid",
                    borderColor: checked
                        ? "rgba(89, 157, 21, 1)"
                        : "rgba(210, 214, 218, 1)",
                    backgroundColor: checked
                        ? "rgba(244, 247, 242, 1)"
                        : "#fff",
                    borderRadius: "50%",
                }}
                svgSx={{
                    width: checked ? 20 : 0,
                    height: checked ? 20 : 0,
                    color: "rgba(89, 157, 21, 1)",
                }}
            />
        </Box>
    );
}

export default CustomRadio;