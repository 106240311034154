// src/services/vacancyApi.js

// Импортируйте axiosClassic из конфигурации
import axiosClassic from "../api/interceptors";
import { fetchDepartments } from "./departmentsApi";
import { responseWrapper } from "./responseWrapper";

// Получение списка вакансий
export const fetchVacancies = async (page = 1, perPage = 10, status_id = 1, searchParams = {}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    // Utility to filter searchParams
    const filterSearchParams = (params) => {
        return Object.fromEntries(
            Object.entries(params).filter(([_, value]) => value !== undefined && value !== "")
        );
    };

    try {
        const filteredSearchParams = filterSearchParams(searchParams);

        if (filteredSearchParams.department_name) {
            const data = await fetchDepartments({searchQuery : filteredSearchParams.department_name});
            const department_ids = data && data.data && data.data.length > 0 ? data.data.map(department => department.id) : [];
            delete filteredSearchParams.department_name;
            if (department_ids.length <= 0){
                return {data: [], meta: {}};
            }
            filteredSearchParams.department_ids = department_ids;
        }

        const response = await axiosClassic.get(`/api/company/${companyId}/vacancies`, {
            params: { 
                page, 
                per_page: perPage,
                status_id: status_id,
                ...filteredSearchParams, // Add additional searching filters
            },
        });

        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};


// Получение конкретной вакансии по ID
export const fetchVacancyById = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/vacancies/${vacancyId}`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

export const fetchVacancyByIdOnEdit = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/vacancies/${vacancyId}?without_stages=1`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

// Создание новой вакансии вручную
export const createVacancy = async (vacancyData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/vacancies`, vacancyData);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};

// Создание вакансии через внешний источник (например, HeadHunter)
export const createVacancyFromExternal = async (externalUrl) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/vacancies/from-external`, {
            external_url: externalUrl,
        });
        return responseWrapper(response); // Предполагаем, что в `data.data` приходит созданная вакансия
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Обновление вакансии по ID
export const updateVacancy = async (vacancyId, vacancyData) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/vacancies/${vacancyId}`,
            vacancyData
        );
    
        return responseWrapper(response); // Возвращаем обновлённые данные вакансии
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Change vacancy status
export const updateVacanciesStatus = async (body) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.put(
            `/api/company/${companyId}/vacancies/change-status`,
            body,
            {
                headers: {
                    "Content-Type": "application/json", // Specify JSON content type
                },
            }
        );
    
        return responseWrapper(response); // Возвращаем обновлённые данные вакансии
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Обновление вакансии по ID
export const deleteVacancy = async (vacancyId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/vacancies/${vacancyId}`
        );
    
        return responseWrapper(response); // Возвращаем обновлённые данные вакансии
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};

// Mass delete
export const deleteMassVacancies = async (data) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.delete(
            `/api/company/${companyId}/vacancies/mass-delete`,
            {
                data: data,
                headers: {
                    "Content-Type": "application/json", // Specify JSON content type
                },
            }
        );
    
        return responseWrapper(response); // Возвращаем обновлённые данные вакансии
    } catch (error) {
        throw error; // Пробрасываем ошибку для обработки
    }
};



