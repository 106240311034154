
/**
 * 
 * @param {String} link 
 * @param {String} path 
 * @param {Object} data 
 * @returns 
 */
export const redirectWrapper = (link, path, provider, data) => {
    if (!link || typeof link !== "string") {
        console.error("Invalid link provided.");
        return link;
    }

    try {
        if (path) {

            const targetInfo = {
                path,
                data,
                provider,
            };
            
            const encodedState = encodeURIComponent(JSON.stringify(targetInfo));

            const url = new URL(link);
            url.searchParams.set("state", encodedState);
            return url.toString();
        } else {
            return link;
        }
    } catch (error) {
        console.error("Error processing redirectWrapper:", error);
        return link;
    }
};
