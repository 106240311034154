import React from "react";
import { keyframes, styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";

const l3 = keyframes`
  20% { background-position: 0%   0%, 50% 50%, 100% 50%; }
  40% { background-position: 0% 100%, 50%  0%, 100% 50%; }
  60% { background-position: 0%  50%, 50% 100%, 100%  0%; }
  80% { background-position: 0%  50%, 50% 50%, 100% 100%; }
`;

const LoaderContainer = styled("div")({
  width: "20px",
  aspectRatio: "2",
  "--_g": "no-repeat radial-gradient(circle closest-side, #000 90%, transparent)",
  background: `
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%
  `,
  backgroundSize: "calc(100%/3) 50%",
  animation: `${l3} 1s infinite linear`,
});

export const DotsLoader = ({ align = "center" }) => {
  // Map the type prop to a top percentage
  const topMapping = {
    top: "0%",
    semiTop: "25%",
    center: "50%",
    semiBottom: "75%",
    bottom: "100%",
  };

  const topValue = topMapping[align] || "50%";

  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%", flex: 1 }}>
      <Box
        sx={{
          position: "absolute",
          top: topValue,
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LoaderContainer />
        <Typography>Загрузка</Typography>
      </Box>
    </Box>
  );
};
