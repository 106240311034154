import React, {
    useState,
    memo,
    useRef,
    useMemo,
    useEffect,
    useCallback,
} from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // Горизонтальное троеточие
import {
    Box,
    Avatar,
    Typography,
    Drawer,
    Tabs,
    Tab,
    IconButton,
} from "@mui/material";
import {
    ArrowUpIcon,
    CalendarPlusIcon,
    CheckIcon,
    CloseIcon,
    LinkIcon2,
    MailIcon,
} from "../svgComponents.jsx";
import { deleteCandidate, fetchCandidateById, unlinkCandidate } from "../../services/candidatesApi.js";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TabLabel } from "../common/TabComponents.jsx";
import CustomSelect from "../styledComponents/CustomSelect.jsx";
import { CircularLoading, CustomToolTip } from "../tapHrStyledComponents.jsx";
import { CustomError } from "../styledComponents/CustomErrors.jsx";
import CandidateInfoHolder from "./CandidateInfoHolder.jsx";
import CandidateInterviewHolder from "./CandidateInterviewHolder.jsx";
import CustomInput from "../styledComponents/CustomInput.jsx";
import MailModalUi from "../candidateInfo/MailModalUi.jsx";
import PopUpWindow from "../popUpWindow.jsx";
import { CANDIDATE_DELETE, CANDIDATE_UNLINK_VACANCY } from "../../utils/candidateActions.js";
import CustomizedDeleteModal from "../common/modalWindows/CustomizedDeleteModal.jsx";
import CustomizedWarningModal from "../common/modalWindows/CustomizedWarningModal.jsx";

const getCandidateSuffix = (n) => {
    const number = Number(n);
    // Check if number is valid and if it ends with 1 but is not 11
    if (!isNaN(number) && number % 10 === 1 && number % 100 !== 11) {
        return "кандидата";
    }
    return "кандидатов";
};

const candidateActions = [
    { id : CANDIDATE_UNLINK_VACANCY, name: "Отвязать от вакансии" },
    { id : CANDIDATE_DELETE, name: "Удалить кандидата" },
]

const TopPanel = ({
    onClose,
    candidateTotals,
    stages,
    targetCandidatePath,
    setTargetCandidatePath,
}) => {
    const order = useMemo(() => {
        // Sum up the total candidates in all stages before the candidate's stage
        const totalBefore = stages
            .slice(0, targetCandidatePath.col)
            .reduce((acc, stage) => acc + (stage.candidates?.length || 0), 0);

        // The overall order is the candidates from previous stages plus the candidate's place in the current stage
        return totalBefore + targetCandidatePath.row + 1;
    }, [stages, targetCandidatePath]);
    const isPrevDisabled = order <= 1;
    const isNextDisabled = order >= candidateTotals;

    const handleNext = () => {
        const { col: currentCol, row: currentRow } = targetCandidatePath;
        const currentColCount = stages[currentCol]?.candidates?.length || 0;

        // If there's a next candidate in the same column, update the row index.
        if (currentRow + 1 < currentColCount) {
            setTargetCandidatePath({ col: currentCol, row: currentRow + 1 });
            return;
        }

        // Otherwise, move to the next column that has candidates.
        let nextCol = currentCol + 1;
        while (nextCol < stages.length) {
            const nextColCount = stages[nextCol]?.candidates?.length || 0;
            if (nextColCount > 0) {
                setTargetCandidatePath({ col: nextCol, row: 0 });
                return;
            }
            nextCol++;
        }
    };

    const handlePrev = () => {
        const { col: currentCol, row: currentRow } = targetCandidatePath;

        // If there is a previous candidate in the current column, move back.
        if (currentRow > 0) {
            setTargetCandidatePath({ col: currentCol, row: currentRow - 1 });
            return;
        }

        // Otherwise, loop backwards through previous columns until a non-empty column is found.
        let prevCol = currentCol - 1;
        while (prevCol >= 0) {
            const prevColCount = stages[prevCol]?.candidates?.length || 0;
            if (prevColCount > 0) {
                setTargetCandidatePath({ col: prevCol, row: prevColCount - 1 });
                return;
            }
            prevCol--;
        }
    };

    return (
        <Box
            sx={{
                height: "94px",
                backgroundColor: "rgba(248, 249, 249, 1)",
                borderBottom: "1px solid rgba(162, 161, 168, 0.1)",
                padding: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <Box
                        component="button"
                        disabled={isPrevDisabled}
                        onClick={handlePrev}
                        sx={{
                            backgroundColor: "transparent",
                            borderRadius: "8px",
                            "&:hover": {
                                backgroundColor: isPrevDisabled
                                    ? "transparent"
                                    : "rgba(198, 198, 198, 1)",
                            },
                            "&:active": {
                                backgroundColor: isPrevDisabled
                                    ? "transparent"
                                    : "rgba(162, 162, 162, 1)",
                            },
                            cursor: isPrevDisabled ? "default" : "pointer",
                            border: "1px solid",
                            borderColor: isPrevDisabled
                                ? "rgba(241, 242, 243, 1)"
                                : "rgba(198, 198, 198, 1)",
                        }}
                    >
                        <ArrowUpIcon
                            sx={{
                                width: 46,
                                height: 46,
                                transform: "rotate(-90deg)",
                            }}
                            svgSx={{
                                width: 12,
                                height: 5,
                                color: isPrevDisabled
                                    ? "rgba(162, 162, 162, 1)"
                                    : "rgba(21, 24, 19, 1)",
                            }}
                        />
                    </Box>
                    <Box
                        component="button"
                        disabled={isNextDisabled}
                        onClick={handleNext}
                        sx={{
                            backgroundColor: "transparent",
                            borderRadius: "8px",
                            "&:hover": {
                                backgroundColor: isNextDisabled
                                    ? "transparent"
                                    : "rgba(198, 198, 198, 1)",
                            },
                            "&:active": {
                                backgroundColor: isNextDisabled
                                    ? "transparent"
                                    : "rgba(162, 162, 162, 1)",
                            },
                            cursor: isNextDisabled ? "default" : "pointer",
                            border: "1px solid",
                            borderColor: isNextDisabled
                                ? "rgba(241, 242, 243, 1)"
                                : "rgba(198, 198, 198, 1)",
                        }}
                    >
                        <ArrowUpIcon
                            onClick={isNextDisabled ? null : handleNext}
                            sx={{
                                width: 46,
                                height: 46,
                                transform: "rotate(90deg)",
                            }}
                            svgSx={{
                                width: 12,
                                height: 5,
                                color: isNextDisabled
                                    ? "rgba(162, 162, 162, 1)"
                                    : "rgba(21, 24, 19, 1)",
                            }}
                        />
                    </Box>
                </Box>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "rgba(46, 64, 82, 1)",
                    }}
                >
                    {order} из {candidateTotals}{" "}
                    {getCandidateSuffix(candidateTotals)}
                </Typography>
            </Box>
            <CloseIcon
                onClick={onClose}
                sx={{
                    width: 24,
                    heigth: 24,
                    borderRadius: "2px",
                    cursor: "pointer",
                }}
                svgSx={{
                    width: 16,
                    height: 16,
                    color: "rgba(0, 0, 0, 0.5)",
                    hoverColor: "rgba(0, 0, 0, 0.75)",
                    pressedColor: "rgba(0, 0, 0, 1)",
                }}
            />
        </Box>
    );
};

// Main component
const CandidateDrawer = memo(
    ({
        handleModalWindow,
        candidateId,
        vacancyId,
        handleRefusal,
        handleOffer,
        onStageChange,
        negotiationId,
        currStageId,
        stages,
        targetCandidatePath,
        candidateTotals,
        setTargetCandidatePath,
        refetch,
    }) => {
        const [currNegotiationIdx, setCurrNegotiationIdx] = useState(null);
        const [activeTab, setActiveTab] = useState(0);
        const queryKey = useRef(["active_candidate"]);

        // Fetch specific candidate by ID
        const {
            data: candidate,
            isLoading,
            isError,
            error,
        } = useQuery(
            [queryKey.current, candidateId],
            () => fetchCandidateById(candidateId, negotiationId),
            {
                retry: 0,
                refetchOnWindowFocus: false,
            }
        );

        useEffect(() => {
            if (candidate) {
                const index =
                    candidate.data.negotiations?.findIndex(
                        (negotiation) => negotiation.id === +vacancyId
                    ) ?? 0;
                setCurrNegotiationIdx(index);
            }
        }, [candidate, vacancyId]);

        console.log("Show modal window is rendered");

        const handleChange = (event, newValue) => {
            setActiveTab(newValue);
        };

        return (
            <Drawer
                anchor="right"
                open={true}
                onClose={(e) => {
                    e.stopPropagation();
                    handleModalWindow();
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: "rgba(248, 249, 249, 1)",
                        boxShadow: "0px 5px 50px 0px rgba(0, 0, 0, 0.1)",
                    },
                }}
            >
                <Box
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                        backgroundColor: "rgba(248, 249, 249, 1)",
                        border: "none !important",
                        borderRadius: "8px",
                        width: 1120,
                        minHeight: "100svh",
                        overflowY: "auto",
                        overflowX: "hidden",
                        outline: "none",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <TopPanel
                        onClose={handleModalWindow}
                        candidateTotals={candidateTotals}
                        stages={stages}
                        targetCandidatePath={targetCandidatePath}
                        setTargetCandidatePath={setTargetCandidatePath}
                    />
                    {isLoading ? (
                        <Box sx={{ flex: 1 }}>
                            <CircularLoading />
                        </Box>
                    ) : isError ||
                      (candidate?.data &&
                          currNegotiationIdx >=
                              candidate?.data?.negotiations.length) ? (
                        <CustomError error={error || "Неопознанная ошибка"} />
                    ) : (
                        <>
                            <CustomHeader
                                firstName={candidate?.data?.first_name}
                                lastName={candidate?.data?.last_name}
                                candidateId={candidateId}
                                negotiations={candidate?.data?.negotiations}
                                currNegotiationIdx={currNegotiationIdx}
                                avatarSmall={candidate?.data?.avatar_small}
                                setCurrNegotiationIdx={setCurrNegotiationIdx}
                                handleRefusal={handleRefusal}
                                handleOffer={handleOffer}
                                handleModalWindow={handleModalWindow}
                                refetch={refetch}
                            />
                            <Tabs
                                value={activeTab}
                                onChange={handleChange}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "rgba(89, 157, 21, 1)",
                                        height: "3px",
                                        borderRadius: "100px 100px 0 0",
                                    },
                                }}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    "& .MuiTab-root": {
                                        textTransform: "none",
                                        padding: "0 8px",
                                    },
                                    backgroundColor: "white",
                                    paddingLeft: "24px",
                                    width: "100%",
                                    minHeight: "63px",
                                    height: "63px",
                                }}
                            >
                                <Tab
                                    label={
                                        <TabLabel
                                            text={"Профиль"}
                                            activeTab={activeTab}
                                            index={0}
                                        />
                                    }
                                />
                                {candidate?.data?.test_results?.length !==
                                    0 && (
                                    <Tab
                                        label={
                                            <TabLabel
                                                text={"Интервью"}
                                                activeTab={activeTab}
                                                index={1}
                                            />
                                        }
                                    />
                                )}
                            </Tabs>
                            {activeTab === 0 && (
                                <CandidateInfoHolder
                                    candidate={candidate?.data}
                                    currNegotiationIdx={currNegotiationIdx}
                                    queryKey={queryKey.current}
                                    handleRefusal={handleRefusal}
                                    onStageChange={onStageChange}
                                    negotiationId={negotiationId}
                                    currStageId={currStageId}
                                    stages={stages}
                                    vacancyId={vacancyId}
                                />
                            )}
                            {activeTab === 1 && (
                                <CandidateInterviewHolder
                                    results={candidate?.data?.test_results}
                                    queryKey={[queryKey.current, candidateId]}
                                    candidateId={candidateId}
                                />
                            )}
                        </>
                    )}
                </Box>
            </Drawer>
        );
    }
);

const CustomHeader = memo(
    ({
        avatarSmall,
        firstName,
        lastName,
        candidateId,
        negotiations,
        currNegotiationIdx,
        setCurrNegotiationIdx,
        handleRefusal,
        handleOffer,
        handleModalWindow,
        refetch,
    }) => {
        const isStable =
            currNegotiationIdx !== null &&
            currNegotiationIdx < negotiations.length;
        const [isMailModalOpen, setIsMailModalOpen] = useState(false);
        const [anchorEl, setAnchorEl] = useState(null);
        const [openModal, setOpenModal] = useState(null);

        const handleMenuOpen = (event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };

        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        const handleNegotiationChange = useCallback(
            (value) => {
                const idx = negotiations.findIndex(
                    (negotiation) => negotiation.id === value
                );
                if (idx !== -1) {
                    setCurrNegotiationIdx(idx);
                }
            },
            [negotiations]
        );

        const unlinkMutation = useMutation(unlinkCandidate, {
            onSuccess: () => {
                handleMenuClose();
                handleModalWindow();
                refetch();
            },
        })

        const deleteMutation = useMutation(deleteCandidate, {
            onSuccess: () => {
                handleMenuClose();
                handleModalWindow();
                refetch();
            },
        })

        const openModalWindow = useCallback((id) => {
            setOpenModal(id);
        }, []);

        const handleDelete = () => {
            deleteMutation.mutate(candidateId);
            setOpenModal(null);
        };

        const handleUnlink = () => {
            if (isStable) {
                unlinkMutation.mutate(negotiations[currNegotiationIdx]?.negotiation_id);
            }
            setOpenModal(null);
        };

        const handleCopy = () => {
            const domain = localStorage.getItem("company_domain");
            if (!domain) {
                alert("Ошибка: домен компании не найден.");
                return;
            }

            const negotiation = negotiations[currNegotiationIdx];
            const { option } = negotiation.stage;
            const paths = {
                test: "interview",
                interview: "select-slot",
            };

            if (!paths[option]) {
                alert("Ошибка: неизвестный этап переговоров.");
                return;
            }

            const url = `${domain}.taphr.kz/${paths[option]}/${negotiation.hash}`;

            navigator.clipboard
                .writeText(url)
                .then(() => alert("Ссылка скопирована в буфер обмена"))
                .catch(() => alert("Ошибка копирования ссылки"));
        };

        return (
            <Box
                sx={{
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    border: "none",
                    backgroundColor: "#FFFFFF",
                    height: "128px",
                    padding: "16px 24px",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                    <Avatar
                        src={avatarSmall || null}
                        alt={`${firstName} ${lastName}`}
                        sx={{
                            width: 104,
                            height: 104,
                            backgroundColor: "#E0E0E0",
                            borderRadius: "50%",
                            border: "4px solid #FFFFFF",
                            boxShadow:
                                "0px 4px 6px -2px rgba(10, 13, 18, 0.03)",
                            boxShadow:
                                "0px 12px 16px -4px rgba(10, 13, 18, 0.08)",
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: "20px",
                                lineHeight: "24px",
                                color: "black",
                            }}
                        >
                            {firstName} {lastName}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "rgba(116, 116, 115, 1)",
                                }}
                            >
                                Отклик:
                            </Typography>
                            {isStable ? (
                                negotiations.length > 1 ? (
                                    <CustomSelect
                                        value={
                                            negotiations[currNegotiationIdx].id
                                        }
                                        options={negotiations}
                                        onChange={handleNegotiationChange}
                                        sx={{
                                            width: "281px",
                                            height: "56px",
                                        }}
                                    />
                                ) : (
                                    <CustomInput
                                        value={
                                            negotiations[currNegotiationIdx]
                                                .name
                                        }
                                        readOnly
                                        sx={{
                                            border: "1px solid rgba(226, 228, 231, 1)",
                                            inputBackgroundColor: "white",
                                            borderRadius: "8px",
                                            width: "281px",
                                            height: "56px",
                                        }}
                                    />
                                )
                            ) : (
                                <CustomSelect
                                    value={""}
                                    options={[]}
                                    placeholder="Вакансия не найдена"
                                    sx={{
                                        width: "281px",
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <Box
                        onClick={handleOffer}
                        component="button"
                        sx={{
                            backgroundColor: "rgba(89, 157, 21, 1)",
                            borderRadius: "8px",
                            padding: "13px 21px",
                            border: "none",
                            color: "white",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "rgba(80, 140, 19, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(69, 122, 16, 1)",
                            },
                        }}
                    >
                        <CheckIcon
                            sx={{
                                width: 20,
                                height: 20,
                                border: "2px solid white",
                                borderRadius: "50%",
                            }}
                            svgSx={{
                                width: 12,
                                height: 12,
                                color: "white",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                fontWeight: 400,
                            }}
                        >
                            Оффер
                        </Typography>
                    </Box>
                    <Box
                        onClick={handleRefusal}
                        component="button"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "12px 20px",
                            border: "1px solid rgba(255, 59, 48, 1)",
                            color: "rgba(255, 59, 48, 1)",
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "rgba(255, 243, 242, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(255, 201, 200, 1)",
                            },
                        }}
                    >
                        <CloseIcon
                            sx={{
                                width: 20,
                                height: 20,
                                border: "2px solid rgba(255, 59, 48, 1)",
                                borderRadius: "50%",
                            }}
                            svgSx={{
                                width: 12,
                                height: 12,
                                color: "rgba(255, 59, 48, 1)",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                fontWeight: 400,
                            }}
                        >
                            Отказ
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "4px" }}>
                        <Box
                            onClick={() => setIsMailModalOpen(!isMailModalOpen)}
                            component="button"
                            sx={{
                                display: "flex",
                                border: "none",
                                borderRadius: "8px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "46px",
                                height: "46px",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                "&:hover": {
                                    backgroundColor: "rgba(242, 243, 243, 1)",
                                },
                                "&:active": {
                                    backgroundColor: "rgba(226, 228, 231, 1)",
                                },
                                cursor: "pointer",
                            }}
                        >
                            <MailIcon
                                sx={{
                                    width: 24,
                                    height: 24,
                                }}
                                svgSx={{
                                    width: 24,
                                    height: 24,
                                    color: "rgba(46, 64, 82, 1)",
                                }}
                            />
                        </Box>
                        {/* <Box
                            component="button"
                            sx={{
                                display: "flex",
                                border: "none",
                                borderRadius: "8px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "46px",
                                height: "46px",
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                "&:hover": {
                                    backgroundColor: "rgba(242, 243, 243, 1)",
                                },
                                "&:active": {
                                    backgroundColor: "rgba(226, 228, 231, 1)",
                                },
                                cursor: "pointer",
                            }}
                        >
                            <CalendarPlusIcon
                                sx={{
                                    width: 24,
                                    height: 24,
                                }}
                                svgSx={{
                                    width: 24,
                                    height: 24,
                                    color: "rgba(46, 64, 82, 1)",
                                }}
                            />
                        </Box> */}
                        {isStable &&
                            negotiations[currNegotiationIdx]?.stage?.option &&
                            negotiations[currNegotiationIdx]?.hash && (
                                <CustomToolTip
                                    title="Ссылка для прохождения теста для кандидата, скопировать в буфер обмена"
                                    align={"top"}
                                >
                                    <Box
                                        onClick={handleCopy}
                                        component="button"
                                        sx={{
                                            display: "flex",
                                            border: "none",
                                            borderRadius: "8px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "46px",
                                            height: "46px",
                                            backgroundColor:
                                                "rgba(255, 255, 255, 1)",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(242, 243, 243, 1)",
                                            },
                                            "&:active": {
                                                backgroundColor:
                                                    "rgba(226, 228, 231, 1)",
                                            },
                                            cursor: "pointer",
                                        }}
                                    >
                                        <LinkIcon2
                                            sx={{
                                                width: 24,
                                                height: 24,
                                            }}
                                            svgSx={{
                                                width: 24,
                                                height: 24,
                                                color: "rgba(46, 64, 82, 1)",
                                            }}
                                        />
                                    </Box>
                                </CustomToolTip>
                            )}

                        <IconButton
                            onClick={handleMenuOpen}
                            className="trippleButton"
                            disableRipple={true}
                            sx={{
                                padding: "8px",
                                backgroundColor: "transparent",
                                borderRadius: "8px",
                                height: "46px",
                                width: "46px",
                                "&:hover": {
                                    backgroundColor:
                                        "rgba(242, 243, 243, 1)",
                                },
                                "&:active": {
                                    backgroundColor:
                                        "rgba(226, 228, 231, 1)",
                                    borderRadius: "8px",
                                },
                            }}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </Box>
                </Box>
                <MailModalUi
                    first_name={firstName}
                    last_name={lastName}
                    candidateId={candidateId}
                    open={isMailModalOpen}
                    onClose={() => setIsMailModalOpen(false)}
                />
                <PopUpWindow
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClick={openModalWindow}
                    onClose={handleMenuClose}
                    middleItems={candidateActions}
                    sx={{
                        "& .MuiMenuItem-root": {
                            padding: "16px",
                        },
                    }}
                />
                {openModal === CANDIDATE_DELETE && (
                    <CustomizedDeleteModal
                        title="Вы действительно хотите удалить кандидата?"
                        subtitle="Данные кандидата будут безвозвратно удалены из системы."
                        handleDelete={handleDelete}
                        handleClose={() => setOpenModal(null)}
                    />
                )}
                    {openModal === CANDIDATE_UNLINK_VACANCY && (
                    <CustomizedWarningModal
                        title="Вы действительно хотите отвязать кандидата от вакансии?"
                        subtitle="При отвязке кандидат будет удален из канбана, но его данные сохранятся в базе."
                        handleDelete={handleUnlink}
                        handleClose={() => setOpenModal(null)}
                    />
                )}
            </Box>
        );
    }
);

export default CandidateDrawer;
