import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, List, ListItem, ListItemAvatar, ListItemText, Avatar, Divider } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { CloseIcon } from '../svgComponents';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { useQuery } from '@tanstack/react-query';
import { fetchTelegramChannels } from '../../services/telegramApi';

// const channels = [
//     {
//         id: 1,
//         name: "Dev KZ | Vacancy",
//         subscribers: "17 985 подписчиков",
//         description: "Канал для объявлений о IT вакансиях в KZ (только от компаний из KZ)",
//         icon: "https://cdn-icons-png.flaticon.com/512/5968/5968804.png",
//     },
//     {
//         id: 2,
//         name: "Релокация&Удаленка (Все страны)",
//         subscribers: "4 214 подписчиков",
//         description: "Вакансии с релокацией и с удаленным форматом работы",
//         icon: "https://cdn-icons-png.flaticon.com/512/5968/5968804.png",
//     },
//     {
//         id: 3,
//         name: "Dev KZ | Vacancy",
//         subscribers: "17 985 подписчиков",
//         description: "Канал для объявлений о IT вакансиях в KZ (только от компаний из KZ)",
//         icon: "https://cdn-icons-png.flaticon.com/512/5968/5968804.png",
//     },
//     {
//         id: 4,
//         name: "Dev KZ | Vacancy",
//         subscribers: "17 985 подписчиков",
//         description: "Канал для объявлений о IT вакансиях в KZ (только от компаний из KZ)",
//         icon: "https://cdn-icons-png.flaticon.com/512/5968/5968804.png",
//     },
//     {
//         id: 5,
//         name: "Релокация&Удаленка (Все страны)",
//         subscribers: "4 214 подписчиков",
//         description: "Вакансии с релокацией и с удаленным форматом работы",
//         icon: "https://cdn-icons-png.flaticon.com/512/5968/5968804.png",
//     },
//     {
//         id: 6,
//         name: "Dev KZ | Vacancy",
//         subscribers: "17 985 подписчиков",
//         description: "Канал для объявлений о IT вакансиях в KZ (только от компаний из KZ)",
//         icon: "https://cdn-icons-png.flaticon.com/512/5968/5968804.png",
//     }
// ];

const PublicationModalTelegram = ({ isModalOpen, closeModal, onNext }) => {
    const [search, setSearch] = useState('');

    // Запрос списка каналов через React Query
    const { data: channels, isLoading, isError } = useQuery({
        queryKey: ['telegramChannels'],
        queryFn: fetchTelegramChannels,
    });

    const handleChannelSelect = (selectedChannel) => {
        onNext(selectedChannel)
    };

    const filteredChannels = channels?.data?.filter(channel =>
        channel?.title?.toLowerCase()?.includes(search?.toLowerCase())
    );

    console.log(channels)

    return (
        <Modal open={isModalOpen} onClose={closeModal}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 525,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: '24px',
                borderRadius: '8px'
            }}>
                {/* Заголовок */}
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} mb={2}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#E8EFE4" />
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F7F2" stroke-width="8" />
                        <path d="M18.8967 26.7969L34.2854 20.1462C35.009 19.8335 35.7932 20.4421 35.6698 21.2207L33.5657 34.4967C33.4358 35.3167 32.4208 35.6304 31.8509 35.0266L28.398 31.3691C27.7208 30.6518 27.6678 29.548 28.273 28.7691L30.711 25.6313C30.8503 25.4519 30.6303 25.216 30.4416 25.3425L25.5943 28.5944C24.7717 29.1462 23.7737 29.3732 22.7933 29.2314L19.1503 28.7045C18.1523 28.5602 17.9711 27.1969 18.8967 26.7969Z" fill="#599D15" />
                    </svg>
                    <CloseIcon
                        onClick={closeModal}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 24,
                            height: 24,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>
                <Typography variant="h6" sx={{ marginTop: '24px', marginBottom: '16px', fontWeight: 600, fontSize: '20px', lineHeight: '24px' }} fontWeight={600}>
                    Выберите Telegram канал
                </Typography>
                <Typography variant="p" sx={{ color: '#747473', fontSize: '16px', fontWeight: 300, lineHeight: '20px', mb: 2 }}>
                    Определите, где будет размещена ваша вакансия — так вы быстрее найдете подходящих кандидатов
                </Typography>

                {/* Поле поиска */}
                <Box
                    sx={{
                        my: 2,
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        padding: "0 16px",
                        display: "flex",
                        alignItems: "center",
                        border: '1px solid #D5D7DA'
                    }}
                >
                    <svg style={{ marginRight: '8px' }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.2915 9.58464C2.2915 13.6117 5.55609 16.8763 9.58317 16.8763C13.6102 16.8763 16.8748 13.6117 16.8748 9.58464C16.8748 5.55756 13.6102 2.29297 9.58317 2.29297C5.55609 2.29297 2.2915 5.55756 2.2915 9.58464ZM9.58317 18.1263C4.86574 18.1263 1.0415 14.3021 1.0415 9.58464C1.0415 4.8672 4.86574 1.04297 9.58317 1.04297C14.3006 1.04297 18.1248 4.8672 18.1248 9.58464C18.1248 11.7184 17.3424 13.6694 16.0489 15.1665L18.7751 17.8927C19.0192 18.1368 19.0192 18.5325 18.7751 18.7766C18.531 19.0207 18.1353 19.0207 17.8912 18.7766L15.165 16.0504C13.668 17.3439 11.7169 18.1263 9.58317 18.1263Z" fill="#ADB5BD" />
                    </svg>
                    <input
                        className="custom-input"
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        placeholder="Поиск по названию канала"
                        style={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            color: "#000",
                            fontSize: "16px",
                            height: '56px',
                        }}
                    />
                </Box>
                {/* Список каналов */}
                <List sx={{ maxHeight: '352px', overflowY: 'auto' }}>
                    {filteredChannels?.map(channel => (
                        <React.Fragment key={channel.id}>
                            <ListItem
                                button
                                // selected={selectedChannel === channel.id}
                                onClick={() => handleChannelSelect(channel)}
                                sx={{
                                    borderRadius: 1,
                                    transition: '0.3s',
                                    '&.Mui-selected': {
                                        bgcolor: 'rgba(92, 184, 92, 0.1)',
                                    }
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar src={channel.avatar} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={channel.title}
                                    secondary={
                                        <>
                                            <Typography variant="caption" color="text.secondary">
                                                {channel.member_count} подписчиков
                                            </Typography>
                                            <br />
                                            <Typography variant="body2" color="text.secondary">
                                                {channel.description}
                                            </Typography>
                                        </>
                                    }
                                />
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1199 20.5192C10.7426 20.2173 10.6814 19.6667 10.9833 19.2893L15.2127 14.0026L10.9833 8.71585C10.6814 8.33849 10.7426 7.78786 11.1199 7.48598C11.4973 7.18409 12.0479 7.24528 12.3498 7.62263L17.0165 13.456C17.2721 13.7755 17.2721 14.2296 17.0165 14.5492L12.3498 20.3825C12.0479 20.7599 11.4973 20.821 11.1199 20.5192Z" fill="#151813" />
                                </svg>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>

                {/* Кнопки управления */}
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                    {/* <Button
                        variant="outlined"
                        sx={{
                            marginRight: 2,
                            height: "46px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            color: "#2E4052",
                            borderColor: "#2E4052",
                            fontSize: "16px",
                            fontWeight: "400",
                            flex: 1
                        }}
                    >
                        Отменить
                    </Button> */}
                    {/* <Button
                        onClick={() => onNext(selectedChannel)}
                        variant="contained"
                        sx={{
                            backgroundColor: "#599D15",
                            color: "white",
                            height: "46px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "400",
                            flex: 1
                        }}
                    >
                        Далее
                    </Button> */}
                </Box>
            </Box>
        </Modal>
    );
};

export default PublicationModalTelegram;
