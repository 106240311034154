import React, { useState } from "react";
import './ViewModeSelect.css'

const ViewModeSelect = ({ viewMode, setViewMode }) => {
    const [isOpen, setIsOpen] = useState(false);

    const options = [
        { value: "month", label: "Месяц" },
        { value: "week", label: "Неделя" }
    ];

    const selectedOption = options.find(opt => opt.value === viewMode);

    return (
        <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
            <div className="selected-option">
                {selectedOption?.label}
                <span className={`arrow-icon ${isOpen ? "rotate" : ""}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 14L17 10" stroke="#ADB5BD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
            </div>
            {isOpen && (
                <div className="dropdown">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={`dropdown-item ${option.value === viewMode ? "active" : ""}`}
                            onClick={() => {
                                setViewMode(option.value);
                                setIsOpen(false);
                            }}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ViewModeSelect;
