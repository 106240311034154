import { forwardRef, memo } from "react";
import { Box, TextField } from "@mui/material";

const CustomNumericInput = forwardRef(
    (
        {
            value,
            placeholder,
            readOnly,
            multiline,
            rows,
            onChange = () => {},
            sx = {},
            renderOutput,
            renderInput,
        },
        ref
    ) => {
        return (
            <Box sx={{width: 67, ...sx}}>
                <TextField
                    ref={ref}
                    sx={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: sx.inputBorder || "none",
                        "& fieldset": { border: "none" },
                        "& input": {
                            padding: sx.inputPadding || "10px",
                            fontSize: "16px",
                            lineHeight: "20px",
                            height: "20px",
                            cursor: readOnly ? "default" : "text",
                        },
                    }}
                    value={renderOutput ? renderOutput(value) : value}
                    onChange={(e) =>
                        onChange(
                            renderOutput
                                ? renderInput(e.target.value)
                                : e.target.value
                        )
                    }
                    placeholder={placeholder || undefined}
                    multiline={Boolean(multiline)}
                    rows={rows}
                    slotProps={{
                        input: {
                            readOnly: Boolean(readOnly),
                            type: "number",
                        },
                        htmlInput: {
                            autoComplete: "off",
                        },
                    }}
                />
            </Box>
        );
    }
);

export default memo(CustomNumericInput);
