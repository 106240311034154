import { useMutation } from '@tanstack/react-query';
import { login } from '../services/authApi';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
    const navigate = useNavigate();

    return useMutation(login, {
        onSuccess: ({data}) => {
            const { access_token, invite_token, refresh_token } = data;
            if (access_token) {
                localStorage.setItem('access_token', access_token);
            }
            if (invite_token) {
                localStorage.setItem('invite_token', invite_token);
            }
            if (refresh_token) {
                localStorage.setItem('refresh_token', refresh_token);
            }

            if (invite_token) {
                navigate('/new_password'); // Перенаправление на страницу смены пароля
            } else {
                // navigate('/select_account'); // Перенаправление на страницу выбора аккаунта
            }
        },
        onError: (error) => {
            console.error('Ошибка авторизации', error);
        },
    });
};
