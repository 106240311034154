// src/services/main.js
import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

export const getMe = async () => {
    try {
        const response = await axiosClassic.get('/api/me');
        return responseWrapper(response)
    } catch (error) {
        throw error;
    }
};