
// fetch three criteria from the API 
//api/company/{companyId}/ai/base-criteria
import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

export const generateDescription = async ({position_title, short_description}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        ...(position_title && { position_title }),
        ...(short_description && { short_description }),
    };

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/ai/description`, params);
        return responseWrapper(response);
    } catch (error) {
        throw error; // Re-throw the error for the caller to handle
    }
}

/**
 * Fetches the base criteria from the API
 * @param {Object} params - The parameters to pass to the API
 * @param {string} params.evaluation_stage - The evaluation stage
 * @param {string} params.description - The description
 * @returns {Object} The response from the API
 */
export const passBaseCriteria = async ({evaluation_stage, description}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        ...(evaluation_stage && { evaluation_stage }),
        ...(description && { description }),
    };

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/ai/base-criteria`, params);
        return responseWrapper(response);
    } catch (error) {
        throw error; // Re-throw the error for the caller to handle
    }
}

/**
 * Fetches the additional criteria from the API
 * @param {Object} params - The parameters to pass to the API
 * @param {string} params.evaluation_stage - The evaluation stage
 * @param {string} params.description - The description
 * @param {string} params.previous_criterion - The previous criterion
 * @returns {Object} The response from the API
 */
export const passAdditionalCriteria = async ({evaluation_stage, description, previous_criterion}) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    const params = {
        ...(evaluation_stage && { evaluation_stage }),
        ...(description && { description }),
        previous_criterion,
    };

    try {
        const response = await axiosClassic.post(`/api/company/${companyId}/ai/additional-criteria`, params);
        return responseWrapper(response);
    } catch (error) {
        throw error; // Re-throw the error for the caller to handle
    }
}

/**
 * Fetches the criteria from the API
 * @param {string} requestId - The request ID
 * @returns {Object} The response from the API
 */
export const getCriteria = async (requestId) => {
    const companyId = localStorage.getItem("company_id");
    if (!companyId) throw new Error("ID компании не найден");

    try {
        const response = await axiosClassic.get(`/api/company/${companyId}/ai/criteria/${requestId}`);
        return responseWrapper(response);
    } catch (error) {
        throw error; // Re-throw the error for the caller to handle
    }
}