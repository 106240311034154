import {
    CloseIcon,
    GeoPlusIcon,
} from "../../svgComponents";
import { Dialog, Box, DialogActions, Button, Typography } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import CustomInput from "../../styledComponents/CustomInput";
import { fetchCities } from "../../../services/dictionariesApi";
import CustomAutoComplete from "../../styledComponents/CustomAutoComplete";

const cityKey = ["cities"];
const CreateNewAddressModal = ({
    handleClose,
    saveData,
    information,
}) => {
    const [city, setCity] = useState(information?.city_name ? {name: information?.city_name} : null);
    const [address, setAddress] = useState(information?.address || "");
    const [name, setName] = useState(information?.name || "");

    const isDisabled = !city || !address || !name || !city.name;

    const handleSubmit = useCallback(() => {
        if (isDisabled) return;
        if (!(city?.name === information?.city_name && address === information?.address && name === information?.name)) {
            saveData({
                city_name: city?.name,
                address: address,
                name: name,
            });
        }
        handleClose();
    }, [
        isDisabled,
        city,
        address,
        name,
    ]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                event.preventDefault(); // Prevent default "Enter" behavior like form submission
                handleSubmit();
            }
        };

        // Attach the keydown event listener
        window.addEventListener("keydown", handleKeyDown);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleSubmit]); // Dependency array ensures the latest state values are used

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "403px",
                    minHeight: "540px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                },
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 0,
                        marginBottom: "8px",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            width: 56,
                            height: 56,
                            backgroundColor: "rgba(232, 239, 228, 1)",
                            border: "8px solid rgba(244, 247, 242, 1)",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            left: "-4px",
                            top: "-4px",
                        }}
                    >
                        <GeoPlusIcon
                            sx={{
                                width: 24,
                                height: 24,
                            }}
                            svgSx={{
                                width: 24,
                                height: 24,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                    </Box>
                    <CloseIcon
                        onClick={handleClose}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        fontSize: 20,
                        lineHeight: "24px",
                        mt: 2,
                    }}
                >
                    Добавить новый филиал
                </Typography>
            </Box>
            <Box>
                <Typography
                    sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: 500,
                        color: "rgba(21, 24, 19, 1)",
                        mb: "6px",
                    }}
                >
                    Город
                </Typography>
                <CustomAutoComplete
                    value={city}
                    setValue={setCity}
                    queryKeyStartingValue={cityKey}
                    fetchFunction={fetchCities}
                    target={information?.city || ""}
                />
            </Box>
            <CustomInput
                label="Адрес"
                value={address}
                onChange={setAddress}
                placeholder="Введите адрес"
            />
            <CustomInput
                label="Название"
                value={name}
                onChange={setName}
                placeholder="Введите название"
            />
            <DialogActions sx={{ justifyContent: "center", padding: 0 }}>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        flex: 1,
                        color: "rgba(46, 64, 82, 1)",
                        borderColor: "rgba(46, 64, 82, 1)",
                        height: "44px",
                        borderRadius: "8px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                    }}
                >
                    Отменить
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={isDisabled}
                    sx={{
                        flex: 1,
                        backgroundColor: "rgba(89, 157, 21, 1)",
                        color: "#fff",
                        height: "44px",
                        borderRadius: "8px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                            boxShadow: "none !important",
                        },
                        "&:active": {
                            backgroundColor: "rgba(69, 122, 16, 1)",
                        },
                    }}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateNewAddressModal;
