import { Box, Typography } from "@mui/material";
import { useState, useRef, useEffect, useCallback, memo } from "react";
import TemplatesPanel from "./TemplatesPanel";
import { createEmailTemplate, fetchEmailTemplates, updateEmailTemplate, deleteEmailTemplate } from "../../services/emailTemplatesApi";
import { useMutation, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { CircularLoading, Loading } from "../tapHrStyledComponents";
import EmptyTextTemplate from "./EmptyTextTemplate";
import CreateEmailTemplate from "../common/modalWindows/CreateEmailTemplate";
import { EDIT_STATUS } from "../../utils/statusesStorage";
import { CustomError } from "../styledComponents/CustomErrors";

export const EmailTab = memo(({showEmptyTemplate, renderBanner, type, sx={}}) => {

    console.log('EmailTab rendered', type);
    const observerRef = useRef(null);
    const [modalWindow, setModalWindow] = useState({open: false, data: null});
    const [globalLoading, setGlobalLoading] = useState(false);
    const queryClient = useQueryClient();
    const [currentTarget, setCurrentTarget] = useState(null);
    const queryKey = useRef(["templates_email"]);

    // if data is passed to handle modal window then store it in the state, as weel as its id and index
    const handleModalWindow = (data) => {
        if (!modalWindow.open && data?.status === EDIT_STATUS) {
            setCurrentTarget({ id: data?.template.id, index: data?.index });
        }
        setModalWindow((prev) => ({
            open: !prev.open,
            data: !prev.open && data?.status === EDIT_STATUS ? data.template : null,
        }));
    };

    const {
        data,
        isLoading: isDataLoading,
        isError: isDataError,
        error: dataError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        [queryKey.current, type],
        async ({ pageParam = 1 }) => fetchEmailTemplates({ page: pageParam, type: type }),
        {
            getNextPageParam: (lastPage) => {
                const { meta } = lastPage;
                const currentPage = parseInt(meta.current_page, 10);
                const totalPages = Math.ceil(meta.total / meta.per_page);
                return currentPage < totalPages ? currentPage + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    const flatData = (data && data.pages && data.pages.flatMap((page) => page?.data || page)) || [];
    const lastMeta = (data && data.pages && data.pages[data.pages.length - 1].meta || null);

    useEffect(() => {
        if (!hasNextPage || isFetchingNextPage) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    fetchNextPage();
                }
            },
            { threshold: 1.0 }
        );

        const currentRef = observerRef.current; // Store the current ref value

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const handleDataAddAndDelete = (newData, newType) => {
        if (!newType) {
            newType = type;
        }

        const toEmpty = lastMeta && !lastMeta.is_empty && newData.meta.is_empty;
        const toNonEmpty = lastMeta && lastMeta.is_empty && !newData.meta.is_empty;
        if (toEmpty || toNonEmpty) {
            queryClient.setQueryData([queryKey.current, newType === 2 ? 1 : 2], (oldData) => {
                if (!oldData) return;
                return {
                    ...oldData,
                    pages: [
                        {
                            data: [],
                            meta: {
                                is_empty: newData.meta.is_empty,
                            },
                        },
                    ],
                };
            });
        }
        
        const currentQueryKey = [queryKey.current, newType];
        queryClient.setQueryData(currentQueryKey, (oldData) => {
            return {
                ...oldData, 
                pages: [
                    newData,
                ]
            }
        });
    }

    const handleDataEdit = useCallback((newData, newType) => {
        // if type changed, should invalidate both queries
        if (newType !== type) {
            setGlobalLoading(true);

            queryClient.invalidateQueries([queryKey.current, newType]);
            Promise.all([
                queryClient.invalidateQueries([queryKey.current, type])]).finally(() => {
                setGlobalLoading(false);
            });
            return;
        }
        const currentQueryKey = [queryKey.current, type];
        const page = newData.meta.current_page;
        queryClient.setQueryData(currentQueryKey, (oldData) => {
            return {
                ...oldData,
                pages: oldData.pages.map((oldPage, index) => {
                    if (index === page - 1) {
                        return newData;
                    }
                    return oldPage;
                }),
            };
        });
    }, []);

    const createMutation = useMutation(createEmailTemplate, {
        onSuccess: ({type, response}) => {
            handleDataAddAndDelete(response, type);
        }
    });

    const updateMutation = useMutation(updateEmailTemplate, {
        onSuccess: ({type, response}) => {
            handleDataEdit(response, type);
            setCurrentTarget(null);
        }
    });

    const handleNewItem = (body) => {
        const formData = new FormData();

        formData.append("type", body.type);

        formData.append("name", body.name);

        formData.append("content", body.content);

        // Remove HTML tags using a regular expression
        const plainTextSubject = body.subject.replace(/<[^>]*>/g, "").trim();
        formData.append("subject", plainTextSubject);

        if (body?.file) {
            formData.append("file", body.file);
        }

        if (currentTarget) {
            let page = 1;
            // type changed, should use page 1
            if (body.type === type) {
                page = Math.floor(currentTarget.index / 10) + 1;
            }
            updateMutation.mutate({id: currentTarget.id, body: formData, page, type: body.type});
        } else {
            createMutation.mutate({body: formData, type : body.type});
        }
        handleModalWindow();
    } 

    const { mutate: deleteTemplateMutate } = useMutation(deleteEmailTemplate, {
        onSuccess: (data) => {
            handleDataAddAndDelete(data);
        },
    });

    const handleDelete = useCallback(
        (id) => {
            deleteTemplateMutate({
                id,
                type,
            });
        },
        [deleteTemplateMutate, type]
    );

    const isLoading = isDataLoading || createMutation.isLoading || deleteTemplateMutate.isLoading || globalLoading;
    const isError = isDataError || createMutation.isError || updateMutation.isError || deleteTemplateMutate.isError;
    const error = dataError || createMutation.error || updateMutation.error || deleteTemplateMutate.error;

    return isLoading ? (
        <CircularLoading sx={sx} />
    ) : isError || !lastMeta ? (
        <CustomError error={error.message || "Неопознанная причина"} sx={sx}/>
    ) : (
        <>
            {modalWindow.open && (
                <CreateEmailTemplate
                    handleClose={handleModalWindow}
                    handleNewItem={handleNewItem}
                    data={modalWindow.data}
                />
            )}
            {lastMeta.is_empty ? (
                showEmptyTemplate ? (
                    <EmptyTextTemplate
                        handleModalWindow={handleModalWindow}
                        mainText={"Шаблоны сообщений для писем"}
                        subText={
                            "Настройте шаблоны для эффективного общения с кандидатами"
                        }
                    />
                ) : null
            ) : (
                <>
                    {renderBanner && renderBanner(handleModalWindow)}
                    <Box sx={sx}>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                lineHeight: "24px",
                                fontWeight: 500,
                                mb: 2,
                                ml: 3,
                            }}
                        >
                            {type === 1 ? "Личные" : "Общие"}
                        </Typography>
                        <TemplatesPanel
                            templates={flatData}
                            handleDelete={handleDelete}
                            handleModalWindow={handleModalWindow}
                            currentId={currentTarget?.id}
                            isLoading={updateMutation.isLoading}
                            hideAuthor={type === 1}
                        />
                    </Box>
                    {isFetchingNextPage && <Loading />}
                    <div ref={observerRef} style={{ height: "1px", width: "100%" }} />
                </>
            )}
        </>
    );
});