import React, { useCallback, useState, memo, useMemo, useRef } from "react";
import {
    Box,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Typography,
    Chip,
    Tooltip,
} from "@mui/material";

import {
    CheckIcon,
    CloseIcon,
    EmptyBoxIcon,
    FilterIcon,
    MinusIcon,
    PlusIcon,
    StarIcon,
    ThumbUpIcon,
} from "../svgComponents";
import TestResultsPreviewModal from "../common/modalWindows/TestResultsPreviewModal";
import { CustomSearch } from "../styledComponents/CustomSearch";
import FilterDrawer from "./FilterDrawer";
import { changeRecruiterScore, fetchResults } from "../../services/resultsApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DotsLoader } from "../styledComponents/CustomLoaders";
import { CustomError } from "../styledComponents/CustomErrors";
import {
    TEST_STORAGE_AUDIO,
    TEST_STORAGE_CHECKBOX,
    TEST_STORAGE_FILE,
    TEST_STORAGE_RADIO,
    TEST_STORAGE_VIDEO,
} from "../../utils/testTypesStorage";
import { createComment } from "../../services/commentsApi";
import { mapScoreToText } from "../../utils/aiScore";
import ResultsComment from "../common/ResultsComment";

// Helper to color-code the status
const getStatusChipProps = (status) => {
    switch (status) {
        case 2:
            return {
                backgroundColor: "rgba(242, 248, 244, 1)",
                color: "rgba(18, 156, 82, 1)",
            };
        case 3:
            return {
                backgroundColor: "rgba(255, 243, 242, 1)",
                color: "rgba(228, 53, 43, 1)",
            };
        default:
            return {
                backgroundColor: "rgba(253, 249, 242, 1)",
                color: "rgba(208, 165, 44, 1)",
            };
    }
};

const mapCandidateStatus = (status) => {
    if (typeof status === "number") {
        switch (status) {
            case 1:
                return "Начал";
            case 2:
                return "Закончил";
            case 3:
                return "Авто-отказ";
            default:
                return status;
        }
    }
    return status;
};

// Helper to render the final assessment icon
const renderAssessmentIcon = (type) => {
    switch (type) {
        case 1:
            return (
                <StarIcon
                    sx={{
                        width: 42,
                        height: 42,
                        borderRadius: "50%",
                        backgroundColor: "rgba(232, 185, 49, 1)",
                    }}
                    svgSx={{ width: 20, height: 20, backgroundColor: "white" }}
                />
            );
        case 3:
            // Empty circle with a border
            return (
                <MinusIcon
                    sx={{
                        width: 42,
                        height: 42,
                        borderRadius: "50%",
                        backgroundColor: "rgba(165, 169, 173, 1)",
                    }}
                    svgSx={{
                        width: 20,
                        height: 20,
                        backgroundColor: "rgba(242, 243, 243, 1)",
                    }}
                />
            );
        case 2:
            return (
                <ThumbUpIcon
                    sx={{
                        width: 42,
                        height: 42,
                        borderRadius: "50%",
                        backgroundColor: "rgba(20, 174, 92, 1)",
                    }}
                    svgSx={{
                        width: 24,
                        height: 24,
                        backgroundColor: "rgba(242, 248, 244, 1)",
                    }}
                />
            );
        case 4:
            return (
                <ThumbUpIcon
                    sx={{
                        width: 42,
                        height: 42,
                        borderRadius: "50%",
                        backgroundColor: "rgba(255, 126, 122, 1)",
                        transform: "scale(1, -1)",
                    }}
                    svgSx={{
                        width: 24,
                        height: 24,
                        backgroundColor: "rgba(242, 248, 244, 1)",
                    }}
                />
            );
        default:
            return (
                <Box
                    sx={{
                        width: 42,
                        height: 42,
                        borderRadius: "50%",
                        border: "2px dashed rgba(210, 214, 218, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <PlusIcon
                        sx={{ width: 24, height: 24 }}
                        svgSx={{
                            width: 13.5,
                            height: 13.5,
                            color: "rgba(210, 214, 218, 1)",
                        }}
                    />
                </Box>
            );
    }
};

const AnswersComponent = ({
    candidate,
    quizData,
    specificCandidate,
    setResultsModal,
    stageIdx,
}) => {
    const handleCellClick = (answer, questionIdx) => {
        if (!answer) {
            return;
        }
        setResultsModal({
            stageIdx: stageIdx,
            candidateIdx: specificCandidate,
            questionIdx: questionIdx,
        });
    };

    // Render array type answer
    const renderArrayAnswer = (answerArray, question, key) => {
        let indices = answerArray[0].split(",").map((idx) => parseInt(idx));
        // Check if we have a valid answers array and if conversion is needed.
        if (question?.answers?.length) {
            // If the first element is not a valid index, assume it's an answer id
            indices =
                indices &&
                indices.map((answerId) => {
                    return question.answers.findIndex(
                        (ans) => ans.id === answerId
                    );
                });
        }

        if (question.weight) {
            const allCorrect = question.answers.every(
                (answer, idx) =>
                    (answer.is_correct && indices.includes(idx)) ||
                    (!answer.is_correct && !indices.includes(idx))
            );
            return (
                <Box
                    key={key}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flex: 1,
                        width: "100%",
                    }}
                >
                    {allCorrect ? (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                <CheckIcon
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        border: "2px solid rgba(20, 174, 92, 1)",
                                        borderRadius: "50%",
                                    }}
                                    svgSx={{
                                        width: 10,
                                        height: 10,
                                        color: "rgba(20, 174, 92, 1)",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        color: "rgba(20, 174, 92, 1)",
                                    }}
                                >
                                    Прошел
                                </Typography>
                            </Box>
                            <Chip
                                label={`${question.weight} баллов`}
                                sx={{
                                    backgroundColor: "rgba(248, 249, 249, 1)",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                <CloseIcon
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        border: "2px solid rgba(255, 59, 48, 1)",
                                        borderRadius: "50%",
                                    }}
                                    svgSx={{
                                        width: 10,
                                        height: 10,
                                        color: "rgba(255, 59, 48, 1)",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "18px",
                                        color: "rgba(255, 59, 48, 1)",
                                    }}
                                >
                                    Провалил
                                </Typography>
                            </Box>
                            <Chip
                                label={`0 баллов`}
                                sx={{
                                    backgroundColor: "rgba(248, 249, 249, 1)",
                                }}
                            />
                        </>
                    )}
                </Box>
            );
        } else {
            return (
                <Box
                    key={key}
                    sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "293px",
                    }}
                >
                    {indices.map((idx, index) => (
                        <React.Fragment key={index}>
                            {question.answers[idx]?.text}
                            {index < indices.length - 1 && ", "}
                        </React.Fragment>
                    ))}
                </Box>
            );
        }
    };

    // Render answer content based on type
    const renderAnswerContent = (answer, question, index) => {
        switch (question.type) {
            case TEST_STORAGE_CHECKBOX:
            case TEST_STORAGE_RADIO:
                return renderArrayAnswer(answer, question, index);
            case TEST_STORAGE_FILE:
                return (
                    <Typography
                        sx={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "rgba(31, 107, 197, 1)",
                        }}
                    >
                        Прикрепленный файл
                    </Typography>
                );
            case TEST_STORAGE_AUDIO:
                return (
                    <Typography
                        sx={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "rgba(31, 107, 197, 1)",
                        }}
                    >
                        Аудио Файл
                    </Typography>
                );
            case TEST_STORAGE_VIDEO:
                return (
                    <Typography
                        sx={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "rgba(31, 107, 197, 1)",
                        }}
                    >
                        Видео Файл
                    </Typography>
                );
            default:
                return (
                    <Typography
                        sx={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "293px",
                        }}
                    >
                        {answer[0]}
                    </Typography>
                );
        }
    };

    return (
        <>
            {quizData.map((question, questionIdx) => {
                const answer = candidate.answers[questionIdx];
                return (
                    <TableCell
                        key={questionIdx}
                        onClick={() => handleCellClick(answer, questionIdx)}
                        sx={{
                            backgroundColor: "transparent",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: answer
                                    ? "rgba(226, 228, 231, 1)"
                                    : "transparent",
                            },
                        }}
                    >
                        {answer && 0 < answer?.length ? (
                            renderAnswerContent(answer, question, questionIdx)
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "rgba(162, 162, 162, 1)",
                                }}
                            >
                                Нет ответа
                            </Typography>
                        )}
                    </TableCell>
                );
            })}
        </>
    );
};

const CandidateRow = memo(
    ({
        candidate,
        quizData,
        candidateIdx,
        handleChange,
        stageIdx,
        setResultsModal,
    }) => {
        console.log("CandidateRow rendered, " + candidate.full_name);
        const recruiterRef = useRef(null);

        return (
            <TableRow>
                <TableCell sx={{ padding: "26px 24px" }}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "20px ",
                        }}
                    >
                        {candidate.full_name}
                    </Typography>
                </TableCell>

                <TableCell>
                    <Chip
                        label={mapCandidateStatus(candidate.status)}
                        sx={getStatusChipProps(candidate.status)}
                    />
                </TableCell>

                <TableCell>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <StarIcon
                            sx={{
                                widht: 24,
                                height: 24,
                                mr: 1,
                            }}
                            svgSx={{
                                width: 20,
                                height: 20,
                                backgroundColor: candidate?.ai_score_general
                                    ? "rgba(232, 185, 49, 1)"
                                    : "rgba(173, 181, 189, 1)",
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "20px ",
                            }}
                        >
                            {candidate?.ai_score_general || "-"}
                        </Typography>
                    </Box>
                </TableCell>

                <TableCell sx={{ position: "relative" }}>
                    <Tooltip
                        title={
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Box
                                    onClick={() =>
                                        handleChange(
                                            recruiterRef?.current,
                                            stageIdx,
                                            candidateIdx,
                                            null
                                        )
                                    }
                                    sx={{
                                        width: 42,
                                        height: 42,
                                        borderRadius: "50%",
                                        border: "2px dashed rgba(210, 214, 218, 1)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        "&:hover": {
                                            borderColor:
                                                "rgba(210, 214, 218, 0.8)",
                                        },
                                    }}
                                >
                                    <PlusIcon
                                        sx={{
                                            width: 24,
                                            height: 24,
                                        }}
                                        svgSx={{
                                            width: 13.5,
                                            height: 13.5,
                                            color: "rgba(210, 214, 218, 1)",
                                        }}
                                    />
                                </Box>
                                <StarIcon
                                    onClick={() =>
                                        handleChange(
                                            recruiterRef?.current,
                                            stageIdx,
                                            candidateIdx,
                                            1
                                        )
                                    }
                                    sx={{
                                        width: 42,
                                        height: 42,
                                        borderRadius: "50%",
                                        backgroundColor:
                                            "rgba(232, 185, 49, 1)",
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(232, 185, 49, 0.8)",
                                        },
                                    }}
                                    svgSx={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: "white",
                                    }}
                                />
                                <ThumbUpIcon
                                    onClick={() =>
                                        handleChange(
                                            recruiterRef?.current,
                                            stageIdx,
                                            candidateIdx,
                                            2
                                        )
                                    }
                                    sx={{
                                        width: 42,
                                        height: 42,
                                        borderRadius: "50%",
                                        backgroundColor: "rgba(20, 174, 92, 1)",
                                        cursor: "pointer",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(20, 174, 92, 0.8)",
                                        },
                                    }}
                                    svgSx={{
                                        width: 24,
                                        height: 24,
                                        backgroundColor:
                                            "rgba(242, 248, 244, 1)",
                                    }}
                                />
                                <MinusIcon
                                    onClick={() =>
                                        handleChange(
                                            recruiterRef?.current,
                                            stageIdx,
                                            candidateIdx,
                                            3
                                        )
                                    }
                                    sx={{
                                        width: 42,
                                        height: 42,
                                        borderRadius: "50%",
                                        backgroundColor:
                                            "rgba(165, 169, 173, 1)",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(165, 169, 173, 0.8)",
                                        },
                                        cursor: "pointer",
                                    }}
                                    svgSx={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor:
                                            "rgba(242, 243, 243, 1)",
                                    }}
                                />
                                <ThumbUpIcon
                                    onClick={() =>
                                        handleChange(
                                            recruiterRef?.current,
                                            stageIdx,
                                            candidateIdx,
                                            4
                                        )
                                    }
                                    sx={{
                                        width: 42,
                                        height: 42,
                                        borderRadius: "50%",
                                        backgroundColor:
                                            "rgba(255, 126, 122, 1)",
                                        cursor: "pointer",
                                        transform: "scale(1, -1)",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(255, 126, 122, 0.8)",
                                        },
                                    }}
                                    svgSx={{
                                        width: 24,
                                        height: 24,
                                        backgroundColor:
                                            "rgba(242, 248, 244, 1)",
                                    }}
                                />
                            </Box>
                        }
                        arrow
                        slotProps={{
                            tooltip: {
                                sx: {
                                    color: "rgba(46, 64, 82, 1)",
                                    backgroundColor: "white",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "16px",
                                    boxShadow:
                                        "0px 4px 10px rgba(0, 0, 0, 0.2)", // Added shadow
                                },
                            },
                            arrow: {
                                sx: {
                                    color: "white",
                                },
                            },
                        }}
                        placement={"top"}
                    >
                        <Box
                            ref={recruiterRef}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {renderAssessmentIcon(
                                parseInt(candidate?.recruiter_score)
                            )}
                        </Box>
                    </Tooltip>
                </TableCell>
                <AnswersComponent
                    candidate={candidate}
                    quizData={quizData}
                    specificCandidate={candidateIdx}
                    setResultsModal={setResultsModal}
                    stageIdx={stageIdx}
                />
            </TableRow>
        );
    }
);

const InterviewTable = memo(
    ({ stageInfo, stageIdx, setResultsModal, handleUpdate }) => {
        console.log("rendered");

        return (
            <Box>
                <Typography
                    sx={{
                        fontSize: "20px",
                        lineHeight: "24px",
                        fontWeight: 500,
                        color: "rgba(121, 128, 136, 1)",
                        mb: 2,
                    }}
                >
                    {stageInfo.stage_name}
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow
                                sx={{
                                    borderBottom:
                                        "1px solid rgba(226, 228, 231, 1)",
                                    backgroundColor: "rgba(248, 249, 249, 1)",
                                }}
                            >
                                <TableCell sx={{ minWidth: "30svw" }}>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            lineHeight: "18px ",
                                        }}
                                    >
                                        ФИО кандидата
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            lineHeight: "18px ",
                                        }}
                                    >
                                        Статус
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            lineHeight: "18px ",
                                        }}
                                    >
                                        Рейтинг
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            lineHeight: "18px ",
                                        }}
                                    >
                                        Оценка
                                    </Typography>
                                </TableCell>
                                {stageInfo?.test?.questions?.map(
                                    (quiz, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                width: "fit-content",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    lineHeight: "18px ",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    width: "293px",
                                                }}
                                            >
                                                {`#${index + 1} - ${quiz.text}`}
                                            </Typography>
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stageInfo.test_candidates?.map(
                                (candidate, index) => (
                                    <CandidateRow
                                        stageIdx={stageIdx}
                                        candidate={candidate}
                                        quizData={stageInfo?.test?.questions}
                                        candidateIdx={index}
                                        key={index}
                                        handleChange={handleUpdate}
                                        setResultsModal={setResultsModal}
                                    />
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }
);

const InterviewTab = ({ vacancyName, vacancyId }) => {
    const [resultsModal, setResultsModal] = useState({
        stageIdx: null,
        candidateIdx: null,
        questionIdx: null,
    });
    const queryKey = useRef(["results"]);
    const queryClient = useQueryClient();

    const [filters, setFilters] = useState({
        name: "",
        selectedStatuses: [], // multiple statuses
        ratingRange: [1, 5], // integer slider from 1 to 5
        selectedScores: [], // multiple recruiter scores
    });

    const [openFilter, setOpenFilter] = useState(false);
    const [newComment, setNewComment] = useState({
        anchorEl: null,
        stageIdx: null,
        candidateIdx: null,
        score: null,
    });

    const {
        data: testData,
        isLoading,
        isError,
        error,
    } = useQuery(queryKey.current, () => fetchResults(vacancyId), {
        refetchOnWindowFocus: false,
    });

    const { mutate: updateRecruiterScoreMutation } = useMutation(
        changeRecruiterScore,
        {
            onSuccess: ({ updatedScore, resultId }) => {
                queryClient.setQueryData(queryKey.current, (oldData) => {
                    if (!oldData || !oldData.data) return null;

                    return {
                        ...oldData, // Keep the original structure
                        data: oldData.data.map((stage) => ({
                            ...stage,
                            test_candidates: stage.test_candidates.map(
                                (candidate) =>
                                    candidate.result_id === resultId
                                        ? {
                                              ...candidate,
                                              recruiter_score: updatedScore,
                                          }
                                        : candidate
                            ),
                        })),
                    };
                });
            },
        }
    );

    const handleUpdatePhase1 = useCallback(
        (targetRef, stageIdx, candidateIdx, score) => {
            if (!testData?.data[stageIdx]?.test_candidates[candidateIdx])
                return;

            const resultId =
                testData.data[stageIdx].test_candidates[candidateIdx].result_id;

            if (targetRef) {
                setNewComment({
                    anchorEl: targetRef,
                    stageIdx,
                    candidateIdx,
                    score,
                });
            } else {
                updateRecruiterScoreMutation({
                    resultId,
                    recruiter_score: score,
                });
            }
        },
        [updateRecruiterScoreMutation, testData]
    );

    const createMutation = useMutation(createComment);

    const handleUpdatePhase2 = useCallback(
        (text) => {
            if (text) {
                createMutation.mutate({
                    candidateId:
                        testData?.data[newComment.stageIdx]?.test_candidates[
                            newComment.candidateIdx
                        ]?.id,
                    text: text,
                    is_visible: true,
                    score: newComment.score,
                });
            }
            updateRecruiterScoreMutation({
                resultId:
                    testData?.data[newComment.stageIdx]?.test_candidates[
                        newComment.candidateIdx
                    ]?.result_id,
                recruiter_score: newComment.score,
            });
            handleCloseComment();
        },
        [updateRecruiterScoreMutation, newComment]
    );

    const handleCloseComment = useCallback(() => {
        setNewComment({
            anchorEl: null,
            stageIdx: null,
            candidateIdx: null,
            score: null,
        });
    }, []);

    const handleClearFilters = useCallback(() => {
        setFilters({
            name: "",
            selectedStatuses: [],
            ratingRange: [1, 5],
            selectedScores: [],
        });
    }, []);

    const handleOpenFilter = useCallback(() => setOpenFilter(true), []);
    const handleCloseFilter = useCallback(() => setOpenFilter(false), []);

    const handleStatusChange = useCallback((field, status, checked) => {
        setFilters((prev) => {
            const target = prev[field];
            let updatedStatuses;
            if (checked) {
                // add status
                updatedStatuses = [...target, status];
            } else {
                // remove status
                updatedStatuses = target.filter((s) => s !== status);
            }
            return { ...prev, [field]: updatedStatuses };
        });
    }, []);

    const closeResultsModal = useCallback(() => {
        setResultsModal({
            stageIdx: null,
            candidateIdx: null,
            questionIdx: null,
        });
    }, []);

    const handleField = useCallback((field, value) => {
        setFilters((prev) => ({
            ...prev,
            [field]: value,
        }));
    }, []);

    const test = testData?.data;

    // Filter logic
    const filteredTestData = useMemo(() => {
        return (
            test &&
            test
                .map((stage) => {
                    const filteredCandidates = stage.test_candidates.filter(
                        (candidate) => {
                            // 1) Name filter
                            const matchesName = filters.name
                                ? candidate.full_name
                                      .toLowerCase()
                                      .includes(filters.name.toLowerCase())
                                : true;

                            // 2) Status filter (if user has selected any statuses, candidate’s status must be in that array)
                            const matchesStatus = filters.selectedStatuses
                                .length
                                ? filters.selectedStatuses.includes(
                                      candidate.status
                                  )
                                : true;

                            // 3) Rating filter (round or floor the candidate’s rating to an integer)
                            const candidateRating = Math.round(
                                candidate.ai_score
                            );
                            const [minRating, maxRating] = filters.ratingRange;
                            const matchesRating = candidate?.ai_score
                                ? candidateRating >= minRating &&
                                  candidateRating <= maxRating
                                : true;

                            // 4) Recruiter score filter
                            const matchesRecruiterScore = filters.selectedScores
                                .length
                                ? filters.selectedScores.includes(
                                      candidate.recruiter_score
                                  )
                                : true;

                            return (
                                matchesName &&
                                matchesStatus &&
                                matchesRating &&
                                matchesRecruiterScore
                            );
                        }
                    );

                    // If the filtered list is exactly the same as before, return the same stage reference
                    if (
                        filteredCandidates.length ===
                            stage.test_candidates.length &&
                        // optionally check if the arrays have the same items
                        filteredCandidates.every(
                            (c, i) => c === stage.test_candidates[i]
                        )
                    ) {
                        return stage; // same object reference
                    }

                    return { ...stage, test_candidates: filteredCandidates };
                })
                .filter((stage) => stage.test_candidates.length > 0)
        );
    }, [testData, filters]);

    return (
        <Box
            sx={{
                backgroundColor: "white",
                pt: "36px",
                display: "flex",
                gap: "24px",
                flexDirection: "column",
                pb: 3,
                minHeight: "560px",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
                <CustomSearch
                    setValue={(value) => {
                        handleField("name", value);
                    }}
                />
                <Box
                    onClick={handleOpenFilter}
                    sx={{
                        border: "1px solid rgba(198, 198, 198, 1)",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        padding: "18px 25px",
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "rgba(242, 242, 242, 1)",
                        },
                        "&:active": { borderColor: "rgba(162, 162, 162, 1)" },
                    }}
                >
                    <FilterIcon
                        sx={{
                            width: 20,
                            height: 20,
                            color: "rgba(173, 181, 189, 1)",
                        }}
                        svgSx={{
                            width: 20,
                            height: 20,
                            color: "rgba(21, 24, 19, 1)",
                        }}
                    />
                    Фильтр
                </Box>
            </Box>
            {isLoading ? (
                <DotsLoader align="center" />
            ) : isError || !test ? (
                <CustomError
                    error={error?.message || " Неопознанная ошибка"}
                    align="center"
                />
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        gap: "36px",
                        flexDirection: "column",
                    }}
                >
                    {filteredTestData.length !== 0 ? (
                        filteredTestData?.map((stageInfo, index) => (
                            <InterviewTable
                                key={index}
                                stageInfo={stageInfo}
                                stageIdx={index}
                                setTestData={() => {}}
                                setResultsModal={setResultsModal}
                                handleUpdate={handleUpdatePhase1}
                            />
                        ))
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: "36px",
                            }}
                        >
                            <EmptyBoxIcon
                                sx={{
                                    width: 250,
                                    height: 200,
                                }}
                                svgSx={{
                                    width: 250,
                                    height: 200,
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "rgba(121, 128, 136, 1)",
                                    textAlign: "center",
                                }}
                            >
                                Собеседования отсутствуют
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
            {resultsModal?.stageIdx !== null &&
                resultsModal?.candidateIdx !== null &&
                resultsModal?.questionIdx !== null && (
                    <TestResultsPreviewModal
                        quizData={test[resultsModal.stageIdx].test.questions}
                        candidate={
                            test[resultsModal.stageIdx].test_candidates[
                                resultsModal.candidateIdx
                            ]
                        }
                        questionIdx={resultsModal.questionIdx}
                        vacancyName={vacancyName}
                        handleClose={closeResultsModal}
                    />
                )}
            {/* Drawer for advanced filters */}
            <FilterDrawer
                open={openFilter}
                onClose={handleCloseFilter}
                filters={filters}
                handleClearFilters={handleClearFilters}
                handleStatusChange={handleStatusChange}
                handleField={handleField}
            />

            {Boolean(newComment.anchorEl) && (
                <ResultsComment
                    open={Boolean(newComment.anchorEl)}
                    anchorEl={newComment.anchorEl}
                    handleClose={handleCloseComment}
                    handleSave={handleUpdatePhase2}
                    scoreInfo={mapScoreToText(newComment.score)}
                />
            )}
        </Box>
    );
};

export default InterviewTab;
