import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, MenuItem, Chip, Autocomplete, Alert, InputAdornment } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { sendTelegramPost } from '../../services/telegramApi';
import { useMutation } from '@tanstack/react-query';



const PublicationSettingsModal = ({ isModalOpen, closeModal, onBack, channelFields, selectedChannel, vacancyId }) => {
    const [selectedValues, setSelectedValues] = useState({});
    const [error, setError] = useState(null);


    const mutation = useMutation({
        mutationFn: ({ vacancy_id, channel_id, fields }) => sendTelegramPost(vacancy_id, channel_id, fields),
        onSuccess: () => closeModal(),
        onError: () => setError("Ошибка при публикации. Попробуйте снова.")
    });

    const handleSelectChange = (fieldName, value, maxCount) => {
        if (value.length > maxCount) return;
        setSelectedValues(prev => ({
            ...prev,
            [fieldName]: value
        }));
    };

    const handlePublish = () => {
        const formattedFields = channelFields.map(field => ({
            sort: field.sort,
            values: Array.isArray(selectedValues[field.name]) ? selectedValues[field.name] : [selectedValues[field.name]]
        }));

        mutation.mutate({
            vacancy_id: vacancyId,
            channel_id: selectedChannel.id,
            fields: formattedFields
        });
    };

    const isFormValid = channelFields?.every(field => selectedValues[field.name] && selectedValues[field.name].length > 0);

    return (
        <Modal open={isModalOpen} onClose={closeModal}>
            <Box sx={{ width: 460, bgcolor: 'white', p: 3, borderRadius: 2, mx: 'auto', my: '10%' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} mb={2}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#E8EFE4" />
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F4F7F2" stroke-width="8" />
                        <path d="M18.8967 26.7969L34.2854 20.1462C35.009 19.8335 35.7932 20.4421 35.6698 21.2207L33.5657 34.4967C33.4358 35.3167 32.4208 35.6304 31.8509 35.0266L28.398 31.3691C27.7208 30.6518 27.6678 29.548 28.273 28.7691L30.711 25.6313C30.8503 25.4519 30.6303 25.216 30.4416 25.3425L25.5943 28.5944C24.7717 29.1462 23.7737 29.3732 22.7933 29.2314L19.1503 28.7045C18.1523 28.5602 17.9711 27.1969 18.8967 26.7969Z" fill="#599D15" />
                    </svg>
                    <CloseIcon
                        onClick={closeModal}
                        sx={{ width: 24, height: 24, cursor: "pointer" }}
                        svgSx={{
                            width: 24,
                            height: 24,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>
                <Typography variant="h6" sx={{ marginTop: '24px', marginBottom: '16px', fontWeight: 600, fontSize: '20px', lineHeight: '24px' }} fontWeight={600}>Публикация в Telegram</Typography>

                <Typography variant="p" sx={{ color: '#747473', fontSize: '16px', fontWeight: 300, lineHeight: '20px', mb: 2 }}>
                    Перед публикацией вакансии выберите подходящие хэштеги и укажите дополнительные параметры.
                </Typography>


                {channelFields?.map((field) => (
                    <Box key={field.name} sx={{ mt: 2 }}>
                        {field.max_count ? (
                            <>
                                <Autocomplete
                                    multiple
                                    limitTags={field.max_count}
                                    options={field.values}
                                    value={selectedValues[field.name] || []}
                                    onChange={(event, newValue) => handleSelectChange(field.name, newValue.slice(0, field.max_count), field.max_count)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={field.placeholder}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment style={{ paddingLeft: '5px' }} position="start">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.2915 9.58464C2.2915 13.6117 5.55609 16.8763 9.58317 16.8763C13.6102 16.8763 16.8748 13.6117 16.8748 9.58464C16.8748 5.55756 13.6102 2.29297 9.58317 2.29297C5.55609 2.29297 2.2915 5.55756 2.2915 9.58464ZM9.58317 18.1263C4.86574 18.1263 1.0415 14.3021 1.0415 9.58464C1.0415 4.8672 4.86574 1.04297 9.58317 1.04297C14.3006 1.04297 18.1248 4.8672 18.1248 9.58464C18.1248 11.7184 17.3424 13.6694 16.0489 15.1665L18.7751 17.8927C19.0192 18.1368 19.0192 18.5325 18.7751 18.7766C18.531 19.0207 18.1353 19.0207 17.8912 18.7766L15.165 16.0504C13.668 17.3439 11.7169 18.1263 9.58317 18.1263Z" fill="#ADB5BD" />
                                                        </svg>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                    renderTags={() => null}
                                />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                                    {(selectedValues[field.name] || []).map((option) => (
                                        <Chip key={option} label={option} onDelete={() => handleSelectChange(field.name, selectedValues[field.name].filter(item => item !== option))} />
                                    ))}
                                </Box>
                                {selectedValues[field.name]?.length >= field.max_count && (
                                    <Alert severity="warning" sx={{ mt: 1 }}>Достигнут лимит в {field.max_count} элемента(ов)</Alert>
                                )}
                            </>
                        ) : (
                            <TextField select fullWidth label={field.placeholder} value={selectedValues[field.name] || ''} onChange={(e) => handleSelectChange(field.name, e.target.value)}>
                                {field.values.map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </TextField>
                        )}
                    </Box>
                ))}
                <Box display="flex" justifyContent="space-between" mt={3}>
                    <Button
                        variant="outlined"
                        sx={{
                            marginRight: 2,
                            height: "46px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            color: "#2E4052",
                            borderColor: "#2E4052",
                            fontSize: "16px",
                            fontWeight: "400",
                            flex: 1
                        }}
                        onClick={onBack}
                    >
                        Назад
                    </Button>
                    <Button
                        onClick={handlePublish}
                        variant="contained"
                        sx={{
                            backgroundColor: "#599D15",
                            color: "white",
                            height: "46px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            fontSize: "16px",
                            fontWeight: "400",
                            flex: 1
                        }}
                        color="success"
                        disabled={!isFormValid || mutation.isLoading}
                    >
                        {mutation.isLoading ? "Публикация..." : "Опубликовать"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PublicationSettingsModal;
