import { useCallback, useRef, useState } from "react";
import { Divider, Typography, Box } from "@mui/material";
import PageContainer from "../../../components/common/PageContainer";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    createDepartment,
    deleteDepartment,
    fetchDepartments,
    updateDepartment,
} from "../../../services/departmentsApi";
import { CircularLoading } from "../../../components/tapHrStyledComponents";
import { CustomError } from "../../../components/styledComponents/CustomErrors";
import DepartmentRow from "../../../components/departments/DepartmentRow";
import { DeleteModalWindow } from "../../../components/common/modalWindows/deleteModalWindow";
import CreateDepartment from "../../../components/departments/CreateDepartment";
import { PlusIcon } from "../../../components/svgComponents";
import ErrorAlert from "../../../components/common/alerts/ErrorAlert";
import { useNavigate } from 'react-router-dom';

const PADDING_WITH_ICON = 28;
const PADDING_WITHOUT_ICON = 4;
const MAX_LEVEL = 4;

const underUpdate = {}

const DepartmentsPage = () => {
    const queryKey = useRef(["departments"]);
    const queryClient = useQueryClient();
    const [newChildInput, setNewChildInput] = useState(false);
    const [deleting, setDeleting] = useState({ idx: null, name: null });
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const {
        data: departmentsData,
        isLoading: isDepartmentsDataLoading,
        isError: isDepartmentsDataError,
        error: departmentsDataError,
    } = useQuery(queryKey.current, fetchDepartments, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });

    const handleDataChange = useCallback(
        (data) => {
            queryClient.setQueryData(queryKey.current, (oldData) => {
                return {
                    ...oldData,
                    data: data,
                };
            });
        },
        [queryClient]
    );

    const deleteMutation = useMutation(deleteDepartment, {
        onSuccess: ({ data }) => {
            handleDataChange(data);
        },
        onError: (error) => {
            if (error?.response?.status === 400 && error.response.data
                && error.response.data?.data?.id && error.response.data?.message && error.response.data?.data?.name
            ) {
                setError({
                    vacancyId: error?.response?.data?.data?.id,
                    message: error?.response?.data?.message,
                    vacancyName: error?.response?.data?.data?.name,
                });
            } else {
                setError({
                    message: error?.message || "Неопознанная ошибка",
                });
            }
        },
    });

    const {mutate : createMutate} = useMutation(createDepartment, {
        onSuccess: ({ data }) => {
            handleDataChange(data);
        },
    });

    const {mutate : updateMutate} = useMutation(updateDepartment, {
        onSuccess: ({ data, id }) => {
            handleDataChange(data?.data);
            delete underUpdate[id];
        },
    });

    const handleDelete = useCallback(
        (departmentId) => {
            deleteMutation.mutate(departmentId);
            setDeleting({ idx: null, name: null });
        },
        [deleteMutation]
    );

    const handleUpdating = useCallback(
        (departmentId) => {
            underUpdate[departmentId] = true;
        }, 
    [underUpdate]);

    const handleEdit = useCallback(
        (departmentId, name) => {
            updateMutate({ id: departmentId, name: name });
            handleUpdating(departmentId);
        },
        [updateMutate]
    );

    const handleCreate = useCallback(
        (name, parentId) => {
            createMutate({ name: name, parent_id: parentId });
        },
        [createMutate]
    );

    const handleNewForRow = (name, parentId) => {
        handleCreate(name, parentId);
        setNewChildInput(false);
    };

    const departments = departmentsData?.data || [];
    return (
        <PageContainer>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    minHeight: "calc(100vh - 200px)",
                }}
            >
                <Box
                    sx={{
                        padding: "24px 24px 8px 24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            fontSize: "24px",
                            lineHeight: "28px",
                        }}
                    >
                        Отделы
                    </Typography>
                    <Divider
                        sx={{ backgroundColor: "rgba(235, 238, 239, 1)" }}
                    />
                </Box>
                {isDepartmentsDataLoading ? (
                    <CircularLoading />
                ) : isDepartmentsDataError ? (
                    <CustomError
                        error={
                            departmentsDataError?.message ||
                            "Неопознанная причина"
                        }
                    />
                ) : (
                    <Box
                        sx={{
                            padding: "0px 24px 24px 24px",
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "rgba(229, 230, 231, 1)",
                                padding: "13px 16px",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                fontSize: "14px",
                                lineHeight: "18px",
                                fontWeight: 500,
                                color: "rgba(116, 116, 115, 1)",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            Название отдела
                            <PlusIcon
                                onClick={() => setNewChildInput(true)}
                                sx={{
                                    width: 46,
                                    height: 46,
                                    cursor: "pointer",
                                    borderRadius: "8px",
                                    "&:hover": {
                                        backgroundColor:
                                            "rgba(242, 243, 243, 1)",
                                    },
                                }}
                                svgSx={{
                                    width: 12,
                                    height: 12,
                                    color: "rgba(46, 64, 82, 1)",
                                    hoverColor: "rgba(41, 57, 73, 1)",
                                    pressedColor: "rgba(36, 50, 64, 1)",
                                }}
                            />
                        </Box>
                        {newChildInput && (
                            <CreateDepartment
                                setNewChildInput={setNewChildInput}
                                handleNew={handleNewForRow}
                                paddingLeft={`16px`}
                                parentId={null}
                            />
                        )}
                        {departments.map((department) => {
                            return (
                                <DepartmentRow
                                    key={department.id}
                                    title={department.name}
                                    id={department.id}
                                    nodes={department.children}
                                    setDeleting={setDeleting}
                                    handleNew={handleCreate}
                                    handleEdit={handleEdit}
                                    level={1}
                                    maxLevel={MAX_LEVEL}
                                    paddingLeft={PADDING_WITH_ICON}
                                />
                            );
                        })}
                    </Box>
                )}
            </Box>
            {deleting?.idx && (
                <DeleteModalWindow
                    text={deleting?.name || "Отдел"}
                    title="отдела"
                    subtitle="этот отдел"
                    handleDelete={() => {
                        handleDelete(deleting.idx);
                        setDeleting({ idx: null, name: null });
                    }}
                    handleClose={() => setDeleting({ idx: null, name: null })}
                />
            )}
            {error && (
                <ErrorAlert
                    handleClose={() => setError(null)}
                    title="Удаление отдела невозможно"
                    message={error?.message}
                    additionInformation={{
                        target: "Вакансия",
                        name: error?.vacancyName,
                        clickable: error?.vacancyId,
                        onClick: () => {
                            navigate(`/vacancy/${error?.vacancyId}`);
                        },
                    }}
                />
            )}
        </PageContainer>
    );
};

export default DepartmentsPage;
