
// Импортируйте axiosClassic из конфигурации
import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";

/**
 * 
 * @param {String} searchQuery - search query default empty string
 * @param {Object} additional - additional object
 * @returns {Object} - object that contains data and meta
 */

export const fetchCategories = async ({searchQuery, additional}) => {
    try {
        const response = await axiosClassic.get(`/api/vacancy/categories`, {
            params: {
                ...(searchQuery ? { name: searchQuery } : {}),
                ...(typeof additional.is_theme !== 'undefined' && { is_theme: additional.is_theme }),
            }
        });
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
};