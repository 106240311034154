import { useState } from "react";
import {
    BookPlusIcon,
    CloseIcon,
} from "../../svgComponents";
import { Dialog, Box, Typography, DialogActions, Button } from "@mui/material";
import CustomInput from "../../styledComponents/CustomInput";

const SaveTestTemplateModal = ({ handleClose, handleSave }) => {
    const [text, setText] = useState("");
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "403px",
                    maxWidth: "unset",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    boxShadow: "0px 5px 50px 0px rgba(0, 0, 0, 0.1)",
                },
            }}
        >
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {/* Header */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 0,
                        marginBottom: "24px",
                        position: "relative",
                        height: "48px",
                    }}
                >
                    {/* Icon Circle */}
                    <Box
                        sx={{
                            width: 56,
                            height: 56,
                            backgroundColor: "rgba(232, 239, 228, 1)",
                            border: "8px solid rgba(244, 247, 242, 1)",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            left: "-4px",
                            top: "-4px",
                        }}
                    >
                        <BookPlusIcon
                            sx={{
                                width: 24,
                                height: 24,
                                borderRadius: "50%",
                            }}
                            svgSx={{
                                width: 24,
                                height: 24,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                    </Box>

                    {/* Close Button */}
                    <CloseIcon
                        onClick={handleClose}
                        sx={{
                            width: 24,
                            height: 24,
                            cursor: "pointer",
                        }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            color: "rgba(0,0,0, 0.5)",
                            hoverColor: "rgba(0,0,0, 0.75)",
                            pressedColor: "rgba(0,0,0, 1)",
                        }}
                    />
                </Box>

                {/* Title */}
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        fontSize: 20,
                        lineHeight: "24px",
                        marginBottom: "16px",
                    }}
                >
                    {/* If data is loaded, show the actual test name; otherwise fallback */}
                    Создание теста
                </Typography>

                {/* Subtitle  */}
                <Box
                    sx={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                        marginBottom: "16px",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: "20px",
                            color: "rgba(116, 116, 115, 1)",
                        }}
                    >
                        Чтобы сохранить данный тест в шаблонах, введите название теста
                    </Typography>
                </Box>

                <CustomInput
                    value={text}
                    onChange={setText}
                    sx={{
                        border : "1px solid rgba(226, 228, 231, 1)",
                        borderRadius: "8px",
                        inputPadding: "18px 16px",
                    }}
                />
            </Box>
            <DialogActions sx={{ justifyContent: "center", mt: 3, padding: 0 }}>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    sx={{
                        flex: 1,
                        color: "rgba(46, 64, 82, 1)",
                        borderColor: "rgba(46, 64, 82, 1)",
                        height: "44px",
                        borderRadius: "8px",
                        padding: "13px 20px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(242, 243, 243, 1)",
                        },
                    }}
                >
                    Отменить
                </Button>
                <Button
                    onClick={() => {
                        handleSave(text);
                        handleClose();
                    }}
                    disabled={!text}
                    variant="contained"
                    sx={{
                        flex: 1,
                        backgroundColor: "rgba(89, 157, 21, 1)",
                        color: "#fff",
                        height: "44px",
                        borderRadius: "8px",
                        padding: "13px 20px",
                        boxShadow: "none",
                        "&:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                            boxShadow: "none !important",
                        },
                        "&:active": {
                            backgroundColor: "rgba(69, 122, 16, 1)",
                        },
                    }}
                >
                    Добавить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SaveTestTemplateModal;
