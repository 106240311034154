import React, { useState, useRef } from "react";
import { Container, Box, Paper } from "@mui/material";
import { Pagination } from "../../components/tapHrStyledComponents";
import FilterBar from "../../components/vacancy/FilterBar";
import VacancyTable from "../../components/vacancy/VacancyTable";
import CreateVacancyModal from "../../components/vacancy/CreateVacancyModal";
import { useQuery } from "@tanstack/react-query";
import { fetchVacancies } from "../../services/vacancyApi";
import { fetchDrafts } from "../../services/draftsApi";
import useDebounce from "../../hooks/useDebounce";

const VacanciesPage = () => {
    const [rowsPerPage] = useState(10); // Количество строк на странице
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [statusId, setStatusId] = useState(1); // Vacancy status id (1 - vacancies, 2 - drafts, etc.)
    const [open, setOpen] = useState(false); // Состояние модального окна

    const { debouncedValue, value, setValue } = useDebounce({
        vacancy: "",
        department: "",
        region: "",
    });

    // Create a query key that also indicates which fetcher is used
    const queryKey = [
        statusId === 2 ? "drafts" : "vacancies",
        statusId,
        currentPage,
        rowsPerPage,
        debouncedValue,
    ];

    // Define a single fetcher that decides which API to call
    const fetcher = async () => {
        const filters = {
            name: debouncedValue.vacancy,
            city_name: debouncedValue.region,
            department_name: debouncedValue.department,
        };

        let result;
        if (statusId === 2) {
            result = await fetchDrafts(currentPage, rowsPerPage, filters);
        } else {
            result = await fetchVacancies(
                currentPage,
                rowsPerPage,
                statusId,
                filters
            );
        }

        // If the result returns no data and we are not on the first page, reduce the page number.
        if (result?.data?.length === 0 && currentPage !== 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
        return result;
    };

    const { data, isLoading, isError, error } = useQuery(queryKey, fetcher, {
        refetchOnWindowFocus: false,
    });

    // Обработчик изменения текущей страницы
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const paginationMeta = data?.meta;

    return (
        <Container
            maxWidth="none"
            sx={{ mt: 3, display: "flex", flexDirection: "column" }}
        >
            <Paper
                elevation={0}
                sx={{
                    flex: 1,
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "calc(90vh - 25px)",
                    backgroundColor: "rgba(248, 249, 249, 1)",
                }}
            >
                {/* Фильтр */}
                <FilterBar
                    label={"Создать вакансию"}
                    handleOpen={handleClickOpen}
                    filter={value}
                    setFilter={setValue}
                />

                {/* Таблица */}
                <Box sx={{ flex: 1, overflowY: "auto", padding: "0" }}>
                    <VacancyTable
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                        vacancies={data?.data}
                        queryKey={queryKey}
                        activeTab={statusId - 1}
                        setActiveTab={setStatusId}
                        setCurrentPage={setCurrentPage}
                    />
                </Box>

                {/* Пагинация и выбор количества строк */}
                {!isLoading && (
                    <Pagination
                        sx={{ my: 3 }}
                        count={paginationMeta?.last_page || 1}
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                )}
            </Paper>

            {/* Модальное окно */}
            <CreateVacancyModal open={open} handleClose={handleClose} />
        </Container>
    );
};

export default VacanciesPage;
