import React, { useState } from "react";
import { Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel, RadioGroup, FormControlLabel, Radio, IconButton, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LabeledInput from "../../components/main/LabeledInput";
import OldCustomAutoComplete from "../../components/main/CustomAutocomplete";
import { fetchVacancies } from "../../services/vacancyApi";
import { fetchCandidates } from "../../services/candidatesApi";
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import useDebounce from "../../hooks/useDebounce";
import { getAddress, createAction } from "../../services/calendarApi";
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom";

const EventModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { data: addressData, isLoading, isError } = useQuery({
        queryKey: ['addressData'], // Важно включить candidateId в ключ
        queryFn: () => getAddress(),
    })

    console.log(addressData)

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Количество строк на странице

    const [title, setTitle] = useState('')
    const [vacancy, setVacancy] = useState([]); // Множественный выбор требует массива
    const [candidates, setCandidates] = useState([])
    const { debouncedValue: debouncedCandidateSearchQuery, setValue: setCandidateSearchQuery } = useDebounce("");
    const [date, setDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [duration, setDuration] = useState('')
    const [type, setType] = useState(0)
    const [meetingAddress, setMeetingAddress] = useState('')
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // error (красный) или success (зелёный)
    const [typeMeet, setTypeMeet] = useState('offline')


    const showSnackbar = (message, severity = "error") => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const mutation = useMutation(createAction, {
        onSuccess: () => {
            queryClient.invalidateQueries(["actions"]); // Обновляем список кандидатов
            showSnackbar("Событие успешно создано!", "success"); // Успешное сообщение (зелёный)
            onClose()
        },
        onError: (error) => {
            console.error("Ошибка при создании события:", error);
            showSnackbar("Ошибка при создании события", "error"); // Сообщение об ошибке (красный)
        }
    })


    // Запрос на вакансии
    const {
        data: vacancyOptions,
        fetchNextPage: fetchNextVacanciesPage,
        hasNextPage: hasNextVacanciesPage,
        isFetchingNextPage: isFetchingNextVacanciesPage,
        isLoading: isVacanciesLoading
    } = useInfiniteQuery(
        ['vacancies'],
        ({ pageParam = 1 }) => fetchVacancies(pageParam, rowsPerPage),
        {
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.data.length === rowsPerPage ? allPages.length + 1 : undefined;
            }
        }
    );

    console.log(vacancyOptions)


    const {
        data: candidateOptions,
        fetchNextPage: fetchNextCandidatesPage,
        hasNextPage: hasNextCandidatesPage,
        isFetchingNextPage: isFetchingNextCandidatesPage,
        isLoading: isCandidatesLoading
    } = useInfiniteQuery(
        ['candidates', vacancy?.id, debouncedCandidateSearchQuery],
        ({ pageParam = 1 }) => fetchCandidates({
            page: pageParam,
            vacancy_id: vacancy?.id,
            searchQuery: debouncedCandidateSearchQuery
        }),
        {
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.data.length === rowsPerPage ? allPages.length + 1 : undefined;
            },
            enabled: !!vacancy?.id // Опционально: включаем запрос только при выбранной вакансии
        }
    );

    console.log(candidateOptions)



    const handleSubmit = () => {
        console.log(title)
        console.log(vacancy)
        console.log(candidates)
        console.log(date)
        console.log(startTime)
        console.log(duration)
        const startDateTime = dayjs(`${date} ${startTime}`, "YYYY-MM-DD HH:mm");

        if (!startDateTime.isValid()) {
            console.error("Некорректная дата", date, startTime);
            showSnackbar("Некорректная дата и время", "error");
            return;
        }

        const endDateTime = startDateTime.add(duration, "minute");

        const payload = {
            title,
            candidate_id: candidates?.id || null,
            vacancy_id: vacancy?.id || null,
            start_time: startDateTime.format("YYYY-MM-DD HH:mm"),
            end_time: endDateTime.format("YYYY-MM-DD HH:mm"),
            type: typeMeet === 'offline' ? 0 : type,
        };
        if (typeMeet === 'offline') {
            payload.meeting_address = meetingAddress
        }
        mutation.mutate(payload)
    }

    return (
        <Dialog open={isOpen} onClose={onClose} sx={{ width: '670px', margin: 'auto', borderRadius: '8px' }} fullWidth>
            <DialogTitle sx={{ padding: '24px' }}>
                Создание события
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: "absolute", right: 16, top: 16 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '24px' }}>
                <LabeledInput
                    label="Название события"
                    placeholder="Введите название события, которое будет отображаться в календаре"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);

                    }}
                />

                <Box sx={{ mt: 2 }}>
                    <OldCustomAutoComplete
                        label="Вакансия"
                        value={vacancy}
                        onChange={setVacancy}
                        fetchNextPage={fetchNextVacanciesPage}
                        hasNextPage={hasNextVacanciesPage}
                        options={vacancyOptions?.pages.flatMap(page => page.data) || []}
                        isLoading={isVacanciesLoading || isFetchingNextVacanciesPage}
                        searchQuery={''}
                        setSearchQuery={() => { }}
                        multiple={false}
                        sx={{ mb: 0, width: '100%', borderRadius: '8px' }}
                    />
                </Box>

                <Box sx={{ mt: 2 }}>

                    <OldCustomAutoComplete
                        label="Кандидаты"
                        value={candidates}
                        onChange={setCandidates}
                        fetchNextPage={fetchNextCandidatesPage}
                        hasNextPage={hasNextCandidatesPage}
                        options={candidateOptions?.pages.flatMap(page => page.data) || []}
                        // isLoading={isCandidatesLoading || isFetchingNextCandidatesPage}
                        searchQuery={''}
                        setSearchQuery={(value) => setCandidateSearchQuery(value)}
                        multiple={false}
                        sx={{ mb: 0, width: '100%', borderRadius: '8px' }}
                        disabled={!vacancy && !vacancy?.id}
                    />
                </Box>

                <div style={{ display: "flex", gap: "16px", marginTop: "24px" }}>
                    <FormControl fullWidth>
                        <TextField onChange={(e) => setDate(e.target.value)} type="date" label="Дата" InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField onChange={(e) => setStartTime(e.target.value)} type="time" label="Время" InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Длительность</InputLabel>
                        <Select onChange={(e) => setDuration(e.target.value)}>
                            <MenuItem value={15}>15 минут</MenuItem>
                            <MenuItem value={30}>30 минут</MenuItem>
                            <MenuItem value={45}>45 минут</MenuItem>
                            <MenuItem value={60}>60 минут</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <FormControl component="fieldset" margin="dense">
                    <RadioGroup onChange={e => setTypeMeet(e.target.value)} row>
                        <FormControlLabel checked={typeMeet === 'offline'} value="offline" control={<Radio />} label="Оффлайн" />
                        <FormControlLabel checked={typeMeet === 'online'} value="online" control={<Radio />} label="Онлайн" />
                    </RadioGroup>
                </FormControl>

                {typeMeet === 'offline' && (
                    <FormControl sx={{ marginTop: '8px' }} fullWidth>
                        <InputLabel>Выберите адрес интервью</InputLabel>
                        <Select onChange={(e) => setMeetingAddress(e.target.value)}>
                            {addressData?.data.map(el => (
                                <MenuItem value={el.city_name + " " + el.address}>{el.city_name} {el.address}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {typeMeet === 'online' && (
                    <FormControl sx={{ marginTop: '8px' }} fullWidth>
                        <InputLabel>Выберите </InputLabel>
                        <Select onChange={(e) => setType(e.target.value)}>
                            <MenuItem value={1}>Google Meet</MenuItem>
                            <MenuItem value={2}>Zoom</MenuItem>
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "space-around", padding: '0 24px 24px' }}>
                <Box sx={{ display: "flex", width: '100%', mt: 1 }}>
                    <Button
                        onClick={() => navigate('/calendar/action')}
                        sx={{
                            marginRight: 2,
                            height: "46px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            color: "#151813",
                            borderColor: "#151813",
                            fontSize: "14px",
                            fontWeight: "400",
                            flex: 1,
                        }}
                    >
                        Полная настройка события
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{
                            backgroundColor: "#599D15",
                            color: "white",
                            height: "46px",
                            boxShadow: "none",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontWeight: "400",
                            flex: 1
                        }}
                    >
                        Далее
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default EventModal;
