import { SettingsIcon } from "../../svgComponents";
import { Chip, Box, Typography } from "@mui/material";

const TemplateChip = ({ template }) => {
    return (
        <Chip
            label={
                <Box
                    sx={{
                        display: "flex",
                        gap: 0.5,
                        alignItems: "center",
                    }}
                >
                    <SettingsIcon
                        sx={{
                            width: 16,
                            height: 16,
                        }}
                        svgSx={{
                            width: 16,
                            height: 16,
                            backgroundColor: template
                                ? "rgba(18, 156, 82, 1)"
                                : "rgba(41, 57, 73, 1)",
                            color: template
                                ? "rgba(18, 156, 82, 1)"
                                : "rgba(41, 57, 73, 1)",
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: template
                                ? "rgba(18, 156, 82, 1)"
                                : "rgba(41, 57, 73, 1)",
                        }}
                    >
                        {template ? "Собственный шаблон" : "Системный шаблон"}
                    </Typography>
                </Box>
            }
        ></Chip>
    );
};

export default TemplateChip;
