import { Box, Typography } from "@mui/material";
import { CircularLoading } from "../tapHrStyledComponents";
import {
    GoogleMeetIcon,
    GoogleCalendarIcon,
    GmailIcon,
    ZoomIcon,
    YandexCalendarIcon,
    YandexMailIcon,
    MSOutlookIcon,
    MSTeamsIcon,
} from "../svgComponents";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteProcess } from "../../services/processesApi";
import { CustomError } from "../styledComponents/CustomErrors";
import { redirectWrapper } from "../../utils/redirectWrapper";

const mappings = {
    google: [
        {
            title: "Google Calendar",
            subtitle: "Календарь",
            icon: (
                <GoogleCalendarIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: "50%",
                    }}
                    svgSx={{ width: 24, height: 24 }}
                />
            ),
        },
        {
            title: "Google Meet",
            subtitle: "Видеосервис",
            icon: (
                <GoogleMeetIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: "50%",
                    }}
                    svgSx={{ width: 24, height: 24 }}
                />
            ),
        },
        {
            title: "Gmail",
            subtitle: "Почта",
            icon: (
                <GmailIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: "50%",
                    }}
                    svgSx={{ width: 24, height: 24 }}
                />
            ),
        },
    ],
    yandex: [
        {
            title: "Yandex Календарь",
            subtitle: "Календарь",
            icon: (
                <YandexCalendarIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: "50%",
                    }}
                    svgSx={{ width: 24, height: 24 }}
                />
            ),
        },
        {
            title: "Yandex почта",
            subtitle: "Почта",
            icon: (
                <YandexMailIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: "50%",
                    }}
                    svgSx={{ width: 24, height: 24 }}
                />
            ),
        },
    ],
    microsoft: [
        {
            title: "Outlook",
            subtitle: "Календарь",
            icon: (
                <MSOutlookIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: "50%",
                    }}
                    svgSx={{ width: 24, height: 24 }}
                />
            ),
        },
        {
            title: "MS Teams",
            subtitle: "Видеосервис",
            icon: (
                <MSTeamsIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: "50%",
                    }}
                    svgSx={{ width: 24, height: 24 }}
                />
            ),
        },
        {
            title: "Outlook",
            subtitle: "Почта",
            icon: (
                <MSOutlookIcon
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: "50%",
                    }}
                    svgSx={{ width: 24, height: 24 }}
                />
            ),
        },
    ],
};

const ConnectingCard = ({ icon, name, type, link, deleteMutation, parent }) => {
    const handleClick = () => {
        if (link) {
            window.location.href = redirectWrapper(
                link,
                "/settings/integrations?tab=1",
                parent
            );
        } else {
            deleteMutation.mutate({ name: parent });
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                padding: "24px",
                gap: "24px",
                backgroundColor: "rgba(248, 249, 249, 1)",
                borderRadius: "8px",
                width: "287.33px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "16px",
                }}
            >
                {icon}
                <Typography
                    sx={{
                        fontSize: "18px",
                        lineHeight: "22px",
                        color: "rgba(21, 24, 19, 1)",
                    }}
                >
                    {name}
                    <Typography
                        component="span"
                        sx={{
                            display: "block",
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "rgba(155, 162, 169, 1)",
                        }}
                    >
                        {type}
                    </Typography>
                </Typography>
            </Box>
            <Box
                component="button"
                onClick={handleClick}
                sx={{
                    backgroundColor: link
                        ? "rgba(89, 157, 21, 1)"
                        : "rgba(255, 59, 48, 1)",
                    "&:hover": {
                        backgroundColor: link
                            ? "rgba(80, 140, 19, 1)"
                            : "rgba(228, 53, 43, 1)",
                    },
                    "&:active": {
                        backgroundColor: link
                            ? "rgba(69, 122, 16, 1)"
                            : "rgba(198, 46, 37, 1)",
                    },
                    border: "none",
                    borderRadius: "8px",
                    padding: "12px 20px",

                    color: "white",
                    fontSize: "16px",
                    lineHeight: "20px",

                    cursor: "pointer",
                }}
            >
                {link ? " Подключить" : "Отключить"}
            </Box>
        </Box>
    );
};

const PossibleProcesses = ({ data, isLoading, isError, error, queryKey }) => {
    const queryClient = useQueryClient();

    const deleteMutation = useMutation(deleteProcess, {
        onSuccess: (data) => {
            queryClient.setQueryData(queryKey, data);
        },
    });

    return (
        <Box
            sx={{
                display: "flex",
                gap: "36px",
                flexWrap: "wrap",
            }}
        >
            {isLoading ? (
                <CircularLoading />
            ) : (isError || !data) ? (
                <CustomError error={error?.message || "Неопознанная причина"} sx={{minHeight: "400px"}} />
            ) : (
                <>
                    {data.google &&
                        mappings.google.map((process, index) => (
                            <ConnectingCard
                                key={index}
                                icon={process.icon}
                                name={process.title}
                                type={process.subtitle}
                                link={
                                    !data.google.connected &&
                                    data.google.auth_url
                                }
                                deleteMutation={deleteMutation}
                                parent="google"
                            />
                        ))}
                    {data.yandex &&
                        mappings.yandex.map((process, index) => (
                            <ConnectingCard
                                key={index}
                                icon={process.icon}
                                name={process.title}
                                type={process.subtitle}
                                link={
                                    !data.yandex.connected &&
                                    data.yandex.auth_url
                                }
                                deleteMutation={deleteMutation}
                                parent="yandex"
                            />
                        ))}
                    {data.microsoft &&
                        mappings.microsoft.map((process, index) => (
                            <ConnectingCard
                                key={index}
                                icon={process.icon}
                                name={process.title}
                                type={process.subtitle}
                                link={
                                    !data.microsoft.connected &&
                                    data.microsoft.auth_url
                                }
                                deleteMutation={deleteMutation}
                                parent="microsoft"
                            />
                        ))}
                    {data.zoom && (
                        <ConnectingCard
                            icon={
                                <ZoomIcon
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                    }}
                                    svgSx={{ width: 24, height: 24 }}
                                />
                            }
                            name="Zoom"
                            type="Видеосервис"
                            link={!data.zoom.connected && data.zoom.auth_url}
                            deleteMutation={deleteMutation}
                            parent="zoom"
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default PossibleProcesses;
