import { Box, Chip, Divider, Typography } from "@mui/material";
import { useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import {
    ListIcon,
    AudioIcon,
    TIcon,
    LinkIcon,
    VideoIcon2,
} from "../../svgComponents";
import { fetchTestQuestionTemplates } from "../../../services/testApi";
import {
    CircularLoading,
    Pagination,
} from "../../tapHrStyledComponents";
import { CustomError } from "../../styledComponents/CustomErrors";
import TemplateChip from "./TemplateChip";
import { TEST_STORAGE_AUDIO, TEST_STORAGE_CHECKBOX, TEST_STORAGE_FILE, TEST_STORAGE_RADIO, TEST_STORAGE_TEXT, TEST_STORAGE_VIDEO } from "../../../utils/testTypesStorage";

const renderTypeChip = (type) => {
    const storage = {
        [TEST_STORAGE_RADIO]: (
            <Chip
                label={
                    <Box
                        sx={{
                            color: "rgba(80, 140, 19, 1)",
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: 500,
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        <ListIcon
                            sx={{ width: 16, height: 16 }}
                            svgSx={{
                                width: 16,
                                height: 16,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                        Один из списка
                    </Box>
                }
                sx={{
                    backgroundColor: "rgba(232, 239, 228, 1)",
                    py: "4px",
                }}
            />
        ),
        [TEST_STORAGE_TEXT]: (
            <Chip
                label={
                    <Box
                        sx={{
                            color: "rgba(58, 134, 255, 1)",
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: 500,
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        <TIcon
                            sx={{ width: 16, height: 16 }}
                            svgSx={{
                                width: 16,
                                height: 16,
                                color: "rgba(58, 134, 255, 1)",
                            }}
                        />
                        Текстовый ответ
                    </Box>
                }
                sx={{
                    backgroundColor: "rgba(237, 244, 255, 1)",
                    py: "4px",
                }}
            />
        ),
        [TEST_STORAGE_CHECKBOX]: (
            <Chip
                label={
                    <Box
                        sx={{
                            color: "rgba(80, 140, 19, 1)",
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: 500,
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        <ListIcon
                            sx={{ width: 16, height: 16 }}
                            svgSx={{
                                width: 16,
                                height: 16,
                                color: "rgba(89, 157, 21, 1)",
                            }}
                        />
                        Несколько из списка
                    </Box>
                }
                sx={{
                    backgroundColor: "rgba(232, 239, 228, 1)",
                    py: "4px",
                }}
            />
        ),
        [TEST_STORAGE_AUDIO]: (
            <Chip
                label={
                    <Box
                        sx={{
                            color: "rgba(175, 82, 222, 1)",
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: 500,
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        <AudioIcon
                            sx={{ width: 16, height: 16 }}
                            svgSx={{
                                width: 16,
                                height: 16,
                                color: "rgba(175, 82, 222, 1)",
                            }}
                        />
                        Аудио ответ
                    </Box>
                }
                sx={{
                    backgroundColor: "rgba(244, 222, 255, 1)",
                    py: "4px",
                }}
            />
        ),
        [TEST_STORAGE_VIDEO]: (
            <Chip
                label={
                    <Box
                        sx={{
                            color: "#EF233C",
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: 500,
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        <VideoIcon2
                            sx={{ width: 16, height: 16 }}
                            svgSx={{
                                width: 16,
                                height: 16,
                                backgroundColor: "#EF233C",
                            }}
                        />
                        Видео ответ
                    </Box>
                }
                sx={{
                    backgroundColor: "rgba(255, 224, 228, 1)",
                    py: "4px",
                }}
            />
        ),
        [TEST_STORAGE_FILE]: (
            <Chip
                label={
                    <Box
                        sx={{
                            color: "rgba(251, 153, 7, 1)",
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: 500,
                            display: "flex",
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        <LinkIcon
                            sx={{ width: 16, height: 16 }}
                            svgSx={{
                                width: 16,
                                height: 16,
                                color: "rgba(251, 153, 7, 1)",
                            }}
                        />
                        Файл
                    </Box>
                }
                sx={{
                    backgroundColor: "rgba(255, 244, 226, 1)",
                    py: "4px",
                }}
            />
        ),
    };

    return storage[type] || null;
};

const QuestionTemplatesHolder = ({ filter, handleNewQuestion }) => {
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const rowsPerPage = useRef(10); // Number of lines
    const queryQuestionKey = useRef(["testQuestionTemplates"]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const {
        data: questionsData,
        isLoading: isQuestionLoading,
        isError: isQuestionsError,
        error: questionsError,
    } = useQuery(
        [queryQuestionKey.current, currentPage, rowsPerPage.current, filter],
        () =>
            fetchTestQuestionTemplates({
                page: currentPage,
                perPage: rowsPerPage.current,
                name: filter.name,
                template: filter.template,
                direction: filter.direction?.id,
                types: filter.types,
                theme: filter.theme?.id,
            }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        }
    );

    if (isQuestionLoading) {
        return <CircularLoading sx={{ height: "80vh" }} />;
    }

    if (isQuestionsError) {
        return (
            <CustomError error={questionsError.message || "Неопознанная причина"} />
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "36px",
                justifyContent: "space-between",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                {questionsData?.data?.map((info, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            p: 2,
                            backgroundColor: "white",
                            borderRadius: "8px",
                            width: "100%",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "18px",
                                lineHeight: "22px",
                                color: "rgba(0, 0, 0, 1)",
                                fontWeight: 500,
                            }}
                        >
                            {info.question.text}
                        </Typography>
                        <Divider
                            sx={{
                                backgroundColor: "rgba(241, 242, 243, 1)",
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "center",
                                }}
                            >
                                {renderTypeChip(info.question.type)}
                                <TemplateChip
                                    template={info?.company_id}
                                />
                            </Box>
                            <Box
                                onClick={() => handleNewQuestion(info.question)}
                                component="button"
                                sx={{
                                    border: "none",
                                    backgroundColor: "rgba(46, 64, 82, 1)",
                                    color: "white",
                                    borderRadius: "8px",
                                    "&:hover": {
                                        backgroundColor: "rgba(29, 40, 52, 1)",
                                    },
                                    "&:active": {
                                        backgroundColor: "rgba(21, 29, 37, 1)",
                                    },
                                    p: "13px 25px",
                                    cursor: "pointer",
                                }}
                            >
                                Добавить
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box>
                {!isQuestionLoading && (
                    <Pagination
                        count={questionsData?.meta?.last_page || 1}
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                )}
            </Box>
        </Box>
    );
};

export default QuestionTemplatesHolder;
