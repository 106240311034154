import React, { useState, memo, useCallback, useRef, useEffect, useMemo } from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    Avatar,
    IconButton,
    Chip,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getTimeStampFomatted } from "../../utils/formatedDate";
import {
    createComment,
    updateComment,
    deleteComment,
} from "../../services/commentsApi";
import { CheckBox } from "../tapHrStyledComponents";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PopUpWindow from "../popUpWindow";
import { mapScoreToText } from "../../utils/aiScore";

const ShowCommentUi = memo(
    ({
        candidateId,
        isChangeble,
        comment,
        handleActivity,
        isEditable,
        setEditableId,
        onEditingRef,
        setPreventClick,
        now,
    }) => {
        const [text, setText] = useState(comment.text);
        const [anchorEl, setAnchorEl] = useState(null);
        const textFieldRef = useRef(null);
        const containerRef = useRef(null);

        useEffect(() => {
            if (isEditable && textFieldRef.current) {
                textFieldRef.current.focus();
                const length = textFieldRef.current.value.length;
                textFieldRef.current.setSelectionRange(length, length);

                onEditingRef && onEditingRef(containerRef.current);
            }
        }, [isEditable]);

        const handleMenuOpen = (event) => {
            event.stopPropagation();
            if (isEditable) {
                setPreventClick(true);
            }
            setAnchorEl(event.currentTarget);
        };

        const handleMenuClose = () => {
            if (isEditable) {
                setPreventClick(false);
            }
            setAnchorEl(null);
        };

        const updateMutation = useMutation(updateComment, {
            onSuccess: (data) => {
                handleActivity(data);
                setEditableId(false);
            },
            onError: (error) => {
                console.error("Error updating comment:", error.message);
            },
        });

        const deleteMutation = useMutation(deleteComment, {
            onSuccess: (data) => {
                handleActivity(data);
            },
            onError: (error) => {
                console.error("Error deleting comment:", error.message);
            },
        });

        const handleDelete = () => {
            deleteMutation.mutate({
                candidateId: candidateId,
                commentId: comment.id,
            });
        };

        const handleUpdate = () => {
            if (!isEditable) {
                setEditableId(comment?.id);
                return;
            }

            if (!text || comment.text === text) {
                setEditableId(null);
                return;
            }

            updateMutation.mutate({
                text: text,
                candidateId: candidateId,
                commentId: comment.id,
            });
        };

        const isLoading = deleteMutation.isLoading || updateMutation.isLoading;

        // Handler for key press events in the text field
        const handleKeyDown = (event) => {
            if (event.key === "Enter" && !event.shiftKey && isEditable) {
                event.preventDefault();
                handleUpdate();
            }
        };

        const scoreInfo = mapScoreToText(comment.score);
        return (
            <Box
                ref={containerRef}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "16px",
                    backgroundColor: "rgba(248, 249, 249, 1)",
                    borderRadius: "8px",
                    position: "relative",
                    pointerEvents: isLoading ? "none" : "auto",
                }}
            >
                {/* Dimmed overlay */}
                {isLoading && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            borderRadius: "8px",
                            backgroundColor: "rgba(44, 44, 44, 0.7)", // Semi-transparent overlay
                            zIndex: 1, // To cover the content
                        }}
                    />
                )}

                {/* Content */}
                <Box sx={{ flex: 1 }}>
                    {/* Header */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "8px",
                            py: "1px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                            }}
                        >
                            {/* Avatar */}
                            <Avatar
                                src={comment.manager.avatar || null}
                                alt={`${comment.manager.name}`}
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    bgcolor: "rgba(89, 157, 21, 1)",
                                    color: "#fff",
                                    fontWeight: "bold",
                                }}
                            />
                            <Box>
                                {/* Name */}
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        color: "rgba(31, 41, 55, 1)",
                                    }}
                                >
                                    {comment.manager.name}
                                </Typography>
                                {/* Time */}
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        lineHeight: "16px",
                                        color: "rgba(107, 114, 128, 1)",
                                    }}
                                >
                                    {getTimeStampFomatted(
                                        new Date(),
                                        comment.created_at
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        {isChangeble && (
                            <IconButton
                                onClick={handleMenuOpen}
                                className="trippleButton"
                                sx={{
                                    padding: "8px",
                                    backgroundColor: "transparent",
                                    borderRadius: "50%",
                                    "&:hover": {
                                        backgroundColor: "white !important",
                                    },
                                }}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        )}
                    </Box>

                    {/* Comment Text */}
                    <TextField
                        placeholder="Добавить комментарий"
                        multiline={true}
                        value={text} // Controlled value
                        onKeyDown={handleKeyDown}
                        inputRef={textFieldRef}
                        onChange={(e) => {
                            if (isEditable) {
                                setText(e.target.value); // Allow typing only if editable
                            }
                        }}
                        sx={{
                            width: "calc(100% - 48px)",
                            "& .MuiInputBase-root": {
                                alignItems: "flex-start",
                                padding: 0,
                                "& fieldset": {
                                    border: "none",
                                    borderRadius: "0px",
                                    borderBottom: isEditable
                                        ? "1px solid rgba(210, 214, 218, 1)"
                                        : "none", // Remove the fieldset border
                                },
                            },
                            "& .MuiInputBase-input": {
                                resize: "none",
                                minHeight: "20px",
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "rgba(31, 41, 55, 1)",
                                padding: 0,
                            },
                            ml: "48px",
                        }}
                        fullWidth
                        slotProps={{
                            input: {
                                readOnly: !isEditable,
                            },
                        }}
                    />

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            ml: "48px",
                            mt: "16px",
                        }}
                    >
                        <Chip
                            label={
                                comment.is_visible ? "Видно всем" : "Только мне"
                            }
                            sx={{
                                backgroundColor: "rgba(242, 244, 250, 1)",
                                borderRadius: "16px",
                                padding: "2px 8px",
                                color: "rgba(28, 96, 176, 1)",
                            }}
                        />
                        {comment?.score && (
                        <Chip
                            label={
                                <Typography
                                    variant="body1"
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        color: scoreInfo?.primaryColor,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "6px",
                                            height: "6px",
                                            borderRadius: "50%",
                                            backgroundColor:
                                                scoreInfo?.primaryColor,
                                            display: "inline-block",
                                            mr: "6px",
                                        }}
                                    />
                                    Поставил (-а) оценку {scoreInfo?.text}
                                </Typography>
                            }
                            sx={{
                                backgroundColor: scoreInfo?.secondaryColor,
                                borderRadius: "16px",
                                padding: "2px 0px",
                                height: "fit-content",
                                "& span": {
                                    padding: "0 6px",
                                },
                            }}
                        />
                    )}
                    </Box>

                    {/* Actions */}
                    {anchorEl && (
                        <PopUpWindow
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            middleItems={[
                                {
                                    id: 1,
                                    name: isEditable ? "Сохранить" : "Изменить",
                                },
                                { id: 2, name: "Удалить" },
                            ]}
                            onClick={(id) => {
                                if (id === 1) {
                                    handleUpdate();
                                } else if (id === 2) {
                                    handleDelete();
                                }
                                handleMenuClose();
                            }}
                        />
                    )}
                </Box>
            </Box>
        );
    }
);

const NewCommentUi = memo(({ candidateId, handleActivity }) => {
    const [focused, setFocused] = useState(false);
    const [text, setText] = useState("");
    const [isChecked, setIsChecked] = useState(true);

    const handleClear = () => {
        setFocused(false); // Reset the focused state
        setText(""); // Reset the value to an empty string
        setIsChecked(true); // Reset the checkbox state
    };

    const createMutation = useMutation(createComment, {
        onSuccess: (data) => {
            handleClear();
            handleActivity(data);
        },
        onError: (error) => {
            console.error("Error saving comment:", error.message);
        },
    });

    console.log("Main comment being rendered");

    const handleSave = () => {
        if (!text) {
            return;
        }

        // Call the API to create a new comment
        createMutation.mutate({
            text: text,
            is_visible: isChecked,
            candidateId: candidateId,
        });
    };

    return (
        <>
            {/* Title */}
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    display: "block",
                }}
            >
                Комментарии
            </Typography>

            {/* Text Area */}
            <TextField
                placeholder="Добавить комментарий"
                multiline
                value={text} // Controlled value
                onChange={(e) => setText(e.target.value)} // Update state on change
                onFocus={() => setFocused(true)}
                sx={{
                    "& .MuiInputBase-root": {
                        alignItems: "flex-start",
                        transition: "min-height 0.3s",
                        padding: "7px 13px",
                        minWidth: 392,
                    },
                    "& .MuiInputBase-input": {
                        resize: "vertical",
                        minHeight: focused ? "93px" : "20px",
                        fontSize: "16px",
                        lineHeight: "20px",
                        padding: 0,
                    },
                }}
                fullWidth
            />

            {/* Checkbox */}
            {focused && (
                <Box
                    onClick={() => setIsChecked((prev) => !prev)}
                    sx={{
                        margin: 0,
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        cursor: "pointer",
                        width: "fit-content",
                    }}
                >
                    <CheckBox checked={isChecked} />
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "18px",
                        }}
                    >
                        Видно всем
                    </Typography>
                </Box>
            )}

            {/* Buttons */}
            {focused && (
                <Box sx={{ display: "flex", gap: "18px" }}>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        sx={{
                            width: "226px",
                            height: "46px",
                            borderRadius: "8px",
                            padding: "12px 20px",
                            backgroundColor: "rgba(89, 157, 21, 1)",
                            color: "#fff",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "rgba(80, 140, 19, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(69, 122, 16, 1)",
                            },
                            flex: 1,
                        }}
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleClear}
                        sx={{
                            width: "226px",
                            height: "46px",
                            borderRadius: "8px",
                            padding: "12px 20px",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            color: "rgba(46, 64, 82, 1)",
                            border: "1px solid rgba(46, 64, 82, 1)",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "rgba(242, 243, 243, 1)",
                            },
                            "&:active": {
                                backgroundColor: "rgba(226, 228, 231, 1)",
                            },
                            flex: 1,
                        }}
                    >
                        Отменить
                    </Button>
                </Box>
            )}
        </>
    );
});

const CommentListUi = memo(({ candidateId, comments, handleActivity }) => {
    const managerId = localStorage.getItem("manager_id") || null;
    const [editableId, setEditableId] = useState(null);
    const [preventClick, setPreventClick] = useState(false);
    const editingContainerRef = useRef(null);

    const now = useMemo(() => new Date(), []);

    const handleEditingRef = useCallback((node) => {
        editingContainerRef.current = node;
    }, []);

    useEffect(() => {
        if (!editableId) return;
        const handleClickOutside = (event) => {
            // If a comment is being edited and the click target is not inside its container, clear editing.
            if (
                editableId &&
                !preventClick &&
                editingContainerRef.current &&
                !editingContainerRef.current.contains(event.target)
            ) {
                setEditableId(null);
            }
        };

        if (editableId) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, [editableId, preventClick, editingContainerRef.current]);

    return (
        <>
            {comments &&
                comments.map((comment) => {
                    return (
                        <ShowCommentUi
                            key={comment.id}
                            candidateId={candidateId}
                            isChangeble={
                                managerId !== null &&
                                +managerId === +comment.manager.id
                            }
                            comment={comment}
                            handleActivity={handleActivity}
                            isEditable={editableId === comment.id}
                            setEditableId={setEditableId}
                            setPreventClick={setPreventClick}
                            onEditingRef={handleEditingRef}
                            now={now}
                        />
                    );
                })}
        </>
    );
});

const CommentSectionUi = memo(({ candidateId, comments, queryKey }) => {
    const queryClient = useQueryClient();

    const handleActivity = useCallback(
        (comments) => {
            if (!comments) {
                return;
            }

            queryClient.setQueryData(queryKey, (oldData) => {
                return {
                    ...oldData, // Preserve all existing properties of oldData
                    data: {
                        ...oldData.data, // Preserve all properties of oldData.data
                        comments: comments.data, // Replace the comments field
                    },
                };
            });
        },
        [queryKey]
    );

    return (
        <Box
            sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                padding: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                borderRadius: "8px",
                transition: "all 0.3s",
            }}
        >
            <NewCommentUi
                candidateId={candidateId}
                handleActivity={handleActivity}
            />
            <CommentListUi
                candidateId={candidateId}
                comments={comments}
                handleActivity={handleActivity}
            />
        </Box>
    );
});

export default CommentSectionUi;
