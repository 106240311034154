import { useState, memo } from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { ArrowUpIcon } from "../svgComponents";

const CustomSelect = memo(
    ({
        children,
        label,
        value,
        options,
        icons,
        placeholder,
        readOnly,
        onChange = () => {},
        sx = {},
        required,
        targetRender,
        targetSave,
    }) => {
        const [open, setOpen] = useState(false);
        return (
            <Box sx={{ flex: 1, ...sx }}>
                {label && (
                    <Box
                        sx={{
                            display: "flex",
                            mb: sx.labelMarginBottom || 1,
                            gap: 0.5,
                            alignItems: "center",
                        }}
                    >
                        <label
                            style={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                fontWeight: 500,
                                display: "block",
                            }}
                        >
                            {label}{" "}
                            {required && (
                                <span style={{ color: "red" }}>*</span>
                            )}
                        </label>
                        {children}
                    </Box>
                )}
                <FormControl
                    sx={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid rgba(226, 228, 231, 1)",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            "& fieldset": { border: "none" },
                            cursor: readOnly ? "default" : "pointer",
                        },
                        "& .MuiInputBase-input": {
                            display: "flex",
                            gap: "8px",
                            padding: "18px 8px 18px 16px !important",
                            fontSize: "16px",
                            lineHeight: "20px",
                            minHeight: "unset!important",
                            width: "calc(100% - 64px)",
                            fontWeight: 500,
                        },
                    }}
                    onClick={() => setOpen(!open)} // Toggle dropdown
                >
                    <Select
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        displayEmpty
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        inputProps={{ readOnly: Boolean(readOnly) }}
                        // This renderValue function ensures that the selected value is shown as the option's name or title
                        renderValue={(selected) => {
                            if (!selected && placeholder) {
                                return (
                                    <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>{placeholder}</span>
                                );
                            }
                            const selectedOption = options.find(
                                (opt) => (targetSave && opt ? opt[targetSave] : opt.id) === selected
                            );
                            return (targetRender && selectedOption) ? selectedOption[targetRender] || "" : selectedOption?.name || "";
                        }}
                        slotProps={{
                            sx: {
                                "& .Mui-selected": {
                                    backgroundColor:
                                        "rgb(222, 80, 33) !important",
                                },
                            },
                        }}
                        IconComponent={() => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    transition: "transform 0.2s ease",
                                    transform: open
                                        ? "rotate(0deg)"
                                        : "rotate(180deg)",
                                }}
                            >
                                <ArrowUpIcon
                                    sx={{ width: 24, height: 24 }}
                                    svgSx={{ width: 10, height: 4 }}
                                />
                            </Box>
                        )}
                    >
                        {placeholder && (
                            <MenuItem value="" disabled>
                                {placeholder}
                            </MenuItem>
                        )}
                        {options.map((option, index) => (
                            <MenuItem
                                sx={{
                                    padding: "16px",
                                    display: "flex",
                                    gap: "8px",
                                }}
                                key={index}
                                value={targetSave && option ? option[targetSave] : option.id}
                            >
                                {icons && icons[index]}
                                {(targetRender && option) ? option[targetRender] || "" : option?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        );
    }
);

export default CustomSelect;
