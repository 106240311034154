import CheckboxComponent from "./CheckboxComponent";
import AudioComponent from "./AudioComponent";
import VideoComponent from "./VideoComponent";
import FileComponent from "./FileComponent";
import TextComponent from "./TextComponent";
import RadioComponent from "./RadioComponent";
import { Box, Divider, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import {
    ArrowWithStickIcon,
    SaveIcon,
    DuplicateIcon,
    TrashIcon,
    DragIcon,
    InformationIcon,
} from "../../svgComponents";
import CustomInput from "../../styledComponents/CustomInput";
import { LinkIcon } from "../../svgComponents";
import ImageWrapper from "./ImageWrapper";
import { CustomToolTip } from "../../tapHrStyledComponents";
import {
    TEST_STORAGE_AUDIO,
    TEST_STORAGE_CHECKBOX,
    TEST_STORAGE_FILE,
    TEST_STORAGE_RADIO,
    TEST_STORAGE_TEXT,
    TEST_STORAGE_VIDEO,
} from "../../../utils/testTypesStorage";
import { GreenSwitch } from "../CustomSwitch";

const BaffedButton = ({ onClick, condition, children, tooltip }) => {
    return (
        <CustomToolTip title={tooltip} placement="top">
            <Box>
                <Box
                    component="button"
                    onClick={onClick}
                    disabled={condition}
                    sx={{
                        width: 46,
                        height: 46,
                        cursor: "pointer",
                        borderRadius: "8px",
                        backgroundColor: "transparent",
                        "&:hover": {
                            backgroundColor: condition
                                ? "transparent"
                                : "rgba(242, 243, 243, 1)",
                        },
                        "&:active": {
                            backgroundColor: condition
                                ? "transparent"
                                : "rgba(226, 228, 231, 1)",
                        },
                        border: "none",
                    }}
                >
                    {children}
                </Box>
            </Box>
        </CustomToolTip>
    );
};

const QuestionWrapperActive = memo(
    ({
        disableUp,
        disableDown,
        data,
        type,
        onClick,
        index,
        types,
        handleSwitch,
        handleDuplicate,
        handleDelete,
        isOnly,
        handleQuestion,
        handleQuestionField,
        isNull,
        provided,
        questionInputRef,
        handleCreateQuestionTemplate,
    }) => {
        const allowedImageTypes = ["image/png", "image/jpg", "image/jpeg"];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB in bytes
        const questionInputStyle = useMemo(() => ({
            flex: 1,
            border: isNull 
              ? "1px solid rgba(255, 59, 48, 1)" 
              : "1px solid rgba(226, 228, 231, 1)",
            borderRadius: "8px",
            inputPadding: "18px 16px",
          }), [isNull]);
        console.log("Question wrapper active rendered");

        const handleUpload = (e) => {
            const file = e.target.files[0];
            if (!file) {
                return;
            }

            if (!allowedImageTypes.includes(file.type)) {
                alert("Недопустимый формат файла");
                return;
            }

            if (file.size > maxFileSize) {
                alert("Файл слишком большой");
                return;
            }

            handleQuestionField(index, "image_path", file);
            e.target.value = "";
        };

        const isFirst = disableUp;
        const isLast = disableDown;
        const disable = data.text === "";

        const handleQuestionText = useCallback(
            (value) => {
                handleQuestionField(index, "text", value);
            },
            [handleQuestionField, index]
        );

        const deleteImage = useCallback((index) => {
            handleQuestionField(index, "image_path", null);
        }, [handleQuestionField, index]);

        useEffect(() => {
            if (questionInputRef.current) {
                questionInputRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                });
            }
        }, []);

        return (
            <Box
                {...provided.draggableProps}
                ref={questionInputRef}
                onClick={() => onClick(index)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "614px",
                    gap: "24px",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    position: "relative",
                    transform: "translateZ(0)",
                    willChange: "transform",
                    outline: "none",
                    pt: 2,
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "white",
                        padding: "16px",
                        border: "2px solid rgba(175, 202, 162, 1)",
                        borderRadius: "8px",
                    }}
                    ref={provided.innerRef}
                >
                    <Box {...provided.dragHandleProps}>
                        <DragIcon
                            onMouseDown={() => onClick(index)}
                            sx={{
                                width: 48,
                                height: 48,
                                position: "absolute",
                                top: 0,
                                left: "-56px",
                            }}
                            svgSx={{
                                width: 24,
                                height: 24,
                                color: "rgba(165, 169, 173, 0.5)",
                                hoverColor: "rgba(173, 165, 165, 0.75)",
                                pressedColor: "rgba(165, 169, 173, 1)",
                                backgroundColor: "rgba(165, 169, 173, 0.5)",
                                hoverBackColor: "rgba(165, 169, 173, 0.75)",
                                pressedBackColor: "rgba(165, 169, 173, 1)",
                            }}
                            noFill={true}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            // minHeight: "158px",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "8px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "24px",
                            }}
                        >
                            {/* Order and Question Input */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "13px",
                                    position: "relative",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "46px",
                                        height: "46px",
                                        borderRadius: "50%",
                                        backgroundColor: "#e0e0e0",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: 500,
                                        border: "6px solid rgba(242, 243, 243, 1)",
                                        position: "relative",
                                        left: "-3px",
                                    }}
                                >
                                    {index + 1}
                                </Box>
                                <CustomInput
                                    value={data.text}
                                    placeholder="Введите вопрос"
                                    onChange={handleQuestionText}
                                    sx={questionInputStyle}
                                />
                                <CustomToolTip
                                    title={"Загрузить изображение"}
                                    placement="top"
                                >
                                    <Box
                                        sx={{
                                            position: "relative",
                                            "&:hover": {
                                                backgroundColor:
                                                    "rgba(242, 243, 243, 1)",
                                            },
                                            "&:active": {
                                                backgroundColor:
                                                    "rgba(226, 228, 231, 1)",
                                            },
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <LinkIcon
                                            sx={{
                                                width: 46,
                                                height: 46,
                                            }}
                                            svgSx={{
                                                width: 24,
                                                height: 24,
                                                color: "rgba(173, 181, 189, 1)",
                                            }}
                                        />
                                        <input
                                            accept=".png, .jpg, .jpeg"
                                            type="file"
                                            onChange={handleUpload}
                                            style={{
                                                position: "absolute",
                                                width: "100%",
                                                height: "100%",
                                                opacity: 0,
                                                top: 0,
                                                left: 0,
                                                cursor: "pointer",
                                            }}
                                        />
                                    </Box>
                                </CustomToolTip>
                            </Box>
                            {data.image_path && (
                                <Box sx={{ position: "relative" }}>
                                    <ImageWrapper
                                        imgKey={data.image_path}
                                        imageFit="cover"
                                        imageRadius={4}
                                        index={index}
                                        onClick={deleteImage}
                                    />
                                </Box>
                            )}
                            {type === TEST_STORAGE_VIDEO && (
                                <VideoComponent
                                    data={data}
                                    isActive={true}
                                    order={index + 1}
                                    types={types}
                                    handleQuestion={handleQuestion}
                                    handleQuestionField={handleQuestionField}
                                />
                            )}
                            {type === TEST_STORAGE_AUDIO && (
                                <AudioComponent
                                    data={data}
                                    isActive={true}
                                    order={index + 1}
                                    types={types}
                                    handleQuestion={handleQuestion}
                                    handleQuestionField={handleQuestionField}
                                />
                            )}
                            {type === TEST_STORAGE_FILE && (
                                <FileComponent
                                    data={data}
                                    isActive={true}
                                    order={index + 1}
                                    types={types}
                                    handleQuestion={handleQuestion}
                                    handleQuestionField={handleQuestionField}
                                />
                            )}
                            {type === TEST_STORAGE_RADIO && (
                                <RadioComponent
                                    data={data}
                                    isActive={true}
                                    order={index + 1}
                                    types={types}
                                    handleQuestion={handleQuestion}
                                    handleQuestionField={handleQuestionField}
                                />
                            )}
                            {type === TEST_STORAGE_CHECKBOX && (
                                <CheckboxComponent
                                    data={data}
                                    isActive={true}
                                    order={index + 1}
                                    types={types}
                                    handleQuestion={handleQuestion}
                                    handleQuestionField={handleQuestionField}
                                />
                            )}
                            {type === TEST_STORAGE_TEXT && (
                                <TextComponent
                                    data={data}
                                    isActive={true}
                                    order={index + 1}
                                    types={types}
                                    handleQuestion={handleQuestion}
                                    handleQuestionField={handleQuestionField}
                                />
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ display: "flex", gap: "12px" }}>
                            {(data.type === TEST_STORAGE_RADIO ||
                                data.type === TEST_STORAGE_CHECKBOX) && (
                                <>
                                    <GreenSwitch
                                        checked={data.weight}
                                        onChange={(e) => {
                                            if (data.weight) {
                                                handleQuestionField(
                                                    index,
                                                    "weight",
                                                    0
                                                );
                                            } else {
                                                handleQuestionField(
                                                    index,
                                                    "weight",
                                                    5
                                                );
                                            }
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            color: "rgba(21, 24, 19, 1)",
                                        }}
                                    >
                                        Тест
                                    </Typography>
                                    <CustomToolTip
                                        title="Настройка максимальных баллов, указание ответов"
                                        placement="top"
                                    >
                                        <Box>
                                            <InformationIcon
                                                sx={{
                                                    width: 24,
                                                    height: 24,
                                                }}
                                                svgSx={{
                                                    width: 24,
                                                    height: 24,
                                                    color: "rgba(155, 162, 169, 1)",
                                                }}
                                            />
                                        </Box>
                                    </CustomToolTip>
                                </>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "4px",
                            }}
                        >
                            <BaffedButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSwitch(index, index - 1);
                                }}
                                condition={isFirst}
                            >
                                <ArrowWithStickIcon
                                    sx={{
                                        width: 46,
                                        height: 46,
                                    }}
                                    svgSx={{
                                        width: 18,
                                        height: 18,
                                        color: isFirst
                                            ? "rgba(137, 139, 141, 0.5)"
                                            : "rgba(137, 139, 141, 1)",
                                    }}
                                />
                            </BaffedButton>
                            <BaffedButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSwitch(index, index + 1);
                                }}
                                condition={isLast}
                            >
                                <ArrowWithStickIcon
                                    sx={{
                                        width: 46,
                                        height: 46,
                                        transform: "rotate(180deg)",
                                    }}
                                    svgSx={{
                                        width: 18,
                                        height: 18,
                                        color: isLast
                                            ? "rgba(137, 139, 141, 0.5)"
                                            : "rgba(137, 139, 141, 1)",
                                    }}
                                    style={{ transform: "rotate(180deg)" }}
                                />
                            </BaffedButton>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    backgroundColor: "rgba(226, 228, 231, 1)",
                                }}
                            />
                            <BaffedButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                tooltip="Сохранить в шаблоны"
                            >
                                <SaveIcon
                                    onClick={() =>
                                        handleCreateQuestionTemplate(index)
                                    }
                                    sx={{
                                        width: 46,
                                        height: 46,
                                    }}
                                    svgSx={{
                                        width: 20,
                                        height: 20,
                                        color: disable
                                            ? "rgba(137, 139, 141, 0.5)"
                                            : "rgba(137, 139, 141, 1)",
                                    }}
                                />
                            </BaffedButton>
                            <BaffedButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDuplicate(index);
                                }}
                                tooltip="Дублировать"
                            >
                                <DuplicateIcon
                                    sx={{
                                        width: 46,
                                        height: 46,
                                    }}
                                    svgSx={{
                                        width: 22,
                                        height: 22,
                                        color: disable
                                            ? "rgba(137, 139, 141, 0.5)"
                                            : "rgba(137, 139, 141, 1)",
                                    }}
                                />
                            </BaffedButton>
                            <BaffedButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(index);
                                }}
                                condition={isOnly}
                                tooltip="Удалить"
                            >
                                <TrashIcon
                                    sx={{
                                        width: 46,
                                        height: 46,
                                    }}
                                    svgSx={{
                                        width: 24,
                                        height: 24,
                                        color: isOnly
                                            ? "rgba(137, 139, 141, 0.5)"
                                            : "rgba(137, 139, 141, 1)",
                                    }}
                                />
                            </BaffedButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
);

export default QuestionWrapperActive;
