import axiosClassic from "../api/interceptors";
import { responseWrapper } from "./responseWrapper";
const APP_REDIRECT_PATH = process.env.REACT_APP_API_REDIRECT_PATH;

// get all processes
export const getProcesses = async () => {
    try{
        const response = await axiosClassic.get("/api/integrations");
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

// pass code to get process
export const getProcess = async ({name, code}) => {
    try{
        const response = await axiosClassic.get(`/api/oauth/${name}/redirect?code=${code}`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}

// delete api from processes
export const deleteProcess = async ({name}) => {
    try{
        const response = await axiosClassic.delete(`/api/integration/${name}`);
        return responseWrapper(response);
    } catch (error) {
        throw error;
    }
}
