import { Button, Dialog, DialogActions, Box, Typography } from '@mui/material';
import { CloseIcon } from '../../svgComponents';

const ShowUpdatesModal = ({ body, handleClose }) => {
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    width: "575px",
                    maxWidth: "unset",
                    maxHeight: "664px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    overflowX: "hidden",
                    overflowY: "auto",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    gap: "8px",
                    position: "relative",
                }}>
                    <Box
                        sx = {{
                            padding: "24px 24px 0 24px",
                            display: "flex",
                            gap: "8px",
                            flexDirection: "column",
                            top: 0, 
                            width: "100%",
                            position: "-webkit-sticky", 
                            position: "sticky", 
                            alignSelf: "flex-start",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <CloseIcon
                                onClick={handleClose}
                                sx={{ width: 24, height: 24, cursor: "pointer"}}
                                svgSx={{
                                    width: 22,
                                    height: 22,
                                    color: "rgba(0,0,0, 0.5)",
                                    hoverColor: "rgba(0,0,0, 0.75)",
                                    pressedColor: "rgba(0,0,0, 1)",
                                }}
                            />
                        </Box>
                        <Typography
                            variant='h2'
                            sx={{
                                fontSize: "30px",
                                lineHeight: "34px",
                                color: "rgba(0, 0, 0, 1)",
                                fontWeight: "600",
                                textAlign: "center",
                            }}
                        >
                            {body.title}
                        </Typography>
                        <Typography
                            sx={{
                                display: "block",
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "rgba(116, 116, 115, 1)",
                                textAlign: "center",
                                mb: 1,
                            }}
                        >
                            {body.subtitle}
                        </Typography>
                    </Box>
                    <Box
                        dangerouslySetInnerHTML={{ __html: body.body }}
                        sx={{
                            fontSize: "16px",
                            lineHeight: "20px",
                            width: "100%",
                            padding: "0 24px",
                            overflowX: "hidden",
                            "& ul": {
                                margin: "16px 0",
                                paddingLeft: "1.5rem", // space for the bullet points
                                listStyleType: "disc",
                            },
                            "& li": {
                                marginBottom: "0.5rem",
                            },
                        }}
                    />
            </Box>
            <DialogActions sx={{padding: "0 0 24px 0"}}>
                <Button
                    onClick={handleClose}
                    sx= {{
                        width: "100%",
                        margin: "24px 166px 0 166px",
                        height: "48px",
                        borderRadius: "12px",
                        backgroundColor: "rgba(89, 157, 21, 1)",
                        color: "#fff",
                        fontSize: "16px",
                        lineHeight: "24px",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "rgba(80, 140, 19, 1)",
                        },
                        "&:active": {
                            backgroundColor: "rgba(69, 122, 16, 1)",
                        },
                    }}
                >
                    {body.button_text}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShowUpdatesModal;