import React, { useCallback, memo, useState } from "react";
import { Box, Typography, TextField, MenuItem, Divider } from "@mui/material";
import LabeledInput from "../main/LabeledInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchCities, fetchCurrencies } from "../../services/dictionariesApi";
import { fetchDepartments } from "../../services/departmentsApi";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "../tapHrStyledComponents";
import { fetchCategories } from "../../services/categoriesApi";
import CustomAutoComplete from "../styledComponents/CustomAutoComplete";
import CustomRadioList from "../styledComponents/CustomRadioList";
import CustomEditor from "../styledComponents/CustomEditor";
import CustomAiButton from "../styledComponents/CustomAiButton";
import { generateDescription } from "../../services/aiApi";
import GenerateVacancyDescriptionModal from "../common/modalWindows/GenerateVacancyDescriptionModal";

const NameInputProps = {
    sx: { minWidth: "356px", flex: 1 },
};

const SalaryFromInputProps = {
    sx: { minWidth: "356px" },
    InputProps: {
        type: "numeric",
    },
};

const SalaryToInputProps = {
    sx: { minWidth: "356px", marginTop: "20px" },
    InputProps: {
        type: "numeric",
    },
};

const isOnlyNumbersAndSpecialChars = (str) => /^[^A-Za-z]*$/.test(str);

const startingQueryKeys = {
    city: ["cities"],
    department: ["departments"],
    category: ["categories"],
};

const Currency = memo(
    ({ currency, onChange, currencies, isCurrencyLoading }) => {
        console.log("Currency component re-rendered");
        return (
            <TextField
                select
                placeholder="₸"
                value={currency}
                onChange={onChange}
                variant="outlined"
                sx={{
                    backgroundColor: "#fff",
                    border: "1px solid rgba(226, 228, 231, 1)",
                    borderRadius: "8px",
                    width: "100%",
                    "&:hover": {
                        borderColor: "#599D15", // Hover border color
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                        height: "54px",
                        borderRadius: "8px",
                    },
                    "&:has(.Mui-focused)": {
                        border: "2px solid rgba(205, 221, 198, 1)", // Focused border color
                        "& .MuiOutlinedInput-root": {
                            height: "52px", // Height when focused
                        },
                    },
                }}
            >
                {isCurrencyLoading ? (
                    <MenuItem>Загрузка...</MenuItem>
                ) : (
                    currencies &&
                    currencies.data.map((cur) => (
                        <MenuItem key={cur.code} value={cur.code}>
                            {cur.name}
                        </MenuItem>
                    ))
                )}
            </TextField>
        );
    }
);

const ExperienceHandler = memo(({ experience, onChange }) => {
    return (
        <>
            <Typography sx={{ mt: "36px", mb: "12px", lineHeight: "20px" }}>
                Опыт работы
            </Typography>
            <Typography
                sx={{
                    mb: 2,
                    color: "rgba(173, 181, 189, 1)",
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontWidth: "400",
                }}
            >
                Если нужен опыт от нескольких месяцев до года, то выберите “Нет
                опыта”
            </Typography>

            <CustomRadioList
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                }}
                defaultValue={experience}
                inputs={[
                    { value: "noExperience", text: "Нет опыта" },
                    { value: "between1And3", text: "От 1 года до 3 лет" },
                    { value: "between3And6", text: "От 3 до 6 лет" },
                    { value: "moreThan6", text: "Более 6 лет" },
                ]}
                onClick={onChange}
            />
        </>
    );
});

const EmploymentHandler = memo(
    ({
        acceptTemporary,
        employmentType,
        onToggleTemporary,
        onChangeEmployment,
    }) => {
        return (
            <>
                <Typography sx={{ mt: "36px", mb: 2, lineHeight: "20px" }}>
                    Тип занятости
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "8px",
                        mb: "36px",
                        cursor: "pointer",
                    }}
                    onClick={onToggleTemporary}
                >
                    <CheckBox checked={acceptTemporary} onClick={() => {}} />
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#151813",
                        }}
                    >
                        Возможно временное оформление
                        <Typography
                            component="span"
                            variant="body2"
                            sx={{
                                display: "block",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "18px",
                                color: "#9BA2A9",
                            }}
                        >
                            По совместительству или по ГПХ: услуги, подряд, ИП,
                            самозанятые
                        </Typography>
                    </Typography>
                </Box>
                <CustomRadioList
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                    }}
                    isCheckBox={true}
                    defaultValue={employmentType}
                    inputs={[
                        { value: "full", text: "Полная занятость" },
                        { value: "part", text: "Частичная занятость" },
                        { value: "project", text: "Проектная работа" },
                        { value: "volunteer", text: "Волонтерство" },
                        { value: "probation", text: "Стажировка" },
                    ]}
                    onClick={onChangeEmployment}
                />
            </>
        );
    }
);

const ScheduleHandler = memo(({ workSchedule, onChangeSchedule }) => {
    return (
        <>
            <Typography sx={{ mt: "36px", mb: 2, lineHeight: "20px" }}>
                График работы
            </Typography>
            <CustomRadioList
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                }}
                isCheckBox={true}
                defaultValue={workSchedule}
                inputs={[
                    { value: "fullDay", text: "Полный день" },
                    { value: "shift", text: "Сменный график" },
                    { value: "project", text: "Гибкий график" },
                    { value: "remote", text: "Удаленная работа" },
                    { value: "flyInFlyOut", text: "Вахтовый метод" },
                ]}
                onClick={onChangeSchedule}
            />
        </>
    );
});

const FirstStep = ({ vacancyInformation, onChange, onToggle, characters }) => {
    const navigate = useNavigate();
    const [generateDescriptionModal, setGenerateDescriptionModal] = useState(false);

    const handleGenerateDescriptionModal = () => {
        if (!vacancyInformation?.name) {
            return;
        };
        setGenerateDescriptionModal(prev => !prev);
    };

    const descriptionGenerationMutation = useMutation(generateDescription, {
        onSuccess: ({data}) => {
            onChange("text", data);
        },
    })

    const handleDescriptionGeneration = (shortDescription) => {
        // Generate description logic
        if (!vacancyInformation?.name) {
            alert("Введите название вакансии");
            return;
        }
        descriptionGenerationMutation.mutate({
            position_title: vacancyInformation?.name,
            short_description: shortDescription
        });
        onChange("text", "");
        setGenerateDescriptionModal(false);
    }


    const { data: currencies, isLoading: isCurrencyLoading } = useQuery(
        ["currencies"],
        fetchCurrencies
    );

    const handleName = useCallback(
        (e) => {
            onChange("name", e.target.value);
        },
        [onChange]
    );

    const handleCity = useCallback(
        (value) => {
            onChange("city", value);
        },
        [onChange]
    );

    const handleDepartment = useCallback(
        (value) => {
            onChange("department", value);
        },
        [onChange]
    );

    const handleCategory = useCallback(
        (value) => {
            onChange("category", value);
        },
        [onChange]
    );

    const handleSalaryFrom = useCallback(
        (e) => {
            if (isOnlyNumbersAndSpecialChars(e.target.value)) {
                onChange("salaryFrom", e.target.value);
            }
        },
        [onChange]
    );

    const handleSalaryTo = useCallback(
        (e) => {
            if (isOnlyNumbersAndSpecialChars(e.target.value)) {
                onChange("salaryTo", e.target.value);
            }
        },
        [onChange]
    );

    const handleCurrency = useCallback(
        (e) => {
            onChange("currency", e.target.value);
        },
        [onChange]
    );

    const handleExperience = useCallback(
        (value) => {
            onChange("experience", value);
        },
        [onChange]
    );

    const handleWorkSchedule = useCallback(
        (value) => {
            onChange("workSchedule", value);
        },
        [onChange]
    );

    const handleEmploymentType = useCallback(
        (value) => {
            onChange("employmentType", value);
        },
        [onChange]
    );

    const handleToggleTemporary = useCallback(() => {
        onToggle("accept_temporary");
    }, [onToggle]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h5"
                    component="h1"
                    sx={{ fontWeight: 500, fontSize: 20, lineHeight: "24px" }}
                >
                    Основная информация
                </Typography>
                <Box
                    component="button"
                    onClick={() => navigate("/")}
                    variant="outlined"
                    sx={{
                        color: "rgba(255, 59, 48, 1)",
                        border: "1px solid transparent",
                        backgroundColor: "transparent",
                        padding: "12px 20px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: "400",
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "rgba(255, 243, 242, 1)",
                        },
                        "&:active": {
                            color: "rgba(198, 46, 37, 1)",
                        },
                    }}
                >
                    Отменить
                </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 4 }} />

            <Box sx={{ display: "flex", gap: 2, width: "100%", mb: "36px" }}>
                <LabeledInput
                    label="Название"
                    value={vacancyInformation?.name}
                    onChange={handleName}
                    placeholder="Введите название вакансии"
                    sx={NameInputProps.sx}
                    required
                />
                <Box sx={{ minWidth: "356px", flex: 1 }}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#151813",
                            mb: 1,
                        }}
                    >
                        Город <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomAutoComplete
                        value={vacancyInformation?.city}
                        setValue={handleCity}
                        queryKeyStartingValue={startingQueryKeys.city}
                        fetchFunction={fetchCities}
                    />
                </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 2, mb: "36px" }}>
                <Box sx={{ minWidth: "356px", flex: 1 }}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#151813",
                            mb: 1,
                        }}
                    >
                        Отдел <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomAutoComplete
                        value={vacancyInformation?.department}
                        setValue={handleDepartment}
                        queryKeyStartingValue={startingQueryKeys.department}
                        fetchFunction={fetchDepartments}
                    />
                </Box>
                <Box sx={{ minWidth: "356px", flex: 1 }}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#151813",
                            mb: 1,
                        }}
                    >
                        Категория <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CustomAutoComplete
                        value={vacancyInformation?.category}
                        setValue={handleCategory}
                        queryKeyStartingValue={startingQueryKeys.category}
                        fetchFunction={fetchCategories}
                        additional={{ is_theme: 0}}
                    />
                </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 2, mb: 2, height: "84px" }}>
                <LabeledInput
                    label="Заработная плата"
                    InputProps={SalaryFromInputProps.InputProps}
                    value={vacancyInformation?.salaryFrom}
                    onChange={handleSalaryFrom}
                    placeholder="от"
                    sx={SalaryFromInputProps.sx}
                />
                <LabeledInput
                    InputProps={SalaryToInputProps.InputProps}
                    value={vacancyInformation?.salaryTo}
                    onChange={handleSalaryTo}
                    placeholder="до"
                    sx={SalaryToInputProps.sx}
                />
                <Box sx={{ width: "94px" }}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#151813",
                            mb: 1,
                        }}
                    >
                        Валюта
                    </Typography>
                    <Currency
                        currency={vacancyInformation?.currency || "KZT"}
                        onChange={handleCurrency}
                        currencies={currencies}
                        isCurrencyLoading={isCurrencyLoading}
                    />
                </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mt: 0 }}>
                <CustomRadioList
                    sx={{ display: "flex", gap: "16px" }}
                    isHor={true}
                    defaultValue={vacancyInformation?.taxType}
                    inputs={[
                        { value: true, text: "До вычета налогов" },
                        { value: false, text: "На руки" },
                    ]}
                    onClick={(value) => onChange("taxType", value)}
                />
            </Box>

            <Box sx={{ marginTop: "36px" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Typography sx={{ mb: "12px" }}>Описание</Typography>
                        <p
                            style={{
                                color: "#ADB5BD",
                                fontSize: "14px",
                                lineHeight: "18px",
                                fontWeight: 400,
                                marginBottom: "16px",
                            }}
                        >
                            Описание вакансии не может быть менее 150 символов
                        </p>
                    </Box>
                    <CustomAiButton text="Сгенерировать описание" onClick={handleGenerateDescriptionModal} disabled={!vacancyInformation?.name}/>
                </Box>
                <CustomEditor
                    value={vacancyInformation?.text}
                    readOnly={descriptionGenerationMutation.isLoading}
                    onChange={(value) => onChange("text", value)}
                    formats={["bold", "italic", "underline", "align", "list"]}
                    slotProps={{
                        fullEditor: {
                            height: "412px",
                        },
                    }}
                    className="vacancy-create-ai-generation"
                />
                <Typography
                    sx={{
                        mt: "6px",
                        color: "rgba(116, 116, 115, 1)",
                    }}
                >
                    Количество символов :{" "}
                    <span
                        style={{ color: characters >= 150 ? "#599D15" : "red" }}
                    >
                        {characters}
                    </span>
                </Typography>
            </Box>

            <ExperienceHandler
                experience={vacancyInformation?.experience}
                onChange={handleExperience}
            />

            {/* New EmploymentHandler with useCallback-wrapped functions */}
            <EmploymentHandler
                acceptTemporary={vacancyInformation?.accept_temporary}
                employmentType={vacancyInformation?.employmentType}
                onToggleTemporary={handleToggleTemporary}
                onChangeEmployment={handleEmploymentType}
            />

            {/* New ScheduleHandler with useCallback-wrapped function */}
            <ScheduleHandler
                workSchedule={vacancyInformation?.workSchedule}
                onChangeSchedule={handleWorkSchedule}
            />

            {generateDescriptionModal && (
                <GenerateVacancyDescriptionModal
                    title="Сгенерируйте вакансию с помощью AI"
                    subtitle="Подсказка"
                    handleClose={handleGenerateDescriptionModal}
                    handleGenerate={handleDescriptionGeneration}
                />
            )}
        </>
    );
};

export default FirstStep;
