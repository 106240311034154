import { Box, TextField } from "@mui/material";
import { ImageIcon, CloseIcon } from "../../svgComponents";
import { CheckBox, CustomToolTip } from "../../tapHrStyledComponents";
import CustomRadio from "../../styledComponents/CustomRadio";
import ImageWrapper from "./ImageWrapper";
import { memo, useCallback } from "react";

const Option = memo(
    ({
        isRadio,
        handleChange,
        option,
        isNotLast,
        index,
        deleteImage,
        deleteOption,
        handleUpload,
    }) => {
        const uniqueId = crypto.randomUUID();
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                    px: 1,
                }}
            >
                {isRadio ? (
                    <CustomRadio
                        checked={option.is_correct}
                        onClick={() =>
                            handleChange(
                                index,
                                "is_correct",
                                !option.is_correct 
                            )
                        }
                    />
                ) : (
                    <CheckBox
                        checked={option.is_correct}
                        onClick={() =>
                            handleChange(
                                index,
                                "is_correct",
                                !option.is_correct
                            )
                        }
                    />
                )}
                {option.image_path && (
                    <Box sx={{ ml: "6px" }}>
                        <ImageWrapper
                            imgKey={option.image_path}
                            imageWidth={136}
                            imageHeight={89}
                            imageFit="cover"
                            imageRadius={4}
                            index={index}
                            onClick={deleteImage}
                        />
                    </Box>
                )}
                <TextField
                    multiline
                    value={option.text}
                    onChange={(e) =>
                        handleChange(index, "text", e.target.value)
                    }
                    placeholder="Введите текст"
                    variant="outlined"
                    size="small"
                    sx={{
                        ml: "6px",
                        width: 344,
                        "& fieldset": {
                            border: "none",
                        },
                        "& .MuiInputBase-formControl": {
                            padding: 0,
                        },
                        "& .MuiInputBase-input": {
                            padding: "0 0 8px 0",
                            borderBottom: "1px solid rgba(226, 228, 231, 1)",
                        },
                    }}
                />
                <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                    <CustomToolTip
                        title={"Загрузить изображение"}
                        placement="top"
                    >
                        <Box
                            sx={{
                                position: "relative",
                                cursor: "pointer",
                                width: 20,
                                height: 20,
                                "&:hover path": {
                                    stroke: "rgba(21, 24, 19, 0.75)",
                                },
                                "&:active path": {
                                    stroke: "rgba(21, 24, 19, 1)",
                                },
                            }}
                        >
                            <ImageIcon
                                sx={{ width: 20, height: 20 }}
                                svgSx={{
                                    width: 12,
                                    height: 12,
                                    color: "rgba(21, 24, 19, 0.5)",
                                    cursor: "pointer",
                                }}
                            />
                            <input
                                id={uniqueId}
                                accept=".png, .jpg, .jpeg"
                                type="file"
                                onChange={(e) => handleUpload(e, index)}
                                style={{
                                    display: "none",
                                }}
                            />
                            <label
                                htmlFor={uniqueId}
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0,
                                    top: 0,
                                    left: 0,
                                    cursor: "pointer",
                                }}
                            />
                        </Box>
                    </CustomToolTip>
                    <CustomToolTip title={"Удалить ответ"} placement="top">
                        <Box
                            onClick={() => {
                                if (isNotLast) {
                                    deleteOption(index);
                                }
                            }}
                        >
                            <CloseIcon
                                sx={{ width: 20, height: 20 }}
                                svgSx={{
                                    width: 10,
                                    height: 10,
                                    color: "rgba(21, 24, 19, 0.5)",
                                    hoverColor: isNotLast
                                        ? "rgba(21, 24, 19, 0.5)"
                                        : "rgba(21, 24, 19, 0.75)",
                                    pressedColor: isNotLast
                                        ? "rgba(21, 24, 19, 0.5)"
                                        : "rgba(21, 24, 19, 1)",
                                }}
                            />
                        </Box>
                    </CustomToolTip>
                </Box>
            </Box>
        );
    }
);

const OptionsInputList = memo(({ options, isRadio, updateOptions }) => {
    const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxFileSize = 2097152; // 2 MB in bytes

    const handleChange = useCallback(
        (index, field, value) => {
            const newOptions = [...options];
            if (field === "is_correct" && isRadio) {
                newOptions.forEach((el) => (el.is_correct = false));
            }
            newOptions[index][field] = value;
            updateOptions(newOptions);
        },
        [options, isRadio, updateOptions]
    );

    const addOption = () => {
        updateOptions([
            ...options,
            {
                text: `Вопрос ${options.length + 1}`,
                image_path: null,
                is_correct: false,
            },
        ]);
    };

    // create updated options where element at given index is removed
    const deleteOption = (index) => {
        const newOptions = options.filter((_, i) => i !== index);
        updateOptions(newOptions);
    };

    const deleteImage = useCallback(
        (index) => {
            handleChange(index, "image_path", null);
        },
        [handleChange]
    );

    const handleUpload = (e, index) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        if (!allowedImageTypes.includes(file.type)) {
            alert("Недопустимый формат файла");
            return;
        }

        if (file.size > maxFileSize) {
            alert("Файл слишком большой");
            return;
        }

        const newOptions = [...options];
        newOptions[index].image_path = file;
        updateOptions(newOptions);
        e.target.value = "";
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
            }}
        >
            {options.map((option, index) => (
                <Option
                    key={index}
                    isRadio={isRadio}
                    handleChange={handleChange}
                    option={option}
                    isNotLast={options.length > 1}
                    index={index}
                    deleteImage={deleteImage}
                    deleteOption={deleteOption}
                    handleUpload={handleUpload}
                />
            ))}
            <Box
                sx={{
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    gap: 1,
                    px: 1,
                    "&:hover div": {
                        color: "rgb(117, 117, 117)!important",
                    },
                }}
                onClick={addOption}
            >
                {isRadio ? <CustomRadio /> : <CheckBox />}
                <Box
                    sx={{
                        pb: "4px",
                        borderBottom: "1px solid rgba(226, 228, 231, 1)",
                        color: "rgba(173, 181, 189, 1)",
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    Добавить пункт
                </Box>
            </Box>
        </Box>
    );
});

export default OptionsInputList;
